.results-Result {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: rem(21);
  text-align: center;
  border-bottom: 1px solid $color-gray-plus2;

  @include mq-up(md) {
    padding-bottom: rem(10);
    text-align: left;
  }
}

// 検索結果数
.results-Result_Hits {
  width: 100%;
  margin-bottom: 1em;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.5;
  color: $color-gray-minus2;

  @include mq-up(md) {
    width: auto;
    margin-right: auto;
    margin-bottom: 0;
    padding-right: rem(40);
  }
}

.results-Result_Hits > strong {
  font-size: rem(16);
  line-height: 1.3125;
}

// 表示件数
.results-Result_PerPage {
  display: none;

  @include mq-up(md) {
    display: block;
    padding-right: rem(40);
    font-size: rem(14);
    line-height: 1.3;
  }
}

.results-Result_PerPage span {
  color: #999;
}

.results-Result_PerPage a {
  text-decoration: none;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

// 並び順
.results-Result_Sort {
  width: 100%;
  font-size: rem(13);
  line-height: 1.4;
  color: #999;

  @include mq-up(md) {
    width: auto;
  }
}

.results-Result_Sort a {
  text-decoration: none;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.results-Result_Sort [id="sortSelected"] {
}
