/*
---
name: sw-ListUnorder
namespace: sw-
category: List
id: LIST-1
---

<span class="sw-Label">LIST-1</span>

順序をもたない並列なリストです。

```jade
ul.sw-ListUnorder
  li Unordered nested list level 1
  li Unordered nested list level 1
    ul.sw-ListUnorder_Child
      li Unordered nested list level 1 Unordered nested list level 1 Unordered nested list level 1
      li Unordered nested list level 1 Unordered nested list level 1 Unordered nested list level 1
  li Unordered nested list level 1 Unordered nested list level 1 Unordered nested list level 1
```
*/
.sw-ListUnorder {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  margin-left: rem(20);
  padding-left: 0;
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;
  list-style-type: none;

  & > li {
    position: relative;
    margin-bottom: rem(5);
  }

  & > li::before {
    content: "";
    display: block;
    position: absolute;
    top: rem(9);
    left: rem(-15);
    width: rem(5);
    height: rem(5);
    border: 2.5px solid #fc9898;
    border-radius: 50%;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.sw-ListUnorder_Child {
  margin-top: rem(10);
  padding-left: 0;
  list-style-type: none;

  & > li {
    position: relative;
    margin-bottom: rem(5);
    margin-left: rem(16);
  }

  & > li::before {
    content: "";
    display: block;
    position: absolute;
    top: rem(10);
    left: rem(-16);
    width: rem(8);
    margin: auto;
    border-top: 1px solid $color-gray;
  }
}
