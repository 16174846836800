/*
---
name: st-GlobalNav
namespace: sw-
category: Navigation
---

ホームページとカテゴリトップページで使用するナビゲーションです。


```jade
```
*/
.st-GlobalNav {
}
