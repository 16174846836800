.home-ProductsList {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-5);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-left: rem(-6);
  }
}

.home-ProductsList_Item {
  flex-basis: 50%;
  max-width: 50%;
  padding-left: rem(5);

  @include mq-up(md) {
    padding-left: rem(6);
  }

  .home-Products_GridItem:nth-of-type(1) & {
    @include mq-up(md) {
      flex-basis: percentage(1 / 3);
      max-width: percentage(1 / 3);
    }

    @include mq-up(lg) {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: rem(5);
    }

    &:nth-of-type(n + 1) {
      @include mq-up(lg) {
        margin-top: 0;
      }
    }

    &:nth-of-type(n + 2) {
      @include mq-up(lg) {
        margin-top: rem(12);
      }
    }

    &:nth-of-type(n + 3) {
      margin-top: rem(5);

      @include mq-up(md) {
        margin-top: 0;
      }

      @include mq-up(lg) {
        margin-top: rem(12);
      }
    }
  }

  .home-Products_GridItem:nth-of-type(2) & {
    @include mq-up(md) {
      flex-basis: percentage(1 / 3);
      max-width: percentage(1 / 3);
    }

    &:nth-of-type(n + 3) {
      margin-top: rem(5);

      @include mq-up(md) {
        margin-top: 0;
      }
    }

    &:nth-of-type(n + 4) {
      @include mq-up(md) {
        margin-top: rem(12);
      }
    }
  }
}

.home-ProductsList_Link {
  display: block;
  height: 100%;
  text-align: center;
  text-decoration: none;
  background-image: linear-gradient(-180deg, #f1f4f6 0%, #dde1e5 100%);
  transition-duration: $transition-duration;

  @include mq-up(md) {
    background: #f2f4f6;
  }

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.home-ProductsList_Head {
  @include mq-up(md) {
    background-image: linear-gradient(-180deg, #f1f4f6 0%, #dde1e5 100%);
  }
}

.home-ProductsList_Thumbnail {
}

.home-ProductsList_Body {
  padding: rem(14) rem(10);
  text-align: left;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.28;
  color: #000;

  @include mq-up(md) {
    padding: rem(20) rem(15);
    font-size: rem(15);
    line-height: 1.38;
    background: #f2f4f6;
  }

  @include mq-up(lg) {
    padding: rem(20) rem(10);
  }
}

.home-ProductsList_Icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: rem(-1);
  height: rem(16);
  font-size: rem(16);

  @include mq-up(md) {
    top: 0;
    height: rem(10);
    font-size: rem(10);
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.2em;
    left: rem(4);
    color: #df0523;
    transform: scale(0.5);
    transform-origin: center left;

    @include mq-up(md) {
      left: rem(2);
      transform: scale(1);
      transform-origin: inherit;
    }
  }
}

.home-ProductsList_ExternalIcon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: rem(10);
  font-size: rem(10);

  @include mq-up(md) {
    height: rem(12);
    font-size: rem(12);
  }

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.3em;
    left: 5px;
    color: #df0523;
  }
}
