/*
---
name: l-ButtonC
namespace: l-
category: Layout
id: BTN-7A
---

全デバイスでなりゆきです。

<span class="sw-Label">BTN-7A</span>

```jade
ul.l-ButtonC
  li.l-ButtonC_Item
    a.sw-Button(href="#") More
  li.l-ButtonC_Item
    a.sw-Button(href="#") More Details
  li.l-ButtonC_Item
    a.sw-Button(href="#") Button with Long Name
```

センター揃えにします。

- `.l-ButtonC-center`
- `.l-ButtonC-centerSm`
- `.l-ButtonC-centerMd`
- `.l-ButtonC-centerLg`
- `.l-ButtonC-centerXl`

```jade
ul.l-ButtonC.l-ButtonC-center
  li.l-ButtonC_Item
    a.sw-Button(href="#") More
  li.l-ButtonC_Item
    a.sw-Button(href="#") More Details
  li.l-ButtonC_Item
    a.sw-Button(href="#") Button with Long Name
```

右揃えにします。

- `.l-ButtonC-right`
- `.l-ButtonC-rightSm`
- `.l-ButtonC-rightMd`
- `.l-ButtonC-rightLg`
- `.l-ButtonC-rightXl`

```jade
ul.l-ButtonC.l-ButtonC-right
  li.l-ButtonC_Item
    a.sw-Button(href="#") More
  li.l-ButtonC_Item
    a.sw-Button(href="#") More Details
  li.l-ButtonC_Item
    a.sw-Button(href="#") Button with Long Name
```
*/
.l-ButtonC {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-20);
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  list-style-type: none;

  & > :last-child {
    margin-bottom: 0;
  }
}

.l-ButtonC_Item {
  flex-grow: 0;
  margin-top: rem(20);
  margin-right: rem(10);
}

.l-ButtonC-center {
  justify-content: center;
}

@include mq-up(sm) {
  .l-ButtonC-centerSm {
    justify-content: center;
  }
}

@include mq-up(md) {
  .l-ButtonC-centerMd {
    justify-content: center;
  }
}

@include mq-up(lg) {
  .l-ButtonC-centerLg {
    justify-content: center;
  }
}

@include mq-up(xl) {
  .l-ButtonC-centerXl {
    justify-content: center;
  }
}

.l-ButtonC-right {
  justify-content: flex-end;
}

@include mq-up(sm) {
  .l-ButtonC-rightSm {
    justify-content: flex-end;
  }
}

@include mq-up(md) {
  .l-ButtonC-rightMd {
    justify-content: flex-end;
  }
}

@include mq-up(lg) {
  .l-ButtonC-rightLg {
    justify-content: flex-end;
  }
}

@include mq-up(xl) {
  .l-ButtonC-rightXl {
    justify-content: flex-end;
  }
}
