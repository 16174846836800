.result-NoResult {
  padding-top: rem(20);
  padding-bottom: rem(20);

  @include mq-up(md) {
    padding-top: rem(50);
    padding-bottom: rem(50);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
