 /* #Icon
   -------------------------------------------------------------------------- */
@font-face {
  font-family: "iconfont";
  src: url('../font/iconfont.eot');
  src: url('../font/iconfont.eot?#iefix') format('eot'),
    url('../font/iconfont.woff') format('woff'),
    url('../font/iconfont.ttf') format('truetype'),
    url('../font/iconfont.svg#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

// アイコンフォントのベーススタイルです。
@mixin icon {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  speak: none;
}

// アイコンフォントを`content: "";`で表示できるように変数化しています。
// インターポレーション（`#{}`）の中に入れる必要があります。
// example: content: "#{$icon-LinkMore}";
$icon-arrow_drop_down: "\EA01" !default;
$icon-arrow_left: "\EA02" !default;
$icon-arrow_right: "\EA03" !default;
$icon-calender: "\EA04" !default;
$icon-chevron-down: "\EA05" !default;
$icon-chevron-left: "\EA06" !default;
$icon-chevron-right: "\EA07" !default;
$icon-chevron-up: "\EA08" !default;
$icon-circle_arrow: "\EA09" !default;
$icon-close: "\EA0A" !default;
$icon-cloud-download: "\EA0B" !default;
$icon-external-link: "\EA0C" !default;
$icon-filter_none: "\EA0D" !default;
$icon-fullscreen: "\EA0E" !default;
$icon-fullscreen_exit: "\EA0F" !default;
$icon-instagram: "\EA10" !default;
$icon-link: "\EA11" !default;
$icon-mail: "\EA12" !default;
$icon-mail2: "\EA13" !default;
$icon-pause: "\EA14" !default;
$icon-pdf: "\EA15" !default;
$icon-pin: "\EA16" !default;
$icon-play_arrow: "\EA17" !default;
$icon-search: "\EA18" !default;
$icon-slide_arrow_left: "\EA19" !default;
$icon-slide_arrow_right: "\EA1A" !default;
$icon-slider-bar: "\EA1B" !default;
$icon-sphere: "\EA1C" !default;
$icon-triangle: "\EA1D" !default;
$icon-volume_down: "\EA1E" !default;
$icon-volume_mute: "\EA1F" !default;
$icon-volume_off: "\EA20" !default;
$icon-volume_up: "\EA21" !default;


/* 汎用的なアイコンフォントです。 */
.sw-Icon { display: inline-block; }
.sw-Icon:before { @include icon; }

.sw-Icon-arrow_drop_down:before { content: "\EA01"; }
.sw-Icon-arrow_left:before { content: "\EA02"; }
.sw-Icon-arrow_right:before { content: "\EA03"; }
.sw-Icon-calender:before { content: "\EA04"; }
.sw-Icon-chevron-down:before { content: "\EA05"; }
.sw-Icon-chevron-left:before { content: "\EA06"; }
.sw-Icon-chevron-right:before { content: "\EA07"; }
.sw-Icon-chevron-up:before { content: "\EA08"; }
.sw-Icon-circle_arrow:before { content: "\EA09"; }
.sw-Icon-close:before { content: "\EA0A"; }
.sw-Icon-cloud-download:before { content: "\EA0B"; }
.sw-Icon-external-link:before { content: "\EA0C"; }
.sw-Icon-filter_none:before { content: "\EA0D"; }
.sw-Icon-fullscreen:before { content: "\EA0E"; }
.sw-Icon-fullscreen_exit:before { content: "\EA0F"; }
.sw-Icon-instagram:before { content: "\EA10"; }
.sw-Icon-link:before { content: "\EA11"; }
.sw-Icon-mail:before { content: "\EA12"; }
.sw-Icon-mail2:before { content: "\EA13"; }
.sw-Icon-pause:before { content: "\EA14"; }
.sw-Icon-pdf:before { content: "\EA15"; }
.sw-Icon-pin:before { content: "\EA16"; }
.sw-Icon-play_arrow:before { content: "\EA17"; }
.sw-Icon-search:before { content: "\EA18"; }
.sw-Icon-slide_arrow_left:before { content: "\EA19"; }
.sw-Icon-slide_arrow_right:before { content: "\EA1A"; }
.sw-Icon-slider-bar:before { content: "\EA1B"; }
.sw-Icon-sphere:before { content: "\EA1C"; }
.sw-Icon-triangle:before { content: "\EA1D"; }
.sw-Icon-volume_down:before { content: "\EA1E"; }
.sw-Icon-volume_mute:before { content: "\EA1F"; }
.sw-Icon-volume_off:before { content: "\EA20"; }
.sw-Icon-volume_up:before { content: "\EA21"; }
