/*
---
name: sw-TableA
namespace: .sw-
category: List
id: [TBL-1, TBL-2, TBL-3, TBL-4]
---

<span class="sw-Label">TBL-1</span>

ベーシックなテーブルです。横幅は100%で、`th`と`td`の幅はなりゆきです。

```jade
.sw-TableA.sw-TableA-typeA
  table
    caption Tabletitle
    tbody
      tr
        th Applicable wire
        td Copper: AVSS0.5, CAVS0.5, CIVUS0.5<br>Aluminum: ALVUS0.75, ALVUS0.5, ALVSSH0.5
      tr
        th Rated current
        td Copper: AC/DC 9A/Contact (Copper Wire 0.5mm2, Aluminum Wire 0.75 mm2)<br>AC/DC 8A/Contact (Aluminum Wire 0.5 mm2)
      tr
        th Rated voltage
        td AC/DC 125V Contact
      tr
        th D.W. Voltage
        td AC 1,000Vrms/min.
      tr
        th Operating temperature range
        td -40 to +85 °C (185 °F)
  p.sw-TableA_Note
    em The values are typical material properties and may vary according to product configuration and manufacturing process. ** temperature dependency graph see above
```

<span class="sw-Label">TBL-2</span>

- `thead`あり

```jade
.sw-TableA.sw-TableA-typeA
  table
    thead
      tr
        th Product
        th Blade length
        th.sw-TableA_Emphasis Product name
        th Unit Price
    tbody
      tr
        td Cooking knive
        td 18㎝
        td.sw-TableA_Emphasis FK-110WH-BK
        td 39,00€
      tr
        td Santoku Knive
        td 16㎝
        td.sw-TableA_Emphasis FK-110WH-BK
        td 39,00€
      tr
        td Santoku Knive
        td 14㎝
        td.sw-TableA_Emphasis FK-110WH-BK
        td 39,00€
      tr
        td Universal Knivee
        td 13㎝
        td.sw-TableA_Emphasis FK-110WH-BK
        td 39,00€
      tr
        td Fluit and vegetable Knivee
        td 11㎝
        td.sw-TableA_Emphasis FK-110WH-BK
        td 39,00€
      tr
        td Shin gift set, comprised of ZK-160BK-BK and ZK-110BK-BK
        td ----
        td.sw-TableA_Emphasis FK-110WH-BK
        td 39,00€
  p.sw-TableA_Note
    em The values are typical material properties and may vary according to product configuration and manufacturing process. ** temperature dependency graph see above
```

<span class="sw-Label">TBL-3</span>

- `.sw-TableA_Scroll`でラップすると横スクロールになります。

```jade
.sw-TableA.sw-TableA-typeB
  .sw-TableA-scroll
    table
      thead
        tr
          th Size
          th Resolution
          th Part Number
          th.IF
          th Outline Dimensions
          th.sw-TableA_Right Brightness<br>(cd/m²)
          th.sw-TableA_Right Viewing Angle<br>(U/D/L/R)
          th.sw-TableA_Right Lifetime<br>(hrs)
      tbody
        tr
          th(rowspan="4") 3.5"
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 1,200
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 60,000
        tr
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 400
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 60,000
        tr
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 1,000
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 50,000
        tr
          td QVGA
          td TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 400
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 60,000
        tr
          th(rowspan="2") 4.3"
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 1,200
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 100,000
        tr
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 400
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 100,000
        tr
          th(rowspan="6") 5.7"
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 1,200
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 60,000
        tr
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 400
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 60,000
        tr
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 400
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 60,000
        tr
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 1,000
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 50,000
        tr
          td QVGA
          td TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 400
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 60,000
        tr
          td QVGA
          td: a(href="#") TG035QVLQAANN-GN00
          td CMOS+SPI
          td 76.9×63.9×4.9
          td.sw-TableA_Right 1,000
          td.sw-TableA_Right 70/60/80/80
          td.sw-TableA_Right 50,000
  p.sw-TableA_Note
    em The values are typical material properties and may vary according to product configuration and manufacturing process. ** temperature dependency graph see above
```

<span class="sw-Label">TBL-4</span>

```jade
.sw-TableA.sw-TableA-typeC
  table
    tbody
      tr
        th Managing Directors
        td Shoichi Aoki <br>Masafumi Ikeuchi<br> Ken Ishii<br> Koichi Kano <br>Shigeru Koyama<br> Yusuke Mizukami <br>Rafael Schroeer <br>Hideo Tanimoto<br> Goro Yamaguchi
      tr
        th Registered Office
        td Amtsgericht Stuttgart, Germany
      tr
        th Registration Number
        td HRB 210 334
      tr
        th Register Court
        td Esslingen am Neckar
      tr
        th VAT Number
        td DE 145350234
```
*/
.sw-TableA {
  margin-top: rem(20);
  margin-bottom: rem(20);
}

.sw-TableA table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  background-color: $color-white;
}

.sw-TableA caption {
  margin-bottom: rem(20);
  text-align: left;
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.3;
  color: $color-black;
}

.sw-TableA th,
.sw-TableA td {
  vertical-align: top;
  padding: rem(15) rem(10);
  text-align: left;
  font-size: rem(14);
  border: 1px solid $color-gray-plus2;
  border-right-width: 0;
  border-left-width: 0;
  background-color: transparent;
  color: $color-gray-minus2;

  @include mq-up(md) {
    padding: rem(15) rem(20);
  }
}

.sw-TableA th {
  font-weight: 600;
  line-height: 1.3;
}

.sw-TableA td {
  line-height: 1.4;
}

.sw-TableA a {
  text-decoration: underline;
  color: $color-red;

  &:hover {
    text-decoration: none;
  }
}

.sw-TableA_Emphasis {
  color: $color-red !important;
}

.sw-TableA_Note {
  margin-top: rem(20);

  @include mq-up(md) {
    margin-top: rem(24);
  }

  & > * {
    font-size: rem(14);
    font-style: italic;
    line-height: 1.3;
  }

  & + & {
    margin-top: rem(10);
  }
}

.sw-TableA .sw-TableA_Center {
  text-align: center;
}

.sw-TableA .sw-TableA_Right {
  text-align: right;
}

.sw-TableA-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  & th,
  & td {
    white-space: nowrap;
  }
}

.sw-TableA-typeA {
  thead th,
  thead td {
    padding: rem(15) rem(10);
    border-bottom-width: 2px;

    @include mq-up(md) {
      padding: rem(15) rem(20);
    }
  }

  tbody tr {
    &:nth-of-type(odd) {
      background-color: $color-gray-plus4;
    }

    &:nth-of-type(even) {
      background-color: transparent;
    }
  }
}

.sw-TableA-typeB {
  thead tr {
    background-color: $color-gray-plus4;
  }

  thead th,
  thead td {
    padding: rem(12) rem(15);
    font-size: rem(13);
    font-weight: 600;
    line-height: 1.3;
  }

  tbody tr {
    &:nth-of-type(odd) {
      background-color: $color-gray-plus4;
    }

    &:nth-of-type(even) {
      background-color: transparent;
    }
  }

  tbody th {
    background-color: $color-white;
  }
}

.sw-TableA-typeC {
  th,
  td {
    padding: rem(15);
    border-right-width: 1px;
    border-left-width: 1px;

    @include mq-up(md) {
      padding: rem(20);
    }
  }

  th {
    font-size: rem(14);
    font-weight: 700;
    line-height: 1.3;

    @include mq-up(md) {
      font-size: rem(16);
    }
  }

  td {
    font-size: rem(16);
    line-height: 1.6;
  }
}

.sw-TableA_Hint {
  margin-bottom: rem(15);
  text-align: center;

  @include mq-up(md) {
    margin-bottom: rem(20);
  }
}

.sw-TableA_HintSp,
.sw-TableA_HintPc {
  font-size: rem(14);
  line-height: 1.5;
  color: $color-gray-minus1;
}

.sw-TableA_IconLeft {
  margin-right: rem(10);
}

.sw-TableA_IconRight {
  margin-left: rem(10);
}
