/*
---
name: sw-Strong
namespace: sw-
category: Text
id: TXT-8
---

<span class="sw-Label">TXT-8</span>

`<strong>`で使われるスタイルです。重要性や緊急性・深刻性があることを示します。

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from
  strong.sw-Strong  the vehicle’s main wiring harness.
```
*/
.sw-Strong {
  font-weight: 700;
}

/* CMS対応 */
strong {
  .news-Body & {
    font-weight: 700;
  }

  .l-DetailTemplate & {
    font-weight: 700;
  }
}
