/*
---
name: news-Header
namespace: news-
category: Block
id: NT-1
---

<span class="sw-Label">NT-1</span>

News詳細のタイトル部分です。


```jade
header.st-TitleC.news-Header
  h1.news-Header_Title
    | KYOCERA introduces reliable, Waterproof Branch Connector for harsh conditions of automotive electronics
  p.news-Header_Lead
    | Sheltap&reg; clamshell-style connector is designed to withstand water-pressure and extreme temperature; helps streamline auto assembly workflow
  .news-Header_Meta
    .news-Header_PostDate
      | 26 October 2018
    ul.news-Header_Categories
      li.news-Header_Category Semiconductor Components
```
*/

.news-Header {
  margin-bottom: rem(30);
  padding-bottom: rem(27);
  border-bottom: 1px solid $color-gray-plus3;

  @include mq-up(md) {
    padding-bottom: rem(31);
  }
}

.news-Header_Title {
  font-size: rem(30);
  font-weight: 300;
  line-height: 1.3;
  color: #000;

  @include mq-up(md) {
    font-size: rem(36);
    line-height: 1.2;
  }
}

.news-Header_Lead {
  margin: rem(15) 0;
  font-size: rem(20);
  font-weight: 300;
  line-height: 1.3;
  color: $color-gray-minus1;

  @include mq-up(md) {
    margin: rem(14) 0 rem(25);
    font-size: rem(24);
    line-height: 1.4;
  }
}

.news-Header_Meta {
  @include mq-up(md) {
    display: flex;
    align-items: flex-start;
    margin-bottom: rem(10);
  }
}

.news-Header_PostDate {
  display: block;
  white-space: nowrap;
  font-size: rem(14);
  line-height: 1.114285714;
  color: $color-red;

  @include mq-up(md) {
    display: inline-block;
    line-height: 1.3;

    &::after {
      content: "";
      width: 0;
      height: rem(20);
      margin: 0 rem(12);
      border-left: 1px solid $color-gray-plus2;
    }
  }
}

.news-Header_Categories {
  display: block;
  padding: rem(3) 0 0 0;
  list-style: none;

  @include mq-up(md) {
    display: inline-block;
    margin-top: rem(-5);
    padding: 0;
  }
}

.news-Header_Category {
  display: inline-block;
  font-size: rem(13);
  font-weight: 600;
  line-height: 1.2;
  color: #444;

  &::after {
    content: "/";
    vertical-align: middle;
    margin-left: rem(4);
    font-size: rem(19);
    font-weight: 300;
    line-height: 1;
    color: $color-gray-plus2;
  }

  &:last-child::after {
    content: none;
  }
}
