/*
---
name: st-SideNavNews
namespace: st-
category: Navigation
---

News下層ページのサイドバーにあるナビゲーションです。

- スマホでは非表示になり、`.st-CategoryNavNews`が代わりに表示されます
- 表示中ページがカレント表示されます

```jade
ul.st-SideNavNews
  li.st-SideNavNews_Item
    a.st-SideNavNews_Link(href="/news/" aria-current="location")
      | Latest News
      span.st-SideNavNews_Icon(aria-hidden="true")
    ul.st-SideNavNews_Parent
      li.st-SideNavNews_ParentItem
        a.st-SideNavNews_ParentLink(href="/news/2019/") 2019
      li.st-SideNavNews_ParentItem
        a.st-SideNavNews_ParentLink(href="/news/2018/") 2018
  li.st-SideNavNews_Item
    span.st-SideNavNews_Text Category
    ul.st-SideNavNews_Parent
      each str in ['Printers / Multifunctionals', 'Fine Ceramic Components', 'Semiconductor Components', 'Automotive Components', 'Cutting Tools', 'Electronic Components & Devices', 'Printing Devices', 'LCDs and Touch Solutions', 'Organic Materials', 'Solar Electric Systems', 'Watch and Jewelry Industry', 'Kitchen Products', 'Office Products']
        li.st-SideNavNews_ParentItem
          a.st-SideNavNews_ParentLink(href='"/news/" + str')= str
```
*/
.st-SideNavNews {
  padding-left: 0;
  border-top: 1px dotted #979797;
  list-style-type: none;
}

.st-SideNavNews_Item {
  padding-bottom: rem(14);
  border-bottom: 1px dotted #979797;
}

.st-SideNavNews_Text {
  display: block;
  position: relative;
  padding: rem(12) rem(10);
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.6;
  color: #000;
}

.st-SideNavNews_Link {
  display: block;
  position: relative;
  padding: rem(12) rem(10);
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.6;
  color: #000;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    color: $color-red;
  }

  &[aria-current="location"] {
    background-color: #f2f4f6;
    color: $color-red;
  }
}

.st-SideNavNews_Icon {
  position: absolute;
  top: 0;
  right: rem(11);
  bottom: 0;
  height: 1rem;
  margin: auto;
  font-size: 0.7em;
  color: $color-gray-minus1;

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.1em;
  }
}

// Productsのカテゴリ
.st-SideNavNews_Parent {
  padding-left: 0;
  list-style-type: none;

  &[aria-current="location"] {
    background-color: #f5f5f5;
  }
}

.st-SideNavNews_ParentItem {
}

.st-SideNavNews_ParentLink {
  display: block;
  padding: rem(9) rem(20);
  text-decoration: none;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.4;
  color: #666;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: underline;
    color: $color-red;
  }

  &[aria-current="location"] {
    background-color: #f0f3f6;
    color: $color-red;
  }
}
