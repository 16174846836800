/*
---
name: sw-LinkDownload
namespace: sw-
category: Link
id: LINK-3
---

<span class="sw-Label">LINK-3</span>

ファイルをダウンロードするためのリンクであることを示す場合に使います。

```jade
p.sw-Text
  span.sw-LinkDownload
    span.sw-LinkDownload_Icon(aria-hidden="true")
    span.sw-LinkDownload_Text
      a.sw-LinkDownload_Link(href="#") Multi Layer - Open tooled package list Multi Layer - Open tooled package list
      span.sw-LinkDownload_Size  (50.30 KB)
```
*/
.sw-LinkDownload {
  display: flex;
  text-decoration: none;
  font-size: rem(15);
  line-height: 1.3;
  color: $color-red;
}

.sw-LinkDownload_Icon {
  width: rem(32);
  height: rem(22);
  font-size: rem(22);
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-cloud-download}";
    position: relative;
    top: -0.3em;
  }
}

.sw-LinkDownload_Text {
  flex-basis: 0;
  flex-grow: 1;
  padding-top: rem(3);

  .sw-LinkDownload:hover & {
    text-decoration: none;
  }
}

.sw-LinkDownload_Link {
  text-decoration: none;
  color: $color-red;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: underline;
  }
}

.sw-LinkDownload_Size {
  color: #999;
}
