// @desc - キーボード操作やスクリーンリーダーへの読み上げをさせたまま非表示にします。
// @param {String} $important [false] - 引数に"important"を渡すと`!important`の指定ができます。
// @example scss - Usage
// .foo { @include sr-only(); }
// .bar { @include sr-only(important); }
//
// @example css - CSS output
// .foo {
//   position: absolute;
//   width: 1px;
//   height: 1px;
//   margin: -1px;
//   border: 0;
//   overflow: hidden;
//   padding: 0;
//   clip: rect(0, 0, 0, 0);
// }
// .bar {
//   position: absolute !important;
//   width: 1px !important;
//   height: 1px !important;
//   margin: -1px !important;
//   border: 0 !important;
//   overflow: hidden !important;
//   padding: 0 !important;
//   clip: rect(0, 0, 0, 0) !important;
// }
@mixin sr-only($important: false) {
  @if ($important == "important") {
    $important: unquote("!important");
  } @else {
    $important: null;
  }

  position: absolute $important;
  overflow: hidden $important;
  clip: rect(0, 0, 0, 0) $important;
  width: 1px $important;
  height: 1px $important;
  margin: -1px $important;
  padding: 0 $important;
  border: 0 $important;
}
