/*
---
name: l-BlockA
namespace: l-
category: Layout
id: BOX-7
---

<span class="sw-Label">BOX-7</span>

コンポーネント同士を縦に並べます。

```jade
.l-BlockA
  .l-BlockA_Item
    .l-MediaA.l-MediaA-right
      .l-MediaA_Image
        .sw-Image
          img(src="/assets/img/common/image_708_440.jpg" alt)
      .l-MediaA_Body
        p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow throughgies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
  .l-BlockA_Item
    .l-MediaA.l-MediaA-right
      .l-MediaA_Image
        .sw-Image
          img(src="/assets/img/common/image_708_440.jpg" alt)
      .l-MediaA_Body
        p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992
```

`.l-BlockA-divider`をつけるとボーダーが付きます。

```jade
.l-BlockA.l-BlockA-divider
  .l-BlockA_Item
    .l-MediaA.l-MediaA-right
      .l-MediaA_Image
        .sw-Image
          img(src="/assets/img/common/image_708_440.jpg" alt)
      .l-MediaA_Body
        p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow throughgies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
  .l-BlockA_Item
    .l-MediaA.l-MediaA-right
      .l-MediaA_Image
        .sw-Image
          img(src="/assets/img/common/image_708_440.jpg" alt)
      .l-MediaA_Body
        p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992
```
*/
.l-BlockA {
  display: block;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  list-style-type: none;

  & > :last-child {
    margin-bottom: 0;
  }
}

.l-BlockA_Item {
  margin-bottom: rem(30);

  .l-BlockA-divider & {
    &:nth-of-type(n + 2) {
      content: "";
      display: block;
      padding-top: rem(30);
      border-top: 1px solid $color-gray-plus3;
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
