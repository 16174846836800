/*
---
name: l-Wrapper
namespace: l-
category: Layout
---

コンテンツの横幅を制限します。左右のpaddingも入ります。

```jade
.l-Wrapper
  section.l-Section2
    h2.sw-Heading2 吾輩はここで始めて人間というものを見た。
    p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
```
*/
.l-Wrapper {
  max-width: $max-width;
  margin: auto;
  padding: 0 rem(15);

  @include mq-up(md) {
    padding: 0 rem(30);
  }

  @include mq-up(xl) {
    padding: 0;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
