/*
---
name: sw-Pager
namespace: sw-
category: Navigation
id: CTL-5
---

<span class="sw-Label">CTL-5</span>

ページネーション。戻る進むリンクと、ブレイクポイント(md)以降はcurrentとその前後のリンク、以前はcurrent表示。

```jade
nav.sw-Pager
  p.sw-Pager_Prev
    a.sw-Pager_PrevLink(href="#" aria-disabled="true")
      span.sw-Pager_PrevIcon(aria-label="Previous")
  p.sw-Pager_Location
    span.sw-Pager_LocationItem 1
    span.sw-Pager_LocationItem of
    span.sw-Pager_LocationItem 10
  ol.sw-Pager_List
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#" aria-current="page") 1
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#") 2
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#") 3
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#") 4
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#") 5
  p.sw-Pager_Next
    a.sw-Pager_NextLink(href="#")
      span.sw-Pager_NextIcon(aria-label="Next")
```

```jade
nav.sw-Pager
  p.sw-Pager_Prev
    a.sw-Pager_PrevLink(href="#")
      span.sw-Pager_PrevIcon(aria-label="Previous")
  p.sw-Pager_Location
    span.sw-Pager_LocationItem 2
    span.sw-Pager_LocationItem of
    span.sw-Pager_LocationItem 10
  ol.sw-Pager_List
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#") 1
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#" aria-current="page") 2
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#") 3
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#") 4
    li.sw-Pager_Item
      a.sw-Pager_Link(href="#") 5
  p.sw-Pager_Next
    a.sw-Pager_NextLink(href="#")
      span.sw-Pager_NextIcon(aria-label="Next")
```
*/
.sw-Pager {
  display: flex;
  justify-content: center;
  padding: rem(10) 0 0;

  &-hidden {
    /* cms: 初期表示OFF、ページ番号調整後に表示。 */
    /* hack: ariaにすべきか */
    visibility: hidden;
  }

  @include mq-up(md) {
    padding: rem(23) 0 rem(10);
  }
}

.js-Pager {
  &[aria-hidden="true"] {
    display: none;
  }
}

.sw-Pager_Location {
  display: flex;

  @include mq-up(md) {
    display: none;
  }
}

.sw-Pager_LocationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: rem(48);
  height: rem(45);
  font-weight: 600;
  box-shadow: 0 -1px 0 0 $color-gray-plus3 inset;
  color: $color-gray-minus1;

  &:first-child {
    color: $color-red;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid $color-red;
      pointer-events: none;
    }
  }
}

.sw-Pager_List {
  display: none;

  @include mq-up(md) {
    display: flex;
    padding: 0;
  }
}

.sw-Pager_Item {
  @include mq-up(md) {
    list-style: none;
  }
}

.sw-Pager_Link {
  @include mq-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: rem(48);
    height: rem(45);
    text-decoration: none;
    font-weight: 600;
    box-shadow: 0 -1px 0 0 $color-gray-plus3 inset;
    color: $color-gray-minus1;
    transition-duration: $transition-duration;

    &:hover {
      color: $color-red;
    }

    &[aria-current="page"] {
      color: $color-red;
      pointer-events: none;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-bottom: 2px solid $color-red;
        pointer-events: none;
      }
    }
  }
}

.sw-Pager_Prev {
}

.sw-Pager_Next {
}

.sw-Pager_PrevLink,
.sw-Pager_NextLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(48);
  height: rem(45);
  color: #999;

  @include mq-up(md) {
    transition-duration: $transition-duration;

    &:hover {
      color: $color-red;
    }
  }

  &[aria-disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

.sw-Pager_PrevIcon,
.sw-Pager_NextIcon {
  &::before {
    @include icon;

    font-size: rem(24);
  }
}

.sw-Pager_PrevIcon {
  &::before {
    content: "#{$icon-slide_arrow_left}";
  }
}

.sw-Pager_NextIcon {
  &::before {
    content: "#{$icon-slide_arrow_right}";
  }
}

.sw-Pager_PrevLink,
.sw-Pager_NextLink {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: rem(48);
  height: rem(45);
  text-decoration: none;
}
