/*
---
name: news-BoxA
namespace: news-
category: Block
id: NL-4
---

<span class="sw-Label">NL-4</span>

Newsのトップと一覧で使用する一覧です。


```jade
.news-BoxA
  .news-BoxA_Body
    ol.news-BoxA_List
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA Introduces Fuji Ceramic Knives Just in Time for Holidays
        .news-BoxA_Meta
          p.news-BoxA_PostDate 26 October 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Kitchen Products
        .news-BoxA_ItemBody
          p.news-BoxA_Text Costa Mesa, Calif. – December 6, 2018 – Combining the high performance of zirconia ceramic blades with the beauty and serenity inspired by Japan’s Fuji Sanctuary, Kyocera has created a new line of Fuji Ceramic Knives just in time for the holidays. Innovative, durable …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image01.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA Enters U.S. Cybersecurity Endpoint Management Market
        .news-BoxA_Meta
          p.news-BoxA_PostDate 2 October 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Endpoint Security Software
        p.news-BoxA_SubTitle Interfocus’ LanScope Cat Helps Small Businesses Manage Devices, Mitigate Cyber Risk; Free 14-day Trial
        .news-BoxA_ItemBody
          p.news-BoxA_Text Costa Mesa, Calif. – December 6, 2018 – Combining the high performance of zirconia ceramic blades with the beauty and serenity inspired by Japan’s Fuji Sanctuary, Kyocera has created a new line of Fuji Ceramic Knives just in time for the holidays. Innovative, durable …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image02.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA Launches Rugged, Military-Grade, Waterproof DuraForce PRO 2 Smartphone with Verizon Wireless
        .news-BoxA_Meta
          p.news-BoxA_PostDate 20 september 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Smartphones &amp; Feature Phones
            li.news-BoxA_Category Semiconductor Components
            li.news-BoxA_Category Electronic Components &amp; Devices
        .news-BoxA_ItemBody
          p.news-BoxA_Text TIJUANA, MÈXICO – Kyocera has been awarded Mexico’s highest honor for environmental protection — the national “Recognition of Environmental Excellence” award — for the third consecutive year. Presented by Mexico’s Federal Environmental Protection Agency (PROFEPA), the …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image03.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA Launches Rugged, Military-Grade, Waterproof DuraForce PRO 2 Smartphone with Verizon Wireless
        .news-BoxA_Meta
          p.news-BoxA_PostDate 26 July 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Smartphones &amp; Feature Phones
        .news-BoxA_ItemBody
          p.news-BoxA_Text TIJUANA, MÈXICO – Kyocera has been awarded Mexico’s highest honor for environmental protection — the national “Recognition of Environmental Excellence” award — for the third consecutive year. Presented by Mexico’s Federal Environmental Protection Agency (PROFEPA), the …
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title Mobile Industry Works Together to Deliver Complete 5G System Standard on Time
        .news-BoxA_Meta
          p.news-BoxA_PostDate 15 July 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Smartphones &amp; Feature Phones
        .news-BoxA_ItemBody
          p.news-BoxA_Text TIJUANA, MÈXICO – Kyocera has been awarded Mexico’s highest honor for environmental protection — the national “Recognition of Environmental Excellence” award — for the third consecutive year. Presented by Mexico’s Federal Environmental Protection Agency (PROFEPA), the …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image04.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA’s New Air-Cooled UV Curing Light is World’s Smallest, Lightest and Most Powerful
        .news-BoxA_Meta
          p.news-BoxA_PostDate 26 May 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Electronic Components &amp; Devices
        p.news-BoxA_SubTitle Supports UV ink printing, including billboard printing, as well as UV-cured coatings and adhesives
        .news-BoxA_ItemBody
          p.news-BoxA_Text Kyocera Corporation (President: Hideo Tanimoto) announced today that it has developed a new air-cooled UV-LED curing light that is not only the world’s most powerful*1, but also the world’s smallest*2. The new Kyocera G5A Series is ideal for use in industrial ultravio …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image05.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA to Exhibit Fine Ceramic Technologies at Materials Research Society’s Fall 2016 Meeting, Nov. 29 to Dec. 2 in Boston
        .news-BoxA_Meta
          p.news-BoxA_PostDate 26 May 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Electronic Components &amp; Devices
        p.news-BoxA_SubTitle Supports UV ink printing, including billboard printing, as well as UV-cured coatings and adhesives
        .news-BoxA_ItemBody
          p.news-BoxA_Text Kyocera Corporation (President: Hideo Tanimoto) announced today that it has developed a new air-cooled UV-LED curing light that is not only the world’s most powerful*1, but also the world’s smallest*2. The new Kyocera G5A Series is ideal for use in industrial ultravio …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image06.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA Introduces Fuji Ceramic Knives Just in Time for Holidays
        .news-BoxA_Meta
          p.news-BoxA_PostDate 6 May 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Kitchen Products
        .news-BoxA_ItemBody
          p.news-BoxA_Text Costa Mesa, Calif. – December 6, 2018 – Combining the high performance of zirconia ceramic blades with the beauty and serenity inspired by Japan’s Fuji Sanctuary, Kyocera has created a new line of Fuji Ceramic Knives just in time for the holidays. Innovative, durable …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image01.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA Enters U.S. Cybersecurity Endpoint Management Market
        .news-BoxA_Meta
          p.news-BoxA_PostDate 26 October 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Endpoint Security Software
        p.news-BoxA_SubTitle Interfocus’ LanScope Cat Helps Small Businesses Manage Devices, Mitigate Cyber Risk; Free 14-day Trial
        .news-BoxA_ItemBody
          p.news-BoxA_Text Costa Mesa, Calif. – December 6, 2018 – Combining the high performance of zirconia ceramic blades with the beauty and serenity inspired by Japan’s Fuji Sanctuary, Kyocera has created a new line of Fuji Ceramic Knives just in time for the holidays. Innovative, durable …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image02.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
      li.news-BoxA_Item
        p.news-BoxA_Title KYOCERA Launches Rugged, Military-Grade, Waterproof DuraForce PRO 2 Smartphone with Verizon Wireless
        .news-BoxA_Meta
          p.news-BoxA_PostDate 26 October 2018
          ul.news-BoxA_Categories
            li.news-BoxA_Category Smartphones &amp; Feature Phones
            li.news-BoxA_Category Semiconductor Components
            li.news-BoxA_Category Electronic Components &amp; Devices
        .news-BoxA_ItemBody
          p.news-BoxA_Text TIJUANA, MÈXICO – Kyocera has been awarded Mexico’s highest honor for environmental protection — the national “Recognition of Environmental Excellence” award — for the third consecutive year. Presented by Mexico’s Federal Environmental Protection Agency (PROFEPA), the …
          figure.news-BoxA_Figure
            img.news-BoxA_Image(src="/assets/img/news/image03.jpg" alt)
        a.news-BoxA_Link(href="#") Read more
    .news-BoxA_Footer
      nav.sw-Pager
        p.sw-Pager_Prev
          a.sw-Pager_PrevLink(href="#" aria-disabled="true")
            span.sw-Pager_PrevIcon(aria-label="Previous")
        p.sw-Pager_Location
          span.sw-Pager_LocationItem 1
          span.sw-Pager_LocationItem of
          span.sw-Pager_LocationItem 10
        ol.sw-Pager_List
          li.sw-Pager_Item
            a.sw-Pager_Link(href="index.html" aria-current="page") 1
          li.sw-Pager_Item
            a.sw-Pager_Link(href="index2.html") 2
          li.sw-Pager_Item
            a.sw-Pager_Link(href="index3.html") 3
          li.sw-Pager_Item
            a.sw-Pager_Link(href="index4.html") 4
          li.sw-Pager_Item
            a.sw-Pager_Link(href="index5.html") 5
        p.sw-Pager_Next
          a.sw-Pager_NextLink(href="index2.html")
            span.sw-Pager_NextIcon(aria-label="Next")
```
*/
.news-BoxA {
}

.js-news-BoxA {
  &[aria-hidden="true"] {
    display: none;
  }
}

.js-news-BoxA_Header {
  &[aria-hidden="true"] {
    display: none;
  }
}

// body
.news-BoxA_Body:first-child {
  margin-top: rem(-14);

  @include mq-up(md) {
    margin-top: rem(-24);
  }
}

.news-BoxA_List {
  padding-left: 0;
  list-style-type: none;
}

.news-BoxA_Item {
  position: relative;
  padding-top: rem(30);
  padding-bottom: rem(30);

  @include mq-up(md) {
    min-height: rem(138 + 20);
    padding: rem(30) rem(198 + 30) rem(32) 0;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: rem(-15);
    bottom: 0;
    left: rem(-15);
    height: 1px;
    margin-right: calc(-50vw + 50%);
    margin-left: calc(-50vw + 50%);
    border-bottom: 1px solid #d2d2d2;

    @include mq-up(md) {
      width: 100%;
      margin: 0;
      border-bottom: 1px solid #e9e9e9;
    }
  }
}

.news-BoxA_Title {
  margin-bottom: rem(10);
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.3;
  color: #000;

  @include mq-up(md) {
    margin-bottom: rem(15);
    font-size: rem(24);
    font-weight: 600;
    line-height: 1.375;
  }
}

.news-BoxA_Meta {
  @include mq-up(md) {
    display: flex;
    align-items: flex-start;
    margin-bottom: rem(10);
  }
}

.news-BoxA_PostDate {
  margin-bottom: rem(5);
  font-size: rem(14);
  font-weight: 600;
  line-height: rem(20);
  color: $color-red;

  @include mq-up(md) {
    float: left;
    margin-right: rem(12);
    margin-bottom: 0;
    padding-right: rem(12);
    white-space: nowrap;
    line-height: 1.3;
    border-right: 1px solid $color-gray-plus2;
  }
}

.news-BoxA_Categories {
  margin-bottom: rem(15);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-bottom: 0;
    line-height: 1;
  }
}

.news-BoxA_Category {
  display: inline-block;
  font-size: rem(13);
  font-weight: 600;
  line-height: 1.3;
  color: #444;

  &::after {
    content: "/";
    vertical-align: middle;
    margin-left: rem(4);
    font-size: rem(19);
    font-weight: 300;
    line-height: 1;
    color: $color-gray-plus2;
  }

  &:last-child::after {
    content: none;
  }
}

.news-BoxA_SubTitle {
  margin-bottom: rem(5);
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.3;
  color: #666;

  @include mq-up(md) {
    margin-bottom: rem(13);
    font-size: rem(20);
    line-height: 1.2;
  }
}

.news-BoxA_ItemBody {
  display: flex;
  position: relative;
  margin: rem(7) 0 rem(22);

  @include mq-up(md) {
    display: block;
    margin: 0 0 rem(22);
  }
}

.news-BoxA_Sumally {
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: rem(22);
  font-size: rem(14);
  font-weight: 400;
  line-height: 1.5;
  color: #666;

  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;

  @include mq-up(md) {
    display: block;
    overflow: visible;
    margin-bottom: rem(22);
    font-size: rem(15);
  }
}

.news-BoxA_Text {
  display: -webkit-box;
  overflow: hidden;
  margin-top: rem(2);
  font-size: rem(14);
  font-weight: 400;
  line-height: 1.5;
  color: #666;

  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;

  @include mq-up(md) {
    font-size: rem(15);
    -webkit-line-clamp: 3;
  }
}

.news-BoxA_Figure {
  height: rem(92);
  min-width: rem(130);
  margin: rem(8) 0 0 rem(15);

  @include mq-up(md) {
    position: absolute;
    bottom: 0;
    left: 100%;
    width: rem(195);
    height: rem(138);
    margin: 0 0 0 rem(30);
  }
}

.news-BoxA_Link {
  @include sw-Button;

  width: 100%;
  max-width: none;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    width: rem(150);
    max-width: percentage(1 / 2);

    &:hover {
      background-color: $color-red;
      color: $color-white;
    }
  }
}

.news-BoxA_LinkIcon {
  display: inline-block;
  position: relative;
  height: rem(11);
  margin-left: rem(5);
  font-size: rem(11);
}

.news-BoxA_LinkIconExternalA {
  display: inline-block;
  position: relative;
  height: rem(11);
  margin-left: rem(5);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.25em;
  }
}

.news-BoxA_LinkIconExternalB {
  @extend .news-BoxA_LinkIconExternalA;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
  }
}

.news-BoxA_Foot {
  margin-top: rem(20);
  text-align: center;

  @include mq-up(md) {
    margin-top: rem(30);
  }
}

.news-BoxA_Button {
  @include sw-Button;

  width: 100%;
  max-width: rem(162);
  transition-duration: $transition-duration;

  @include mq-up(md) {
    max-width: percentage(5 / 12);
  }

  @include mq-up(lg) {
    &:hover {
      background-color: $color-red;
      color: #fff;
    }
  }
}

// footer
.news-BoxA_Footer {
}
