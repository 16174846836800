/*
---
name: l-MediaB
namespace: l-
category: Layout
id: [IMG-9A, IMG-9B, IMG-10A, IMG-10B]
---

<span class="sw-Label">IMG-9A</span>

- 回り込みなし
- スマホで100%、タブレット以降でテキスト7/12画像、5/12幅
- 画像は右配置

```jade
.l-MediaB.l-MediaB-col7to5
  .l-MediaB_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
  .l-MediaB_Image
    img(src="/assets/img/common/image_708_440.jpg" alt)
```

- 回り込みなし
- スマホで100%、タブレット以降でテキスト8/12、画像4/12幅
- 画像は右配置

```jade
.l-MediaB.l-MediaB-col8to4
  .l-MediaB_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
  .l-MediaB_Image
    img(src="/assets/img/common/image_708_440.jpg" alt)
```

<span class="sw-Label">IMG-9B</span>

- 回り込みなし
- スマホでテキスト100%、画像4/12幅、タブレット以降でテキスト9/12、画像3/12幅
- 画像は右配置

```jade
.l-MediaB.l-MediaB-col9to3
  .l-MediaB_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
  .l-MediaB_Image
    img(src="/assets/img/common/img_290_180.jpg" alt)
```

- 回り込みなし
- スマホでテキスト100%、画像4/12幅、タブレット以降でテキスト10/12、画像2/12幅
- 画像は右配置

```jade
.l-MediaB.l-MediaB-col10to2
  .l-MediaB_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
  .l-MediaB_Image
    img(src="/assets/img/common/img_290_180.jpg" alt)
```

<span class="sw-Label">IMG-10A</span>

- 回り込みなし
- スマホで100%、タブレット以降でテキスト5/12、画像7/12幅
- 画像は左配置

```jade
.l-MediaB.l-MediaB-col5to7
  .l-MediaB_Image
    img(src="/assets/img/common/image_708_440.jpg" alt)
  .l-MediaB_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

- 回り込みなし
- スマホで100%、タブレット以降でテキスト4/12画像、8/12幅
- 画像は左配置

```jade
.l-MediaB.l-MediaB-col4to8
  .l-MediaB_Image
    img(src="/assets/img/common/image_708_440.jpg" alt)
  .l-MediaB_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

<span class="sw-Label">IMG-10B</span>

- 回り込みなし
- スマホでテキスト100%、画像4/12幅、タブレット以降でテキスト3/12、画像9/12幅
- 画像は左配置

```jade
.l-MediaB.l-MediaB-col3to9
  .l-MediaB_Image
    img(src="/assets/img/common/img_290_180.jpg" alt)
  .l-MediaB_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

- 回り込みなし
- スマホでテキスト100%、画像4/12幅、タブレット以降でテキスト2/12、画像10/12幅
- 画像は左配置

```jade
.l-MediaB.l-MediaB-col2to10
  .l-MediaB_Image
    img(src="/assets/img/common/img_290_180.jpg" alt)
  .l-MediaB_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```
*/
.l-MediaB {
  display: flex;
  flex-wrap: wrap;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  margin-left: rem(-15);

  @include mq-up(md) {
    margin-left: rem(-30);
  }
}

.l-MediaB_Body {
  padding-left: rem(15);

  @include mq-up(md) {
    padding-left: rem(30);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  .l-MediaB-col7to5 .l-MediaB_Image + &,
  .l-MediaB-col8to4 .l-MediaB_Image + &,
  .l-MediaB-col5to7 .l-MediaB_Image + &,
  .l-MediaB-col4to8 .l-MediaB_Image + & {
    margin-top: rem(20);

    @include mq-up(md) {
      margin-top: 0;
    }
  }

  .l-MediaB-col9to3 .l-MediaB_Image + &,
  .l-MediaB-col10to2 .l-MediaB_Image + &,
  .l-MediaB-col3to9 .l-MediaB_Image + &,
  .l-MediaB-col2to10 .l-MediaB_Image + & {
    margin-top: rem(15);

    @include mq-up(md) {
      margin-top: 0;
    }
  }
}

.l-MediaB_Image {
  padding-left: rem(15);
  text-align: center;

  @include mq-up(md) {
    padding-left: rem(30);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  .l-MediaB-col7to5 .l-MediaB_Body + &,
  .l-MediaB-col8to4 .l-MediaB_Body + &,
  .l-MediaB-col5to7 .l-MediaB_Body + &,
  .l-MediaB-col4to8 .l-MediaB_Body + & {
    margin-top: rem(20);

    @include mq-up(md) {
      margin-top: 0;
    }
  }

  .l-MediaB-col9to3 .l-MediaB_Body + &,
  .l-MediaB-col10to2 .l-MediaB_Body + &,
  .l-MediaB-col3to9 .l-MediaB_Body + &,
  .l-MediaB-col2to10 .l-MediaB_Body + & {
    margin-top: rem(15);

    @include mq-up(md) {
      margin-top: 0;
    }
  }
}

// 7:5, 8:4
.l-MediaB-col7to5 {
  & > :nth-of-type(odd) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(7 / 12);
      max-width: percentage(7 / 12);
    }
  }

  & > :nth-of-type(even) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(5 / 12);
      max-width: percentage(5 / 12);
    }
  }
}

.l-MediaB-col5to7 {
  & > :nth-of-type(odd) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(5 / 12);
      max-width: percentage(5 / 12);
    }
  }

  & > :nth-of-type(even) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(7 / 12);
      max-width: percentage(7 / 12);
    }
  }
}

.l-MediaB-col8to4 {
  & > :nth-of-type(odd) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(8 / 12);
      max-width: percentage(8 / 12);
    }
  }

  & > :nth-of-type(even) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }
  }
}

.l-MediaB-col4to8 {
  & > :nth-of-type(odd) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }
  }

  & > :nth-of-type(even) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(8 / 12);
      max-width: percentage(8 / 12);
    }
  }
}

// 9:3, 10:2
.l-MediaB-col9to3 {
  & > :nth-of-type(odd) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(9 / 12);
      max-width: percentage(9 / 12);
    }
  }

  & > :nth-of-type(even) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(3 / 12);
      max-width: percentage(3 / 12);
    }
  }

  & > .l-MediaB_Image {
    @include mq-down(md) {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }
  }
}

.l-MediaB-col3to9 {
  & > :nth-of-type(odd) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(3 / 12);
      max-width: percentage(3 / 12);
    }
  }

  & > :nth-of-type(even) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(9 / 12);
      max-width: percentage(9 / 12);
    }
  }

  & > .l-MediaB_Image {
    @include mq-down(md) {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }
  }
}

.l-MediaB-col10to2 {
  & > :nth-of-type(odd) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(10 / 12);
      max-width: percentage(10 / 12);
    }
  }

  & > :nth-of-type(even) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(2 / 12);
      max-width: percentage(2 / 12);
    }
  }

  & > .l-MediaB_Image {
    @include mq-down(md) {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }
  }
}

.l-MediaB-col2to10 {
  & > :nth-of-type(odd) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(2 / 12);
      max-width: percentage(2 / 12);
    }
  }

  & > :nth-of-type(even) {
    flex-basis: 100%;
    max-width: 100%;

    @include mq-up(md) {
      flex-basis: percentage(10 / 12);
      max-width: percentage(10 / 12);
    }
  }

  & > .l-MediaB_Image {
    @include mq-down(md) {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }
  }
}
