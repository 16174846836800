.st-GlobalRegionPc {
  display: none;

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }
}

.st-GlobalRegionPc_Toggle {
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  font-family: inherit;
  font-size: rem(12);
  line-height: 1;
  border: none;
  background-color: transparent;
  color: #666;

  @include mq-up(lg) {
    vertical-align: middle;
    width: auto;
  }

  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 19px;
    margin-right: rem(20);
    margin-left: rem(14);
    background-color: #e6e6e6;
  }

  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    appearance: none;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.focus-ring:focus {
    box-shadow: $form-box-shadow-focus;
  }
}

.st-GlobalRegionPc_Region {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    vertical-align: middle;
    position: relative;
    top: rem(-1);
    width: rem(24) !important;
    height: rem(24);
    margin-right: rem(6);

    // @note:使われていないよう（region_global.svgのsrc指定なし）
    &[src*="region_global"] {
      width: rem(19) !important;
      height: rem(19);
    }
  }
}

.st-GlobalRegionPc_SubArea {
  display: none;
  position: absolute;
  top: 64px;
  left: 0;
  z-index: 10000;
  width: 100%;
  padding-bottom: rem(46);
  text-align: left;
  background-color: rgba(79, 79, 79, 0.9);

  @include mq-up(lg) {
    top: 84px;
  }
}

.st-GlobalRegionPc_Heading {
  display: block;
  margin-bottom: rem(30);
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.375;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
}

.st-GlobalRegionPc_HeadingIcon {
  position: relative;
  top: -0.1em;
  margin-left: 0.25rem;
  font-size: 0.8em;

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
  }
}

.st-GlobalRegionPc_RegionList {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(35);
    margin-left: rem(-15);
    padding-left: 0;
    list-style-type: none;
  }
}

.st-GlobalRegionPc_RegionItem {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    flex-basis: percentage(1 / 4);
    max-width: percentage(1 / 4);
    padding-left: rem(15);

    @include mq-up(xl) {
      flex-basis: percentage(1 / 6);
      max-width: percentage(1 / 6);
    }

    &:nth-of-type(n + 5) {
      margin-top: rem(24);

      @include mq-up(xl) {
        margin-top: 0;
      }
    }

    &:nth-of-type(n + 7) {
      @include mq-up(xl) {
        margin-top: rem(24);
      }
    }
  }
}

.st-GlobalRegionPc_RegionLink {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: rem(15);
    line-height: 1.3;
    color: #ebebeb;

    &:hover {
      text-decoration: underline;
    }
  }
}

.st-GlobalRegionPc_RegionText {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
  }
}

.st-GlobalRegionPc_RegionImage {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    width: rem(24);
    height: rem(24);
    margin-right: rem(8);
  }
}
