/*
---
name: sw-LinkArrow
namespace: sw-
category: Link
id: LINK-7
---

<span class="sw-Label">LINK-7</span>

矢印付きのテキストリンクです。

```jade
a.sw-LinkArrow(href="#")
  | Contact Us
  span.sw-LinkArrow_Icon(aria-hidden="true")
```

別窓リンク

```jade
a.sw-LinkArrow(href="#")
  | Contact Us
  span.sw-LinkArrow_IconExternalA(aria-hidden="true")
```

外部リンク

```jade
a.sw-LinkArrow(href="#")
  | Contact Us
  span.sw-LinkArrow_IconExternalB(aria-hidden="true")
```
*/
.sw-LinkArrow {
  display: block;
  position: relative;
  text-decoration: none;
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.2;
  color: $color-red;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    &:hover {
      text-decoration: underline;
      color: $color-link-hover;
    }
  }
}

.sw-LinkArrow_Icon {
  display: inline-block;
  vertical-align: baseline;
  height: rem(12);
  margin-left: rem(6);
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.15em;
  }

  .sw-LinkArrow_Link:hover & {
    color: $color-red;
  }
}

.sw-LinkArrow_IconExternalA {
  display: inline-block;
  vertical-align: baseline;
  height: rem(14);
  margin-left: rem(5);
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.15em;
  }

  .sw-LinkArrow_Link:hover & {
    color: $color-red;
  }
}

.sw-LinkArrow_IconExternalB {
  display: inline-block;
  vertical-align: baseline;
  height: rem(14);
  margin-left: rem(5);
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.15em;
  }

  .sw-LinkArrow_Link:hover & {
    color: $color-red;
  }
}
