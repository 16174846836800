/*
---
name: st-RegionSelect
namespace: st-
category: Global
---

グローバルフッターで地域の切り替えをします。

- スマホではselectタグになります
- PCでは要素を生成してulタグになります


```jade
.st-Sitemap_Region
  .st-RegionSelect
    button.st-RegionSelect_Button(type="button" data-toggle="0")
      span.st-RegionSelect_Region Europe
      span.st-RegionSelect_Icon(aria-hidden="true")
    select#st-RegionSelect_Select.st-RegionSelect_Select
      option(value="0") Europe
      option(value="1") Austria
      option(value="2") Denmark
      option(value="3") Finland
      option(value="4") France
      option(value="5") Germany
      option(value="6") Italy
      option(value="7") Netherlands
      option(value="8") Norway
      option(value="9") Portugal
      option(value="10") Russia
      option(value="11") Spain
      option(value="12") Sweden
      option(value="13") Switzerland
      option(value="14") South Africa
      option(value="15") United Kingdom
  a.st-Sitemap_GlobalLink(href="#")
    span.st-Sitemap_GlobalIcon(aria-hidden="true")
    | Global
```
*/
.st-RegionSelect {
  display: inline-block;
  position: relative;

  @include mq-up(lg) {
  }

  // 文字数の多い国名でレイアウトが崩れるのを防ぐ
  [lang="nl-NL"] & { width: rem(158) }
  [lang="de-CH"] & { width: rem(153) }
  [lang="en-ZA"] & { width: rem(157) }
  [lang="en-GB"] & { width: rem(184) }
}

.st-RegionSelect_Button {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: auto;
  height: 42px;
  padding: 0 rem(10) 0 rem(12);
  text-align: right;
  font-size: rem(14);
  font-weight: 600;
  line-height: 42px;
  white-space: nowrap;
  border: none;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.13);
  color: #000;
  appearance: none;

  @include mq-up(lg) {
    position: relative;
    left: -4px;
    width: auto;
    text-align: left;
    line-height: 1;
  }

  &:not(.focus-ring) {
    outline: none;
  }
}

.st-RegionSelect_Select {
  position: relative;
  width: auto;
  height: 42px;
  text-align: center;
  text-indent: 0.01px;
  text-overflow: "";
  font-size: rem(16);
  font-weight: bold;
  line-height: 1;
  border: none;
  background-color: transparent;
  color: #000;
  color: transparent;
  appearance: none;

  @include mq-up(lg) {
    display: none;
  }

  &::-ms-expand {
    display: none;
  }
}

.st-RegionSelect_Mock {
  display: none;
  position: absolute;
  z-index: 10;
  overflow: auto;
  min-width: rem(180);
  margin: 0.5rem 0;
  padding: rem(13) rem(11);
  font-size: rem(13);
  font-weight: normal;
  line-height: 1;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.33);
  list-style-type: none;
  pointer-events: none;

  @include mq-up(lg) {
    .st-RegionSelect_Button[data-toggle] ~ & {
      display: none;
      pointer-events: none;
    }

    .st-RegionSelect_Button[data-toggle="1"] ~ & {
      display: inherit;
      pointer-events: auto;
    }

    .st-RegionSelect_Button[data-position="0"] ~ & {
      bottom: 100%;
    }

    .st-RegionSelect_Button[data-position="1"] ~ & {
      top: 100%;
    }

    @for $i from 0 through 20 {
      .st-RegionSelect_Select[data-selected="#{$i}"] ~ & li:nth-child(#{$i + 1}) {
        font-weight: bold;
        background-color: #f5f5f5;
      }

      &[data-cursor="#{$i}"] li:nth-child(#{$i + 1}) {
        color: $color-red;
      }

      &[data-cursor="#{$i}"]::before {
        transform: translate3d(0, #{$i * 100%}, 0);
      }
    }
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.26);
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    left: 0.5rem;
    height: 40px;
    color: $color-red;
  }

  & li {
    position: relative;
    height: 40px;
    margin: 0;
    padding: 0 rem(10);
    white-space: nowrap;
    line-height: 40px;
    cursor: pointer;
    transition: background 0.2s;
  }
}

.st-RegionSelect_Icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: rem(42);
  font-size: 10px;
  color: $color-gray-minus1;
  transform: scale3d(0.8, 0.8, 1);

  &::after {
    @include icon;

    content: "#{$icon-arrow_drop_down}";
    position: relative;
    top: 0;
  }
}

.st-RegionSelect_Region {
  display: inline-block;
  vertical-align: middle;
}
