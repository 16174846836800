/*
---
name: st-TitleB
namespace: sw-
category: Heading
id: CT-1B
---

<span class="sw-Label">CT-1B</span>

`.st-TitleA`にお問い合わせボタンが付いたコンポーネントです。

- 画面上部に固定表示されます
- ページの構造によって`.st-TitleB_Title`は`h1`と`p`を使い分けてください


```jade
header.st-ContentsHeader
  .st-ContentsHeader_Title
    .st-TitleB
      .st-TitleB_Inner
        h1.st-TitleB_Title Division Title
        a.st-TitleB_Inquiry(href="#")
          span.st-TitleB_InquiryIcon(aria-hidden="true")
          span.st-TitleB_InquiryText Inquiry Form
```
*/
.st-TitleB {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: rem(-6);
    left: 0;
    width: 100vw;
    height: rem(6);
    margin-right: calc(-50vw + 50%);
    margin-left: calc(-50vw + 50%);
    background-image: linear-gradient(
      0deg,
      rgba(102, 102, 102, 0) 0%,
      rgba(102, 102, 102, 0.15) 98%
    );
  }

  &.st-TitleB-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    padding: 0 rem(15);
    background-color: $color-white;

    @include mq-up(md) {
      padding: 0 rem(30);
    }

    @include mq-up(xl) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.st-TitleB_Inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .st-TitleB-fixed > & {
    max-width: $max-width;
    margin: auto;
  }
}

.st-TitleB_Title {
  position: relative;
  width: 100%;
  padding: rem(15) 0;
  padding-right: 1em;
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.2;
  color: $color-black;

  @include mq-up(md) {
    width: auto;
    font-size: rem(22);
  }
}

.st-TitleB_Inquiry {
  display: flex;
  align-items: center;
  width: 20px;
  text-decoration: none;
  color: $color-red;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    justify-content: center;align-items: center;
    width: auto;
    padding: rem(8) rem(28);
    text-align: center;
    white-space: nowrap;
    border: 1px solid $color-gray-plus2;
    border-radius: rem(3);
    background: $color-white;
    box-shadow: 1px 1px 2px 0 rgba(#000, 0.1);
  }

  &:hover {
    text-decoration: none;

    @include mq-up(md) {
      border-color: $color-red;
      background-color: $color-red;
      color: $color-white;
    }
  }
}

.st-TitleB_InquiryIcon {
  position: relative;
  height: rem(16);
  font-size: rem(20);

  @include mq-up(md) {
    height: rem(13);
    font-size: rem(17);
  }

  &::before {
    @include icon;

    content: "#{$icon-mail2}";
    position: relative;
    top: -0.5em;
  }
}

.st-TitleB_InquiryText {
  display: none;
  font-size: rem(13);
  font-weight: 600;

  @include mq-up(md) {
    display: inline-block;
    margin-left: rem(6);
  }
}
