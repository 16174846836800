/*
---
name: sw-Box
namespace: sw-
category: Block
id: BOX-1
---

<span class="sw-Label">BOX-1</span>

背景色ありBOX


```jade
.sw-Box
  | 背景色ありBOX
```
*/
.sw-Box {
  margin-top: rem(30);
  margin-bottom: rem(30);
  padding: rem(30) rem(40);
  background-color: #f2f4f6;
}
