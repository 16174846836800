/*
---
name: st-SubNav
namespace: sw-
category: Navigation
---

下層ページで使用するナビゲーションです。

`a.st-SideNav_Link + ul.st-Sidトグル操作しないサブナビがある場合、eNav_Parent#st-SideNav_SubService`のようにすると、サブナビの表示ができます。

`#st-SideNav_SubService`は`Service`の部分をカテゴリによって、以下のように変更します（SideNav.jsの`includedCategories`で定義）。

- `#st-SideNav_SubNews`
- `#st-SideNav_SubEvents`
- `#st-SideNav_Subcontact`
- `#st-SideNav_SubService`
- `#st-SideNav_SubDownload`


```jade
ul.st-SideNav
  li.st-SideNav_Item
    a.st-SideNav_Link(href="/products/solar_electric_systems/")
      | Top
      span.st-SideNav_Icon(aria-hidden="true")
  li.st-SideNav_Item
    button.st-SideNav_Toggle#st-SideNav_Toggle(type="button" aria-expanded="false" aria-controls="st-SideNav_Parent")
      | Products
      span.st-SideNav_ToggleIcon(aria-hidden="true")
    ul.st-SideNav_Parent#st-SideNav_Parent(aria-hidden="true")
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/prd/solar_modules/index.html") Solar Modules
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/prd/battery_systems/index.html") Battery Systems
  li.st-SideNav_Item
    a.st-SideNav_Link(href="/products/solar_electric_systems/service/")
      | Service
      span.st-SideNav_Icon(aria-hidden="true")
    ul.st-SideNav_Parent#st-SideNav_SubService(aria-hidden="true")
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/service/yield_check/") PV System and Yield Check
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/service/maintenance/") Maintenance
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/service/pv_reports/") PV Expert Reports
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/service/repowering/") Repowering
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/service/inverter_service/") Fronius &amp; SMA Inverter Service
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/service/test_methods/") Test Methods
  li.st-SideNav_Item
    a.st-SideNav_Link(href="/products/solar_electric_systems/news/")
      | News
      span.st-SideNav_Icon(aria-hidden="true")
    ul.st-SideNav_Parent#st-SideNav_SubNews(aria-hidden="true")
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/news/2019/") 2019
      li.st-SideNav_ParentItem
        a.st-SideNav_ParentLink(href="/products/solar_electric_systems/news/2018/") 2018
  li.st-SideNav_Item
    a.st-SideNav_Link(href="/products/solar_electric_systems/contact/")
      | Sales Office
      span.st-SideNav_Icon(aria-hidden="true")
```
*/
.st-SubNav {
}
