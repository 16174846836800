/*
---
name: sw-LinkIcon
namespace: sw-
category: Link
id: LINK-6
---

<span class="sw-Label">LINK-6</span>

リンク＋アイコン(後付き)

```jade
a.sw-LinkIcon(href="#")
  | asia.kyocera.com/uploads/KAP01022018.pdf
  span.sw-LinkIcon_Pdf(aria-hidden="true")
```

```jade
a.sw-LinkIcon(href="#")
  | global.kyocera.com/inamori/management/
  span.sw-LinkIcon_ExternalA(aria-hidden="true")
```

```jade
a.sw-LinkIcon(href="#")
  | global.kyocera.com/inamori/management/
  span.sw-LinkIcon_ExternalB(aria-hidden="true")
```
*/
.sw-LinkIcon {
  text-decoration: none;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: rem(15);
  line-height: 1.4;
  color: $color-red;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: underline;
  }
}

.sw-LinkIcon_Pdf,
.sw-LinkIcon_ExternalA,
.sw-LinkIcon_ExternalB {
  display: inline-block;
  vertical-align: baseline;

  &::before {
    @include icon;

    position: relative;
    top: -0.3em;
  }
}

.sw-LinkIcon_Pdf {
  height: rem(15);
  margin-left: rem(6);
  font-size: rem(15);

  &::before {
    content: "#{$icon-pdf}";
  }
}

.sw-LinkIcon_ExternalA {
  height: rem(14);
  margin-left: rem(5);
  font-size: rem(14);

  &::before {
    content: "#{$icon-filter_none}";
  }
}

.sw-LinkIcon_ExternalB {
  height: rem(14);
  margin-left: rem(5);
  font-size: rem(14);

  &::before {
    content: "#{$icon-external-link}";
  }
}
