/*
---
name: l-Article
namespace: l-
category: Layout
---

h1相当の見出しをラップするarticleの余白を指定します。

```jade
.l-Wrapper
  p.sw-Text 吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。

  article.l-Article
    header.news-Header
      h1.news-Header_Title
      | しかしその当時は何という考もなかったから別段恐しいとも思わなかった。ただ彼の掌に載せられてスーと持ち上げられた時何だかフワフワした感じがあったばかりである。掌の上で少し落ちついて書生の顔を見たのがいわゆる人間というものの見始であろう。この時妙なものだと思った感じが今でも残っている。
```
*/

.l-Article {
  margin-bottom: 0;

  @include mq-up(md) {
    margin-bottom: rem(50);
  }
}
