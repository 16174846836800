/*
---
name: l-DetailTemplate
namespace: l-
category: Layout
---

下層ページの2カラムテンプレートです。

- PCではサイドバーが左になります

```jade
.l-DetailTemplate
  .l-DetailTemplate_Wrapper
    .l-DetailTemplate_Main
      p Main

    .l-DetailTemplate_Sub
      p Sub
```
*/
.l-DetailTemplate {
  max-width: $max-width;
  margin: auto;
  margin-top: rem(30);
  padding: 0 rem(15);

  @include mq-up(md) {
    margin-top: rem(60);
    margin-bottom: rem(90);
    padding: 0 rem(30);
  }

  @include mq-up(xl) {
    padding: 0;
  }

  &.l-DetailTemplate-News {
    margin-top: rem(22);

    @include mq-up(md) {
      margin-top: rem(60);
    }
  }
}

.l-DetailTemplate_Wrapper {
  display: flex;
  flex-wrap: wrap;

  @include mq-up(lg) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.l-DetailTemplate_Main {
  flex-basis: 100%;
  max-width: 100%;

  @include mq-up(lg) {
    flex-basis: percentage(870 / 1170);
    max-width: percentage(870 / 1170);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0 !important;
  }
}

.l-DetailTemplate_Sub {
  flex-basis: 100%;
  max-width: 100%;
  margin-top: rem(40);

  @include mq-up(lg) {
    flex-basis: percentage(240 / 1170);
    max-width: percentage(240 / 1170);
    margin-top: 0;
  }
}

.l-DetailTemplate_SubNav {
  display: none;

  @include mq-up(lg) {
    display: block;
  }

  &.l-DetailTemplate_SubNav-showAlways {
    display: block;
    padding-bottom: rem(40);

    @include mq-up(md) {
      padding-bottom: 0;
    }
  }
}

.l-DetailTemplate_SubNavSp {
  display: block;
  margin-bottom: rem(60);

  @include mq-up(md) {
    display: none;
  }
}

.l-DetailTemplate_CategoryNavSp {
  display: block;
  margin-bottom: rem(40);

  @include mq-up(md) {
    display: none;
  }
}

.l-DetailTemplate_related {
  // サイドナビがある場合は上に余白を入れる
  .l-DetailTemplate_SubNavSp + &,
  .l-DetailTemplate_SubNav + & {
    margin-top: rem(60);
  }

  .l-DetailTemplate_SubNav-showAlways + & {
    margin-top: rem(20);

    @include mq-up(md) {
      margin-top: rem(60);
    }
  }
}

.l-DetailTemplate_ProductsOffice {
  margin-top: rem(40);

  @include mq-up(md) {
    margin-top: rem(30);
  }
}

.l-DetailTemplate_office {
  margin: 0 0 rem(40);

  @include mq-up(md) {
    display: flex;
    justify-content: space-between;
    margin: 0 rem(-15);

    .office-BoxC {
      width: calc(50% - 15px);
      margin: 0 rem(15) rem(60);
    }
  }

  @include mq-up(lg) {
    display: block;
    margin: rem(25) 0;

    .office-BoxC {
      width: auto;
      margin: inherit;
    }
  }
}
