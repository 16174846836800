/*
---
name: sw-Bold
namespace: sw-
category: Text
id: TXT-6
---

<span class="sw-Label">TXT-6</span>

`<b>`で使うスタイルです。注意だけを引きたい場合に使うキーワードや製品名などに使用します。

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from
  b.sw-Bold  the vehicle’s main wiring harness.
```
*/
.sw-Bold {
  font-weight: 700;
}
