.st-PrivacyNotice {
  height: rem(38);
  position: relative;
  z-index: 0;
  border-bottom: 1px solid #e6e6e6;
}
.st-PrivacyNotice_Inner {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 rem(15);
  text-align: right;

  @include mq-up(md) {
    margin: 0 auto;
    padding: 0 rem(12);
  }

  @media print, screen and (min-width: ($max-width + 24px)) {
    max-width: $max-width;
    padding-right: 0;
    padding-left: 0;
  }
}
.st-PrivacyNotice_Link {
  display: inline-flex;
  align-items: center;
  font-size: rem(12);
  line-height: 1.42;
  color: $color-brand;
  text-decoration: none;

  @include mq-up(md) {
    font-size: rem(14);
    line-height: 1.36;
  }

  &:hover {
    text-decoration: underline;
    color: $color-brand;
  }
}
.st-PrivacyNotice_LinkIcon {
  margin-left: rem(4);
  font-size: rem(10);

  @include mq-up(md) {
    margin-left: rem(6);
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    color: currentColor;
  }
}
