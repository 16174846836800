/*
---
name: office-BoxA
namespace: office-
category: Block
id: BOX-6
---

<span class="sw-Label">BOX-6</span>

ProductsのSales Office一覧です。Productsのカテゴリ内のSales Officeトップページで使用しています。

- オフィスごとに複数人が入る場合があります

```jade
ul.office-BoxA
  li.office-BoxA_Item
    h2.office-BoxA_Country Great Britain
    p.office-BoxA_CompanyName KYOCERA Fineceramics Ltd.
    p.office-BoxA_Text
      | Prospect House, Archipelago 
      br
      | Lyon Way, Frimley 
      br
      | Surrey GU16 7ER
    p.office-BoxA_Text
      | Phone: +44 (0)1276 - 69 34 50
      br
      | Fax: +44 (0)1276 - 69 34 60
    a.office-BoxA_Link(href="#") http://www.xxxx.com
      span.office-BoxA_IconExternal(aria-hidden="true")
    a.office-BoxA_Mail(href="mailto:salessupport@kyocera.de")
      span.office-BoxA_MailIcon(aria-hidden="true")
      span.office-BoxA_MailText salessupport@kyocera.de
    p.office-BoxA_Text Contact person: Jens Wittau
  li.office-BoxA_Item
    h2.office-BoxA_Country France
    p.office-BoxA_CompanyName KYOCERA Fineceramics SAS
    p.office-BoxA_Text
      | Parc Icade Orly - Rungis
      br
      | 21 rue de Villeneuve
      br
      | 94150 Rungis
    p.office-BoxA_Text
     | Phone: +33 (0)1 41 73 73 30
     br
     | Fax: +33 (0)1 41 73 73 59
    a.office-BoxA_Link(href="#") http://www.xxxx.com
      span.office-BoxA_IconExternalB(aria-hidden="true")
    a.office-BoxA_Mail(href="mailto:sales.france@kyocera.de")
      span.office-BoxA_MailIcon(aria-hidden="true")
      span.office-BoxA_MailText sales.france@kyocera.de
    p.office-BoxA_Text Contact person: Jens Wittau
  li.office-BoxA_Item
    h2.office-BoxA_Country Germany
    p.office-BoxA_CompanyName KYOCERA Fineceramics GmbH
    ul.office-BoxA_Grid
      li.office-BoxA_GridItem
        p.office-BoxA_Text
          | Fritz-Müller-Straße 27
          br
          | 73730 Esslingen
        p.office-BoxA_Text
          | Phone: +49 (0)711 - 93 93 4-0
          br
          | Fax: +49 (0)711 - 93 93 4-950
        a.office-BoxA_Link(href="#") http://www.xxxx.com
        a.office-BoxA_Mail(href="mailto:info@kyocera.de")
          span.office-BoxA_MailIcon(aria-hidden="true")
          span.office-BoxA_MailText info@kyocera.de
        p.office-BoxA_Text Contact person: Jens Wittau
      li.office-BoxA_GridItem
        p.office-BoxA_Text
          | Hammfelddamm 6
          br
          | 41460 Neuss
        p.office-BoxA_Text
          | Phone: +49 (0)2131 - 16 37-0
          br
          | Fax: +49 (0)2131 - 16 37-150
        a.office-BoxA_Link(href="#") http://www.xxxx.com
        a.office-BoxA_Mail(href="mailto:info@kyocera.de")
          span.office-BoxA_MailIcon(aria-hidden="true")
          span.office-BoxA_MailText info@kyocera.de
        p.office-BoxA_Text Contact person: Jens Wittau
```

`.sw-Heading3A`の入れ子になっている場合は、`.office-BoxA_CompanyNameB`を使用することもできます。

```jade
section.l-Section3
  h3.sw-Heading3A I. Controller/Contact
  p.sw-Text Controller, according to the applicable data protection law, is
  ul.office-BoxA
    li.office-BoxA_Item
      p.office-BoxA_CompanyNameB KYOCERA Fineceramics GmbH
      p.office-BoxA_Text
        | Fritz-Müller-Straße 27
        br
        | 73730 Esslingen
      p.office-BoxA_Text
        | Phone: +49 (0)711 - 93 93 4-0
        br
        | Fax: +49 (0)711 - 93 93 4-950
      a.office-BoxA_Mail(href="mailto:webmaster@kyocera.de")
        span.office-BoxA_MailIcon(aria-hidden="true")
        span.office-BoxA_MailText webmaster@kyocera.de
      p.sw-Text Handelsregisternummer: HRB 210 334
      p.sw-Text Geschäftsführer: Shoichi Aoki, Masafumi Ikeuchi, Ken Ishii, Koichi Kano, Shigeru Koyama, Yusuke Mizukami, Rafael Schroeer, Hideo Tanimoto, Goro Yamaguchi
      p.sw-Text For any questions you may have regarding the processing of your personal data or data protection in general, please contact us as stated above or write an email at
        a.sw-Link(href="mailto:privacy@kyocera.de") privacy@kyocera.de.
```
*/
.office-BoxA {
  height: 100%;
  margin-top: rem(30);
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-top: rem(40);
  }

  & > :last-child {
    margin-bottom: 0;
  }

  .cms-Component_Editor & {
    height: auto;
  }
}

.office-BoxA_Item {
  &:nth-of-type(n + 2)::before {
    content: "";
    display: block;
    margin-top: rem(30);
    padding-bottom: rem(30);
    border-top: 1px solid #d8d8d8;

    @include mq-up(md) {
      margin-top: rem(40);
      padding-bottom: rem(40);
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }

  // MTの仕様上、`.office-BoxA`に含める必要があるため
  & > .sw-Image {
    margin-top: rem(40);

    @include mq-up(md) {
      margin-top: rem(50);
    }
  }
}

.office-BoxA_Country {
  margin-bottom: rem(30);
  font-size: rem(24);
  font-weight: 600;
  line-height: 1.3;
  color: #000;
}

.office-BoxA_CompanyName {
  position: relative;
  margin-bottom: rem(10);
  font-size: rem(18);
  line-height: 1.3;
  color: $color-red;
}

.office-BoxA_CompanyNameB {
  margin-bottom: rem(10);
  font-size: rem(16);
  font-weight: 700;
  line-height: 1.6;
  color: $color-gray-minus2;
}

.office-BoxA_Grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-15);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-left: rem(-30);
  }
}

.office-BoxA_GridItem {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: rem(15);

  @include mq-up(md) {
    padding-left: rem(30);

    &:nth-of-type(odd) {
      flex-basis: percentage(373 / 791);
      max-width: percentage(373 / 791);
    }

    &:nth-of-type(even) {
      flex-basis: percentage(418 / 791);
      max-width: percentage(418 / 791);
    }
  }

  &:nth-of-type(n + 2) {
    margin-top: rem(40);

    @include mq-up(md) {
      margin-top: 0;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.office-BoxA_Text {
  margin-bottom: rem(10);
  font-size: rem(16);
  line-height: 1.6;
  color: #444;
}

.office-BoxA_Link {
  display: inline-block;
  margin-bottom: rem(5);
  text-decoration: none;
  word-break: break-all;
  font-size: rem(16);
  line-height: 1.4;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.office-BoxA_IconExternal {
  display: inline;
  margin-left: rem(4);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.2em;
  }
}

.office-BoxA_IconExternalB {
  display: inline;
  margin-left: rem(4);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.2em;
  }
}

.office-BoxA_Mail {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
  text-decoration: none;
  word-break: break-all;
  font-size: rem(16);
  line-height: 1.2;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.office-BoxA_MailIcon {
  position: relative;
  height: rem(16);
  margin-top: 0.2em;
  margin-right: rem(6);
  font-size: rem(16);

  &::before {
    @include icon;

    content: "#{$icon-mail}";
    position: relative;
    top: -0.2em;
  }
}

.office-BoxA_MailText {
}

.office-BoxA_Form {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.2;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.office-BoxA_FormText {
}

.office-BoxA_FormIcon {
  position: relative;
  margin-left: rem(7);
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.3em;
  }
}
