/*
---
name: sw-ListDefinition
namespace: sw-
category: List
id: LIST-4
---

<span class="sw-Label">LIST-4</span>

定義リストです。

```jade
dl.sw-ListDefinition
  dt About Sheltap<sup class="sw-Sup">®</sup>
  dd Kyocera’s Sheltap series of branch-style electronic connectors are engineered for automotive use. The clamshell design allows Sheltap connectors to branch and distribute electricity easily and with long-term reliability, even in harsh environments. Sheltap is registered trademarks of Kyocera in Japan, U.S.A., China, and Europe
```
*/
.sw-ListDefinition {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  color: $color-gray-minus2;
}

.sw-ListDefinition dt {
  font-size: rem(16);
  font-weight: 700;
  line-height: 1.3;
}

.sw-ListDefinition dd {
  margin-top: rem(7);
  font-size: rem(15);
  line-height: 1.4;
}
