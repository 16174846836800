/*
---
name: l-ButtonA
namespace: l-
category: Layout
id: [BTN-5A, BTN-5B, BTN-5B]
---

スマホでは縦並び、タブレット以降で横並び（横幅は均等）になります。

<span class="sw-Label">BTN-5A</span>

```jade
ul.l-ButtonA
  li.l-ButtonA_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
```

<span class="sw-Label">BTN-5B</span>

```jade
ul.l-ButtonA
  li.l-ButtonA_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
  li.l-ButtonA_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
```

<span class="sw-Label">BTN-5B</span>

```jade
ul.l-ButtonA
  li.l-ButtonA_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
  li.l-ButtonA_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
  li.l-ButtonA_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
```
*/
.l-ButtonA {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: rem(20);
  margin-bottom: rem(20);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: rem(-30);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.l-ButtonA_Item {
  flex-grow: 1;
  margin-bottom: rem(20);

  @include mq-up(md) {
    margin-bottom: 0;
    padding-left: rem(30);
  }
}
