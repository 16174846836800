/*
---
name: swmoffice-BoxA
namespace: office-
category: Block
id: M_BOX-6
---

<span class="sw-Label">M_BOX-6</span>

```jade
ul.swmoffice-BoxA
  li.swmoffice-BoxA_Item
    p.swmoffice-BoxA_CompanyNameB KYOCERA Fineceramics GmbH
    p.swmoffice-BoxA_Text
      | Fritz-Müller-Straße 27
      br
      | 73730 Esslingen
    p.swmoffice-BoxA_Text
      | Phone: +49 (0)711 - 93 93 4-0
      br
      | Fax: +49 (0)711 - 93 93 4-950
    a.swmoffice-BoxA_Mail(href="mailto:webmaster@kyocera.de")
      span.swmoffice-BoxA_MailIcon(aria-hidden="true")
      span.swmoffice-BoxA_MailText webmaster@kyocera.de
    p.sw-Text For any questions you may have regarding the processing of your personal data or data protection in general, please contact us as stated above or write an email at
      a.sw-Link(href="mailto:privacy@kyocera.de") privacy@kyocera.de.
```
*/
.swmoffice-BoxA {
  margin-top: rem(30);
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-top: rem(40);
  }

  & > :last-child {
    margin-bottom: 0;
  }

  .cms-Component_Editor & {
    height: auto;
  }
}

.swmoffice-BoxA_Item {
  &:nth-of-type(n + 2)::before {
    content: "";
    display: block;
    margin-top: rem(30);
    padding-bottom: rem(30);
    border-top: 1px solid #d8d8d8;

    @include mq-up(md) {
      margin-top: rem(40);
      padding-bottom: rem(40);
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }

  // MTの仕様上、`.swmoffice-BoxA`に含める必要があるため
  & > .sw-Image {
    margin-top: rem(40);

    @include mq-up(md) {
      margin-top: rem(50);
    }
  }
}

.swmoffice-BoxA_CompanyNameB {
  margin-bottom: rem(10);
  font-size: rem(16);
  font-weight: 700;
  line-height: 1.6;
  color: $color-gray-minus2;
}

.swmoffice-BoxA_Text {
  margin-bottom: rem(10);
  font-size: rem(16);
  line-height: 1.6;
  color: #444;
}

.swmoffice-BoxA_Link {
  display: inline-block;
  margin-bottom: rem(5);
  text-decoration: none;
  word-break: break-all;
  font-size: rem(16);
  line-height: 1.4;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.swmoffice-BoxA_Mail {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
  text-decoration: none;
  word-break: break-all;
  font-size: rem(16);
  line-height: 1.2;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.swmoffice-BoxA_MailIcon {
  position: relative;
  height: rem(16);
  margin-top: 0.2em;
  margin-right: rem(6);
  font-size: rem(16);

  &::before {
    @include icon;

    content: "#{$icon-mail}";
    position: relative;
    top: -0.2em;
  }
}

.swmoffice-BoxA_MailText {
}
