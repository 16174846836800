.result-Contents {
  padding-bottom: rem(40);

  @include mq-up(md) {
    padding-top: rem(15);
    padding-bottom: rem(60);
  }
}

.result-Contents_Item {
  padding: rem(20) 0;
  border-bottom: 1px solid $color-gray-plus2;

  @include mq-up(md) {
    padding: rem(35) 0;
  }

  &:after {
    @include mq-up(md) {
      content: "";
      display: block;
      clear: both;
    }
  }
}

.result-Contents_Image {
  display: none;

  @include mq-up(md) {
    display: block;
    float: left;
    width: rem(200);
    padding-right: rem(30);
  }
}

.result-Contents_Image img {
  display: block;
}

.result-Contents_body {
  @include mq-up(md) {
    overflow: hidden;
  }
}

.result-Contents_Title {
  margin-bottom: rem(5);
  font-size: rem(18);
  font-weight: 600;
  line-height: 1.3;
  color: $color-black;

  @include mq-up(md) {
    font-size: rem(20);
  }
}

.result-Contents_Title .SS_highlight {
  font-weight: 700;
}

.result-Contents_Link {
  margin-bottom: rem(10);
}

.result-Contents_Link a {
  text-decoration: none;
  font-size: rem(15);
  line-height: 1.4;
  color: $color-red;

  @include mq-up(md) {
    line-height: 1.5;
  }

  &:hover {
    text-decoration: underline;
  }
}

.result-Contents_Link a .SS_highlight {
  font-weight: 700;
}

.result-Contents_Link a[href*=".pdf"]::after {
  @include icon;

  content: "#{$icon-pdf}";
  height: rem(15);
  margin-left: rem(6);
  font-size: rem(15);
}

.result-Contents_Summary {
  margin-bottom: rem(10);
  font-size: rem(15);
  line-height: 1.4;
  color: $color-gray-minus1;

  &::after {
    content: "…";
  }
}

.result-Contents_Summary .SS_highlight {
  font-weight: 700;
}

.result-Contents_LastModified {
  font-size: rem(15);
  line-height: 1.4;
  color: $color-gray-minus1;
}
