/*
---
name: contact-BoxA
namespace: contact-
category: Block
---

Support / Contactページで使用する一覧です。

```jade
section.l-Section2
  h2.sw-Heading2A#btob Components / Industrial
  ul.l-Grids.l-Grids-col3Md.contact-Content
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_industrial-ceramics.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Industrial Ceramics
          a.contact-BoxA_Link(href="https://contact.kyocera.co.jp/inquiry/gl/cera/input.html", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_semiconductor-packaging.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Semiconductor Packaging
          a.contact-BoxA_Link(href="https://contact.kyocera.co.jp/inquiry/us/sc/input.html")
            | Contact
            span.contact-BoxA_Icon(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_electronic-components.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Electronic Components
          a.contact-BoxA_Link(href="https://contact.kyocera.co.jp/inquiry/gl/elect/input.html", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_uv-led-curingsystem.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title UV-LED Curing System
          a.contact-BoxA_Link(href="https://contact.kyocera.co.jp/inquiry/us/pd/input.html")
            | Contact
            span.contact-BoxA_Icon(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_thermal-printheads.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Thermal Printheads
          a.contact-BoxA_Link(href="https://contact.kyocera.co.jp/inquiry/us/pd/input.html")
            | Contact
            span.contact-BoxA_Icon(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_inkjet-printheads.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Inkjet Printheads
          a.contact-BoxA_Link(href="https://contact.kyocera.co.jp/inquiry/us/pd/input.html")
            | Contact
            span.contact-BoxA_Icon(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_liquid-crystal-displays.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Liquid Crystal Displays
          a.contact-BoxA_Link(href="http://www.kyocera-display.com/contacts/corp.asp", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_lens-components.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Lens Components
          a.contact-BoxA_Link(href="https://global.kyocera.com/prdct/optec/contact.html", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_medical-products.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Medical Products
          a.contact-BoxA_Link(href="https://www.kyocera-medical.com/contact-us-2/", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_cutting-tool-Indexable.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Cutting Tool – Indexable
          a.contact-BoxA_Link(href="http://www.kyoceraprecisiontools.com/contact/", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_cutting-tool-solid-round.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Cutting Tool - Solid Round
          a.contact-BoxA_Link(href="http://www.kyoceraprecisiontools.com/contact/", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_cutting-tool-printed-circuit-board.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Cutting Tool - Printed Circuit Board
          a.contact-BoxA_Link(href="http://www.kyoceraprecisiontools.com/contact/", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_organic-chemical-materials.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Organic Chemical Materials
          a.contact-BoxA_Link(href="https://contact.kyocera.co.jp/inquiry/us/sc/input.html")
            | Contact
            span.contact-BoxA_Icon(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_solar-solutions-for-business.png" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Solar Solutions for Business
          a.contact-BoxA_Link(href="https://www.kyocerasolar.com/contact/", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
    li.l-Grids_Item
      .contact-BoxA
        .contact-BoxA_Media
          img.contact-BoxA_Thumbnail(src="/assets/img/index/image_information-communication-and-security-technology.jpg" alt)
        .contact-BoxA_Body
          h3.contact-BoxA_Title Endpoint Security Software
          a.contact-BoxA_Link(href="https://interfocus.us/", target="_blank")
            | Contact
            span.contact-BoxA_ExternalA(aria-hidden="true")
```
*/
.contact-BoxA {
  display: flex;
  height: 100%;
  padding-bottom: rem(30);
  border-bottom: 1px solid $color-gray-plus2;

  .l-Grids_Item:nth-of-type(n + 2) & {
    padding-top: rem(18);

    @include mq-up(md) {
      padding-top: 0;
    }
  }
}

.contact-BoxA_Media {
  flex-basis: percentage(110 / 345);
  max-width: percentage(110 / 345);

  @include mq-up(md) {
    flex-basis: percentage(150 / 370);
    max-width: percentage(150 / 370);
  }
}

.contact-BoxA_Thumbnail {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
  }
}

.contact-BoxA_Body {
  flex-basis: percentage(235 / 345);
  max-width: percentage(235 / 345);
  padding-left: rem(15);

  @include mq-up(md) {
    flex-basis: percentage(220 / 370);
    max-width: percentage(220 / 370);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.contact-BoxA_Title {
  margin-bottom: rem(18);
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.3;
  color: $color-gray-minus2;

  @include mq-up(md) {
    margin-bottom: rem(20);
  }
}

.contact-BoxA_Link {
  display: block;
  position: relative;
  margin-bottom: rem(10);
  text-decoration: none;
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.2;
  color: $color-red;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    &:hover {
      text-decoration: underline;
      color: $color-link-hover;
    }
  }
}

.contact-BoxA_Icon {
  display: inline-block;
  vertical-align: baseline;
  height: rem(12);
  margin-left: rem(6);
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.15em;
  }

  .sw-LinkArrow_Link:hover & {
    color: $color-red;
  }
}

.contact-BoxA_ExternalA {
  display: inline-block;
  vertical-align: baseline;
  height: rem(14);
  margin-left: rem(5);
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.15em;
  }

  .sw-LinkArrow_Link:hover & {
    color: $color-red;
  }
}
