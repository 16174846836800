.home-Heading2 {
  margin-bottom: rem(18);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: em(4.29, 24);
  font-family: $font-family-pt-sans;
  font-size: rem(24);
  font-weight: 400;
  line-height: 1.3;
  color: #444;

  @include mq-up(md) {
    margin-bottom: rem(30);
    letter-spacing: em(5.36, 30);
    font-size: rem(30);
    color: #000;
  }

  & > span {
    display: inline-block;
    width: auto;
    padding: 0 rem(6) rem(8);
    border-bottom: 3px solid #dadada;

    @include mq-up(md) {
      padding: 0 0 rem(8);
    }
  }
}
