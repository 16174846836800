// @desc - メディアクエリを`min-width`で挿入します。
// @param {String} $breakpoint [$default-breakpoint] - 引数に変数のキーワードを渡します。
// @see - $breakpoint-down
// @example scss - Usage
// .foo {
//   color: red;
//   @include mq-up {
//     color: blue;
//   }
// }
// @example css - CSS output
// .foo {
//   color: red;
// }
// @media (max-width: 767px) {
//   .foo {
//     color: blue;
//   }
// }
@mixin mq-down($breakpoint: $default-breakpoint) {
  @if map-has-key($breakpoint-down, $breakpoint) {
    @media #{inspect(map-get($breakpoint-down, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoint-down` map.";
  }
}
