/*
---
name: l-Section4
namespace: l-
category: Layout
---

h4相当の見出しをラップするセクションの余白を指定します。

```jade
.l-Wrapper
  p.sw-Text 吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。

  section.l-Section3
    h3.sw-Heading3A 吾輩はここで始めて人間というものを見た。
    section.l-Section4
      h4.sw-Heading4A 吾輩はここで始めて人間というものを見た。
      p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。

  section.l-Section3
    h3.sw-Heading3A 吾輩はここで始めて人間というものを見た。
    p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。

    section.l-Section4
      h4.sw-Heading4A 吾輩はここで始めて人間というものを見た。
      p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
    section.l-Section4
      h4.sw-Heading4A 吾輩はここで始めて人間というものを見た。
      p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
```

`.l-BlockA-divider`をつけるとボーダーが付きます。

```jade
.l-Wrapper
  p.sw-Text 吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。

  section.l-Section3
    h3.sw-Heading3A 吾輩はここで始めて人間というものを見た。
    section.l-Section4.l-Section4-divider
      h4.sw-Heading4A 吾輩はここで始めて人間というものを見た。
      p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
    section.l-Section4.l-Section4-divider
      h4.sw-Heading4A 吾輩はここで始めて人間というものを見た。
      p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
```
*/
.l-Section4 {
  margin-top: rem(20);
  margin-bottom: rem(20);

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  &.l-Section4-divider:nth-of-type(n + 2)::before {
    content: "";
    display: block;
    padding-top: rem(20);
    border-top: 1px solid $color-gray-plus3;
  }
}
