/*
---
name: st-RelatedA
namespace: st-
category: Global
id: [LINK-9, BOX-3]
---

<span class="sw-Label">LINK-9</span><span class="sw-Label">BOX-3</span>

サイドバーに配置する関連情報です。

```jade
.l-DetailTemplate_related
  .st-RelatedA
    p.st-RelatedA_Heading Download
    ul.st-RelatedA_List
      li.st-RelatedA_Item
        a.st-RelatedA_Link(href="#")
          span.st-RelatedA_DownloadIcon(aria-hidden="true")
          span.st-RelatedA_Text Multi Layer - Open tooled package list
            span.st-RelatedA_Size  (50.30 KB)
            span.st-RelatedA_ExternalAIcon(aria-hidden="true")
      li.st-RelatedA_Item
        a.st-RelatedA_Link(href="#")
          span.st-RelatedA_DownloadIcon(aria-hidden="true")
          span.st-RelatedA_Text Single Layer - Open tooled package list
            span.st-RelatedA_Size  (52.61 KB)
            span.st-RelatedA_ExternalBIcon(aria-hidden="true")
.l-DetailTemplate_related
  .st-RelatedA
    p.st-RelatedA_Heading Related Links
    ul.st-RelatedA_List
      li.st-RelatedA_Item
        a.st-RelatedA_Link(href="#")
          span.st-RelatedA_RelatedIcon(aria-hidden="true")
          span.st-RelatedA_Text Organic Packages (Global Website)
            span.st-RelatedA_ExternalAIcon(aria-hidden="true")
      li.st-RelatedA_Item
        a.st-RelatedA_Link(href="#")
          span.st-RelatedA_RelatedIcon(aria-hidden="true")
          span.st-RelatedA_Text Semiconductor Components (Global Website)
            span.st-RelatedA_ExternalBIcon(aria-hidden="true")
```
*/
.st-RelatedA {
  padding-top: rem(10);
  padding-bottom: rem(30);
  border-top: 2px solid $color-gray-plus2;
}

.st-RelatedA_Heading {
  margin-bottom: rem(20);
  text-transform: uppercase;
  letter-spacing: em(1, 12);
  font-size: rem(12);
  font-weight: 700;
  line-height: 1.3;
  color: $color-gray-minus2;
}

.st-RelatedA_List {
  padding-left: 0;
  list-style-type: none;

  & > :last-child {
    margin-bottom: 0;
  }
}

.st-RelatedA_Item {
  margin-bottom: rem(10);
}

.st-RelatedA_Link {
  display: flex;
  text-decoration: none;
  font-size: rem(14);
  line-height: 1.4;
  color: $color-gray-minus2;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: underline;
    color: $color-red;
  }
}

.st-RelatedA_Text {
  flex-basis: 0;
  flex-grow: 1;
  font-size: rem(13);
}

.st-RelatedA_DownloadIcon {
  width: rem(23);
  height: rem(16);
  font-size: rem(16);
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-cloud-download}";
    position: relative;
    top: -0.3em;
  }
}

.st-RelatedA_RelatedIcon {
  width: rem(27);
  padding-left: rem(4);
  font-size: rem(12);
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-link}";
    position: relative;
    top: 0.1em;
  }
}

.st-RelatedA_ExternalAIcon {
  margin-left: rem(4);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.1em;
  }
}

.st-RelatedA_ExternalBIcon {
  margin-left: rem(4);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.1em;
  }
}

.st-RelatedA_Size {
  color: #999;
  transition-duration: $transition-duration;

  .st-RelatedA_Link:hover & {
    color: $color-red;
  }
}
