/*
---
name: sw-ImageB
namespace: sw-
category: Image
id: IMG-7
---

<span class="sw-Label">IMG-7</span>

- 画像を中央揃えにします
- タブレット以降で画面幅50%で表示します
- 画像自体はなりゆきです
- キャプションは<a href="/styleguide/id/IMG-2A/index.html">IMG-2A</a>と似ていますが、コードは違うので注意してください

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronicshas created new demand for connectors that can
.sw-ImageB
  img.sw-ImageB_Image(src="/assets/img/common/img_690_296.jpg" alt="")
```

- キャプションあり

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronicshas created new demand for connectors that can
.sw-ImageB
  figure.sw-ImageB_Figure
    img.sw-ImageB_Image(src="/assets/img/common/img_690_296.jpg" alt="")
    figcaption.sw-ImageB_Caption Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
```

- キャプションあり
- 背景色あり

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronicshas created new demand for connectors that can
.sw-ImageB
  figure.sw-ImageB_Figure.sw-ImageB_Figure-bg
    img.sw-ImageB_Image(src="/assets/img/common/img_690_296.jpg" alt="")
    figcaption.sw-ImageB_Caption Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
```
*/
.sw-ImageB {
  width: 100%;
  margin-top: rem(20);
  margin-bottom: rem(20);

  @include mq-up(md) {
    margin-top: rem(30);
    margin-bottom: rem(30);
    padding: 0 25%;
  }
}

.sw-ImageB_Figure {
}

.sw-ImageB_Figure-bg {
  padding: rem(10);
  background-color: #f2f4f6;
}

.sw-ImageB_Image {
  display: block;
  width: 100%;
  margin: auto;

  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: block;
    width: 100%;
    margin: auto;
  }
}

.sw-ImageB_Caption {
  display: block;
  margin: auto;
  padding-top: rem(10);
  text-align: center;
  font-size: rem(13);
  line-height: 1.3;
  color: $color-gray-minus2;

  &.sw-ImageB-bg {
    padding: rem(10) rem(10) rem(15);
    background-color: #f2f4f6;
  }
}
