.st-GlobalHeader_Logo {
  @include mq-up(lg) {
    width: 314px;
  }
}

.st-GlobalNav {
  @include mq-up(lg) {
    width: calc(100% - 314px - 234px);
  }
}

.st-GlobalSiteNav_SubItem {
  @include mq-up(lg) {
    // Careers
    .st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-career + .st-GlobalSiteNav_SubArea & {
      width: percentage(1 / 3);
    }
  }
}
