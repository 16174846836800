/*
---
name: sw-Caption
namespace: sw-
category: Image
id: [IMG-2A, IMG-2B, IMG-2C]
---

<span class="sw-Label">IMG-2A</span>

画像にキャプション（短い説明文）をつけます。キャプションは画像の横幅を基準に折り返されます。

ベーシックなスタイルです。

```jade
figure.sw-Caption
  span.sw-Caption_Image
    img(src="/assets/img/common/img_290_180.jpg" alt="")
  figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
```

```jade
figure.sw-Caption
  span.sw-Caption_Image
    img(src="/assets/img/common/img_290_180.jpg" alt="")
  figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
figure.sw-Caption
  span.sw-Caption_Image
    img(src="/assets/img/common/img_290_180.jpg" alt="")
  figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
```

画像の横並びは、2つ並べた画像を用意します。

```jade
figure.sw-Caption
  span.sw-Caption_Image
    img(src="/assets/img/common/img_710_230.jpg" alt="")
  figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
```

<span class="sw-Label">IMG-2B</span>

```jade
figure.sw-Caption.sw-Caption-Bg
  span.sw-Caption_Image
    img(src="/assets/img/common/img_290_180.jpg" alt="")
  figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
```

<span class="sw-Label">IMG-2C</span>

```jade
figure.sw-Caption.sw-Caption-center
  span.sw-Caption_Image
    img(src="/assets/img/common/img_290_180.jpg" alt="")
  figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
```
*/

/**
 * 1. キャプションの最小幅です。
 *    画像が指定幅以下になってもキャプションの幅はそれ以上小さくなりません。
 */
.sw-Caption {
  display: table;
  min-width: 100px; /* 1 */
  margin-top: rem(20);
  margin-bottom: rem(20);

  &.sw-Caption-full {
    width: 100%;
  }

  &.sw-Caption-Bg {
    padding: rem(10);
    padding-bottom: 0;
    background-color: #f2f4f6;
  }

  .l-MediaA_Image & {
    display: block;
    min-width: auto;
  }
}

/* CMSの仕様のためimgにクラス名をつけれない */
.sw-Caption_Image {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    .sw-Caption-full & {
      width: 100%;
      max-width: none;
    }
  }
}

.sw-Caption-center {
  margin-right: auto;
  margin-left: auto;
}

/**
 * 1. キャプションは常に画像の下に配置します
 */
.sw-Caption_Text {
  display: table-caption;
  padding-top: rem(10);
  text-align: center;
  font-size: rem(13);
  line-height: 1.3;
  color: $color-gray-minus2;
  caption-side: bottom; /* 1 */

  .sw-Caption-Bg & {
    padding: rem(10) rem(10) rem(15);
    background-color: #f2f4f6;
  }

  .l-MediaA_Image & {
    display: block;
  }
}
