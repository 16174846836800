/*
---
name: sw-LinkMail
namespace: sw-
category: Link
id: [LINK-4, LINK-5]
---

<span class="sw-Label">LINK-4</span>

メールへのリンクであることを示す場合に使います。

```jade
.sw-LinkMail
  span.sw-LinkMail_Icon(aria-hidden="true")
  a.sw-LinkMail_Link(href="mailto:webmaster.pressglwebmaster.pressglwebmaster.pressgl@kyocera.jp") webmaster.pressglwebmaster.pressglwebmaster.pressgl@kyocera.jp
```

入れ子にする場合は、`.sw-LinkMail`を`span`にしても構いません。

```jade
p.sw-Text
  | Phone: +49 6074 48 510
  br
  span.sw-LinkMail
    span.sw-LinkMail_Icon(aria-hidden="true")
    a.sw-LinkMail_Link(href="mailto:info@kyocera-display.eu") info@kyocera-display.eu
```

<span class="sw-Label">LINK-5</span>

サイズを小さくします。

```jade
.sw-LinkMail.sw-LinkMail-small
  span.sw-LinkMail_Icon(aria-hidden="true")
  a.sw-LinkMail_Link(href="mailto:webmaster.pressglwebmaster.pressglwebmaster.pressgl@kyocera.jp") webmaster.pressglwebmaster.pressglwebmaster.pressgl@kyocera.jp
```

*/
.sw-LinkMail {
  display: block;
  position: relative;
  margin-left: rem(27);
  text-decoration: none;
  font-size: rem(15);
  font-weight: 600;
  line-height: 1.3;
  color: $color-red;
}

.sw-LinkMail-small {
  font-size: rem(13);
}

.sw-LinkMail_Icon {
  position: absolute;
  top: rem(5);
  left: rem(-27);
  height: rem(12);
  font-size: rem(17);

  .sw-LinkMail-small & {
    font-size: rem(16);
  }

  &::before {
    @include icon;

    content: "#{$icon-mail}";
    position: relative;
    top: -0.35em;
  }
}

.sw-LinkMail_Link {
  text-decoration: none;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: $color-red;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: underline;
  }
}

.sw-LinkMail_Size {
  color: #999;
}
