/*
---
name: sw-FormRadio
namespace: sw-
category: Form
id: FO-2
---

<span class="sw-Label">FO-2</span>

`type="radio"`のデフォルトスタイルです。

```jade
input.sw-FormRadio(type="radio" id="radio1" name="radio1" value="0" checked)
label(for="radio1")
  span Radio label
input.sw-FormRadio(type="radio" id="radio2" name="radio1" value="1")
label(for="radio2")
  span Radio labelRadio labelRadio labelRadio labelRadio labelRadio label
```
*/
.sw-FormRadio {
  @include sw-FormRadio();
}
