/*
---
name: swm-NavB
namespace: swm-
category: Navigation
id: [M_CARD-2A, M_CARD-2B]
---

<span class="sw-Label">M_CARD-2A</span>

Productsページで使用されるナビゲーションです。

- ボタンに.swm-LinkIcon~を付けるとリンクアイコンを表示します

スマホ1カラム、タブレット2カラム

```jade
.swm-NavB.swm-NavB-col2Md
  .swm-NavB_Item
    a.swm-NavB_Box.swm-LinkIconArrow(href="#")
      .swm-NavB_Head
        span.swm-NavB_Thumbnail
          img(src="/assets/img/common/img_690_224.jpg" alt)
      .swm-NavB_Body.js-swmLinkIcon
        | Automotive
      .swm-NavB_Foot Alumina is the most widely used material among fine ceramics, and has the same sintered crystal structure (α-Al2O3) as sapphire and ruby. Its application is diverse due to its superb properties such as high insulation, high strength, high wear resistance and chemical resistance.
  .swm-NavB_Item
    a.swm-NavB_Box.swm-LinkIconArrow(href="#")
      .swm-NavB_Head
        span.swm-NavB_Thumbnail
          img(src="/assets/img/common/img_690_224.jpg" alt)
      .swm-NavB_Body.js-swmLinkIcon
        | LED Lighting
      .swm-NavB_Foot Silicon nitride (Si3N4) exceeds other ceramics in thermal shock resistance. As its strength does not deteriorate at elevated temperatures, it is appropriate material for automotive engine and gas turbine parts, including turbo-charger rotors, diesel engine glow plugs and hot plugs.
```

<span class="sw-Label">M_CARD-2B</span>

スマホ1カラム、タブレット3カラム

```jade
.swm-NavB.swm-NavB-col3Md
  .swm-NavB_Item
    a.swm-NavB_Box.swm-LinkIconArrow(href="#")
      .swm-NavB_Head
        span.swm-NavB_Thumbnail
          img(src="/assets/img/common/img_690_224.jpg" alt)
      .swm-NavB_Body.js-swmLinkIcon
        | Automotive
      .swm-NavB_Foot Alumina is the most widely used material among fine ceramics, and has the same sintered crystal structure (α-Al2O3) as sapphire and ruby. Its application is diverse due to its superb properties such as high insulation, high strength, high wear resistance and chemical resistance.
  .swm-NavB_Item
    a.swm-NavB_Box.swm-LinkIconA(href="#")
      .swm-NavB_Head
        span.swm-NavB_Thumbnail
          img(src="/assets/img/common/img_690_224.jpg" alt)
      .swm-NavB_Body.js-swmLinkIcon
        | LED Lighting
      .swm-NavB_Foot Silicon nitride (Si3N4) exceeds other ceramics in thermal shock resistance. As its strength does not deteriorate at elevated temperatures, it is appropriate material for automotive engine and gas turbine parts, including turbo-charger rotors, diesel engine glow plugs and hot plugs.
  .swm-NavB_Item
    a.swm-NavB_Box.swm-LinkIconB(href="#")
      .swm-NavB_Head
        span.swm-NavB_Thumbnail
          img(src="/assets/img/common/img_690_224.jpg" alt)
      .swm-NavB_Body.js-swmLinkIcon
        | Camera Image Sensors
      .swm-NavB_Foot Alumina is the most widely used material among fine ceramics, and has the same sintered crystal structure (α-Al2O3) as sapphire and ruby. Its application is diverse due to its superb properties such as high insulation, high strength, high wear resistance and chemical resistance.
```
*/
$swm-NavB-column-gap: rem(15);
$swm-NavB-row-gap: rem(15);
$swm-NavB-column-gap-md: rem(30);
$swm-NavB-row-gap-md: rem(25);

.swm-NavB {
  display: flex;
  flex-wrap: wrap;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  margin-left: -$swm-NavB-column-gap;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-left: -$swm-NavB-column-gap-md;
  }
}

.swm-NavB_Item {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: $swm-NavB-column-gap;

  @include mq-up(md) {
    padding-left: $swm-NavB-column-gap-md;
  }

  /* 1 Column */
  &:nth-of-type(n + 2) {
    margin-top: $swm-NavB-column-gap;
  }
}

/* 2 Columns */
.swm-NavB-col2 > .swm-NavB_Item {
  flex-basis: percentage(1 / 2);
  max-width: percentage(1 / 2);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 3) {
    margin-top: $swm-NavB-row-gap;
  }
}

@include mq-up(md) {
  .swm-NavB-col2Md > .swm-NavB_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .swm-NavB-col2Lg > .swm-NavB_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .swm-NavB-col2Xl > .swm-NavB_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

/* 3 Columns */
.swm-NavB-col3 > .swm-NavB_Item {
  flex-basis: percentage(1 / 3);
  max-width: percentage(1 / 3);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 4) {
    margin-top: $swm-NavB-row-gap;
  }
}

@include mq-up(md) {
  .swm-NavB-col3Md > .swm-NavB_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .swm-NavB-col3Lg > .swm-NavB_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .swm-NavB-col3Xl > .swm-NavB_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

/* 4 Columns */
.swm-NavB-col4 > .swm-NavB_Item {
  flex-basis: percentage(1 / 4);
  max-width: percentage(1 / 4);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 5) {
    margin-top: $swm-NavB-row-gap;
  }
}

@include mq-up(md) {
  .swm-NavB-col4Md > .swm-NavB_Item {
    flex-basis: percentage(1 / 4);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .swm-NavB-col4Lg > .swm-NavB_Item {
    flex-basis: percentage(1 / 4);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .swm-NavB-col4Xl > .swm-NavB_Item {
    flex-basis: percentage(1 / 4);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

/* 5 Columns */
.swm-NavB-col5 > .swm-NavB_Item {
  flex-basis: percentage(1 / 5);
  max-width: percentage(1 / 5);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 6) {
    margin-top: $swm-NavB-row-gap;
  }
}

@include mq-up(md) {
  .swm-NavB-col5Md > .swm-NavB_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .swm-NavB-col5Lg > .swm-NavB_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .swm-NavB-col5Xl > .swm-NavB_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $swm-NavB-row-gap-md;
    }
  }
}

.swm-NavB_Box {
  display: block;
  height: 100%;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }

  .cms-Component_Editor & {
    height: auto;
  }
}

.swm-NavB_Head {
}

.swm-NavB_Thumbnail {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: block;
    width: 100%;
  }
}

.swm-NavB_Body {
  padding: rem(16) rem(15) rem(20);
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.375;
  background: #f2f4f6;
  color: $color-gray-minus2;

  @include mq-up(md) {
    font-size: rem(16);
  }
}

.swm-NavB_Icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: rem(12);
  margin-left: rem(3);
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.2em;
    color: $color-red;
  }
}

.swm-NavB_IconExternalA {
  position: relative;
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.1em;
    left: 0.5em;
    color: $color-red;
  }
}

.swm-NavB_IconExternalB {
  position: relative;
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.1em;
    left: 0.5em;
    color: $color-red;
  }
}

.swm-NavB_Foot {
  margin-bottom: rem(10);
  padding-top: rem(15);
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;

  @include mq-up(md) {
    margin-bottom: 0;
    padding-top: rem(20);
  }
}
