/*
---
name: l-ButtonD
namespace: l-
category: Layout
id: BTN-7B
---

全デバイスでなりゆきです。スマホでは最小幅60%です。

<span class="sw-Label">BTN-7B</span>

```jade
ul.l-ButtonD
  li.l-ButtonD_Item
    a.sw-Button.sw-Button-full(href="#") More
  li.l-ButtonD_Item
    a.sw-Button.sw-Button-full(href="#") More Details
  li.l-ButtonD_Item
    a.sw-Button.sw-Button-full(href="#") Button with Long Long Name
```
*/
.l-ButtonD {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: rem(20);
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    flex-direction: row;
    justify-content: flex-start;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.l-ButtonD_Item {
  min-width: 60%;
  margin-bottom: rem(20);

  @include mq-up(md) {
    min-width: auto;
    margin-right: rem(10);
    margin-bottom: 0;
  }
}
