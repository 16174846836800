.home-NewsBox {
  padding-left: 0;
  list-style-type: none;
}

.home-NewsBox_Item {
}

.home-NewsBox_Link {
  display: block;
  position: relative;
  padding: 0 rem(30) rem(15) rem(15);
  text-decoration: none;
  background-color: #f2f4f6;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.home-NewsBox_Thumbnail {
  display: block;
  min-width: calc(100% + 45px);
  margin: 0 rem(-15) rem(15);

  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: block;
    width: 100%;
    margin-bottom: rem(15);
  }
}

.home-NewsBox_Title {
  margin-bottom: rem(10);
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.3;
  color: #000;

  @include mq-up(md) {
    font-size: rem(20);
  }
}

.home-NewsBox_PostDate {
  margin-bottom: rem(5);
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.114285714;
  color: #df0523;
}

.home-NewsBox_Text {
  font-size: rem(15);
  line-height: 1.4;
  color: #444;
}

.home-NewsBox_Categories {
  padding-left: 0;
  list-style-type: none;

  @include mq-up(lg) {
    display: inline-block;
  }
}

.home-NewsBox_Category {
  display: inline-block;
  font-size: rem(12);
  font-weight: 600;
  line-height: 1.3;
  color: #444;

  &:nth-of-type(n + 2)::before {
    content: "/";
    margin-right: rem(4);
    font-weight: 300;
  }
}

.home-NewsBox_Icon {
  position: absolute;
  right: rem(15);
  bottom: rem(18);
  height: rem(14);
  font-size: rem(14);

  @include mq-up(md) {
    bottom: rem(15);
    height: rem(14);
    font-size: rem(14);
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.3em;
    color: #df0523;

    @include mq-up(md) {
      top: -0.35em;
    }
  }
}

.home-NewsBox_IconTextPC {
  font-size: rem(14);
  font-weight: bold;
  line-height: 1.2;
}

.home-NewsBox_IconPc {
  position: relative;
  top: -0.1em;
  font-size: 0.8em;

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    color: #df0523;
  }
}
