/*
---
name: sw-LeadB
namespace: sw-
category: Text
id: [TXT-2A, TXT-2B]
---

<span class="sw-Label">TXT-2A</span>

リード文です。見出しではなく`p`を使います。

```jade
p.sw-LeadB Kyocera supplies a wide range of products for automotive applications that require extreme reliability. Our products support the rapid advancement of in-vehicle electronics, making cars smarter, safer, cleaner and more comfortable.
```

<span class="sw-Label">TXT-2B</span>

下余白狭め

```jade
p.sw-LeadB.sw-LeadB-small Kyocera supplies a wide range of products for automotive applications that require extreme reliability. Our products support the rapid advancement of in-vehicle electronics, making cars smarter, safer, cleaner and more comfortable.
```

テキストを中央揃え、右揃え、左揃えに指定できます。

- `.sw-LeadB-center`
- `.sw-LeadB-centerSm`
- `.sw-LeadB-centerMd`
- `.sw-LeadB-centerLg`
- `.sw-LeadB-centerXl`
- `.sw-LeadB-right`
- `.sw-LeadB-rightSm`
- `.sw-LeadB-rightMd`
- `.sw-LeadB-rightLg`
- `.sw-LeadB-rightXl`
- `.sw-LeadB-left`
- `.sw-LeadB-leftSm`
- `.sw-LeadB-leftMd`
- `.sw-LeadB-leftLg`
- `.sw-LeadB-leftXl`

```jade
p.sw-LeadB.sw-LeadB-center A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics
p.sw-LeadB.sw-LeadB-right right A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics
```
*/
.sw-LeadB {
  margin-top: rem(30);
  margin-bottom: rem(30);
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.6;
  color: $color-black;

  @include mq-up(md) {
    margin-top: rem(40);
    margin-bottom: rem(40);
  }

  .sw-LeadA + & {
    margin-top: 0 !important;
  }
}

.sw-LeadB-small {
  @include mq-up(md) {
    margin-bottom: rem(25);
  }
}

.sw-LeadB-center {
  text-align: center;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-LeadB-center#{$suffix} {
    @media #{$breakpoint} {
      text-align: center;
    }
  }
}

.sw-LeadB-right {
  text-align: right;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-LeadB-right#{$suffix} {
    @media #{$breakpoint} {
      text-align: right;
    }
  }
}

.sw-LeadB-left {
  text-align: left;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-LeadB-left#{$suffix} {
    @media #{$breakpoint} {
      text-align: left;
    }
  }
}
