/*
---
name: swm-PageNavA
namespace: swm-
category: Navigation
id: M_CTL-2
---

<span class="sw-Label">M_CTL-2</span>

Productsページで使用されるページ内リンクです。

- ページのh2.sw-Heading2Aタグ、h2.sw-Heading2Bから自動生成され、`.swm-PageNavA`の最初の子要素として出力されます

**エディタ内の表示**

```jade
.cms-Component_Editor 
  .swm-PageNavA
    span Anchor Menu
```

**エディタ内ソース**

```jade
.swm-PageNavA
  span M_CTL-2
h2.sw-Heading2A Automotive Applications
h2.sw-Heading2A Industrial Heater Applications
h2.sw-Heading2A Water-Heating Applications
h2.sw-Heading2B Kerosene, Gas & Pellet Appliances / Consumer Electronic
h2.sw-Heading2B Medical Applications
h2.sw-Heading2B Water-Heating Applications
```

**js実行後ソース**

```jade
.swm-PageNavA
  ul.swm-PageNavA_List
    li.swm-PageNavA_Item
      a.swm-PageNavA_Link(href="#")
        span.swm-PageNavA_Icon(aria-hidden="true")
        | Automotive Applications
    li.swm-PageNavA_Item
      a.swm-PageNavA_Link(href="#")
        span.swm-PageNavA_Icon(aria-hidden="true")
        | Industrial Heater Applications
    li.swm-PageNavA_Item
      a.swm-PageNavA_Link(href="#")
        span.swm-PageNavA_Icon(aria-hidden="true")
        | Water-Heating Applications
    li.swm-PageNavA_Item
      a.swm-PageNavA_Link(href="#")
        span.swm-PageNavA_Icon(aria-hidden="true")
        | Kerosene, Gas & Pellet Appliances / Consumer Electronic
    li.swm-PageNavA_Item
      a.swm-PageNavA_Link(href="#")
        span.swm-PageNavA_Icon(aria-hidden="true")
        | Medical Applications
    li.swm-PageNavA_Item
      a.swm-PageNavA_Link(href="#")
        span.swm-PageNavA_Icon(aria-hidden="true")
        | Water-Heating Applications
```
*/
.swm-PageNavA {
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  // CMS上は空枠表示
  .cms-Component_Editor & {
    display: block;
    width: 100%;
    height: 100px;
    margin: 20px 0;
    line-height: 100px;
    background: #ccc;
    text-align: center;
    font-size: rem(24);
  }
}

.swm-PageNavA_List {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(26);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-bottom: rem(40);
  }
}

.swm-PageNavA_Item {
  flex-basis: 100%;
  max-width: 100%;
  border-left: 1px solid $color-gray-plus2;

  @include mq-up(md) {
    flex-basis: auto;
    max-width: none;
    margin-right: 0;
    margin-bottom: rem(5);

    &:last-of-type {
      border-right: 1px solid $color-gray-plus2;
    }
  }
}

.swm-PageNavA_Link {
  display: block;
  position: relative;
  padding: rem(9) 0 rem(9) rem(34);
  text-decoration: none;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.3;
  color: $color-red;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    padding: rem(9) rem(24) rem(9) rem(32);
    border: 0;
  }

  &:hover {
    text-decoration: underline;
    color: $color-link-hover;
  }
}

.swm-PageNavA_Icon {
  position: absolute;
  top: rem(8);
  left: rem(8);
  font-size: 1.14em;
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-arrow_drop_down}";
    transform: scale(0.5);
  }
}
