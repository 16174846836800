/*
---
name: sw-ListArrow
namespace: sw-
category: List
id: LINK-8
---

<span class="sw-Label">LINK-8</span>

矢印付きのリンクリストです。

```jade
ul.sw-ListArrow
  li.sw-ListArrow_Item
    span.sw-ListArrow_Icon
    a.sw-ListArrow_Link(href="#") Terms of use
  li.sw-ListArrow_Item
    span.sw-ListArrow_Icon
    a.sw-ListArrow_Link(href="#") Data Privacy Notice
  li.sw-ListArrow_Item
    span.sw-ListArrow_Icon
    a.sw-ListArrow_Link(href="#") Imprint
  li.sw-ListArrow_Item
    span.sw-ListArrow_Icon
    a.sw-ListArrow_Link(href="#") Modern Slavery ActModern Slavery ActModern Slavery ActModern Slavery ActModern Slavery Act
      span.sw-ListArrow_ExternalIconA(aria-hidden="true")
  li.sw-ListArrow_Item
    span.sw-ListArrow_Icon
    a.sw-ListArrow_Link(href="#") Tax Strategy Statement
      span.sw-ListArrow_ExternalIconB(aria-hidden="true")

```
*/
.sw-ListArrow {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  font-size: rem(14);
  line-height: 1.3;
  list-style-type: none;

  & > :last-child {
    margin-bottom: 0;
  }
}

.sw-ListArrow_Item {
  position: relative;
  margin-bottom: rem(15);
  padding-left: rem(16);
}

.sw-ListArrow_Icon {
  display: block;
  position: absolute;
  top: rem(4);
  left: rem(2);
  height: rem(16);
  margin: auto;
  font-size: rem(16);
  color: $color-red;
  transform: scale(0.5);
  transform-origin: left top;

  &::before {
    @include icon;

    content: "#{$icon-triangle}";
    position: relative;
    top: -0.3em;
  }
}

.sw-ListArrow_ExternalIconA {
  margin-left: rem(4);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.1em;
  }
}

.sw-ListArrow_ExternalIconB {
  margin-left: rem(4);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.1em;
  }
}

.sw-ListArrow_Link {
  text-decoration: none;
  color: $color-gray-minus2;

  &:hover {
    text-decoration: underline;
    color: $color-red;
  }
}
