/*
---
name: sw-ListLowerAlpha
namespace: sw-
category: List
id: LIST-5
---

<span class="sw-Label">LIST-5</span>

入れ子に対応した順序リストです。

```jade
ul.sw-ListLowerAlpha
  li Ordered nested list level 1
  li Ordered nested list level 1
    ul.sw-ListLowerAlpha_Child
      li Ordered nested list level 2 Ordered nested list level 2 Ordered nested list level 2
      li Ordered nested list level 2
  li Ordered nested list level 1 Ordered nested list level 1 Ordered nested list level 1
```

リストを2から始める場合はstart属性を`start="2"`のように指定します。CSSで表示する順序も属性値によって変更されます。

```jade
ul.sw-ListLowerAlpha(start="2")
  li Ordered nested list level 1
  li Ordered nested list level 1
    ul.sw-ListLowerAlpha_Child
      li Ordered nested list level 2 Ordered nested list level 2 Ordered nested list level 2
      li Ordered nested list level 2
  li Ordered nested list level 1 Ordered nested list level 1 Ordered nested list level 1
```
*/
.sw-ListLowerAlpha {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  margin-left: rem(23);
  padding-left: 0;
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;
  list-style-type: none;
  counter-reset: lower-alpha;

  // start属性値に応じてCSSのカウンターも変更します。
  // デフォルトは`through 9`で`start="10"`まで対応しています。
  @for $i from 1 through 9 {
    &[start="#{$i + 1}"] {
      counter-reset: lower-alpha $i;
    }
  }

  & > li {
    position: relative;
    margin-bottom: rem(5);
    counter-increment: lower-alpha;
  }

  & > li::before {
    content: counters(lower-alpha, "-", lower-alpha) ".";
    display: block;
    position: absolute;
    top: 0;
    left: rem(-23);
    white-space: nowrap;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.sw-ListLowerAlpha_Child {
  margin-top: rem(5);
  padding-left: 0;
  list-style-type: none;

  & > li {
    position: relative;
    margin-bottom: rem(5);
    margin-left: rem(16);
  }

  & > li::before {
    content: "";
    display: block;
    position: absolute;
    top: rem(10);
    left: rem(-16);
    width: rem(8);
    margin: auto;
    border-top: 1px solid $color-gray;
  }
}
