/*
---
name: swm-Margin
namespace: swm-
category: Block
id: [M_MARGIN-1, M_MARGIN-2, M_MARGIN-3, M_MARGIN-4, M_MARGIN-5]
---

<span class="sw-Label">M_MARGIN-A1</span>
<span class="sw-Label">M_MARGIN-A2</span>
<span class="sw-Label">M_MARGIN-A3</span>
<span class="sw-Label">M_MARGIN-A4</span>
<span class="sw-Label">M_MARGIN-A5</span>
<span class="sw-Label">M_MARGIN</span>

余白用のコンポーネントです。

```jade
.swm-Margin1(aria-hidden="true")
.swm-Margin2(aria-hidden="true")
.swm-Margin3(aria-hidden="true")
.swm-Margin4(aria-hidden="true")
.swm-Margin5(aria-hidden="true")
.swm-Margin(aria-hidden="true")
```

<span class="sw-Label">M_MARGIN-B1</span>
<span class="sw-Label">M_MARGIN-B2</span>
<span class="sw-Label">M_MARGIN-B3</span>
<span class="sw-Label">M_MARGIN-B4</span>
<span class="sw-Label">M_MARGIN-B5</span>
<span class="sw-Label">M_MARGIN</span>

罫線付き余白用のコンポーネントです。

```jade
.swm-Margin1(aria-hidden="true")
  .swm-Margin1_Line(aria-hidden="true")
.swm-Margin2(aria-hidden="true")
  .swm-Margin2_Line(aria-hidden="true")
.swm-Margin3(aria-hidden="true")
  .swm-Margin3_Line(aria-hidden="true")
.swm-Margin4(aria-hidden="true")
  .swm-Margin4_Line(aria-hidden="true")
.swm-Margin5(aria-hidden="true")
  .swm-Margin5_Line(aria-hidden="true")
```

CMSエディタ上での見え方

```jade
.cms-Component_Editor
  .swm-Margin1(aria-hidden="true")
  .swm-Margin2(aria-hidden="true")
  .swm-Margin3(aria-hidden="true")
  .swm-Margin4(aria-hidden="true")
  .swm-Margin5(aria-hidden="true")
  .swm-Margin(aria-hidden="true")
```

```jade
.cms-Component_Editor
  .swm-Margin1(aria-hidden="true")
    .swm-Margin1_Line(aria-hidden="true")
  .swm-Margin2(aria-hidden="true")
    .swm-Margin2_Line(aria-hidden="true")
  .swm-Margin3(aria-hidden="true")
    .swm-Margin3_Line(aria-hidden="true")
  .swm-Margin4(aria-hidden="true")
    .swm-Margin4_Line(aria-hidden="true")
  .swm-Margin5(aria-hidden="true")
    .swm-Margin5_Line(aria-hidden="true")
```
*/
.swm-Margin1 {
  display: block;
  width: 100%;
  padding: 0;
  height: rem(0);
  margin: 0;

  @include mq-up(md) {
    height: rem(10);
  }

  .sw-ListUnorder + & {
    height: rem(0);
  }

  & + * {
    margin-top: 0 !important;
  }

  .cms-Component_Editor & {
    width: 100%;
    margin: rem(20) 0 !important;
    border: dashed 2px #ccc;
  }
}

.swm-Margin1_Line {
  display: block;
  width: 100%;
  padding: 0;
  height: rem(0);
  margin: 0;

  @include mq-up(md) {
    height: 50%;
    border-bottom: 1px solid #e9e9e9;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(233, 233, 233);
  }
}

.swm-Margin2 {
  display: block;
  width: 100%;
  padding: 0;
  height: rem(10);
  margin: 0;

  @include mq-up(md) {
    height: rem(20);
  }

  .sw-ListUnorder + & {
    height: rem(10);
  }

  & + * {
    margin-top: 0 !important;
  }

  .cms-Component_Editor & {
    width: 100%;
    margin: rem(20) 0 !important;
    border: dashed 2px #ccc;
  }
}

.swm-Margin2_Line {
  display: block;
  width: 100%;
  padding: 0;
  height: 50%;
  margin: 0;

  @include mq-up(md) {
    border-bottom: 1px solid #e9e9e9;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(233, 233, 233);
  }
}

.swm-Margin3 {
  display: block;
  width: 100%;
  padding: 0;
  height: rem(20);
  margin: 0;

  @include mq-up(md) {
    height: rem(30);
  }

  .sw-ListUnorder + & {
    height: rem(20);
  }

  & + * {
    margin-top: 0 !important;
  }

  .cms-Component_Editor & {
    width: 100%;
    margin: rem(20) 0 !important;
    border: dashed 2px #ccc;
  }
}

.swm-Margin3_Line {
  display: block;
  width: 100%;
  padding: 0;
  height: 50%;
  margin: 0;

  @include mq-up(md) {
    border-bottom: 1px solid #e9e9e9;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(233, 233, 233);
  }
}

.swm-Margin4 {
  display: block;
  width: 100%;
  padding: 0;
  height: rem(30);
  margin: 0;

  @include mq-up(md) {
    height: rem(40);
  }

  .sw-ListUnorder + & {
    height: rem(40);
  }

  & + * {
    margin-top: 0 !important;
  }

  .cms-Component_Editor & {
    width: 100%;
    margin: rem(20) 0 !important;
    border: dashed 2px #ccc;
  }
}

.swm-Margin4_Line {
  display: block;
  width: 100%;
  padding: 0;
  height: 50%;
  margin: 0;

  @include mq-up(md) {
    border-bottom: 1px solid #e9e9e9;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(233, 233, 233);
  }
}

.swm-Margin5 {
  display: block;
  width: 100%;
  padding: 0;
  height: rem(40);
  margin: 0;

  @include mq-up(md) {
    height: rem(50);
  }

  .sw-ListUnorder + & {
    height: rem(50);
  }

  & + * {
    margin-top: 0 !important;
  }

  .cms-Component_Editor & {
    width: 100%;
    margin: rem(20) 0 !important;
    border: dashed 2px #ccc;
  }
}

.swm-Margin5_Line {
  display: block;
  width: 100%;
  padding: 0;
  height: 50%;
  margin: 0;

  @include mq-up(md) {
    border-bottom: 1px solid #e9e9e9;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(233, 233, 233);
  }
}

// 下位互換のため残している
.swm-Margin {
  display: block;
  width: 100%;
  padding: 0;
  height: rem(40);
  margin: 0;

  @include mq-up(md) {
    height: rem(50);
  }

  & + :not(.swm-Margin) {
    margin-top: 0 !important;
  }

  .sw-ListUnorder + &,
  .swm-BoxA + &,
  .eventm-BoxB_Item + & {
    margin-top: -1 * $margin-bottom-base;
  }

  .sw-Text + & {
    margin-top: rem(-20);
  }

  .sw-LeadB + & {
    margin-top: rem(-30);
  }
  @include mq-up(md) {
    .sw-LeadB + & {
      margin-top: rem(-40);
    }
  }

  .cms-Component_Editor & {
    width: 100%;
    margin: rem(20) 0 !important;
    border: dashed 2px #ccc;
  }
}