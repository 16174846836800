/*
---
name: sw-NavB
namespace: sw-
category: Navigation
id: [CARD-2A, CARD-2B]
---

<span class="sw-Label">CARD-2A</span>

Productsページで使用されるナビゲーションです。

スマホ1カラム、タブレット2カラム

```jade
ul.sw-NavB.sw-NavB-col2Md
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        <span class="sw-Delimiter">Automotive<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | LED <span class="sw-Delimiter">Lighting<span aria-hidden="true" class="sw-NavB_IconExternalA"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Camera Image <span class="sw-Delimiter">Sensors<span aria-hidden="true" class="sw-NavB_IconExternalB"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Power - AMB <span class="sw-Delimiter">Substrates<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | MEMS Sensors <span class="sw-Delimiter">Packaging<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Network / <span class="sw-Delimiter"><span class="sw-Delimiter">Multifunctionals<span aria-hidden="true" class="sw-NavB_Icon"></span></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Fiber <span class="sw-Delimiter">Optics<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
```

```jade
ul.sw-NavB.sw-NavB-col2Md
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        <span class="sw-Delimiter">Automotive<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
      .sw-NavB_Foot Alumina is the most widely used material among fine ceramics, and has the same sintered crystal structure (α-Al2O3) as sapphire and ruby. Its application is diverse due to its superb properties such as high insulation, high strength, high wear resistance and chemical resistance.
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | LED <span class="sw-Delimiter">Lighting<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
      .sw-NavB_Foot Silicon nitride (Si3N4) exceeds other ceramics in thermal shock resistance. As its strength does not deteriorate at elevated temperatures, it is appropriate material for automotive engine and gas turbine parts, including turbo-charger rotors, diesel engine glow plugs and hot plugs.
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Camera Image <span class="sw-Delimiter">Sensors<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
      .sw-NavB_Foot Silicon carbide retains its strength at elevated temperatures as high as 1,400°C and features high corrosion resistance in fine ceramic materials. Applications include mechanical seals and pump parts.
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Power - AMB <span class="sw-Delimiter">Substrates<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
      .sw-NavB_Foot Zirconia ceramic offers high strength and toughness at room temperature in engineering ceramics. Before zirconia, ceramics were considered impractical for scissors or knife applications. With its excellent surface smoothness, zirconia is also used for pump parts.
```

<span class="sw-Label">CARD-2B</span>

スマホ1カラム、タブレット3カラム

```jade
ul.sw-NavB.sw-NavB-col3Md
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        <span class="sw-Delimiter">Automotive<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | LED <span class="sw-Delimiter">Lighting<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Camera Image <span class="sw-Delimiter">Sensors<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Power - AMB <span class="sw-Delimiter">Substrates<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | MEMS Sensors <span class="sw-Delimiter">Packaging<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Network / <span class="sw-Delimiter"><span class="sw-Delimiter">Multifunctionals<span aria-hidden="true" class="sw-NavB_Icon"></span></span></span>
  li.sw-NavB_Item
    a.sw-NavB_Box(href="#")
      .sw-NavB_Head
        img.sw-NavB_Thumbnail(src="/assets/img/common/img_690_224.jpg" alt)
      .sw-NavB_Body
        | Fiber <span class="sw-Delimiter">Optics<span aria-hidden="true" class="sw-NavB_Icon"></span></span>
```
*/
$sw-NavB-column-gap: rem(15);
$sw-NavB-row-gap: rem(15);
$sw-NavB-column-gap-md: rem(30);
$sw-NavB-row-gap-md: rem(25);

.sw-NavB {
  display: flex;
  flex-wrap: wrap;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  margin-left: -$sw-NavB-column-gap;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-left: -$sw-NavB-column-gap-md;
  }
}

.sw-NavB_Item {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: $sw-NavB-column-gap;

  @include mq-up(md) {
    padding-left: $sw-NavB-column-gap-md;
  }

  /* 1 Column */
  &:nth-of-type(n + 2) {
    margin-top: $sw-NavB-column-gap;
  }
}

/* 2 Columns */
.sw-NavB-col2 > .sw-NavB_Item {
  flex-basis: percentage(1 / 2);
  max-width: percentage(1 / 2);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 3) {
    margin-top: $sw-NavB-row-gap;
  }
}

@include mq-up(md) {
  .sw-NavB-col2Md > .sw-NavB_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .sw-NavB-col2Lg > .sw-NavB_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .sw-NavB-col2Xl > .sw-NavB_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

/* 3 Columns */
.sw-NavB-col3 > .sw-NavB_Item {
  flex-basis: percentage(1 / 3);
  max-width: percentage(1 / 3);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 4) {
    margin-top: $sw-NavB-row-gap;
  }
}

@include mq-up(md) {
  .sw-NavB-col3Md > .sw-NavB_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .sw-NavB-col3Lg > .sw-NavB_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .sw-NavB-col3Xl > .sw-NavB_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

/* 4 Columns */
.sw-NavB-col4 > .sw-NavB_Item {
  flex-basis: percentage(1 / 4);
  max-width: percentage(1 / 4);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 5) {
    margin-top: $sw-NavB-row-gap;
  }
}

@include mq-up(md) {
  .sw-NavB-col4Md > .sw-NavB_Item {
    flex-basis: percentage(1 / 4);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .sw-NavB-col4Lg > .sw-NavB_Item {
    flex-basis: percentage(1 / 4);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .sw-NavB-col4Xl > .sw-NavB_Item {
    flex-basis: percentage(1 / 4);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

/* 5 Columns */
.sw-NavB-col5 > .sw-NavB_Item {
  flex-basis: percentage(1 / 5);
  max-width: percentage(1 / 5);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 6) {
    margin-top: $sw-NavB-row-gap;
  }
}

@include mq-up(md) {
  .sw-NavB-col5Md > .sw-NavB_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .sw-NavB-col5Lg > .sw-NavB_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .sw-NavB-col5Xl > .sw-NavB_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $sw-NavB-row-gap-md;
    }
  }
}

.sw-NavB_Box {
  display: block;
  height: 100%;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }

  .cms-Component_Editor & {
    height: auto;
  }
}

.sw-NavB_Head {
}

.sw-NavB_Thumbnail {
  display: block;
  width: 100%;

  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: block;
    width: 100%;
  }
}

.sw-NavB_Body {
  padding: rem(16) rem(15) rem(20);
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.375;
  background: #f2f4f6;
  color: $color-gray-minus2;

  @include mq-up(md) {
    font-size: rem(16);
  }
}

.sw-NavB_Icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: rem(12);
  margin-left: rem(3);
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.2em;
    color: $color-red;
  }
}

.sw-NavB_IconExternalA {
  position: relative;
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.1em;
    left: 0.5em;
    color: $color-red;
  }
}

.sw-NavB_IconExternalB {
  position: relative;
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.1em;
    left: 0.5em;
    color: $color-red;
  }
}

.sw-NavB_Foot {
  margin-bottom: rem(10);
  padding-top: rem(15);
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;

  @include mq-up(md) {
    margin-bottom: 0;
    padding-top: rem(20);
  }
}
