/*
---
name: st-CategoryNavNews
namespace: st-
category: Global
---

PC以外で表示するカテゴリのナビゲーションです。


```jade
.st-CategoryNavNews(aria-expanded="true")
  button.st-CategoryNavNews_Parent
    | Latest News
    span.st-CategoryNavNews_ParentIcon(aria-hidden="true")
    -
      var category = [
        {text: 'Latest News', href: '/news/'},
        {text: '2019', href: '/news/2019/'},
        {text: '2018', href: '/news/2018'},
      ];
  ul.st-CategoryNavNews_List(aria-hidden="false")
    each val in category
      li.st-CategoryNavNews_Item
        a.st-CategoryNavNews_Link('href'=val.href)=val.text

.st-CategoryNavNews(aria-expanded="true")
  button.st-CategoryNavNews_Parent
    | Select Category
    span.st-CategoryNavNews_ParentIcon(aria-hidden="true")
    -
      var category = [
        {text: 'Document Solutions - Printers & MFPs', href: 'document_solutions_printers_and_mfps'},
        {text: 'Smartphones & Feature Phones', href: 'smartphones_and_feature_phones'},
        {text: 'Ceramic Cutlery & Kitchen Tools', href: 'ceramic_cutlery_and_kitchen_tools'},
        {text: 'Industrial Ceramics', href: 'industrial_ceramics'},
        {text: 'Semiconductor Packaging', href: 'semiconductor_packaging'},
        {text: 'Electronic Components', href: 'electronic_components'},
        {text: 'UV LED Curing System', href: 'uv_led_curing_system'},
        {text: 'Thermal Printheads', href: 'thermal_printheads'},
        {text: 'Inkjet Printheads', href: 'inkjet_printheads'},
        {text: 'Liquid Crystal Displays', href: 'liquid_crystal_displays'},
        {text: 'Lens Components', href: 'lens_components'},
        {text: 'Medical Products', href: 'medical_products'},
        {text: 'Cutting Tool – Indexable', href: 'cutting_tool_indexable'},
        {text: 'Cutting Tool - Solid Round', href: 'cutting_tool_solid_round'},
        {text: 'Cutting Tool - Printed Circuit Board', href: 'cutting_tool_printed_circuit_board'},
        {text: 'Organic Chemical Materials', href: 'organic_chemical_materials'},
        {text: 'Solar Solutions', href: 'solar_solutions'},
        {text: 'Endpoint Security Software', href: 'endpoint_security_software'}
      ];
  ul.st-CategoryNavNews_List(aria-hidden="false")
    li.st-CategoryNavNews_Item
        a.st-CategoryNavNews_Link(href="/news/") All
    each val in category
      li.st-CategoryNavNews_Item
        a.st-CategoryNavNews_Link('href'='/news/' + val.href + '/')=val.text
```
*/
.st-CategoryNavNews {

  @include mq-up(lg) {
    display: none;
  }
  & + & {
    margin-top: rem(30);
  }
}

.st-CategoryNavNews_Parent {
  display: block;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding: rem(14) rem(48) rem(14) rem(18);
  text-align: left;
  font-size: rem(16);
  line-height: 1.6;
  border: 1px solid $color-gray-plus2;
  border-radius: 4px;
  background-color: $color-white;
  color: $color-gray-minus1;
}

.st-CategoryNavNews_ParentIcon {
  position: absolute;
  top: 0;
  right: rem(18);
  bottom: 0;
  height: rem(10);
  margin: auto;
  font-size: rem(10);
  color: $color-gray-minus2;

  &::before {
    @include icon;

    content: "#{$icon-arrow_drop_down}";
    position: relative;
    top: -0.3em;
    color: $color-gray-minus1;
  }

  .st-CategoryNavNews[aria-expanded="true"] &::before {
    transform: scale3d(1, -1, 1);
  }
}

.st-CategoryNavNews_List {
  overflow: hidden;
  margin-top: rem(12);
  padding: rem(10);
  border: 1px solid $color-gray-plus2;
  border-radius: 4px;
  background-color: $color-white;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
  color: $color-gray-minus1;
  list-style-type: none;

  &[aria-hidden="true"] {
    height: 0;
    margin: 0;
    padding: 0 rem(10);
    border-width: 0 1px;
    box-shadow: none;
  }
}

.st-CategoryNavNews_Item {
  &:first-child .st-CategoryNavNews_Link {
    font-weight: 700;
    color: #888;
  }
}

.st-CategoryNavNews_Link {
  display: flex;
  align-items: center;
  width: 100%;
  height: rem(50);
  padding: 0 rem(20);
  text-decoration: none;
  font-size: rem(15);
  line-height: 1.6;
  color: $color-gray-minus2;

  &[aria-current="location"] {
    font-weight: 700;
    background-color: #f2f4f6;
    color: $color-gray-minus2 !important;
  }
}
