/*
---
name: st-RelatedB
namespace: st-
category: Global
---

サイドバーに配置する関連情報です。


```jade
.l-DetailTemplate_related
  .st-RelatedB
    a.sw-LinkInstagram(href="#")
      .sw-LinkInstagram_Icon(aria-hidden="true")
      span.sw-LinkInstagram_Text Follow us Instagram
```

Instagramのリンクは最後に配置します。Instagramの上余白が追加されます。

```jade
.l-DetailTemplate_related
  .st-RelatedA
    p.st-RelatedA_Heading Download
    ul.st-RelatedA_List
      li.st-RelatedA_Item
        a.st-RelatedA_Link(href="#")
          span.st-RelatedA_DownloadIcon(aria-hidden="true")
          span.st-RelatedA_Text Multi Layer - Open tooled package list
            span.st-RelatedA_Size  (50.30 KB)
            span.st-RelatedA_ExternalAIcon(aria-hidden="true")
      li.st-RelatedA_Item
        a.st-RelatedA_Link(href="#")
          span.st-RelatedA_DownloadIcon(aria-hidden="true")
          span.st-RelatedA_Text Single Layer - Open tooled package list
            span.st-RelatedA_Size  (52.61 KB)
            span.st-RelatedA_ExternalBIcon(aria-hidden="true")
.l-DetailTemplate_related
  .st-RelatedA
    p.st-RelatedA_Heading Related Links
    ul.st-RelatedA_List
      li.st-RelatedA_Item
        a.st-RelatedA_Link(href="#")
          span.st-RelatedA_RelatedIcon(aria-hidden="true")
          span.st-RelatedA_Text Organic Packages (Global Website)
            span.st-RelatedA_ExternalAIcon(aria-hidden="true")
      li.st-RelatedA_Item
        a.st-RelatedA_Link(href="#")
          span.st-RelatedA_RelatedIcon(aria-hidden="true")
          span.st-RelatedA_Text Semiconductor Components (Global Website)
            span.st-RelatedA_ExternalBIcon(aria-hidden="true")
.l-DetailTemplate_related
  .st-RelatedB
    a.sw-LinkInstagram(href="#")
      .sw-LinkInstagram_Icon(aria-hidden="true")
      span.sw-LinkInstagram_Text Follow us Instagram
```
*/
.st-RelatedB {
  padding-bottom: rem(30);

  .l-DetailTemplate_related + .l-DetailTemplate_related & {
    padding-top: rem(30);
  }
}
