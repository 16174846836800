.results-Search {
  padding-top: rem(14);
  padding-bottom: rem(24);

  @include mq-up(md) {
    padding-top: rem(44);
    padding-bottom: rem(40);
  }
}

.results-Search_Form {
  display: flex;
  flex-wrap: wrap;

  @include mq-up(md) {
    align-items: center;
  }
}

// 入力
.results-Search_Query {
  flex-basis: 100%;
  position: relative;
  max-width: 100%;
  margin-bottom: rem(15);

  @include mq-up(md) {
    flex-basis: percentage(800 / 1170);
    max-width: percentage(800 / 1170);
    margin-bottom: 0;
    padding-right: rem(30);
  }
}

.results-Search_Query > input {
  @include sw-Input;

  padding-left: rem(44);
  font-size: rem(17);
  line-height: 1.5;
  border: 1px solid #e9e9e9;
  background: #f8f8f8;
}

.results-Search_QueryIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: rem(12);
  height: rem(24);
  margin: auto;
  font-size: rem(24);
  color: $color-gray-minus2;

  &::before {
    @include icon;

    content: "#{$icon-search}";
    position: relative;
    top: -0.35em;
  }
}

.results-Search_Query .results-Search_Submit {
}

.results-Search_Query .Search_SubmitButton {
  @include sr-only;
}

// カテゴリ指定
.results-Search_Category {
  flex-basis: 100%;
  max-width: 100%;
  text-align: center;

  @include mq-up(md) {
    flex-basis: percentage(370 / 1170);
    max-width: percentage(370 / 1170);
    text-align: left;
  }
}

.results-Search_Category > input {
  @include sw-FormRadio;
}

.results-Search_Category > label {
  margin-right: rem(15);
}
