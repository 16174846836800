/* グローバルテンプレートのスタイル（/_assets/css/template.css）を上書きします。 */

/*
 * IEのバグ対応のため指定していたが、SVGだけ拡大されてしまうためリセットする。
 * 一定の条件下ではアスペクト比が崩れる崩れるおそれがある。
 * https://hail2u.net/blog/webdesign/max-width-and-svg-on-ie11-and-below.html
 */
main [src$=".svg"],
main [data$=".svg"] {
  width: auto;
}

.st-SearchButton,
.st-GlobalNav_SearchClose,
.st-GlobalSiteNav_Link {
  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.focus-ring:focus {
    box-shadow: $form-box-shadow-focus;
  }
}

.st-Breadcrumb_List {
  display: flex;
}

.st-Breadcrumb_Item {
  float: none;
  white-space: nowrap;

  &:last-child {
    overflow: hidden;
    padding-right: rem(200);
    text-overflow: ellipsis;
  }
}

.st-GlobalNav_Area,
.st-GlobalSiteNav_SubArea {
  z-index: 10000;
}

.st-GlobalNav_NavClose[aria-hidden="false"],
.st-GlobalNav_SearchClose {
  z-index: 100000;
}

.st-GlobalHeader_Buttons-devision {
  @include mq-up(lg) {
    width: 600px !important;
  }
}

.st-GlobalSiteNav_SubLinkTitle:not(a):hover,
.st-GlobalSiteNav_SubLinkTop:not(a):hover {
  text-decoration: none;
}

.st-GlobalSiteNav_SubLinkTitle {
  font-weight: 600;

  @include mq-up(lg) {
    & + & {
      margin-top: rem(34);
    }
  }
}

.st-GlobalSiteNav_SubList {
  @include mq-up(lg) {
    .st-DropNav & {
      display: flex;
      flex-wrap: wrap;
      margin-left: rem(-30);
    }
  }
}

.st-GlobalSiteNav_SubItem {
  @include mq-up(lg) {
    // Products
    .st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-products
      + .st-GlobalSiteNav_SubArea
      .l-Grid_Item-9of12Lg
      &,
    [id="st-DropNav1"] .l-Grid_Item:nth-of-type(2) & {
      width: percentage(1 / 3);
    }

    // About
    .st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-company + .st-GlobalSiteNav_SubArea & {
      width: percentage(1 / 3);
    }

    // News
    .st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-news + .st-GlobalSiteNav_SubArea & {
      width: percentage(1 / 4);
    }

    // Contact
    .st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-inquiry
      + .st-GlobalSiteNav_SubArea
      .l-Grid_Item-9of12Lg
      & {
      width: percentage(1 / 3);
    }

    &::before {
      font-size: 10px;
      transform: scale3d(0.9, 0.9, 1) translate3d(5px, 5px, 0);
    }
  }
}

.st-DropNav_SubList {
  @include mq-up(lg) {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(-30);
    margin-left: 0;

    [id="st-DropNav2"] & {
      justify-content: center;
      margin-left: rem(-45);
    }
  }
}

.st-DropNav {
  @include mq-up(lg) {
    margin-left: 0;
  }
}

.st-DropNav_SubItem {
  @include mq-up(lg) {
    .st-DropNav & {
      padding-left: rem(15);
    }

    [id="st-DropNav1"] .l-Grid_Item:nth-of-type(2) & {
      flex-basis: percentage(1 / 3);
      max-width: percentage(1 / 3);
    }

    [id="st-DropNav2"] .l-Grid_Item:nth-of-type(2) & {
      width: auto;
    }

    &::before {
      font-size: 10px;
      transform: scale3d(0.7, 0.7, 1) translate3d(7px, 7px, 0);
    }
  }
}

.st-DropNav_SubList {
  @include mq-up(lg) {
    margin-left: 0;
    padding-left: 0 !important;
  }
}

.st-DropNav_SubLink {
  @include mq-up(lg) {
    [id="st-DropNav2"] & {
      margin-right: rem(60);
    }
  }
}

.st-DropNav_PcButtonArea.st-DropNav_PcButton-devider {
  @include mq-up(lg) {
    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      height: 19px;
      margin-right: 14px;
      margin-left: 20px;
      background-color: #e6e6e6;
    }
  }
}

.st-GlobalNav_Area {
  overflow-x: hidden;
}

.st-GlobalNav_Area {
  // fixedした要素内で慣性スクロールさせるため
  // https://qiita.com/chocolamint/items/67d25933880404c93db0#comment-d2235891388012ad7cd8
  height: 100vh;
}

// 別窓アイコンを追加
.st-GlobalSiteNav_SubLinkExternalA,
.st-DropNav_SubLinkExternalA,
.st-DrawerNav_TriggerExternalA,
.st-DrawerNav_DrawerIconExternalA {
  display: inline-block;
  vertical-align: middle;
  height: rem(11);
  margin-left: rem(3);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.3em;

    @include mq-up(md) {
      top: -0.4em;
    }
  }
}

.st-GlobalSiteNav_SubLinkExternalB,
.st-DropNav_SubLinkExternalB,
.st-DrawerNav_TriggerExternalB,
.st-DrawerNav_DrawerIconExternalB {
  display: inline-block;
  vertical-align: middle;
  height: rem(11);
  margin-left: rem(3);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.3em;

    @include mq-up(md) {
      top: -0.4em;
    }
  }
}

.st-DrawerNav_TriggerExternalA,
.st-DrawerNav_TriggerExternalB {
  margin-left: rem(5);
}

.st-DrawerNav_DrawerIconExternalA,
.st-DrawerNav_DrawerIconExternalB {
  margin-left: rem(6);
}

// ドキュメントをクリックすると、template.jsの`hideAllMenu()`で`display:none;`される。
// それ以降は開閉できなくなってしまうため、強制的に表示させる。
.st-GlobalSiteNav_SubAreaInner {
  display: block !important;
}

.st-GlobalSiteNav_SubLinkTop {
  @include mq-up(lg) {
    height: rem(98);
    line-height: rem(98);
  }
}

.st-GlobalNav_NavClose[aria-hidden="false"] {
  top: 119px;
}

.st-GlobalNav_RegionClose {
  display: none;

  @include mq-up(lg) {
    display: none;

    &[aria-hidden="false"] {
      display: block;
      position: absolute;
      top: rem(119);
      right: 15px;
      z-index: 100000;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: transparent;
      appearance: none;
    }
  }
}

.st-GlobalNav_RegionCloseIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -1px;
  left: -1px;
  width: 32px;
  height: 32px;

  // IE9以上
  :root & {
    top: -0.5px\0;
    left: -0.5px\0;
  }

  &::before {
    @include icon;

    content: "#{$icon-close}";
    display: block;
    position: relative;
    font-size: rem(12);
    color: #fff;
  }
}

.st-GlobalFooter_PageTop {
  .st-AllowCookie_Show &:not(.st-GlobalFooter_PageTop-static) {
    @include mq-up(md) {
      bottom: 170px;
    }

    @include mq-up(lg) {
      bottom: 100px;
    }
  }

  .st-AllowCookie_Show &.st-GlobalFooter_PageTop-static {
    @include mq-up(md) {
      bottom: 256px;
    }

    @include mq-up(lg) {
      bottom: 100px;
    }
  }
}
