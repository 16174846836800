/*
---
name: l-Section2
namespace: l-
category: Layout
---

h2相当の見出しをラップするセクションの余白を指定します。

```jade
.l-Wrapper
  p.sw-Text 吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。

  section.l-Section2
    h2.sw-Heading2 吾輩はここで始めて人間というものを見た。
    p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
    p.sw-Text しかしその当時は何という考もなかったから別段恐しいとも思わなかった。ただ彼の掌に載せられてスーと持ち上げられた時何だかフワフワした感じがあったばかりである。掌の上で少し落ちついて書生の顔を見たのがいわゆる人間というものの見始であろう。この時妙なものだと思った感じが今でも残っている。

  section.l-Section2
    h2.sw-Heading2 吾輩はここで始めて人間というものを見た。
    p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
    p.sw-Text しかしその当時は何という考もなかったから別段恐しいとも思わなかった。ただ彼の掌に載せられてスーと持ち上げられた時何だかフワフワした感じがあったばかりである。掌の上で少し落ちついて書生の顔を見たのがいわゆる人間というものの見始であろう。この時妙なものだと思った感じが今でも残っている。
```

`.l-BlockA-divider`をつけるとボーダーが付きます。

```jade
.l-Wrapper
  p.sw-Text 吾輩は猫である。名前はまだ無い。どこで生れたかとんと見当がつかぬ。何でも薄暗いじめじめした所でニャーニャー泣いていた事だけは記憶している。

  section.l-Section2.l-Section2-divider
    h2.sw-Heading2 吾輩はここで始めて人間というものを見た。
    p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
    p.sw-Text しかしその当時は何という考もなかったから別段恐しいとも思わなかった。ただ彼の掌に載せられてスーと持ち上げられた時何だかフワフワした感じがあったばかりである。掌の上で少し落ちついて書生の顔を見たのがいわゆる人間というものの見始であろう。この時妙なものだと思った感じが今でも残っている。

  section.l-Section2.l-Section2-divider
    h2.sw-Heading2 吾輩はここで始めて人間というものを見た。
    p.sw-Text しかもあとで聞くとそれは書生という人間中で一番獰悪な種族であったそうだ。この書生というのは時々我々を捕えて煮て食うという話である。
    p.sw-Text しかしその当時は何という考もなかったから別段恐しいとも思わなかった。ただ彼の掌に載せられてスーと持ち上げられた時何だかフワフワした感じがあったばかりである。掌の上で少し落ちついて書生の顔を見たのがいわゆる人間というものの見始であろう。この時妙なものだと思った感じが今でも残っている。
```
*/
.l-Section2 {
  margin-top: rem(55);
  margin-bottom: rem(55);

  @include mq-up(md) {
    margin-top: rem(50);
    margin-bottom: rem(50);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  .sw-LeadA + &,
  .st-TitleC + & {
    margin-top: 0;
  }

  &.l-Section2-divider {
    margin-top: rem(30);
    margin-bottom: rem(30);
  }

  &.l-Section2-divider:nth-of-type(n + 2)::before {
    content: "";
    display: block;
    padding-top: rem(30);
    border-top: 1px solid $color-gray-plus3;
  }
}

.l-Section2-News {
  margin-bottom: 0;

  @include mq-up(md) {
    margin-bottom: rem(50);
  }
}
