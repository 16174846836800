/*
---
name: sw-Button
namespace: sw-
category: Button
id: [BTN-1, BTN-2, BTN-2A, BTN-2B, BTN-4]
---

<span class="sw-Label">BTN-1</span>

ボタンのデフォルトスタイルです。

```jade
div
  a.sw-Button(href="#") Primary Button
  a.sw-Button.sw-Button-disabled(href="#" tabindex="-1") Primary Button
div
  button.sw-Button#foo(type="button") Primary Button
  button.sw-Button(disabled type="button") Primary Button
div
  input.sw-Button(type="button" value="Primary Button")
  input.sw-Button(type="button" value="Primary Button" disabled)
```

<span class="sw-Label">BTN-2</span>

アイコンを付けます。

```jade
a.sw-Button.sw-Button-icon(href="#") Primary Button
  span.sw-Button_External(aria-hidden="true")
```

<span class="sw-Label">BTN-2A</span>

Primary Button Small

```jade
a.sw-Button.sw-Button-small(href="#") Small Primary
```

<span class="sw-Label">BTN-2B</span>

Primary Button Small Icon

```jade
a.sw-Button.sw-Button-smallIcon(href="#") Button
  span.sw-Button_External(aria-hidden="true")
```

<span class="sw-Label">BTN-4</span>

サブで使用するボタン

```jade
a.sw-Button.sw-Button-danger(href="#") Danger Button
a.sw-Button.sw-Button-danger.sw-Button-disabled(href="#" tabindex="-1") Danger Button
```

<span class="sw-Label"></span>

全幅

```jade
a.sw-Button.sw-Button-full(href="#") Primary Button
a.sw-Button.sw-Button-icon.sw-Button-full(href="#") Primary Button
  span.sw-Button_External(aria-hidden="true")
a.sw-Button.sw-Button-small.sw-Button-full(href="#") Small Primary
a.sw-Button.sw-Button-smallIcon.sw-Button-full(href="#") Button
  span.sw-Button_External(aria-hidden="true")
a.sw-Button.sw-Button-danger.sw-Button-full(href="#") Primary Button
```
*/
.sw-Button {
  @include sw-Button;

  &:not(.sw-Button-disabled):not(:disabled):hover {
    background-color: $color-red;
    color: $color-white;
  }

  &.swm-LinkIconA,
  &.swm-LinkIconB,
  &.swm-LinkIconPdf,
  &.swm-LinkIconArrow {
    padding: rem(12) rem(20) rem(11) rem(24);
  }
}

.sw-Button-icon {
  padding: rem(12) rem(20) rem(11) rem(24);
}

.sw-Button-small {
  padding: rem(12) rem(15) rem(11);
  font-size: rem(13);
  border-radius: (3 / 13) * 1em;
}

.sw-Button-smallIcon {
  padding: rem(12) rem(15) rem(11) rem(20);
  font-size: rem(13);
  border-radius: (3 / 13) * 1em;
}

.sw-Button_External {
  display: inline-block;
  position: relative;
  height: rem(11);
  margin-left: rem(5);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.25em;
  }
}

.sw-Button-danger {
  padding: rem(11) rem(15) rem(10);
  font-weight: 600;
  background-color: $color-red;
  color: $color-white;

  &:not(.sw-Button-disabled):not(:disabled):hover {
    border-color: #b80f26;
    background: #b80f26;
  }

  &.sw-Button-disabled {
    border-color: $color-gray-plus1;
    background-color: $color-gray-plus1;
  }
}

.sw-Button-full {
  width: 100%;
  max-width: none;
}
