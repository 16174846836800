/*
---
name: sw-ButtonContact
namespace: sw-
category: Button
id: BTN-3
---

<span class="sw-Label">BTN-3</span>

問い合わせボタン

```jade
a.sw-ButtonContact(href="#")
  span.sw-ButtonContact_Icon(aria-hidden="true")
  span.sw-ButtonContact_Text Inquiry Form
```
*/
.sw-ButtonContact {
  @include sw-Button;

  display: flex;
  align-items: center;
  max-width: rem(170);
  padding: rem(10) rem(30);
  text-align: center;
  white-space: nowrap;
  font-weight: 600;
  border: 1px solid #dadada;
  background: #fff;
  box-shadow: 1px 1px 2px 0 rgba(#000, 0.1);

  &:not(.sw-Button-disabled):not(:disabled):hover {
    border-color: $color-red;
    background-color: $color-red;
    color: #fff;
  }
}

.sw-ButtonContact_Icon {
  position: relative;
  height: rem(13);
  font-size: rem(16);

  &::before {
    @include icon;

    content: "#{$icon-mail2}";
    position: relative;
    top: -0.3em;
  }
}

.sw-ButtonContact_Text {
  display: inline-block;
  margin-left: rem(10);
  font-size: rem(13);
}
