/*
---
name: sw-Text
namespace: sw-
category: Text
id: TXT-3
---

<span class="sw-Label">TXT-3</span>

基本のテキストスタイルです。

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics
.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics
```

テキストを中央揃え、右揃え、左揃えに指定できます。

- `.sw-Text-center`
- `.sw-Text-centerSm`
- `.sw-Text-centerMd`
- `.sw-Text-centerLg`
- `.sw-Text-centerXl`
- `.sw-Text-right`
- `.sw-Text-rightSm`
- `.sw-Text-rightMd`
- `.sw-Text-rightLg`
- `.sw-Text-rightXl`
- `.sw-Text-left`
- `.sw-Text-leftSm`
- `.sw-Text-leftMd`
- `.sw-Text-leftLg`
- `.sw-Text-leftXl`

```jade
p.sw-Text.sw-Text-center A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics
.sw-Text.sw-Text-right A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics
```
*/
.sw-Text {
  margin-top: rem(20);
  margin-bottom: rem(20);
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;
}

.sw-Text-center {
  text-align: center;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-Text-center#{$suffix} {
    @media #{$breakpoint} {
      text-align: center;
    }
  }
}

.sw-Text-right {
  text-align: right;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-Text-right#{$suffix} {
    @media #{$breakpoint} {
      text-align: right;
    }
  }
}

.sw-Text-left {
  text-align: left;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-Text-left#{$suffix} {
    @media #{$breakpoint} {
      text-align: left;
    }
  }
}
