/*
---
name: product-BoxA
namespace: product-
category: Block
id: [BOX-8A, BOX-8B, BOX-8C]
---

Products Box

<span class="sw-Label">BOX-8A</span>

- タブレット以降で横並びになります

```jade
ul.product-BoxA
  li.product-BoxA_Item
    .product-BoxA_Box.product-BoxA-BoxMediaMd
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_426.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading BB-100
        p.product-BoxA_Text Flexible cutting board,<br> plastics<br> Size: 37 x 25 x 0.2 cm
        ul.product-BoxA_Colors
          li.product-BoxA_Color
            span.product-BoxA_ColorBlack(aria-label="Black")
          li.product-BoxA_Color
            span.product-BoxA_ColorRed(aria-label="Red")
          li.product-BoxA_Color
            span.product-BoxA_ColorGreen(aria-label="Green")
          li.product-BoxA_Color
            span.product-BoxA_ColorOrange(aria-label="Orange")
          li.product-BoxA_Color
            span.product-BoxA_ColorWhite(aria-label="White")
          li.product-BoxA_Color
            span.product-BoxA_ColorGray(aria-label="Gray")
          li.product-BoxA_Color
            span.product-BoxA_ColorPink(aria-label="Pink")
```

<span class="sw-Label">BOX-8B</span>

```jade
ul.product-BoxA.product-BoxA-col2Md
  li.product-BoxA_Item
    .product-BoxA_Box
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_426.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading BB-100
        p.product-BoxA_Text Flexible cutting board,<br> plastics<br> Size: 37 x 25 x 0.2 cm
        ul.product-BoxA_Colors
          li.product-BoxA_Color
            span.product-BoxA_ColorBlack(aria-label="Black")
          li.product-BoxA_Color
            span.product-BoxA_ColorRed(aria-label="Red")
          li.product-BoxA_Color
            span.product-BoxA_ColorGreen(aria-label="Green")
          li.product-BoxA_Color
            span.product-BoxA_ColorOrange(aria-label="Orange")
          li.product-BoxA_Color
            span.product-BoxA_ColorWhite(aria-label="White")
          li.product-BoxA_Color
            span.product-BoxA_ColorGray(aria-label="Gray")
          li.product-BoxA_Color
            span.product-BoxA_ColorPink(aria-label="Pink")
  li.product-BoxA_Item
    .product-BoxA_Box
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_426.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading BB-100
        p.product-BoxA_Text Flexible cutting board,<br> plastics<br> Size: 37 x 25 x 0.2 cm
        ul.product-BoxA_Colors
          li.product-BoxA_Color
            span.product-BoxA_ColorBlack(aria-label="Black")
          li.product-BoxA_Color
            span.product-BoxA_ColorRed(aria-label="Red")
          li.product-BoxA_Color
            span.product-BoxA_ColorGreen(aria-label="Green")
          li.product-BoxA_Color
            span.product-BoxA_ColorOrange(aria-label="Orange")
          li.product-BoxA_Color
            span.product-BoxA_ColorWhite(aria-label="White")
          li.product-BoxA_Color
            span.product-BoxA_ColorGray(aria-label="Gray")
          li.product-BoxA_Color
            span.product-BoxA_ColorPink(aria-label="Pink")
```

<span class="sw-Label">BOX-8C</span>

```jade
ul.product-BoxA.product-BoxA-col3Md
  li.product-BoxA_Item
    .product-BoxA_Box
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_690.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading CM-50-CF
        p.product-BoxA_Text Multi-purpose ceramic mill; adjustable ceramic grinding gear, with aroma lid, grinds wet salt<br><br>Height: 17 cm
        ul.product-BoxA_Colors
          li.product-BoxA_Color
            span.product-BoxA_ColorBlack(aria-label="Black")
          li.product-BoxA_Color
            span.product-BoxA_ColorRed(aria-label="Red")
          li.product-BoxA_Color
            span.product-BoxA_ColorGreen(aria-label="Green")
          li.product-BoxA_Color
            span.product-BoxA_ColorOrange(aria-label="Orange")
          li.product-BoxA_Color
            span.product-BoxA_ColorWhite(aria-label="White")
          li.product-BoxA_Color
            span.product-BoxA_ColorGray(aria-label="Gray")
          li.product-BoxA_Color
            span.product-BoxA_ColorPink(aria-label="Pink")
  li.product-BoxA_Item
    .product-BoxA_Box
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_690.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading CM-40
        p.product-BoxA_Text Ceramic slim coffee grinder; adjustable ceramic grinding gear, with a two-piece cover for easy filling<br><br>Height: 19.5 cm
        ul.product-BoxA_Colors
          li.product-BoxA_Color
            span.product-BoxA_ColorBlack(aria-label="Black")
          li.product-BoxA_Color
            span.product-BoxA_ColorRed(aria-label="Red")
          li.product-BoxA_Color
            span.product-BoxA_ColorGreen(aria-label="Green")
          li.product-BoxA_Color
            span.product-BoxA_ColorOrange(aria-label="Orange")
          li.product-BoxA_Color
            span.product-BoxA_ColorWhite(aria-label="White")
          li.product-BoxA_Color
            span.product-BoxA_ColorGray(aria-label="Gray")
          li.product-BoxA_Color
            span.product-BoxA_ColorPink(aria-label="Pink")
  li.product-BoxA_Item
    .product-BoxA_Box
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_690.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading CM-25D-BK
        p.product-BoxA_Text Ceramic dual mill; adjustable ceramic grinding gear, grinds wet salt <br> SHeight: 20 cm
  li.product-BoxA_Item
    .product-BoxA_Box
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_690.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading CM-50-CF
        p.product-BoxA_Text Multi-purpose ceramic mill; adjustable ceramic grinding gear, with aroma lid, grinds wet salt<br><br>Height: 17 cm
        ul.product-BoxA_Colors
          li.product-BoxA_Color
            span.product-BoxA_ColorBlack(aria-label="Black")
          li.product-BoxA_Color
            span.product-BoxA_ColorRed(aria-label="Red")
          li.product-BoxA_Color
            span.product-BoxA_ColorGreen(aria-label="Green")
          li.product-BoxA_Color
            span.product-BoxA_ColorOrange(aria-label="Orange")
          li.product-BoxA_Color
            span.product-BoxA_ColorWhite(aria-label="White")
          li.product-BoxA_Color
            span.product-BoxA_ColorGray(aria-label="Gray")
          li.product-BoxA_Color
            span.product-BoxA_ColorPink(aria-label="Pink")
  li.product-BoxA_Item
    .product-BoxA_Box
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_690.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading CM-40
        p.product-BoxA_Text Ceramic slim coffee grinder; adjustable ceramic grinding gear, with a two-piece cover for easy filling<br><br>Height: 19.5 cm
        ul.product-BoxA_Colors
          li.product-BoxA_Color
            span.product-BoxA_ColorBlack(aria-label="Black")
          li.product-BoxA_Color
            span.product-BoxA_ColorRed(aria-label="Red")
          li.product-BoxA_Color
            span.product-BoxA_ColorGreen(aria-label="Green")
          li.product-BoxA_Color
            span.product-BoxA_ColorOrange(aria-label="Orange")
          li.product-BoxA_Color
            span.product-BoxA_ColorWhite(aria-label="White")
          li.product-BoxA_Color
            span.product-BoxA_ColorGray(aria-label="Gray")
          li.product-BoxA_Color
            span.product-BoxA_ColorPink(aria-label="Pink")
  li.product-BoxA_Item
    .product-BoxA_Box
      .product-BoxA_Head
        span.product-BoxA_Image
          img(src="/assets/img/common/img_690_690.png" alt)
      .product-BoxA_Body
        h3.product-BoxA_Heading CM-25D-BK
        p.product-BoxA_Text Ceramic dual mill; adjustable ceramic grinding gear, grinds wet salt <br> SHeight: 20 cm
        ul.product-BoxA_Colors
          li.product-BoxA_Color
            span.product-BoxA_ColorBlack(aria-label="Black")
          li.product-BoxA_Color
            span.product-BoxA_ColorRed(aria-label="Red")
          li.product-BoxA_Color
            span.product-BoxA_ColorGreen(aria-label="Green")
          li.product-BoxA_Color
            span.product-BoxA_ColorOrange(aria-label="Orange")
          li.product-BoxA_Color
            span.product-BoxA_ColorWhite(aria-label="White")
          li.product-BoxA_Color
            span.product-BoxA_ColorGray(aria-label="Gray")
          li.product-BoxA_Color
            span.product-BoxA_ColorPink(aria-label="Pink")
```
*/
$product-BoxA-row-gap: rem(40);
$product-BoxA-row-gap-md: rem(60);

.product-BoxA {
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  margin-top: $product-BoxA-row-gap;
  margin-bottom: $margin-bottom-base;
  margin-left: rem(-15);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-top: $product-BoxA-row-gap-md;
    margin-left: rem(-30);
  }
}

.product-BoxA_Item {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: rem(15);

  @include mq-up(md) {
    padding-left: rem(30);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  /* 1 Column */
  &:nth-of-type(n + 2) {
    margin-top: $product-BoxA-row-gap;
  }
}

@include mq-up(md) {
  .product-BoxA-col2Md > .product-BoxA_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $product-BoxA-row-gap-md;
    }
  }

  .product-BoxA-col3Md > .product-BoxA_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $product-BoxA-row-gap-md;
    }
  }
}

.product-BoxA_Box {
  display: flex;
  flex-wrap: wrap;
}

.product-BoxA-BoxMediaMd {
  @include mq-up(md) {
    margin-left: rem(-30);
  }
}

.product-BoxA_Head {
  flex-basis: 100%;
  max-width: 100%;

  .product-BoxA-BoxMediaMd & {
    @include mq-up(md) {
      flex-basis: percentage(7 / 12);
      max-width: percentage(7 / 12);
      padding-left: rem(30);
    }
  }
}

.product-BoxA_Body {
  flex-basis: 100%;
  max-width: 100%;
  padding: rem(10) rem(20) 0;

  @include mq-up(md) {
    padding: rem(10) 0 0;
  }

  .product-BoxA-BoxMediaMd & {
    @include mq-up(md) {
      flex-basis: percentage(5 / 12);
      max-width: percentage(5 / 12);
      margin-top: 0;
      padding: 0 rem(20) 0 rem(30);
    }
  }
}

.product-BoxA_Heading {
  margin-bottom: rem(10);
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.3;
  color: $color-red;
}

.product-BoxA_Text {
  margin-bottom: rem(8);
  font-size: rem(14);
  line-height: 1.5;
  color: $color-gray-minus2;
}

.product-BoxA_Colors {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
}

.product-BoxA_Color {
  margin-right: rem(7);
}

.product-BoxA_ColorBlack {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(18);
  height: rem(18);
  border: 9px solid #000;
  border-radius: 50%;
}

.product-BoxA_ColorRed {
  @extend .product-BoxA_ColorBlack;

  border-color: #c2100d;
}

.product-BoxA_ColorGreen {
  @extend .product-BoxA_ColorBlack;

  border-color: #859953;
}

.product-BoxA_ColorOrange {
  @extend .product-BoxA_ColorBlack;

  border-color: #f4961a;
}

.product-BoxA_ColorGray {
  @extend .product-BoxA_ColorBlack;

  border-color: $color-gray-plus1;
}

.product-BoxA_ColorPink {
  @extend .product-BoxA_ColorBlack;

  border-color: #f1add2;
}

.product-BoxA_ColorWhite {
  @extend .product-BoxA_ColorBlack;

  position: relative;
  width: rem(17);
  height: rem(17);
  margin-top: rem(1);
  border: 8.5px solid #000;
  border-color: $color-white;
  box-shadow: 0 0 0 1px $color-gray-plus2;
}
