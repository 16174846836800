/*
---
name: sw-Emphasis
namespace: sw-
category: Text
id: TXT-7
---

<span class="sw-Label">TXT-7</span>

`<em>`で使われるスタイルです。アクセントをつけたい単語や短い文章で使用し、強調や重要性を示しません。

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from
  em.sw-Emphasis  the vehicle’s main wiring harness.
```
*/
.sw-Emphasis {
  font-weight: 400;
  font-style: italic;
}

/* CMS対応 */
em {
  .news-Body & {
    font-weight: 400;
    font-style: italic;
  }

  .l-DetailTemplate & {
    font-weight: 400;
    font-style: italic;
  }
}
