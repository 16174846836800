.sitemap-Layout {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-15);
  padding-top: rem(18);
  padding-bottom: rem(70);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-left: rem(-30);
    padding-top: rem(58);
    padding-bottom: rem(90);
  }
}

.sitemap-Layout_Item {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: rem(15);

  @include mq-up(md) {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);
    padding-left: rem(30);
  }

  &:first-of-type::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    margin: rem(30) 0;
    border-top: 1px solid $color-gray-plus2;

    @include mq-up(md) {
      display: none;
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
