/*
---
name: st-Sitemap
namespace: st-
category: Global
---

グローバルフッターでカテゴリのリンクへナビゲートします。

- 項目はMTから出力・出しわけされます
- ホームページとカテゴリトップのレイアウトです

```jade
footer.st-Sitemap
  .st-Sitemap_Inner
    .st-Sitemap_Items
      .st-Sitemap_Item
        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="/products/") Products
            span.st-Sitemap_HeadingIcon(aria-hidden="true")
        p.st-Sitemap_SubHeading Business to Business
        ul.st-Sitemap_Nav
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Printers / Multifunctionals
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Fine Ceramic Components
              span.st-Sitemap_NavLinkExternalA(aria-hidden="true")
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Semiconductor Components
              span.st-Sitemap_NavLinkExternalB(aria-hidden="true")
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Automotive Components
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Cutting Tools
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Electronic Components &amp; Devices
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Printing Devices
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") LCDs and Touch Solutions
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Organic Materials
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Solar Electric Systems
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Watch and Jewelry Industry

      .st-Sitemap_Item.st-Sitempa_Item-offset
        p.st-Sitemap_SubHeading Consumer
        ul.st-Sitemap_Nav
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Kitchen Products
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Solar Electric Systems
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Office Products

      .st-Sitemap_Item
        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="/company/") About
            span.st-Sitemap_HeadingIcon(aria-hidden="true")
        ul.st-Sitemap_Nav
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="/company/asiapacific/") About Kyocera EU
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") Europe Network
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="https://global.kyocera.com/company/summary/index.html") About the KYOCERA Group
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="https://global.kyocera.com/company/division/index.html") Business Segments
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="https://global.kyocera.com/company/movie/index.html") KYOCERA Corporate Profile Video
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="https://global.kyocera.com/ecology/index.html") Corporate Social Responsibility
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="https://global.kyocera.com/company/location/index.html") Global Network

      .st-Sitemap_Item
        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="/news/") News
            span.st-Sitemap_HeadingIcon(aria-hidden="true")
        ul.st-Sitemap_Nav
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="/news/") 2019
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") 2018
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="/index/news/previous_news.na-y-2017.html") Archive(~2017)

        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="/inquiry/") Contact
            span.st-Sitemap_HeadingIcon(aria-hidden="true")

    .st-Sitemap_Region
      .st-RegionSelect
        button.st-RegionSelect_Button(type="button" data-toggle="0")
          span.st-RegionSelect_Region Europe
          span.st-RegionSelect_Icon(aria-hidden="true")
        select#st-RegionSelect_Select.st-RegionSelect_Select
          option(value="0") Europe
          option(value="1") Austria
          option(value="2") Denmark
          option(value="3") Finland
          option(value="4") France
          option(value="5") Germany
          option(value="6") Italy
          option(value="7") Netherlands
          option(value="8") Norway
          option(value="9") Portugal
          option(value="10") Russia
          option(value="11") Spain
          option(value="12") Sweden
          option(value="13") Switzerland
          option(value="14") Africa
          option(value="15") United Kingdom
      a.st-Sitemap_GlobalLink(href="#")
        span.st-Sitemap_GlobalIcon(aria-hidden="true")
        | Global
```

Productsの下層ページで使用するサイトマップです。
「Semiconductor Components」と「Products」はカテゴリによって変更します。

- ProductsエリアはPC未満でトグルになります。

```jade
footer.st-Sitemap.st-Sitemap-products
  .st-Sitemap_Inner
    .st-Sitemap_Items
      .st-Sitemap_Item
        h3.st-Sitemap_HeadingB
          a.st-Sitemap_HeadingBLink(href="/products/") Semiconductor <br class="sw-BrShowLg">Components
            span.st-Sitemap_HeadingBIcon(aria-hidden="true")
      .st-Sitemap_Item
        button.st-Sitemap_Toggle#st-Sitemap_Toggle(type="button" aria-expanded="false" aria-controls="st-SideNav_Parent")
          | Products
          span.st-Sitemap_ToggleIcon(aria-hidden="true")
        ul.st-Sitemap_Products#st-Sitemap_Products(aria-hidden="true")
          li.st-Sitemap_ProductsItem
            a.st-Sitemap_ProductsLink(href="#") Automotive
          li.st-Sitemap_ProductsItem
            a.st-Sitemap_ProductsLink(href="#") LED Lighting
              span.st-Sitemap_NavLinkExternalA(aria-hidden="true")
          li.st-Sitemap_ProductsItem
            a.st-Sitemap_ProductsLink(href="#") Camera Image Sensors
              span.st-Sitemap_NavLinkExternalB(aria-hidden="true")
          li.st-Sitemap_ProductsItem
            a.st-Sitemap_ProductsLink(href="#") Power - AMB Substrates
          li.st-Sitemap_ProductsItem
            a.st-Sitemap_ProductsLink(href="#") MEMS Sensors Packaging
          li.st-Sitemap_ProductsItem
            a.st-Sitemap_ProductsLink(href="#") Network / Infrastructure
          li.st-Sitemap_ProductsItem
            a.st-Sitemap_ProductsLink(href="#") Fiber Optics

      .st-Sitemap_Item
        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="/news/") News
            span.st-Sitemap_HeadingIcon(aria-hidden="true")
        ul.st-Sitemap_Nav
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="/news/") 2019
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="#") 2018
          li.st-Sitemap_NavItem
            a.st-Sitemap_NavLink(href="/index/news/previous_news.na-y-2017.html") Archive(~2017)

      .st-Sitemap_Item
        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="/news/") Sales Office
            span.st-Sitemap_HeadingIcon(aria-hidden="true")
        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="#") Menu
            span.st-Sitemap_HeadingIconExternalA(aria-hidden="true")
        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="#") Menu
            span.st-Sitemap_HeadingIconExternalB(aria-hidden="true")
        .st-Sitemap_Demiliter
        h3.st-Sitemap_Heading
          a.st-Sitemap_HeadingLink(href="/news/") All Products
            span.st-Sitemap_HeadingIcon(aria-hidden="true")

    .st-Sitemap_Region
      .st-RegionSelect
        button.st-RegionSelect_Button(type="button" data-toggle="0")
          span.st-RegionSelect_Region Europe
          span.st-RegionSelect_Icon(aria-hidden="true")
        select#st-RegionSelect_Select.st-RegionSelect_Select
          option(value="0") Europe
          option(value="1") Austria
          option(value="2") Denmark
          option(value="3") Finland
          option(value="4") France
          option(value="5") Germany
          option(value="6") Italy
          option(value="7") Netherlands
          option(value="8") Norway
          option(value="9") Portugal
          option(value="10") Russia
          option(value="11") Spain
          option(value="12") Sweden
          option(value="13") Switzerland
          option(value="14") Africa
          option(value="15") United Kingdom
      a.st-Sitemap_GlobalLink(href="#")
        span.st-Sitemap_GlobalIcon(aria-hidden="true")
        | Global
```
*/
.st-Sitemap {
  padding-bottom: rem(10);
  background-color: #f2f4f6;

  @include mq-up(lg) {
    padding: rem(40) rem(30) rem(20);
  }

  @include mq-up(xl) {
    padding-right: 0;
    padding-left: 0;
  }
}

.st-Sitemap_Inner {
  max-width: 1170px;
  margin: auto;
}

.st-Sitemap_Items {
  border-top: 1px solid #dadada;

  @include mq-up(lg) {
    display: flex;
    width: 100%;
    border-top: 0;
  }
}

.st-Sitemap_Item {
  @include mq-up(lg) {
    width: 100%;
    padding-right: rem(15);

    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: percentage(292.5 / 1170);
    }

    &:nth-of-type(3) {
      width: percentage(377 / 1170);
    }

    &:nth-of-type(4) {
      width: percentage(208 / 1170);
    }

    &.st-Sitempa_Item-offset {
      margin-top: rem(35);
    }

    .st-Sitemap-products & {
      padding-right: rem(30);

      &:nth-of-type(1) {
        width: percentage(300 / 1170);
      }

      &:nth-of-type(2) {
        width: percentage(300 / 1170);
      }

      &:nth-of-type(3) {
        width: percentage(300 / 1170);
      }

      &:nth-of-type(4) {
        width: percentage(270 / 1170);
        padding-right: 0;
      }
    }
  }
}

.st-Sitemap_Heading {
  border-bottom: 1px solid #dadada;

  @include mq-up(lg) {
    margin-bottom: rem(15);
    border-bottom: 0;

    &:nth-of-type(n + 2) {
      margin-top: rem(18);
    }

    .st-Sitemap_Nav + & {
      margin-top: 15px;
    }
  }
}

.st-Sitemap_HeadingLink {
  display: block;
  position: relative;
  padding: rem(14) rem(15);
  padding: rem(14) rem(26) rem(14) rem(15);
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.875;
  color: #000;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    padding: 0;
    line-height: 1.3;

    &:hover {
      text-decoration: underline;
      color: $color-link-hover;
    }
  }
}

.st-Sitemap_HeadingIcon {
  position: absolute;
  top: 0;
  right: rem(15);
  bottom: 0;
  height: rem(17);
  margin: auto;
  font-size: rem(17);
  color: $color-gray;

  @include mq-up(lg) {
    position: relative;
    top: -0.1em;
    right: auto;
    left: rem(8);
    height: rem(9);
    font-size: rem(9);
    color: #e00f2c;
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.5em;

    @include mq-up(lg) {
      top: -0.1em;
      height: rem(18);
      font-size: rem(18);
      transform: scale(0.5);
      transform-origin: left;
    }
  }
}

.st-Sitemap_HeadingIconExternalA {
  @extend .st-Sitemap_HeadingIcon;

  right: rem(18);
  height: rem(14);
  font-size: rem(14);

  @include mq-up(md) {
    right: 0;
    left: rem(4);
    height: rem(11);
    font-size: rem(11);
  }

  &::before {
    content: "#{$icon-filter_none}";
    top: -0.5em;
    height: rem(14);
    font-size: rem(14);

    @include mq-up(lg) {
      top: -0.1em;
      height: rem(11);
      font-size: rem(11);
      transform: scale(1);
    }
  }
}

.st-Sitemap_HeadingIconExternalB {
  @extend .st-Sitemap_HeadingIcon;

  right: rem(18);
  height: rem(14);
  font-size: rem(14);

  @include mq-up(md) {
    right: 0;
    left: rem(4);
    height: rem(11);
    font-size: rem(11);
  }

  &::before {
    content: "#{$icon-external-link}";
    top: -0.5em;
    height: rem(14);
    font-size: rem(14);

    @include mq-up(lg) {
      top: -0.1em;
      height: rem(11);
      font-size: rem(11);
      transform: scale(1);
    }
  }
}

.st-Sitemap_HeadingB {
  @extend .st-Sitemap_Heading;

  border-bottom: 1px solid #dadada !important;

  @include mq-up(lg) {
    border-bottom: none !important;
  }
}

.st-Sitemap_HeadingBLink {
  @extend .st-Sitemap_HeadingLink;

  padding: rem(24) rem(17);
  font-size: rem(20);
  line-height: 1.17;

  @include mq-up(lg) {
    padding: 0;
  }
}

.st-Sitemap_HeadingBIcon {
  @extend .st-Sitemap_HeadingIcon;

  position: relative;
  right: auto;
  left: rem(2);
  font-size: 0.5em;

  @include mq-up(md) {
    left: 0;
  }

  &::before {
    top: -0.2em;
    left: 2px;
    font-size: rem(13);
    color: $color-red;

    @include mq-up(md) {
      font-size: rem(26);
    }
  }
}

.st-Sitemap_SubHeading {
  display: none;

  @include mq-up(lg) {
    display: block;
    margin-bottom: rem(15);
    font-size: rem(13);
    font-weight: 700;
    line-height: 1.6;
    color: #666;
  }
}

.st-Sitemap_Nav {
  display: none;

  @include mq-up(lg) {
    display: block;
    padding-left: 0;
    list-style-type: none;
  }
}

.st-Sitemap_NavItem {
  @include mq-up(lg) {
    & + & {
      margin-top: rem(12);
    }
  }
}

.st-Sitemap_NavLink {
  display: block;
  text-decoration: none;
  font-size: rem(13);
  line-height: 1.4;
  color: #666;

  &:hover {
    text-decoration: underline;
    color: $color-link-hover;
    transition-duration: $transition-duration;
  }
}

.st-Sitemap_NavLinkExternalA {
  display: inline-block;
  height: rem(11);
  margin-left: rem(3);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.2em;
  }
}

.st-Sitemap_NavLinkExternalB {
  display: inline-block;
  height: rem(11);
  margin-left: rem(3);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.2em;
  }
}

.st-Sitemap_SmallLink {
  font-size: rem(13);
  line-height: 1.5;
}

.st-Sitemap_NavIconExternal,
.st-Sitemap_NavIconInternal {
  &::before {
    @include icon;

    margin-top: -0.1em;
    margin-left: rem(4);
    font-size: rem(11);
    color: $color-gray-minus2;
  }
}

.st-Sitemap_NavIconExternal {
  &::before {
    content: "#{$icon-external-link}";
  }
}

.st-Sitemap_NavIconInternal {
  &::before {
    content: "#{$icon-filter_none}";
  }
}

.st-Sitemap_Region {
  padding-top: rem(10);
  padding-right: rem(15);
  padding-left: rem(15);

  @include mq-up(lg) {
    margin-top: rem(40);
  }

  @include mq-up(xl) {
    padding: 0;
  }
}

.st-Sitemap_GlobalLink {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  height: 42px;
  margin-left: rem(22);
  text-decoration: none;
  font-size: rem(14);
  line-height: 42px;
  color: #444;

  @include mq-up(lg) {
    margin-left: rem(28);
  }

  &:hover {
    text-decoration: underline;
    color: $color-link-hover;
    transition-duration: $transition-duration;
  }
}

.st-Sitemap_GlobalIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: rem(8);
  font-size: 1.4em;
  color: #a5a5a5;
  transition-duration: 0s;

  .st-Sitemap_GlobalLink:hover & {
    color: $color-link-hover;
    transition-duration: $transition-duration;
  }

  &::after {
    @include icon;

    content: "#{$icon-sphere}";
    position: relative;
    top: -0.15em;
  }
}

.st-Sitemap_Demiliter {
  display: none;

  @include mq-up(lg) {
    display: block;
    width: 100%;
    height: 1px;
    margin: rem(19) 0;
    border-top: 1px solid #dadada;
  }
}

.st-Sitemap_Toggle {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: rem(14) rem(36) rem(13) rem(15);
  text-align: left;
  text-decoration: none;
  font-family: inherit;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.875;
  border: none;
  background-color: transparent;
  color: #000;
  appearance: none;

  @include mq-up(lg) {
    margin-bottom: rem(18);
    padding: 0;
    line-height: 1.3;
  }

  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    appearance: none;
  }

  &[tabindex="-1"] {
    pointer-events: none;
  }

  &:focus {
    outline: 0;
  }

  &.focus-ring:focus {
    outline: 0;
    box-shadow: $form-box-shadow-focus;
  }

  &[aria-expanded="false"] {
    border-bottom: 1px solid #dadada;

    @include mq-up(lg) {
      border-bottom: none;
    }
  }

  &[aria-expanded="true"] {
    border-bottom: none;
  }
}

.st-Sitemap_ToggleIcon {
  position: absolute;
  top: 0;
  right: rem(15);
  bottom: 0;
  height: rem(17);
  margin: auto;
  font-size: rem(17);
  color: $color-gray;

  @include mq-up(lg) {
    display: none;
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-down}";
    position: relative;
    top: -0.3em;
    transition-duration: $transition-duration;

    .st-Sitemap_Toggle[aria-expanded="true"] & {
      transform: rotate(-180deg);
    }
  }
}

.st-Sitemap_Products {
  padding: 0 rem(15);
  list-style-type: none;

  @include mq-up(lg) {
    padding: 0;
  }

  &[aria-hidden="true"] {
    display: none;

    @include mq-up(lg) {
      display: block;
    }
  }

  &[aria-hidden="false"] {
    display: block;
    border-bottom: 1px solid #dadada;

    @include mq-up(lg) {
      display: block;
      border-bottom: none;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.st-Sitemap_ProductsItem {
  border-top: 1px solid #dadada;

  @include mq-up(lg) {
    margin-bottom: rem(15);
    border-top: none;
  }
}

.st-Sitemap_ProductsLink {
  display: block;
  padding: rem(15) 0;
  text-decoration: none;
  font-size: rem(16);
  line-height: 1.375;
  color: #444;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    padding: 0;
    font-size: rem(13);
    line-height: 1.15;
  }

  &:hover {
    text-decoration: underline;
    color: $color-red;
  }
}
