/*
---
name: office-BoxB
namespace: office-
category: Block
id: BOX-1A
---

<span class="sw-Label">BOX-1A</span>

ProductsのSales Office一覧です。

- オフィスごとに複数人が入る場合があります


```jade
ul.l-Grids.l-Grids-col2Lg
  li.l-Grids_Item
    .office-BoxB
      p.office-BoxB_Country Great Britain
      h3.office-BoxB_CompanyName KYOCERA Fineceramics Ltd.
      p.office-BoxB_Text
        | Prospect House, Archipelago
        br
        | Lyon Way, Frimley
        br
        | Surrey GU16 7ER
      p.office-BoxB_Text
        | Phone: +44 (0)1276 - 69 34 50
        br
        | Fax: +44 (0)1276 - 69 34 60
      a.office-BoxB_Mail(href="mailto:salessupport@kyocera.de")
        span.office-BoxB_MailIcon(aria-hidden="true")
        span.office-BoxB_MailText salessupport@kyocera.de
  li.l-Grids_Item
    .office-BoxB
       p.office-BoxB_Country France
       h3.office-BoxB_CompanyName KYOCERA Fineceramics SAS
       p.office-BoxB_Text
         | Parc Icade Orly - Rungis 
         br
         | 21 rue de Villeneuve
         br
         | 94150 Rungis
       p.office-BoxB_Text
         | Phone: +33 (0)1 41 73 73 30
         br
         | Fax: +33 (0)1 41 73 73 59
       a.office-BoxB_Mail(href="mailto:sales.france@kyocera.de")
         span.office-BoxB_MailIcon(aria-hidden="true")
         span.office-BoxB_MailText sales.france@kyocera.de
ul.l-Grids
  li.l-Grids_Item
    .office-BoxB
       p.office-BoxB_Country Germany
       h3.office-BoxB_CompanyName KYOCERA Fineceramics GmbH
       ul.office-BoxB_List
         li.office-BoxB_Item
           p.office-BoxB_Text
             | Fritz-Müller-Straße 27
             br
             | 73730 Esslingen
           p.office-BoxB_Text
            | Phone: +33 (0)1 41 73 73 30
            br
            | Fax: +33 (0)1 41 73 73 59
           a.office-BoxB_Mail(href="mailto:info@kyocera.de")
             span.office-BoxB_MailIcon(aria-hidden="true")
             span.office-BoxB_MailText info@kyocera.de
         li.office-BoxB_Item
           p.office-BoxB_Text
             | Hammfelddamm 6
             br
             | 41460 Neuss
           p.office-BoxB_Text
             | Phone: +49 (0)2131 - 16 37-0
             br
             | Fax: +49 (0)2131 - 16 37-150
           a.office-BoxB_Form(href="#")
             span.office-BoxB_FormText Contact Us
             span.office-BoxB_FormIcon(aria-hidden="true")
```
*/
.office-BoxB {
  height: 100%;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding: rem(30) rem(40);
  background: #f2f4f6;

  @include mq-up(md) {
    padding: rem(32) rem(40) rem(30);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.office-BoxB_Country {
  margin-bottom: rem(7);
  font-size: rem(15);
  font-weight: 700;
  line-height: 1.3;
  color: #888;
}

.office-BoxB_CompanyName {
  position: relative;
  margin-bottom: rem(15);
  padding-bottom: rem(15);
  font-size: rem(16);
  font-weight: 700;
  line-height: 1.375;
  color: #444;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 1px;
    border-top: 1px solid #bcbcbc;
  }
}

.office-BoxB_List {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-15);
  padding-left: 0;
  list-style-type: none;
}

.office-BoxB_Item {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: rem(15);

  @include mq-up(lg) {
    &:nth-of-type(odd) {
      flex-basis: percentage(373 / 791);
      max-width: percentage(373 / 791);
    }

    &:nth-of-type(even) {
      flex-basis: percentage(418 / 791);
      max-width: percentage(418 / 791);
    }
  }

  &:nth-of-type(n + 2) {
    margin-top: rem(40);

    @include mq-up(lg) {
      margin-top: 0;
    }
  }
}

.office-BoxB_Text {
  margin-bottom: 1em;
  font-size: rem(15);
  line-height: 1.4;
  color: #444;
}

.office-BoxB_Mail {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  text-decoration: none;
  font-size: rem(15);
  line-height: 1.3;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.office-BoxB_MailIcon {
  position: relative;
  height: rem(17);
  margin-right: rem(7);
  font-size: rem(17);

  &::before {
    @include icon;

    content: "#{$icon-mail}";
    position: relative;
    top: -0.2em;
  }
}

.office-BoxB_MailText {
}

.office-BoxB_Form {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  text-decoration: none;
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.3;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.office-BoxB_FormText {
}

.office-BoxB_FormIcon {
  position: relative;
  height: rem(12);
  margin-left: rem(7);
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.3em;
  }
}
