/*
---
name: swm-AccordionA
namespace: swm-
category: InteractiveComponent
id: M_ACC-1
---

<span class="sw-Label">M_ACC-1</span>

- swm-AccordionA_Tabの間の要素がアコーディオンの要素になります

```jade
a.swm-AccordionA_Tab(role="button")
  | Belgium
  span.swm-AccordionA_TabIcon(aria-hidden="true")

ul.office-BoxA
  li.office-BoxA_Item
    h2.office-BoxA_Country Great Britain
    p.office-BoxA_CompanyName KYOCERA Fineceramics Ltd.
    p.office-BoxA_Text
      | Prospect House, Archipelago 
      br
      | Lyon Way, Frimley 
      br
      | Surrey GU16 7ER
    p.office-BoxA_Text
      | Phone: +44 (0)1276 - 69 34 50
      br
      | Fax: +44 (0)1276 - 69 34 60
    a.office-BoxA_Link(href="#") http://www.xxxx.com/hogehoge/hogehoge/hogehoge/hogehoge/hogehoge
      span.office-BoxA_IconExternal(aria-hidden="true")
    a.office-BoxA_Mail(href="mailto:salessupport@kyocera.de")
      span.office-BoxA_MailIcon(aria-hidden="true")
      span.office-BoxA_MailText salessupport@kyocera.de/hogehoge/hogehoge/hogehoge/hogehoge
    p.office-BoxA_Text Contact person: Jens Wittau

p
  a.swm-AccordionA_Tab(role="button")
    | Germany
    span.swm-AccordionA_TabIcon(aria-hidden="true")

ul.office-BoxA
  li.office-BoxA_Item
    h2.office-BoxA_Country Germany
    p.office-BoxA_CompanyName KYOCERA Fineceramics GmbH
    ul.office-BoxA_Grid
      li.office-BoxA_GridItem
        p.office-BoxA_Text
          | Fritz-Müller-Straße 27
          br
          | 73730 Esslingen
        p.office-BoxA_Text
          | Phone: +49 (0)711 - 93 93 4-0
          br
          | Fax: +49 (0)711 - 93 93 4-950
        a.office-BoxA_Link(href="#" target="_blank") http://www.xxxx.com
          span.office-BoxA_IconExternalB(aria-hidden="true")
        a.office-BoxA_Mail(href="mailto:info@kyocera.de")
          span.office-BoxA_MailIcon(aria-hidden="true")
          span.office-BoxA_MailText info@kyocera.de
        p.office-BoxA_Text Contact person: Jens Wittau
      li.office-BoxA_GridItem
        p.office-BoxA_Text
          | Hammfelddamm 6
          br
          | 41460 Neuss
        p.office-BoxA_Text
          | Phone: +49 (0)2131 - 16 37-0
          br
          | Fax: +49 (0)2131 - 16 37-150
        a.office-BoxA_Link(href="#") http://www.xxxx.com
        a.office-BoxA_Mail(href="mailto:info@kyocera.de")
          span.office-BoxA_MailIcon(aria-hidden="true")
          span.office-BoxA_MailText info@kyocera.de
        p.office-BoxA_Text Contact person: Jens Wittau

a.swm-AccordionA_Tab(role="button")
  | Finland
  span.swm-AccordionA_TabIcon(aria-hidden="true")

ul.office-BoxA
  li.office-BoxA_Item
    h2.office-BoxA_Country Germany
    p.office-BoxA_CompanyName KYOCERA Fineceramics GmbH
    ul.office-BoxA_Grid
      li.office-BoxA_GridItem
        p.office-BoxA_Text
          | Fritz-Müller-Straße 27
          br
          | 73730 Esslingen
        p.office-BoxA_Text
          | Phone: +49 (0)711 - 93 93 4-0
          br
          | Fax: +49 (0)711 - 93 93 4-950
        a.office-BoxA_Link(href="#" target="_blank") http://www.xxxx.com
          span.office-BoxA_IconExternalB(aria-hidden="true")
        a.office-BoxA_Mail(href="mailto:info@kyocera.de")
          span.office-BoxA_MailIcon(aria-hidden="true")
          span.office-BoxA_MailText info@kyocera.de
        p.office-BoxA_Text Contact person: Jens Wittau
      li.office-BoxA_GridItem
        p.office-BoxA_Text
          | Hammfelddamm 6
          br
          | 41460 Neuss
        p.office-BoxA_Text
          | Phone: +49 (0)2131 - 16 37-0
          br
          | Fax: +49 (0)2131 - 16 37-150
        a.office-BoxA_Link(href="#") http://www.xxxx.com
        a.office-BoxA_Mail(href="mailto:info@kyocera.de")
          span.office-BoxA_MailIcon(aria-hidden="true")
          span.office-BoxA_MailText info@kyocera.de
        p.office-BoxA_Text Contact person: Jens Wittau
```

- swm-AccordionA_TabEndで、最後のアコーディオンの範囲を指定できます。
※わかりにくいですが、"Test"の前に`swm-AccordionA_TabEnd`があります。

```jade
a.swm-AccordionA_Tab(role="button")
  | Belgium
  span.swm-AccordionA_TabIcon(aria-hidden="true")

ul.office-BoxA
  li.office-BoxA_Item
    h2.office-BoxA_Country Great Britain
    p.office-BoxA_CompanyName KYOCERA Fineceramics Ltd.
    p.office-BoxA_Text
      | Prospect House, Archipelago 
      br
      | Lyon Way, Frimley 
      br
      | Surrey GU16 7ER
    p.office-BoxA_Text
      | Phone: +44 (0)1276 - 69 34 50
      br
      | Fax: +44 (0)1276 - 69 34 60
    a.office-BoxA_Link(href="#") http://www.xxxx.com/hogehoge/hogehoge/hogehoge/hogehoge/hogehoge
      span.office-BoxA_IconExternal(aria-hidden="true")
    a.office-BoxA_Mail(href="mailto:salessupport@kyocera.de")
      span.office-BoxA_MailIcon(aria-hidden="true")
      span.office-BoxA_MailText salessupport@kyocera.de/hogehoge/hogehoge/hogehoge/hogehoge
    p.office-BoxA_Text Contact person: Jens Wittau

a.swm-AccordionA_Tab(role="button")
  | Finland
  span.swm-AccordionA_TabIcon(aria-hidden="true")

ul.office-BoxA
  li.office-BoxA_Item
    h2.office-BoxA_Country Germany
    p.office-BoxA_CompanyName KYOCERA Fineceramics GmbH
    ul.office-BoxA_Grid
      li.office-BoxA_GridItem
        p.office-BoxA_Text
          | Fritz-Müller-Straße 27
          br
          | 73730 Esslingen
        p.office-BoxA_Text
          | Phone: +49 (0)711 - 93 93 4-0
          br
          | Fax: +49 (0)711 - 93 93 4-950
        a.office-BoxA_Link(href="#" target="_blank") http://www.xxxx.com
          span.office-BoxA_IconExternalB(aria-hidden="true")
        a.office-BoxA_Mail(href="mailto:info@kyocera.de")
          span.office-BoxA_MailIcon(aria-hidden="true")
          span.office-BoxA_MailText info@kyocera.de
        p.office-BoxA_Text Contact person: Jens Wittau
      li.office-BoxA_GridItem
        p.office-BoxA_Text
          | Hammfelddamm 6
          br
          | 41460 Neuss
        p.office-BoxA_Text
          | Phone: +49 (0)2131 - 16 37-0
          br
          | Fax: +49 (0)2131 - 16 37-150
        a.office-BoxA_Link(href="#") http://www.xxxx.com
        a.office-BoxA_Mail(href="mailto:info@kyocera.de")
          span.office-BoxA_MailIcon(aria-hidden="true")
          span.office-BoxA_MailText info@kyocera.de
        p.office-BoxA_Text Contact person: Jens Wittau
.swm-AccordionA_TabEnd(aria-hidden)
p
  | Test
```

CMSでの表示。

```jade
.cms-Component_Editor
  a.swm-AccordionA_Tab(role="button")
    | Belgium
    span.swm-AccordionA_TabIcon(aria-hidden="true")
  
  ul.office-BoxA
    li.office-BoxA_Item
      h2.office-BoxA_Country Great Britain
      p.office-BoxA_CompanyName KYOCERA Fineceramics Ltd.
      p.office-BoxA_Text
        | Prospect House, Archipelago 
        br
        | Lyon Way, Frimley 
        br
        | Surrey GU16 7ER
      p.office-BoxA_Text
        | Phone: +44 (0)1276 - 69 34 50
        br
        | Fax: +44 (0)1276 - 69 34 60
      a.office-BoxA_Link(href="#") http://www.xxxx.com/hogehoge/hogehoge/hogehoge/hogehoge/hogehoge
        span.office-BoxA_IconExternal(aria-hidden="true")
      a.office-BoxA_Mail(href="mailto:salessupport@kyocera.de")
        span.office-BoxA_MailIcon(aria-hidden="true")
        span.office-BoxA_MailText salessupport@kyocera.de/hogehoge/hogehoge/hogehoge/hogehoge
      p.office-BoxA_Text Contact person: Jens Wittau
  .swm-AccordionA_TabEnd(aria-hidden)
  p
    | Test
```
*/
.swm-AccordionA {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
}

.swm-AccordionA_Tab {
  display: block;
  vertical-align: middle;
  position: relative;
  width: 100%;
  margin: 0;
  padding: rem(22) rem(34) rem(20) rem(32);
  text-align: left;
  text-decoration: none;
  font-family: inherit;
  font-size: rem(18);
  font-weight: 600;
  line-height: 1;
  border: none;
  border-top: 1px solid $color-gray-plus3;
  border-bottom: 1px solid $color-gray-plus3;
  border-radius: 0;
  background-color: $color-white;
  color: $color-black;
  cursor: pointer;
  transition-duration: $transition-duration;
  transition-property: background-color;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @include mq-up(md) {
    padding: rem(27) rem(52) rem(25) rem(48);
    font-size: rem(20);
  }

  &[role="button"],
  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    appearance: none;
  }

  &:focus {
    outline: 0;
    box-shadow: 0;
  }

  &.focus-ring:focus {
    outline: 0;
    box-shadow: $form-box-shadow-focus;
  }

  &:hover {
    @include mq-up(lg) {
      background-color: #fbf2f1;
    }
  }

  &[aria-expanded="true"] {
    border-bottom-color: transparent;
  }

  .swm-AccordionA_Panel[aria-hidden="false"] + & {
    margin-top: rem(40);
  }

  .swm-AccordionA_Panel[aria-hidden="true"] + & {
    border-top-color: transparent;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: rem(10);
    width: rem(11);
    height: rem(1);
    margin: auto;
    border-top: 1px solid $color-red;

    @include mq-up(md) {
      left: rem(27);
    }
  }

  .cms-Component_Editor & {
    pointer-events: none;
  }
}

.swm-AccordionA_TabIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: rem(8);
  bottom: 0;
  width: rem(24);
  height: rem(24);
  margin: auto;
  font-size: 0.5em;
  border: 1px solid $color-gray-plus2;
  border-radius: 50%;
  background-color: $color-white;
  color: $color-gray-minus2;

  @include mq-up(md) {
    right: rem(21);
    width: rem(31);
    height: rem(31);
  }

  .swm-AccordionA_Tab[aria-expanded="true"] & {
    background-color: #f2f4f6;
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-down}";
    transition-duration: $transition-duration;

    @include mq-up(md) {
      font-size: 0.6em;
    }

    .swm-AccordionA_Tab[aria-expanded="true"] & {
      transform: rotate(-180deg);
    }
  }
}

.swm-AccordionA_Panel {
  transition-timing-function: ease;
  transition-duration: $transition-duration;
  transition-property: margin;

  &[aria-hidden="true"] {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    margin-top: rem(-10);
    padding-top: rem(10);
  }

  &[aria-hidden="false"] {
    visibility: visible;
    overflow: visible;
    height: auto;
    margin-top: rem(10);
    padding: 0 0 0 rem(35);

    @include mq-up(md) {
      padding-left: rem(80);
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.swm-AccordionA_TabEnd {
  display: none;
  height: 0;
  .cms-Component_Editor & {
    display: block;
    width: 100%;
    height: 40px;
    margin: 20px 0;
    line-height: 20px;
    background: #ccc;
    text-align: center;
    font-size: rem(16);
    &::before {
      display: inline-block;
      line-height: 40px;
      content: "M_ACC-1_END";
    }
  }
}
