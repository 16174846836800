/*
---
name: eventm-BoxA
namespace: eventm-
category: Block
id: M_BOX-5A
---

<span class="sw-Label">M_BOX-5A</span>

Products（Kitchen除く）のイベント一覧です。

```jade
.eventm-BoxA_Item
  .eventm-BoxA_Body
    .eventm-BoxA_Content
      .eventm-BoxA_Time
        span.eventm-BoxA_TimeIcon(aria-hidden="true")
        span.eventm-BoxA_TimeText October 17-19, 2018
      p.eventm-BoxA_Heading ChipEXPO 2018
      p.eventm-BoxA_ContentText
        | Expocentre, pavilion "Forum"
        br
        | Booth # B15
        br
        a.eventm-BoxA_ContentLink(href="http://www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
    .eventm-BoxA_Image
      span.eventm-BoxA_ImageThumbnail
        img(src="/assets/img/common/img_690_296.jpg" alt="")
```

続けると罫線を表示します。

```jade
.eventm-BoxA_Item
  .eventm-BoxA_Body
    .eventm-BoxA_Content
      .eventm-BoxA_Time
        span.eventm-BoxA_TimeIcon(aria-hidden="true")
        span.eventm-BoxA_TimeText October 17-19, 2018
      p.eventm-BoxA_Heading ChipEXPO 2018
      p.eventm-BoxA_ContentText
        | Expocentre, pavilion "Forum"
        br
        | Booth # B15
        br
        a.eventm-BoxA_ContentLink(href="http://www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
    .eventm-BoxA_Image
      span.eventm-BoxA_ImageThumbnail
        img(src="/assets/img/common/img_690_296.jpg" alt="")
.eventm-BoxA_Item
  .eventm-BoxA_Body
    .eventm-BoxA_Content
      .eventm-BoxA_Time
        span.eventm-BoxA_TimeIcon(aria-hidden="true")
        span.eventm-BoxA_TimeText October 17-19, 2018
      p.eventm-BoxA_Heading ChipEXPO 2018
      p.eventm-BoxA_ContentText
        | Expocentre, pavilion "Forum"
        br
        | Booth # B15
        br
        a.eventm-BoxA_ContentLink(href="http://www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
    .eventm-BoxA_Image
      span.eventm-BoxA_ImageThumbnail
        img(src="/assets/img/common/img_690_296.jpg" alt="")
.eventm-BoxA_Item
  .eventm-BoxA_Body
    .eventm-BoxA_Content
      .eventm-BoxA_Time
        span.eventm-BoxA_TimeIcon(aria-hidden="true")
        span.eventm-BoxA_TimeText October 17-19, 2018
      p.eventm-BoxA_Heading ChipEXPO 2018
      p.eventm-BoxA_ContentText
        | Expocentre, pavilion "Forum"
        br
        | Booth # B15
        br
        a.eventm-BoxA_ContentLink(href="http://www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
    .eventm-BoxA_Image
      span.eventm-BoxA_ImageThumbnail
        img(src="/assets/img/common/img_690_296.jpg" alt="")
```
*/

.eventm-BoxA_Item {
  .eventm-BoxA_Item + &::before {
    content: "";
    display: block;
    margin-top: rem(30);
    padding-bottom: rem(30);
    border-top: 1px solid #d8d8d8;

    @include mq-up(md) {
      margin-top: rem(40);
      padding-bottom: rem(40);
    }
  }
}

.eventm-BoxA_Body {
  @include mq-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(-30);
  }
}

.eventm-BoxA_Time {
  display: flex;
  align-items: center;
  margin-bottom: rem(22);
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.375;
  color: #444;

  @include mq-up(md) {
    margin-bottom: rem(12);
    font-size: rem(15);
  }
}

.eventm-BoxA_TimeIcon {
  position: relative;
  width: rem(30);
  height: rem(19);
  font-size: rem(19);
  color: $color-gray-minus1;

  @include mq-up(md) {
    width: rem(26);
  }

  &::before {
    @include icon();

    content: "#{$icon-calender}";
    position: relative;
    top: -0.35em;
  }
}

.eventm-BoxA_TimeText {
  width: 100%;
  font-weight: 600;
}

.eventm-BoxA_Content {
  @include mq-up(md) {
    flex-basis: percentage(525 / 870);
    max-width: percentage(525 / 870);
    padding-left: rem(30);
  }
}

.eventm-BoxA_Heading {
  margin-bottom: rem(17);
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.3;
  color: $color-black;

  @include mq-up(md) {
    margin-bottom: rem(21);
    font-size: rem(24);
    line-height: 1.375;
  }
}

.eventm-BoxA_ContentText {
  font-size: rem(15);
  line-height: 1.4;
  color: #444;

  &:nth-of-type(n + 2) {
    margin-top: rem(5);
  }
}

.eventm-BoxA_ContentLink {
  text-decoration: none;
  color: $color-red;
  transition: $transition-duration;

  &:hover {
    text-decoration: underline;
  }
}

.eventm-BoxA_IconExternal {
  display: inline;
  margin-left: rem(4);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.2em;
  }
}

.eventm-BoxA_IconExternalB {
  display: inline;
  margin-left: rem(4);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.2em;
  }
}

.eventm-BoxA_Image {
  margin-top: rem(20);
  text-align: center;

  @include mq-up(md) {
    flex-basis: percentage(345 / 870);
    max-width: percentage(345 / 870);
    margin-top: 0;
    padding-left: rem(30);
  }
}
