/*
---
name: sw-Heading2A
namespace: sw-
category: Heading
id: HD-2
---

<span class="sw-Label">HD-2</span>

ベーシックなh2コンポーネントです。

```jade
h2.sw-Heading2A H2 Title name
```
*/
.sw-Heading2A {
  margin-top: rem(25);
  margin-bottom: rem(25);
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.3;
  color: $color-black;

  @include mq-up(md) {
    font-size: rem(24);
  }

  & + * {
    margin-top: 0 !important;
  }
}
