/*
---
name: sw-Sup
namespace: sw-
category: Text
id: [TXT-9, LINK-11]
---

<span class="sw-Label">TXT-9</span>

登録商標（アールマーク）や著作権（マルシーマーク）などに使う上付き文字です。

```jade
p.sw-Text Other highlights in the display segment are 3D head-up displays (HUD) in the ADAS segment and the patented Haptivity<sup class="sw-Sup">®</sup> technology integrated in a 7-inch display. Additionally.
```

<span class="sw-Label">LINK-11</span>

注釈リンクです。

```jade
p.sw-Text Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO<sup class="sw-Sup"><a class="sw-Link" href="#ref01">*1</a></sup> D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.

ul.sw-ListFootnote
  li#ref01 Japanese Automotive Standards Organization
  li#ref02 Japanese Additional fees may apply
  li#ref03 Additional fees may applyAdditional fees may applyAdditional fees may applyAdditional fees may applyAdditional fees  may applyAdditional fees may applyAdditional fees may applyAdditional fees may applyAdditional fees may apply
```
*/
.sw-Sup {
  vertical-align: super;
  position: static;
  font-size: 0.75em;
}

/* CMS対応 */
sup {
  .news-Body & {
    vertical-align: super;
    position: static;
    font-size: 0.75em;
  }

  .l-DetailTemplate & {
    vertical-align: super;
    position: static;
    font-size: 0.75em;
  }
}
