.home-Lead {
  max-width: 970px;
  margin: auto;
  text-align: center;
  font-size: rem(16);
  font-weight: 300;
  line-height: 1.6;
  color: #000;

  @include mq-up(md) {
    text-align: center;
    font-size: rem(16);
    color: #000;
  }
}
