/*
---
name: st-TitleC
namespace: sw-
category: Heading
id: [HD-1C, HD-1A, HD-1B]
---

<span class="sw-Label">HD-1C</span><span class="sw-Label">HD-1A</span><span class="sw-Label">HD-1B</span>

下層ページで使用されるタイトルです。

- `.st-TitleC_Parent`には親カテゴリが入ります（任意）※使用しなくなりました
- `.st-TitleC_Title`にはページタイトルが入ります
- `.st-TitleC_Hero`にはメインビジュアルが入ります（任意）

```jade
.st-TitleC
  p.st-TitleC_Parent LED Lighting
  h1.st-TitleC_Title General LED Lighting
  .st-TitleC_Hero
    img.st-TitleC_Image(src="/assets/img/common/img_870_280.jpg" alt)
```

```jade
.st-TitleC
  h1.st-TitleC_Title LED Lighting
```

<span class="sw-Label">HD-1A</span>

ページタイトル + 親カテゴリ

```jade
.st-TitleC
  p.st-TitleC_Parent LED Lighting
  h1.st-TitleC_Title General LED Lighting
```

<span class="sw-Label">HD-1B</span>

ページタイトル

```jade
.st-TitleC
  h1.st-TitleC_Title General LED Lighting
```
*/
.st-TitleC {
  margin-bottom: rem(20);

  @include mq-up(md) {
    margin-bottom: rem(25);
  }
}

.st-TitleC_Parent {
  text-transform: uppercase;
  font-size: rem(12);
  font-weight: 700;
  line-height: 1.4;
  color: $color-gray-minus1;

  @include mq-up(md) {
    font-size: rem(14);
    line-height: 1.2;
  }
}

.st-TitleC_Title {
  font-size: rem(30);
  font-weight: 300;
  line-height: 1.3;
  color: $color-black;

  @include mq-up(md) {
    font-size: rem(36);
    line-height: 1.2;
  }
}

.st-TitleC_Hero {
  width: 100vw;
  margin-top: rem(25);
  margin-right: calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);

  @include mq-up(md) {
    width: 100%;
    margin-top: rem(30);
    margin-right: 0;
    margin-left: 0;
  }

  // .st-TitleC + .st-TitleC_Hero でも
  // .st-TitleC > .st-TitleC_Hero でも余白が揃うようにする。
  .st-TitleC_Title + & {
    margin-top: rem(25);
    margin-bottom: 0;

    @include mq-up(md) {
      margin-top: rem(30);
      margin-bottom: 0;
    }
  }
}

.st-TitleC_Image {
  display: block;
  width: 100%;

  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: block;
    width: 100%;
  }
}

.js-st-TitleC_TitleNews-all {
  &[aria-hidden="true"] {
    display: none;
  }
}
.js-st-TitleC_TitleNews-yearmonth {
  &[aria-hidden="true"] {
    display: none;
  }
}

