/*
---
name: sw-Sub
namespace: sw-
category: Text
id: TXT-11
---

<span class="sw-Label">TXT-11</span>

化学式や変数、脚注などで使用する下付き文字です。

```jade
p.sw-Text Alumina is the most widely used material among fine ceramics, and has the same sintered crystal structure (α-Al<sub class="sw-Sub">2</sub>O<sub class="sw-Sub">3</sub>) as sapphire and ruby. Its application is diverse due to its superb properties such as high insulation, high strength, high wear resistance and chemical resistance.
```
*/
.sw-Sub {
  bottom: 0;
  font-size: 0.75em;
}

/* CMS対応 */
sub {
  .news-Body & {
    bottom: 0;
    font-size: 0.75em;
  }

  .l-DetailTemplate & {
    bottom: 0;
    font-size: 0.75em;
  }
}
