/*
---
name: st-Breadcrumb
namespace: st-
category: Navigation
id: CTL-1
---

<span class="sw-Label">CTL-1</span>

パンくずリストです。

```jade
.st-Breadcrumb
  ol.st-Breadcrumb_List
    li.st-Breadcrumb_Item
      a.st-Breadcrumb_Link(href="#") home
     li.st-Breadcrumb_Item
      a.st-Breadcrumb_Link(href="#") news
    li.st-Breadcrumb_Item
      a.st-Breadcrumb_Link(href="#") 2018
    li.st-Breadcrumb_Item
      | KYOCERA introduces reliable, Waterproof Branch Connector for harsh conditions of automotive electronics conditions of automotive elect KYOCERA introduces reliable, Waterproof Branch Connector for harsh conditions of automotive electronics conditions of automotive elect
```
*/

.js-st-Breadcrumb_ItemNews-all {
  &[aria-hidden="true"] {
    display:none;
  }
}
.js-st-Breadcrumb_ItemNews-yearmonth {
  &[aria-hidden="true"] {
    display:none;
  }
}
.js-st-Breadcrumb_ItemNews-year {
  &[aria-hidden="true"] {
    display:none;
  }
}
.js-st-Breadcrumb_ItemNews-month {
  &[aria-hidden="true"] {
    display:none;
  }
}

.js-st-Breadcrumb_ItemNews-month1,
.js-st-Breadcrumb_ItemNews-month2,
.js-st-Breadcrumb_ItemNews-month3,
.js-st-Breadcrumb_ItemNews-month4,
.js-st-Breadcrumb_ItemNews-month5,
.js-st-Breadcrumb_ItemNews-month6,
.js-st-Breadcrumb_ItemNews-month7,
.js-st-Breadcrumb_ItemNews-month8,
.js-st-Breadcrumb_ItemNews-month9,
.js-st-Breadcrumb_ItemNews-month10,
.js-st-Breadcrumb_ItemNews-month11,
.js-st-Breadcrumb_ItemNews-month12 {
  &[aria-hidden="true"] {
    display:none;
  }
}
