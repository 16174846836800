// カテゴリ出力
.ss-group-field {
  margin: 0;
  padding: 0;
  border: none;
}

.results-Search_Category input {
  @include sw-FormRadio;
}

.results-Search_Category label {
  margin-right: rem(18);
}

// 該当検索数
.results-Result_Hits {
  & strong {
    font-size: rem(16);
    line-height: 1.3125;
  }
}

// 検索結果数
.results-Result_PerPage {
  .SS_hitCount {
    display: inline;
  }

  input {
    @include sr-only;
  }

  label {
    position: relative;
    text-decoration: none;
    color: $color-red;

    &:not(:last-child) {
      padding-right: 1em;
    }

    &:not(:last-child)::after {
      position: absolute;
      right: 0.25em;
      content: "|";
      display: inline;
      color: $color-black;
    }
  }

  & input:checked + label {
    color: #999;
  }

  & input:not(:checked) + label {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  & input.focus-ring + label {
    box-shadow: $form-box-shadow-focus;
  }
}

// 関連順と新着順
.results-Result_Sort {
  & :not(.selected) {
    text-decoration: none;
    color: $color-red;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  & #ss-sort-match,
  & #ss-sort-date {
    display: inline !important;
  }
}

[id="ss-result"] {
  padding-right: rem(15);
  padding-bottom: rem(40);
  padding-left: rem(15);

  @include mq-up(md) {
    padding-top: rem(15);
    padding-bottom: rem(60);
  }
}

.result-Contents_Title .ss-highlight,
.result-Contents_Link .ss-highlight,
.result-Contents_Summary .ss-highlight {
  font-weight: 700;
}

.ss-pagination {
  display: flex;
  justify-content: center;
  padding-top: rem(24);
  padding-left: 0;
  text-align: center;
  list-style-type: none;

  @include mq-up(md) {
    padding-top: rem(40);
  }

  & .ss-page {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: rem(48);
    height: rem(45);
    font-weight: 600;
    box-shadow: 0 -1px 0 0 $color-gray-plus3 inset;
    cursor: pointer;
    transition-duration: $transition-duration;

    @include mq-up(md) {
      &:hover {
        color: $color-red;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid transparent;
      pointer-events: none;
    }

    &.ss-select-page {
      pointer-events: none;

      @include mq-up(md) {
        &:hover {
          color: $color-red;
        }
      }

      &::before {
        border-bottom-color: $color-red;
      }
    }
  }

  .ss-page-prev,
  .ss-page-next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: rem(48);
    height: rem(45);
    font-weight: 600;
    color: transparent;
    cursor: pointer;
    transition-duration: $transition-duration;

    @include mq-up(md) {
      &:hover::before {
        color: $color-red;
      }
    }

    &::before {
      @include icon;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: rem(22);
      margin: auto;
      font-size: rem(22);
      color: #999;
    }
  }

  .ss-page-prev {
    &::before {
      content: "#{$icon-slide_arrow_left}";
    }
  }

  .ss-page-next {
    &::before {
      content: "#{$icon-slide_arrow_right}";
    }
  }
}
