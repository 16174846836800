/*
---
name: cms-Component
namespace: cms-
category: MovableType
---

MovableTypeのRichEditorをラップするクラスです。
多数あるため、cms-Component内における各コンポーネントのCSSは、各CSSで定義します。

<span class="sw-Label" id="M_TOP-PRODUCT">M_TOP-PRODUCT</span>

トップページの製品一覧出力エリアを示します。

```jade
.cms-Component_Editor
  div(data-cms-component-top-product data-cms-component-area)
    | Product
```

<span class="sw-Label" id="M_TOP-NEWS">M_TOP-NEWS</span>

トップページのニュース出力エリアを示します。

```jade
.cms-Component_Editor
  div(data-cms-component-top-news data-cms-component-area)
    | News
```

<span class="sw-Label" id="M_TOP-NEWS">M_TOP-EVENT</span>

トップページのイベント出力エリアを示します。

```jade
.cms-Component_Editor
  div(data-cms-component-top-event data-cms-component-area)
    | Event
```

<span class="sw-Label" id="M_CAREER-PAGE">M_PRODUCT-NEWS</span>

製品ページのニュース出力エリアを示します。

```jade
.cms-Component_Editor
  div(data-cms-component-product-news data-cms-component-area)
    | News
```

<span class="sw-Label" id="M_PRODUCT-SALESOFFICE">M_PRODUCT-SALESOFFICE</span>

製品ページのセールスオフィス出力エリアを示します。

```jade
.cms-Component_Editor
  div(data-cms-component-product-salesoffice data-cms-component-area)
    | SalesOffice
```

<span class="sw-Label" id="M_PRODUCT-VIDEO">M_PRODUCT-VIDEO</span>

製品ページのビデオ出力エリアを示します。

```jade
.cms-Component_Editor
  div(data-cms-component-product-video data-cms-component-area)
    | Video
```

<span class="sw-Label" id="M_CAREER_HERO-VIDEO">M_CAREER-HERO</span>

CarrersページのHero画像出力エリアを示します。

```jade
.cms-Component_Editor
  div(data-cms-component-career-hero data-cms-component-area)
    | Hero
```
*/
.cms-Component_Editor {
  padding: rem(10);

  &-en {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
  }
}

.cms-Component_Block {
  margin: 0;
  padding: 0;
  border: none;

  .cms-Component_Editor & {
    display: block;
    margin: rem(10);
    padding: rem(10);
    border: dashed rem(2) $color-gray;
  }

  &-inline {
    .cms-Component_Editor & {
      display: inline-block;
    }
  }

  &-inlinefull {
    max-width: 100%;

    .cms-Component_Editor & {
      display: inline-block;
      width: 100%;
    }
  }
}

/**
 * CMSの都合上、属性セレクタを利用
 *
 * CMS都合: HTML出力時、クラス名を含むと正常に出力できない可能性がある
 */
[data-cms-component-top-product],
[data-cms-component-top-news],
[data-cms-component-top-event],
[data-cms-component-product-salesoffice],
[data-cms-component-product-news],
[data-cms-component-product-video],
[data-cms-component-career-hero],
[data-cms-component-career-page] {
  display: none;
  .cms-Component_Editor & {
    display: block;
    width: 100%;
    height: 100px;
    margin: 20px 0;
    line-height: 100px;
    background: #ccc;
    text-align: center;
    font-size: rem(24);
  }
}
