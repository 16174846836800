// default breakpoint
$default-breakpoint: "lg" !default;

// default wrapper width
$max-width: 1170px !default;

$font-family-open-sans-condensed: "Open Sans Condensed", sans-serif !default;
$font-family-pt-sans: "PT sans", sans-serif !default;

$line-height: 1.6 !default;

$transition-duration: 0.3s !default;

$font-size-secondary: 0.85em;

$opacity: 0.7 !default;

$margin-top-base: rem(30) !default;
$margin-bottom-base: rem(30) !default;

$form-border: 1px solid #767676 !default;
$form-boder-color: #767676 !default;
$form-border-radius: rem(3) !default;
$form-transition-duration: 0.3s !default;
$form-transition-property: border-color, box-shadow, background-color !default;
$form-border-color-focus: #1589ee !default;
$form-box-shadow-focus: 0 0 6px 3px #1589ee !default;
$form-placeholder-color: #999 !default;
$form-cursor-disabled: not-allowed !default;
$form-opacity-disabled: 0.5 !default;
$form-background-color-disabled: #ddd !default;
