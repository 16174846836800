.home-Products {
}

.home-Products_Wrapper {
  max-width: 1170px;
  margin: rem(40) auto 0;
  padding: 0 rem(15);

  @include mq-up(md) {
    margin-top: rem(60);
    padding: 0 rem(30);
  }

  @media print, screen and (min-width: 1230px) {
    padding: 0;
  }
}

.home-Products_Section2 {
  &:nth-of-type(n + 2) {
    margin-top: rem(72);
  }
}

.home-Products_Grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: rem(38);

  @include mq-up(md) {
    margin-top: rem(30);
  }

  @include mq-up(lg) {
    margin-top: rem(50);
  }
}

.home-Products_GridItem {
  flex-basis: 100%;
  max-width: 100%;

  @include mq-up(lg) {
    &:nth-of-type(1) {
      flex-basis: percentage(278 / 1170);
      max-width: percentage(278 / 1170);
    }

    &:nth-of-type(2) {
      flex-basis: percentage(847 / 1170);
      max-width: percentage(847 / 1170);
    }
  }
}

.home-Products_Section3 {
  .home-Products_GridItem:nth-of-type(2) & {
    margin-top: rem(30);

    @include mq-up(md) {
      margin-top: rem(20);
    }

    @include mq-up(lg) {
      margin-top: 0;
    }
  }
}

.home-Products_Heading3 {
  position: relative;
  margin-bottom: rem(30);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: em(2, 15);
  font-size: rem(15);
  font-weight: bold;
  color: #df0523;

  @include mq-up(md) {
    letter-spacing: em(1, 15);
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    margin: auto;
    border-top: 1px dotted currentColor;
  }

  & > span {
    display: inline-block;
    position: relative;
    width: auto;
    padding: 0 rem(8);
    background-color: #fff;

    @include mq-up(md) {
      padding: 0 rem(10);
    }
  }
}

.home-Products_List {
  position: relative;
  width: 100vw;
  margin-right: calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);

  @include mq-up(md) {
    width: 100%;
    margin: 0;
  }

  @include mq-up(lg) {
    .home-Products_GridItem:nth-of-type(2) &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: percentage(-23 / 841);
      width: 1px;
      height: 100%;
      border-left: 1px solid #e6e6e6;
    }
  }
}
