/*
---
name: st-SideNav
namespace: st-
category: Navigation
---

下層ページのサイドバーにあるナビゲーションです。

- Productsはドロップダウンで、Products内では最初から開きます
- スマホでは非表示になり、`.st-CategoryNav`が代わりに表示されます
- 3つの階層でカレント表示されます

```jade
main
  .l-DetailTemplate
    .l-DetailTemplate_Wrapper
      .l-DetailTemplate_Main
      .l-DetailTemplate_Sub
        nav.l-DetailTemplate_SubNav
          ul.st-SideNav
            li.st-SideNav_Item
              a.st-SideNav_Link(href="/")
                | Top
                span.st-SideNav_Icon(aria-hidden="true")
            li.st-SideNav_Item
              button.st-SideNav_Toggle#st-SideNav_Toggle(type="button" aria-expanded="false" aria-controls="st-SideNav_Parent")
                | Products
                span.st-SideNav_ToggleIcon(aria-hidden="true")
              ul.st-SideNav_Parent#st-SideNav_Parent(aria-hidden="true")
                li.st-SideNav_ParentItem
                  a.st-SideNav_ParentLink(href="#") Automotive
                li.st-SideNav_ParentItem
                  a.st-SideNav_ParentLink(href="/products/semiconductor_components/led_lighting/") LED Lighting
                  ul.st-SideNav_Child
                    li.st-SideNav_ChildItem
                      a.st-SideNav_ChildLink(href="/products/semiconductor_components/led_lighting/general.html")
                        span.st-SideNav_ChildIcon(aria-hidden="true")
                        span General LED Lighting
                    li.st-SideNav_ChildItem
                      a.st-SideNav_ChildLink(href="/products/semiconductor_components/led_lighting/uv_led.html")
                        span.st-SideNav_ChildIcon(aria-hidden="true")
                        span UV LED Lighting
                    li.st-SideNav_ChildItem
                      a.st-SideNav_ChildLink(href="/products/semiconductor_components/led_lighting/automotive_led.html")
                        span.st-SideNav_ChildIcon(aria-hidden="true")
                        span Automotive LED Lighting
                li.st-SideNav_ParentItem
                  a.st-SideNav_ParentLink(href="#") Camera Image Sensors
                li.st-SideNav_ParentItem
                  a.st-SideNav_ParentLink(href="#") Power - AMB Substrates
                li.st-SideNav_ParentItem
                  a.st-SideNav_ParentLink(href="#") MEMS Sensors Packaging
                li.st-SideNav_ParentItem
                  a.st-SideNav_ParentLink(href="#") Network / Infrastructure
                    span.st-SideNav_ParentExternalA(aria-hidden="true")
                li.st-SideNav_ParentItem
                  a.st-SideNav_ParentLink(href="#") Fiber Optics
                    span.st-SideNav_ParentExternalB(aria-hidden="true")
            li.st-SideNav_Item
              a.st-SideNav_Link(href="/news/")
                | News
                span.st-SideNav_Icon(aria-hidden="true")
            li.st-SideNav_Item
              a.st-SideNav_Link(href="#")
                | Sales Office
                span.st-SideNav_IconExternalA(aria-hidden="true")
            li.st-SideNav_Item
              a.st-SideNav_Link(href="#")
                | Events
                span.st-SideNav_IconExternalB(aria-hidden="true")
```

各階層はドロップダウンで、もしくはページの階層に応じて開閉されます。
以下はHTML側で最下層まで開いた状態にしたものです（USのCarrerを流用）。

```jade
main
  .l-DetailTemplate
    .l-DetailTemplate_Wrapper
      .l-DetailTemplate_Main
      .l-DetailTemplate_Sub
        nav.l-DetailTemplate_SubNav
          ul.st-SideNav
            li.st-SideNav_Item
              a.st-SideNav_Link(href="/careers/")
                | Careers
                span.st-SideNav_Icon(aria-hidden="true")
            li.st-SideNav_Item
              a.st-SideNav_Link(href="https://usa.kyoceradocumentsolutions.com/en/about-us/careers.html" target="_blank") KYOCERA Document Solutions America, Inc.
                span.st-SideNav_IconExternalA(aria-hidden="true")
            li.st-SideNav_Item
              a.st-SideNav_Link(href="/careers/kii/")
                | KYOCERA International, Inc.
                span.st-SideNav_Icon(aria-hidden="true")
              ul.st-SideNav_Parent#st-SideNav_SubCarrer
                li.st-SideNav_ParentItem
                  a.st-SideNav_ParentLink(href="https://recruiting2.ultipro.com/KYO1000KYO/JobBoard/76b8c73c-befd-f875-1815-e0b7a1f6e65f/?q=&o=postedDateDesc" target="_blank")
                    | Kyocera International, Inc.  Career Opportunities
                    span.st-SideNav_ChildIconExternalA(aria-hidden="true")
                  a.st-SideNav_ParentLink(href="/careers/kii/why-kyocera/")
                    | Why Kyocera?
                    span.st-SideNav_Icon(aria-hidden="true")
                  ul.st-SideNav_Child
                    li.st-SideNav_ChildItem
                      a.st-SideNav_ChildLink(href="/careers/kii/why-kyocera/at-a-glance.html")
                        span.st-SideNav_ChildIcon(aria-hidden="true")
                        span At a Glance
                    li.st-SideNav_ChildItem
                      a.st-SideNav_ChildLink(href="/careers/kii/why-kyocera/csr.html")
                        span.st-SideNav_ChildIcon(aria-hidden="true")
                        span Corporate Social Responsibility
                    li.st-SideNav_ChildItem
                      a.st-SideNav_ChildLink(href="/careers/kii/why-kyocera/culture.html")
                        span.st-SideNav_ChildIcon(aria-hidden="true")
                        span Company Culture
                  a.st-SideNav_ParentLink(href="/careers/kii/benefits/")
                    | Benefits
                    span.st-SideNav_Icon(aria-hidden="true")

            li.st-SideNav_Item
              a.st-SideNav_Link(href="KYOCERA Precision Tools, Inc." target="_blank") KYOCERA Precision Tools, Inc.
                span.st-SideNav_IconExternalA(aria-hidden="true")
            li.st-SideNav_Item
              a.st-SideNav_Link(href="https://www.kyocera-sgstool.com/about/careers" target="_blank") KYOCERA SGS Precision Tools, Inc.
                span.st-SideNav_IconExternalA(aria-hidden="true")
            li.st-SideNav_Item
              a.st-SideNav_Link(href="https://www.senco.com/company/careers" target="_blank") KYOCERA SENCO Industrial Tools, Inc.
                span.st-SideNav_IconExternalA(aria-hidden="true")
```
*/
.st-SideNav {
  padding-left: 0;
  border-bottom: 1px dotted #979797;
  list-style-type: none;
}

.st-SideNav_Item {
}

.st-SideNav_Link {
  display: block;
  position: relative;
  padding: rem(15) rem(26) rem(15) rem(10);
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.3;
  border-top: 1px dotted #979797;
  color: #000;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    color: $color-red;
  }

  &[aria-current="location"],
  &[aria-current="page"],
  &[aria-current="true"] {
    background-color: #f2f4f6;
    color: $color-red;
  }
}

.st-SideNav_Icon {
  position: absolute;
  top: 0;
  right: rem(8);
  bottom: 0;
  height: rem(12);
  margin: auto;
  font-size: rem(12);
  color: $color-gray-minus1;

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.2em;
  }
}

.st-SideNav_IconExternalA {
  position: absolute;
  top: 0;
  right: rem(9);
  bottom: 0;
  height: rem(11);
  margin: auto;
  font-size: rem(11);
  color: $color-gray-minus1;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.3em;
  }
}

.st-SideNav_IconExternalB {
  position: absolute;
  top: 0;
  right: rem(9);
  bottom: 0;
  height: rem(11);
  margin: auto;
  font-size: rem(11);
  color: $color-gray-minus1;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.3em;
  }
}

// Products
.st-SideNav_Toggle {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  padding: rem(12) rem(26) rem(12) rem(10);
  text-align: left;
  text-decoration: none;
  font-family: inherit;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.6;
  border: none;
  border-top: 1px dotted #979797;
  background-color: transparent;
  color: #000;
  transition-duration: $transition-duration;
  transition-property: color;
  appearance: none;

  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    appearance: none;
  }

  &:focus {
    outline: 0;
  }

  &.focus-ring:focus {
    outline: 0;
    box-shadow: $form-box-shadow-focus;
  }

  &:hover {
    text-decoration: none;
    color: $color-red;
  }

  &[aria-expanded="false"] {
  }

  &[aria-expanded="true"] {
    border-bottom-width: 0;
  }
}

.st-SideNav_ToggleIcon {
  position: absolute;
  top: 0;
  right: rem(7);
  bottom: 0;
  height: rem(12);
  margin: auto;
  font-size: rem(12);
  color: $color-gray-minus1;

  &::before {
    @include icon;

    content: "#{$icon-chevron-down}";
    position: relative;
    top: -0.3em;

    // load時にアニメーションさせないように、完了後にJSでクラスが追加される。
    .st-SideNav_Toggle-onAnimation & {
      transition-duration: $transition-duration;
    }

    .st-SideNav_Toggle[aria-expanded="true"] & {
      transform: rotate(-180deg);
    }
  }
}

// Productsのカテゴリ
.st-SideNav_Parent {
  margin-bottom: rem(15);
  padding-left: 0;
  list-style-type: none;

  &[aria-current="location"],
  &[aria-current="page"] {
    background-color: #f5f5f5;
  }

  &[aria-hidden="true"] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
}

.st-SideNav_ParentItem {
}

.st-SideNav_ParentLink {
  display: block;
  padding: rem(9) rem(20);
  text-decoration: none;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.4;
  color: #666;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: underline;
    color: $color-red;
  }

  &[aria-current="location"] {
    background-color: #f0f3f6;
    color: #000;
  }

  &[aria-current="page"] {
    background-color: #f0f3f6;
    color: $color-red;
  }
}

.st-SideNav_ParentExternalA {
  display: inline-block;
  height: rem(11);
  margin-left: rem(5);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.3em;
  }
}

.st-SideNav_ParentExternalB {
  display: inline-block;
  height: rem(11);
  margin-left: rem(5);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.2em;
  }
}

// Productsの子カテゴリ
.st-SideNav_Child {
  display: none;
  padding: rem(12) rem(8) rem(20) rem(23);
  background-color: #f0f3f6;
  list-style-type: none;

  .st-SideNav_ParentLink[aria-current] + & {
    display: block;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.st-SideNav_ChildItem {
  margin-bottom: 0.6em;
}

.st-SideNav_ChildLink {
  display: flex;
  text-decoration: none;
  font-size: rem(14);
  line-height: 1.3;
  color: #555;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    color: $color-red;
  }

  &[aria-current] {
    color: $color-red;
  }
}

.st-SideNav_ChildIcon {
  position: relative;
  margin-right: rem(2);
  margin-left: rem(-2);
  font-size: 0.8em;
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-triangle}";
    position: relative;
    top: 0.15em;
    transform: scale(0.5);
  }
}

.st-SideNav_ChildIconExternalA {
  display: inline-block;
  height: rem(11);
  margin-left: rem(3);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.2em;
  }
}

.st-SideNav_ChildIconExternalB {
  display: inline-block;
  height: rem(11);
  margin-left: rem(3);
  font-size: rem(11);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.2em;
  }
}
