/*
---
name: sw-NavA
namespace: sw-
category: Navigation
---

Productsページで使用されるナビゲーションです。グリッドレイアウトも含んでいます。

```jade
ul.sw-NavA
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://global.kyocera.com/prdct/fc/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_industrial-ceramics.png" alt)
      .sw-NavA_Body
        | Industrial Ceramics
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="/sc/")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_semiconductor-packaging.png" alt)
      .sw-NavA_Body
        | Semiconductor Packaging
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://global.kyocera.com/prdct/electro/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_electronic-components.png" alt)
      .sw-NavA_Body
        | Electronic Components
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://global.kyocera.com/prdct/printing-devices/led-uv/index.html" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_uv-led-curingsystem.png" alt)
      .sw-NavA_Body
        | UV-LED Curing System
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://global.kyocera.com/prdct/printing-devices/thermal-printheads/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_thermal-printheads.png" alt)
      .sw-NavA_Body
        | Thermal Printheads
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://global.kyocera.com/prdct/printing-devices/inkjet-printheads/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_inkjet-printheads.png" alt)
      .sw-NavA_Body
        | Inkjet Printheads
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="http://www.kyocera-display.com/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_liquid-crystal-displays.png" alt)
      .sw-NavA_Body
        | Liquid Crystal Displays
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://global.kyocera.com/prdct/optec/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_lens-components.png" alt)
      .sw-NavA_Body
        | Lens Components
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://www.kyocera-medical.com/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_medical-products.png" alt)
      .sw-NavA_Body
        | Medical Products
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="http://www.kyoceramicrotools.com/indexable/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_cutting-tool-Indexable.png" alt)
      .sw-NavA_Body
        | Cutting Tool – Indexable
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="http://www.kyoceramicrotools.com/micro/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_cutting-tool-solid-round.png" alt)
      .sw-NavA_Body
        | Cutting Tool - Solid Round
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="http://www.kyoceramicrotools.com/pcb/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_cutting-tool-printed-circuit-board.png" alt)
      .sw-NavA_Body
        | Cutting Tool - Printed Circuit Board
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://global.kyocera.com/prdct/chem/", target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_organic-chemical-materials.png" alt)
      .sw-NavA_Body
        | Organic Chemical Materials
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://www.kyocerasolar.com/business/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_solar-solutions-for-business.png" alt)
      .sw-NavA_Body
        | Solar Solutions for Business
        span.sw-NavA_Icon(aria-hidden="true")
  li.sw-NavA_Item
    a.sw-NavA_Link(href="https://interfocus.us/" target="_blank")
      .sw-NavA_Head
        img.sw-NavA_Thumbnail(src="/assets/img/index/image_information-communication-and-security-technology.jpg" alt)
      .sw-NavA_Body
        | Endpoint Security Software
        span.sw-NavA_IconExternalA(aria-hidden="true")
```
*/
.sw-NavA {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-5);
  padding-left: 0;
  list-style-type: none;
}

.sw-NavA_Item {
  flex-basis: 50%;
  max-width: 50%;
  padding-left: rem(5);

  @include mq-up(md) {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);
  }

  @include mq-up(lg) {
    flex-basis: percentage(1 / 4);
    max-width: percentage(1 / 4);
  }

  &:nth-of-type(n + 3) {
    margin-top: rem(5);

    @include mq-up(md) {
      margin-top: 0;
    }
  }

  &:nth-of-type(n + 4) {
    @include mq-up(md) {
      margin-top: rem(5);
    }

    @include mq-up(lg) {
      margin-top: 0;
    }
  }

  &:nth-of-type(n + 5) {
    @include mq-up(lg) {
      margin-top: rem(12);
    }
  }
}

.sw-NavA_Link {
  display: block;
  height: 100%;
  text-align: center;
  text-decoration: none;
  background-image: linear-gradient(-180deg, #f1f4f6 0%, #dde1e5 100%);
  transition-duration: $transition-duration;

  @include mq-up(md) {
    background: #f2f4f6;
  }

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.sw-NavA_Head {
  @include mq-up(md) {
    background-image: linear-gradient(-180deg, #f1f4f6 0%, #dde1e5 100%);
  }
}

.sw-NavA_Thumbnail {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
  }
}

.sw-NavA_Body {
  padding: rem(14) rem(10);
  text-align: left;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.28;
  color: #000;

  @include mq-up(md) {
    padding: rem(20) rem(15);
    font-size: rem(15);
    line-height: 1.38;
    background: #f2f4f6;
  }

  @include mq-up(lg) {
    padding: rem(20) rem(10);
  }
}

.sw-NavA_Icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: rem(-1);
  height: rem(16);
  font-size: rem(16);

  @include mq-up(md) {
    height: rem(10);
    font-size: rem(10);
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.2em;
    left: rem(4);
    color: #df0523;
    transform: scale(0.5);
    transform-origin: center left;

    @include mq-up(md) {
      transform: scale(1);
      transform-origin: inherit;
    }
  }
}

.sw-NavA_IconExternalA {
  position: relative;
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.2em;
    left: 0.5em;
    color: $color-red;
  }
}

.sw-NavA_IconExternalB {
  position: relative;
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.2em;
    left: 0.5em;
    color: $color-red;
  }
}
