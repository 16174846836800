$home-Carousel-max-height: 1170px !default;
$home-Carousel-height: 320px !default;
$home-Carousel-height-sm: 152px !default;
$home-Carousel-height-md: 320px !default;
$home-Carousel-height-lg: 500px !default;

.home-HeroA {
  @include mq-up(md) {
    height: $home-Carousel-height-md;
  }

  @include mq-up(lg) {
    height: $home-Carousel-height-lg;
  }
}

.home-HeroA_Image {
}

.home-HeroA_Image img {
  display: block;
  width: 100%;

  @include mq-up(md) {
    position: relative;
    left: 50%;
    min-height: 320px;
    // タブレット時はサイズをやや縮小する（テキストが隠れないようにするため）
    // アスペクト比が崩れないように64%（320/500）を基準に横幅を指定する
    min-width: (1920 * 0.64) * 1px;
    margin-left: ((1920 * 0.64) / 2) * -1px;
  }

  @include mq-up(lg) {
    min-height: 500px;
    min-width: 1920px;
    margin-left: -960px;
  }

  // 画像を画面幅に合わせて拡大する
  @media print, screen and (min-width: 1920px) {
    left: 50vw;
    min-width: 100vw;
    margin-left: -50vw;
  }
}

.home-HeroA_Bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  margin: auto;
  background-image: url("../img/index/bg_hero.svg");
  background-position: left -210px bottom;
  background-size: 1919px auto;
  background-size: cover;
  background-repeat: no-repeat;

  @include mq-up(sm) {
    background-position-x: -200px;
  }

  @include mq-up(lg) {
    height: $home-Carousel-height-lg;
    background-position-x: -360px;
  }

  @include mq-up(xl) {
    background-position-x: 53%;
  }
}

.home-HeroA_Body {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 117px;
  left: 0;
  z-index: 50;
  width: calc(100vw - 30px);
  max-width: $home-Carousel-max-height;
  margin: auto;

  @include mq-up(sm) {
    width: calc(100vw - 60px);
  }

  @include mq-up(md) {
    bottom: 120px;
    height: auto;
  }

  @include mq-up(lg) {
    bottom: 172px;
  }

  & .bx-viewport {
    height: 130px !important;

    @include mq-up(sm) {
      height: $home-Carousel-height-sm !important;
    }

    @include mq-up(md) {
      height: $home-Carousel-height-md !important;
    }

    @include mq-up(lg) {
      height: $home-Carousel-height-lg !important;
    }
  }
}

.home-HeroA_Inner {
}

.home-HeroA_Title {
  margin-bottom: rem(14);
  text-transform: uppercase;
  letter-spacing: em(2.5, 29);
  font-family: $font-family-pt-sans;
  font-size: rem(29);
  line-height: 1;
  color: #fff;

  @include mq-up(md) {
    letter-spacing: em(2, 40);
    font-size: rem(40);
  }

  @include mq-up(lg) {
    letter-spacing: em(4, 57);
    font-size: rem(57);
  }

  & > span {
    opacity: 0.7;
  }
}

.home-HeroA_Description {
  margin-top: 6px;
  letter-spacing: em(1, 13);
  font-family: $font-family-open-sans-condensed;
  font-size: rem(13);
  font-weight: 600;
  line-height: 1.2;
  color: #fff;

  @include mq-up(md) {
    margin-top: rem(8);
    font-size: rem(13);
  }

  @include mq-up(lg) {
    margin-top: rem(23);
    letter-spacing: em(1.82, 20);
    font-size: rem(20);
  }
}
