/*
---
name: sw-ListFootnote
namespace: sw-
category: List
id: LIST-3
---

<span class="sw-Label">LIST-3</span>

注釈用のリストです。米印やアスタリスクが表示されます。

```jade
ul.sw-ListFootnote
  li Japanese Automotive Standards Organization
  li Japanese Additional fees may apply
```

リストを2から始める場合はstart属性を`start="2"`のように指定します。CSSで表示する順序も属性値によって変更されます。

```jade
ul.sw-ListFootnote(start="2")
  li Japanese Automotive Standards Organization
  li Japanese Additional fees may apply
```
*/
.sw-ListFootnote {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  margin-left: rem(20);
  padding-left: 0;
  font-size: rem(14);
  line-height: 1.3;
  color: $color-gray-minus2;
  list-style-type: none;
  counter-reset: footnote 0;

  & > li {
    position: relative;
    margin-bottom: rem(10);
    font-style: italic;
    counter-increment: footnote;
  }

  & > li:target {
    background-color: lightgray;
  }

  & > li::before {
    content: "*" counter(footnote);
    position: absolute;
    top: 0;
    left: rem(-19);
  }

  // start属性値に応じてCSSのカウンターも変更します。
  // デフォルトは`through 9`で`start="10"`まで対応しています。

  @for $i from 1 through 9 {
    &[start="#{$i + 1}"] {
      counter-reset: footnote $i;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
