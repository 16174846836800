.st-GlobalRegionSp {
  display: block;
  padding: rem(20) rem(15) 0;
  background-color: #4f4f4f;

  @include mq-up(lg) {
    display: none;
  }

  .st-GlobalNav-divition & {
    background-color: transparent;
  }
}

.st-GlobalRegionSp_Toggle {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: rem(10) 0;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  font-family: inherit;
  font-size: rem(16);
  border: none;
  background-color: transparent;
  color: #bcbcbc;

  .st-GlobalNav-divition & {
    color: #8d8d8d;
  }

  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    appearance: none;
  }

  &:focus {
    outline: 0;
  }
}

.st-GlobalRegionSp_Region {
  width: rem(24);
  height: rem(24);
  margin-right: rem(8);
}

.GloablRegion_Icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -0.2em;
  margin-left: rem(10);
  font-size: rem(8);
  color: $color-gray-plus1;

  .st-GlobalNav-divition & {
    color: $color-gray-minus1;
  }

  &::after {
    @include icon;

    content: "#{$icon-arrow_drop_down}";

    .st-GlobalRegionSp_Toggle[aria-expanded="true"] & {
      transform: rotate(-180deg);
    }
  }
}

.st-GlobalRegionSp_SubArea {
  padding: rem(23) rem(20) 0;

  &[aria-hidden="true"] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
  }
}

.st-GlobalRegionSp_Heading {
  display: block;
  margin-bottom: rem(30);
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.375;
  color: #fff;

  .st-GlobalNav-divition & {
    color: $color-gray-minus2;
  }

  &:hover {
    text-decoration: underline;
  }
}

.st-GlobalRegionSp_HeadingIcon {
  position: relative;
  top: -0.2em;
  margin-left: 0.25rem;
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
  }
}

.st-GlobalRegionSp_RegionList {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(35);
  margin-left: rem(-15);
  padding-left: 0;
  list-style-type: none;
}

.st-GlobalRegionSp_RegionItem {
  flex-basis: percentage(1 / 2);
  max-width: percentage(1 / 2);
  padding-left: rem(15);

  &:nth-of-type(n + 3) {
    margin-top: rem(30);
  }
}

.st-GlobalRegionSp_RegionLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: rem(15);
  line-height: 1.3;
  color: #ebebeb;

  .st-GlobalNav-divition & {
    color: $color-gray-minus2;
  }

  &:hover {
    text-decoration: underline;
  }
}

.st-GlobalRegionSp_RegionText {
}

.st-GlobalRegionSp_RegionImage {
  width: rem(24);
  height: rem(24);
  margin-right: rem(8);
}
