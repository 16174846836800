/*
---
name: swm-ListDownload
namespace: swm-
category: List
id: [M_LINK-10]
---

ダウンロードリスト

<span class="sw-Label">M_LINK-10</span>

```jade
.swm-ListDownload_Item
  a.swm-ListDownload_Link(href="#")
    span.swm-ListDownload_DownloadIcon(aria-hidden="true")
    span.swm-ListDownload_Text Multi Layer - Open tooled package listMulti Layer - Open tooled package list
      span.swm-ListDownload_Size  (50.30 KB)
```

並べた例

```jade
.swm-ListDownload_Item
  a.swm-ListDownload_Link(href="#")
    span.swm-ListDownload_DownloadIcon(aria-hidden="true")
    span.swm-ListDownload_Text Multi Layer - Open tooled package listMulti Layer - Open tooled package list
      span.swm-ListDownload_Size  (50.30 KB)
.swm-ListDownload_Item
  a.swm-ListDownload_Link(href="#")
    span.swm-ListDownload_DownloadIcon(aria-hidden="true")
    span.swm-ListDownload_Text Single Layer - Open tooled package list
      span.swm-ListDownload_Size  (52.61 KB)
```

*/
.swm-ListDownload {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  font-size: rem(15);
  line-height: 1.3;
  list-style-type: none;
}

.swm-ListDownload_Item {
  border-top: 1px solid $color-gray-plus2;
  border-bottom: 1px solid $color-gray-plus2;
  margin-top: -1px;
  font-size: rem(15);
  line-height: 1.3;
}

.swm-ListDownload_Link {
  display: flex;
  padding: rem(15) 0 rem(15) rem(20);
  text-decoration: none;
  color: $color-red;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    background-color: #fbf2f1;
  }
}

.swm-ListDownload_Text {
  flex-basis: 0;
  flex-grow: 1;
}

.swm-ListDownload_DownloadIcon {
  width: rem(32);
  height: rem(22);
  font-size: rem(22);
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-cloud-download}";
    position: relative;
    top: -0.3em;
  }
}

.swm-ListDownload_Size {
  color: #999;
}
