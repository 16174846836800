/*
---
name: sw-Heading4A
namespace: sw-
category: Heading
id: HD-4
---

<span class="sw-Label">HD-4</span>

ベーシックなh4コンポーネントです。

```jade
h3.sw-Heading4A H4 Title name
```
*/
.sw-Heading4A {
  margin-top: rem(15);
  margin-bottom: rem(15);
  font-size: rem(16);
  font-weight: 700;
  line-height: 1.375;
  color: $color-gray-minus2;

  & + * {
    margin-top: 0 !important;
  }
}
