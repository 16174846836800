/*
---
name: stAllowCookie
namespace: st-
category: Global
---

Cookie取得の同意エリアです。利用者が許可をするまで画面下部に固定されます。

```jade
.st-AllowCookie#st-AllowCookie(role="alert")
  .st-AllowCookie_Inner
    .st-AllowCookie_Box
      .st-AllowCookie_Description
        p.st-AllowCookie_Text
          | Please be informed that subsequent use of this website imply the acceptance with respect to the use of cookies by KYOCERA. You can find further information - also regarding the revocation - in our
          a.st-AllowCookie_Link(href="#") Data Privacy Notice
          | .
      .st-AllowCookie_Agree
        button.st-AllowCookie_Button#st-AllowCookie_Button(type="button") Agree
```
*/
.st-AllowCookie {
  position: fixed;
  right: 0;
  bottom: -100%;
  left: 0;
  z-index: 1000;
  background: #444;
  transition-delay: 1s;
  transition-duration: 1s;
  transition-property: bottom;

  &:focus {
    outline: none;
  }
}

.st-AllowCookie_Inner {
  max-width: $max-width;
  margin: auto;
  padding: rem(18) rem(15);

  @include mq-up(md) {
    padding-right: rem(30);
    padding-left: rem(30);
  }

  @include mq-up(lg) {
    padding: rem(22) rem(30);
  }

  @include mq-up(xl) {
    padding-right: 0;
    padding-left: 0;
  }
}

.st-AllowCookie_Box {
  display: flex;
  flex-wrap: wrap;

  @include mq-up(lg) {
    justify-content: space-between;
  }
}

.st-AllowCookie_Description {
  width: 100%;
  margin-bottom: rem(16);

  @include mq-up(lg) {
    flex-basis: 0;
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.st-AllowCookie_Agree {
  width: 100%;
  text-align: center;

  @include mq-up(lg) {
    width: rem(300);
    padding-left: rem(130);
  }
}

.st-AllowCookie_Text {
  font-size: rem(14);
  line-height: 1.6;
  color: #fff;
}

.st-AllowCookie_Link {
  color: #ff0021;
}

.st-AllowCookie_Button {
  @include sw-Button;

  width: 100%;
  font-size: rem(14);
  font-weight: 600;
  background: $color-red;
  color: #fff;

  @include mq-up(md) {
    width: percentage(4 / 12);
  }

  @include mq-up(lg) {
    width: 100%;
  }
}
