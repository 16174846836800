$color-white: #fff !default;
$color-black: #000 !default;
$color-red: #df0523 !default;
$color-gray-plus4: #f5f5f5 !default;
$color-gray-plus3: #e9e9e9 !default;
$color-gray-plus2: #dadada !default;
$color-gray-plus1: #bcbcbc !default;
$color-gray: #a5a5a5 !default;
$color-gray-minus1: #666 !default;
$color-gray-minus2: #444 !default;

$color-brand: $color-red !default;
$color-text: $color-black;
$color-link: $color-red !default;
$color-link-hover: $color-red !default;
