/*
---
name: l-RadioA
namespace: l-
category: Layout
id: FO-2
---

<span class="sw-Label">FO-2</span>

`.sw-FormRadio`のレイアウト1。

```jade
ul.l-RadioA
  li.l-RadioA_Item
    input.sw-FormRadio(type="radio" id="radio2-1" name="radio2" value="0" checked)
    label(for="radio2-1")
      span Radio label
  li.l-RadioA_Item
    input.sw-FormRadio(type="radio" id="radio2-2" name="radio2" value="1")
    label(for="radio2-2")
      span Radio label
```
*/
.l-RadioA {
  display: flex;
  flex-wrap: wrap;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-top: rem(-10);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.l-RadioA_Item {
  flex-basis: 100%;
  max-width: 100%;
  margin-bottom: rem(15);

  @include mq-up(md) {
    flex-basis: auto;
    max-width: none;
    margin-top: rem(10);
    margin-right: rem(15);
    margin-bottom: 0;
  }
}
