/*
---
name: swm-LinkIcon
namespace: swm-
category: Link
id: M_LINK_ICON
---

<span class="sw-Label">M_LINK_ICON</span>

swm-LinkIconA, B, Pdf, Arrowクラスをつけた要素の中の末尾にアイコンがつきます。  
html要素はjsで追加されます。

**クラス名とアイコンの意味**

クラス名 | 意味
--- | ---
swm-LinkIconA | ExternalA（グループ内サイト別窓）  
swm-LinkIconB | ExternalB（グループ外別窓）  
swm-LinkIconPdf | Pdf（PDFアイコン）  
swm-LinkIconArrow | Arrow（ > アイコン）  

以下、各ID内で使用した時の表示。

** LINK-2 **

```jade
a.sw-Link.swm-LinkIconA(href="#")
  | global.kyocera.com/inamori/management/
br
a.sw-Link.swm-LinkIconB(href="#")
  | global.kyocera.com/inamori/management/
br
a.sw-Link.swm-LinkIconPdf(href="#")
  | global.kyocera.com/inamori/management/
br
a.sw-Link.swm-LinkIconArrow(href="#")
  | global.kyocera.com/inamori/management/
br
```

** LINK-6 **

```jade
a.sw-LinkIcon.swm-LinkIconA(href="#")
  | global.kyocera.com/inamori/management/
br
a.sw-LinkIcon.swm-LinkIconB(href="#")
  | global.kyocera.com/inamori/management/
br
a.sw-LinkIcon.swm-LinkIconPdf(href="#")
  | global.kyocera.com/inamori/management/
br
a.sw-LinkIcon.swm-LinkIconArrow(href="#")
  | global.kyocera.com/inamori/management/
```

** LINK-7 **

```jade
a.sw-LinkArrow.swm-LinkIconA(href="#")
  | Contact Us
br
a.sw-LinkArrow.swm-LinkIconB(href="#")
  | Contact Us
br
a.sw-LinkArrow.swm-LinkIconPdf(href="#")
  | Contact Us
br
a.sw-LinkArrow.swm-LinkIconArrow(href="#")
  | Contact Us
```

** contact-BoxA **  
対応するIDなし

```jade
a.contact-BoxA_Link.swm-LinkIconA(href="http://www.kyoceradocumentsolutions.eu/" target="_blank")
  | Website
a.contact-BoxA_Link.swm-LinkIconB(href="http://www.kyoceradocumentsolutions.eu/" target="_blank")
  | Website
a.contact-BoxA_Link.swm-LinkIconPdf(href="http://www.kyoceradocumentsolutions.eu/" target="_blank")
  | Website
a.contact-BoxA_Link.swm-LinkIconArrow(href="http://www.kyoceradocumentsolutions.eu/" target="_blank")
  | Website
```

** BOX-4A, BOX-4B **

```jade
.dummy(style="position: relative;height: 70px;")
  a.sw-BoxA_more.swm-LinkIconA(href="/products/automotive_components/heaters/automotive_led.html")
    | SN Header
.dummy(style="position: relative;height: 70px;")
  a.sw-BoxA_more.swm-LinkIconB(href="/products/automotive_components/heaters/automotive_led.html")
    | SN Header
.dummy(style="position: relative;height: 70px;")
  a.sw-BoxA_more.swm-LinkIconPdf(href="/products/automotive_components/heaters/automotive_led.html")
    | SN Header
.dummy(style="position: relative;height: 70px;")
  a.sw-BoxA_more.swm-LinkIconArrow(href="/products/automotive_components/heaters/automotive_led.html")
    | SN Header
```

** M_BOX-4A, M_BOX-4B **

```jade
.dummy(style="position: relative;height: 70px;")
  a.swm-BoxA_more.swm-LinkIconA(href="/products/automotive_components/heaters/automotive_led.html")
    | SN Header
.dummy(style="position: relative;height: 70px;")
  a.swm-BoxA_more.swm-LinkIconB(href="/products/automotive_components/heaters/automotive_led.html")
    | SN Header
.dummy(style="position: relative;height: 70px;")
  a.swm-BoxA_more.swm-LinkIconPdf(href="/products/automotive_components/heaters/automotive_led.html")
    | SN Header
.dummy(style="position: relative;height: 70px;")
  a.swm-BoxA_more.swm-LinkIconArrow(href="/products/automotive_components/heaters/automotive_led.html")
    | SN Header
```

** BOX-5 **

```jade
a.event-BoxA_ContentLink.swm-LinkIconA(href="https://electronica.de/" target="_blank") https://electronica.de/
br
a.event-BoxA_ContentLink.swm-LinkIconB(href="https://electronica.de/" target="_blank") https://electronica.de/
br
a.event-BoxA_ContentLink.swm-LinkIconPdf(href="https://electronica.de/" target="_blank") https://electronica.de/
br
a.event-BoxA_ContentLink.swm-LinkIconArrow(href="https://electronica.de/" target="_blank") https://electronica.de/
```

** M_BOX-5A **

```jade
a.eventm-BoxA_ContentLink.swm-LinkIconA(href="http://www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
br
a.eventm-BoxA_ContentLink.swm-LinkIconB(href="http://www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
br
a.eventm-BoxA_ContentLink.swm-LinkIconPdf(href="http://www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
br
a.eventm-BoxA_ContentLink.swm-LinkIconArrow(href="http://www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
```

** M_BOX-5B **

```jade
a.eventm-BoxB_Link.swm-LinkIconA(href="https://ambiente.messefrankfurt.com/frankfurt/en.html") https://ambiente.messefrankfurt.com/frankfurt/en.html
br
a.eventm-BoxB_Link.swm-LinkIconB(href="https://ambiente.messefrankfurt.com/frankfurt/en.html") https://ambiente.messefrankfurt.com/frankfurt/en.html
br
a.eventm-BoxB_Link.swm-LinkIconPdf(href="https://ambiente.messefrankfurt.com/frankfurt/en.html") https://ambiente.messefrankfurt.com/frankfurt/en.html
br
a.eventm-BoxB_Link.swm-LinkIconArrow(href="https://ambiente.messefrankfurt.com/frankfurt/en.html") https://ambiente.messefrankfurt.com/frankfurt/en.html
br
```

** BOX-6 **

```jade
a.office-BoxA_Link.swm-LinkIconA(href="#") http://www.xxxx.com
br
a.office-BoxA_Link.swm-LinkIconB(href="#") http://www.xxxx.com
br
a.office-BoxA_Link.swm-LinkIconPdf(href="#") http://www.xxxx.com
br
a.office-BoxA_Link.swm-LinkIconArrow(href="#") http://www.xxxx.com
```

** BOX-9 **

```jade
a.home-Events_EventLink.swm-LinkIconA(href="https://electronica.de/index.html" target="_blank") https://electronica.de/index.html
br
a.home-Events_EventLink.swm-LinkIconB(href="https://electronica.de/index.html" target="_blank") https://electronica.de/index.html
br
a.home-Events_EventLink.swm-LinkIconPdf(href="https://electronica.de/index.html" target="_blank") https://electronica.de/index.html
br
a.home-Events_EventLink.swm-LinkIconArrow(href="https://electronica.de/index.html" target="_blank") https://electronica.de/index.html
```

** BTN-1 **

```jade
div
  a.sw-Button.swm-LinkIconA(href="#") Primary Button
div
  a.sw-Button.swm-LinkIconB(href="#") Primary Button
div
  a.sw-Button.swm-LinkIconPdf(href="#") Primary Button
div
  a.sw-Button.swm-LinkIconArrow(href="#") Primary Button
```

** M_BTN-1 **

```jade
div.l-ButtonC
  a.swm-Button.swm-LinkIconA(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-LinkIconB(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-LinkIconPdf(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-LinkIconArrow(href="#") Button
```

** M_BTN-1-C **

```jade
div.l-ButtonC.l-ButtonC-center
  a.l-ButtonC_Item.swm-Button.swm-LinkIconA(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.l-ButtonC_Item.swm-Button.swm-LinkIconB(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.l-ButtonC_Item.swm-Button.swm-LinkIconPdf(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.l-ButtonC_Item.swm-Button.swm-LinkIconArrow(href="#") Button
```

** M_BTN-1-R **

```jade
div.l-ButtonC.l-ButtonC-right
  a.l-ButtonC_Item.swm-Button.swm-LinkIconA(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.l-ButtonC_Item.swm-Button.swm-LinkIconB(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.l-ButtonC_Item.swm-Button.swm-LinkIconPdf(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.l-ButtonC_Item.swm-Button.swm-LinkIconArrow(href="#") Button
```

** BTN-2 **

```jade
a.sw-Button.sw-Button-icon.swm-LinkIconA(href="#") Primary Button
a.sw-Button.sw-Button-icon.swm-LinkIconB(href="#") Primary Button
a.sw-Button.sw-Button-icon.swm-LinkIconPdf(href="#") Primary Button
a.sw-Button.sw-Button-icon.swm-LinkIconArrow(href="#") Primary Button
```

** BTN-2A **

```jade
a.sw-Button.sw-Button-small.swm-LinkIconA(href="#") Small Primary
a.sw-Button.sw-Button-small.swm-LinkIconB(href="#") Small Primary
a.sw-Button.sw-Button-small.swm-LinkIconPdf(href="#") Small Primary
a.sw-Button.sw-Button-small.swm-LinkIconArrow(href="#") Small Primary
```

** BTN-2B **

```jade
a.sw-Button.sw-Button-smallIcon.swm-LinkIconA(href="#") Button
a.sw-Button.sw-Button-smallIcon.swm-LinkIconB(href="#") Button
a.sw-Button.sw-Button-smallIcon.swm-LinkIconPdf(href="#") Button
a.sw-Button.sw-Button-smallIcon.swm-LinkIconArrow(href="#") Button
```

** M_BTN-2A **

```jade
div.l-ButtonC
  a.swm-Button.swm-Button-small.swm-LinkIconA(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-Button-small.swm-LinkIconB(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-Button-small.swm-LinkIconPdf(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-Button-small.swm-LinkIconArrow(href="#") Button
```

** M_BTN-2A-C **

```jade
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-small.swm-LinkIconA(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-small.swm-LinkIconB(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-small.swm-LinkIconPdf(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-small.swm-LinkIconArrow(href="#") Button
```

** M_BTN-2A-R **

```jade
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-small.swm-LinkIconA(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-small.swm-LinkIconB(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-small.swm-LinkIconPdf(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-small.swm-LinkIconArrow(href="#") Button
```

** BTN-4 **

```jade
a.sw-Button.sw-Button-full.swm-LinkIconA(href="#") Primary Button
a.sw-Button.sw-Button-full.swm-LinkIconB(href="#") Primary Button
a.sw-Button.sw-Button-full.swm-LinkIconPdf(href="#") Primary Button
a.sw-Button.sw-Button-full.swm-LinkIconArrow(href="#") Primary Button
div(style="height: 40px;")
a.sw-Button.sw-Button-small.sw-Button-full.swm-LinkIconA(href="#") Small Primary
a.sw-Button.sw-Button-small.sw-Button-full.swm-LinkIconB(href="#") Small Primary
a.sw-Button.sw-Button-small.sw-Button-full.swm-LinkIconPdf(href="#") Small Primary
a.sw-Button.sw-Button-small.sw-Button-full.swm-LinkIconArrow(href="#") Small Primary
div(style="height: 40px;")
a.sw-Button.sw-Button-danger.sw-Button-full.swm-LinkIconA(href="#") Primary Button
a.sw-Button.sw-Button-danger.sw-Button-full.swm-LinkIconB(href="#") Primary Button
a.sw-Button.sw-Button-danger.sw-Button-full.swm-LinkIconPdf(href="#") Primary Button
a.sw-Button.sw-Button-danger.sw-Button-full.swm-LinkIconArrow(href="#") Primary Button
```

** M_BTN-4 **

```jade
div.l-ButtonC
  a.swm-Button.swm-Button-danger.swm-LinkIconA(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-Button-danger.swm-LinkIconB(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-Button-danger.swm-LinkIconPdf(href="#") Button
div.l-ButtonC
  a.swm-Button.swm-Button-danger.swm-LinkIconArrow(href="#") Button
```

** M_BTN-4-C **

```jade
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-danger.swm-LinkIconA(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-danger.swm-LinkIconB(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-danger.swm-LinkIconPdf(href="#") Button
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-danger.swm-LinkIconArrow(href="#") Button
```

** M_BTN-4-R **

```jade
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-danger.swm-LinkIconA(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-danger.swm-LinkIconB(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-danger.swm-LinkIconPdf(href="#") Button
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-danger.swm-LinkIconArrow(href="#") Button
```

** LINK-8 **

```jade
a.sw-ListArrow_Link.swm-LinkIconA(href="#") Slavery ActModern Slavery Act
br
a.sw-ListArrow_Link.swm-LinkIconB(href="#") Slavery ActModern Slavery Act
br
a.sw-ListArrow_Link.swm-LinkIconPdf(href="#") Slavery ActModern Slavery Act
br
a.sw-ListArrow_Link.swm-LinkIconArrow(href="#") Slavery ActModern Slavery Act
```

** CARD-1A, CARD-1B **

```jade
.sw-NavA_Body.swm-LinkIconA
  | Fine Ceramic Components
br
.sw-NavA_Body.swm-LinkIconB
  | Fine Ceramic Components
br
.sw-NavA_Body.swm-LinkIconPdf
  | Fine Ceramic Components
br
.sw-NavA_Body.swm-LinkIconArrow
  | Fine Ceramic Components
```

** M_CARD-1A, M_CARD-1B **

```jade
.swm-NavA_Body.swm-LinkIconA
  | Fine Ceramic Components
br
.swm-NavA_Body.swm-LinkIconB
  | Fine Ceramic Components
br
.swm-NavA_Body.swm-LinkIconPdf
  | Fine Ceramic Components
br
.swm-NavA_Body.swm-LinkIconArrow
  | Fine Ceramic Components
```

** CARD-2A, CARD-2B **

```jade
.sw-NavB_Body.swm-LinkIconA
  | Camera Image Sensors
br
.sw-NavB_Body.swm-LinkIconB
  | Camera Image Sensors
br
.sw-NavB_Body.swm-LinkIconPdf
  | Camera Image Sensors
br
.sw-NavB_Body.swm-LinkIconArrow
  | Camera Image Sensors
```

** M_CARD-2A, M_CARD-2B **

```jade
.swm-NavB_Body.swm-LinkIconA
  | Camera Image Sensors
br
.swm-NavB_Body.swm-LinkIconB
  | Camera Image Sensors
br
.swm-NavB_Body.swm-LinkIconPdf
  | Camera Image Sensors
br
.swm-NavB_Body.swm-LinkIconArrow
  | Camera Image Sensors
```

** CARD-3 **

```jade
.dummy(style="background-color: #ccc; position:relative; min-height: 50px;")
  p.home-RegionNav_Title.swm-LinkIconA
    | CSR Activities
.dummy(style="background-color: #ccc; position:relative; min-height: 50px;")
  p.home-RegionNav_Title.swm-LinkIconB
    | CSR Activities
.dummy(style="background-color: #ccc; position:relative; min-height: 50px;")
  p.home-RegionNav_Title.swm-LinkIconPdf
    | CSR Activities
.dummy(style="background-color: #ccc; position:relative; min-height: 50px;")
  p.home-RegionNav_Title.swm-LinkIconArrow
    | CSR Activities
```
*/
.swm-LinkIconA_Icon,
.swm-LinkIconB_Icon,
.swm-LinkIconPdf_Icon,
.swm-LinkIconArrow_Icon
{
  // LINK-2
  .sw-Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      position: relative;
      top: -0.1em;
    }
  }
  // LINK-6
  .sw-LinkIcon & {
    display: inline-block;
    vertical-align: baseline;
  
    &::before {
      @include icon;
  
      position: relative;
      top: -0.2em;
    }
  }
  // LINK-7
  .sw-LinkArrow & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(12);
    margin-left: rem(6);
    font-size: rem(12);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
}

.swm-LinkIconA_Icon {
  // LINK-2
  .sw-Link & {
    &::before {
      content: "#{$icon-filter_none}";
    }
  }
  // LINK-6
  .sw-LinkIcon & {
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      content: "#{$icon-filter_none}";
    }
  }
  // LINK-7
  .sw-LinkArrow & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
  // LINK-8
  .sw-ListArrow_Link & {
    margin-left: rem(4);
    font-size: rem(11);
    color: $color-gray;
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.1em;
    }
  }
  // contact-BoxA
  .contact-BoxA_Link & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
  // BOX-4A, 4B
  // M_BOX-4A, 4B
  .sw-BoxA_more &,
  .swm-BoxA_more & {
    position: relative;
    margin-left: rem(5);
    font-size: 0.8em;
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
    }
  }
  // BOX-5
  // M_BOX-5A
  // M_BOX-5B
  .event-BoxA_ContentLink &,
  .eventm-BoxA_ContentLink &,
  .eventm-BoxB_Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.2em;
    }
  }
  // BOX-6
  .office-BoxA_Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.2em;
    }
  }
  // BOX-9
  .home-Events_EventLink & {
    height: rem(14);
    margin-left: rem(4);
    font-size: rem(14);

    &::before {
      @include icon;

      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.1em;
    }
  }
  // BTN-1...
  .sw-Button &,
  .swm-Button & {
    display: inline-block;
    position: relative;
    height: rem(11);
    margin-left: rem(5);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.25em;
    }
  }
  // CARD-1A, 1B
  // M_CARD-1A, 1B
  .sw-NavA_Body,
  .swm-NavA_Body & {
    position: relative;
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.2em;
      left: 0.5em;
      color: $color-red;
    }
  }
  // CARD-2A, 2B
  // M_CARD-2A, 2B
  .sw-NavB_Body &,
  .swm-NavB_Body & {
    position: relative;
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.1em;
      left: 0.5em;
      color: $color-red;
    }
  }
  // CARD-3
  .home-RegionNav_Title & {
    position: relative;
    margin-left: rem(4);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-filter_none}";
      position: relative;
      top: -0.2em;
    }
  }
}

.swm-LinkIconB_Icon {
  // LINK-2
  .sw-Link & {
    &::before {
      content: "#{$icon-external-link}";
    }
  }
  // LINK-6
  .sw-LinkIcon & {
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      content: "#{$icon-external-link}";
    }
  }
  // LINK-7
  .sw-LinkArrow & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
  // LINK-8
  .sw-ListArrow_Link & {
    margin-left: rem(4);
    font-size: rem(11);
    color: $color-gray;
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.1em;
    }
  }
  // contact-BoxA
  .contact-BoxA_Link & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
  // BOX-4A, 4B
  // M_BOX-4A, 4B
  .sw-BoxA_more &,
  .swm-BoxA_more & {
    position: relative;
    margin-left: rem(5);
    font-size: 0.8em;
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
    }
  }
  // BOX-5
  // M_BOX-5A
  // M_BOX-5B
  .event-BoxA_ContentLink &,
  .eventm-BoxA_ContentLink &,
  .eventm-BoxB_Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.2em;
    }
  }
  // BOX-6
  .office-BoxA_Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.2em;
    }
  }
  // BOX-9
  .home-Events_EventLink & {
    height: rem(14);
    margin-left: rem(4);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.1em;
    }
  }
  // BTN-1...
  .sw-Button &,
  .swm-Button & {
    display: inline-block;
    position: relative;
    height: rem(11);
    margin-left: rem(5);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.25em;
    }
  }
  // CARD-1A, 1B
  // M_CARD-1A, 1B
  .sw-NavA_Body &,
  .swm-NavA_Body & {
    position: relative;
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.2em;
      left: 0.5em;
      color: $color-red;
    }
  }
  // CARD-2A, 2B
  // M_CARD-2A, 2B
  .sw-NavB_Body &,
  .swm-NavB_Body & {
    position: relative;
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.1em;
      left: 0.5em;
      color: $color-red;
    }
  }
  // CARD-3
  .home-RegionNav_Title & {
    position: relative;
    margin-left: rem(4);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-external-link}";
      position: relative;
      top: -0.2em;
    }
  }
}

.swm-LinkIconPdf_Icon {
  // LINK-2
  .sw-Link & {
    &::before {
      content: "#{$icon-pdf}";
    }
  }
  // LINK-6
  .sw-LinkIcon & {
    height: rem(15);
    margin-left: rem(6);
    font-size: rem(15);
  
    &::before {
      content: "#{$icon-pdf}";
    }
  }
  // LINK-7
  .sw-LinkArrow & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
  // LINK-8
  .sw-ListArrow_Link & {
    margin-left: rem(4);
    font-size: rem(11);
    color: $color-gray;
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.1em;
    }
  }
  // contact-BoxA
  .contact-BoxA_Link & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
  // BOX-4A, 4B
  // M_BOX-4A, 4B
  .sw-BoxA_more &,
  .swm-BoxA_more & {
    position: relative;
    margin-left: rem(5);
    font-size: 0.8em;
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
    }
  }
  // BOX-5
  // M_BOX-5A
  // M_BOX-5B
  .event-BoxA_ContentLink &,
  .eventm-BoxA_ContentLink &,
  .eventm-BoxB_Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.2em;
    }
  }
  // BOX-6
  .office-BoxA_Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.2em;
    }
  }
  // BOX-9
  .home-Events_EventLink & {
    height: rem(14);
    margin-left: rem(4);
    font-size: rem(14);

    &::before {
      @include icon;

      content: "#{$icon-pdf}";
      position: relative;
      top: -0.1em;
    }
  }
  // BTN-1...
  .sw-Button &,
  .swm-Button & {
    display: inline-block;
    position: relative;
    height: rem(11);
    margin-left: rem(5);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.25em;
    }
  }
  // CARD-1A, 1B
  // M_CARD-1A, 1B
  .sw-NavA_Body &,
  .swm-NavA_Body & {
    position: relative;
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.2em;
      left: 0.5em;
      color: $color-red;
    }
  }
  // CARD-2A, 2B
  // M_CARD-2A, 2B
  .sw-NavB_Body &,
  .swm-NavB_Body & {
    position: relative;
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.1em;
      left: 0.5em;
      color: $color-red;
    }
  }
  // CARD-3
  .home-RegionNav_Title & {
    position: relative;
    margin-left: rem(4);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-pdf}";
      position: relative;
      top: -0.2em;
    }
  }
}

.swm-LinkIconArrow_Icon {
  // LINK-2
  .sw-Link & {
    &::before {
      content: "#{$icon-chevron-right}";
    }
  }
  // LINK-6
  .sw-LinkIcon & {
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      content: "#{$icon-chevron-right}";
    }
  }
  // LINK-7
  .sw-LinkArrow & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(12);
    margin-left: rem(6);
    font-size: rem(12);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
  // LINK-8
  .sw-ListArrow_Link & {
    margin-left: rem(4);
    font-size: rem(11);
    color: $color-gray;
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.1em;
    }
  }
  // contact-BoxA
  .contact-BoxA_Link & {
    display: inline-block;
    vertical-align: baseline;
    height: rem(14);
    margin-left: rem(5);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.15em;
    }
  
    .sw-LinkArrow_Link:hover & {
      color: $color-red;
    }
  }
  // BOX-4A, 4B
  // M_BOX-4A, 4B
  .sw-BoxA_more &,
  .swm-BoxA_more & {
    position: relative;
    margin-left: rem(5);
    font-size: 0.8em;
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
    }
  }
  // BOX-5
  // M_BOX-5A
  // M_BOX-5B
  .event-BoxA_ContentLink &,
  .eventm-BoxA_ContentLink &,
  .eventm-BoxB_Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.2em;
    }
  }
  // BOX-6
  .office-BoxA_Link & {
    display: inline;
    margin-left: rem(4);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.2em;
    }
  }
  // BOX-9
  .home-Events_EventLink & {
    height: rem(14);
    margin-left: rem(4);
    font-size: rem(14);

    &::before {
      @include icon;

      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.1em;
    }
  }
  // BTN-1...
  .sw-Button &,
  .swm-Button & {
    display: inline-block;
    position: relative;
    height: rem(11);
    margin-left: rem(5);
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.25em;
    }
  }
  // CARD-1A, 1B
  // M_CARD-1A, 1B
  .sw-NavA_Body &,
  .swm-NavA_Body & {
    position: relative;
    font-size: rem(11);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.2em;
      left: 0.5em;
      color: $color-red;
    }
  }
  // CARD-2A, 2B
  // M_CARD-2A, 2B
  .sw-NavB_Body &,
  .swm-NavB_Body & {
    position: relative;
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.1em;
      left: 0.5em;
      color: $color-red;
    }
  }
  // CARD-3
  .home-RegionNav_Title & {
    position: relative;
    margin-left: rem(4);
    font-size: rem(14);
  
    &::before {
      @include icon;
  
      content: "#{$icon-chevron-right}";
      position: relative;
      top: -0.2em;
    }
  }
}