/*
---
name: st-ContentsHeader
namespace: st-
category: Global
---

カテゴリトップや下層ページのコンテンツ内のヘッダーエリアです。

- パンくずリスト、ページタイトル、メインビジュアルが入ります。
- メインビジュアルがある場合は、タイトルエリアの影がなくなります

```jade
header.st-ContentsHeader
  .st-ContentsHeader_Breadcrumb
    .st-Breadcrumb
      ol.st-Breadcrumb_List
        li.st-Breadcrumb_Item
          a.st-Breadcrumb_Link(href="/") Home
        li.st-Breadcrumb_Item
          a.st-Breadcrumb_Link(href="/products/") Products
        li.st-Breadcrumb_Item
          a.st-Breadcrumb_Link(href="/products/semiconductor_components/") Semiconductor Components
        li.st-Breadcrumb_Item LED Lighting
  .st-ContentsHeader_Title
    .st-TitleB
      .st-TitleB_Inner
        p.st-TitleB_Title Semiconductor Components
        a.st-TitleB_Inquiry(href="#")
          span.st-TitleB_InquiryIcon(aria-hidden="true")
          span.st-TitleB_InquiryText Inquiry Form
```

```jade
header.st-ContentsHeader
  .st-ContentsHeader_Breadcrumb
    .st-Breadcrumb
      ol.st-Breadcrumb_List
        li.st-Breadcrumb_Item
          a.st-Breadcrumb_Link(href="/") Home
        li.st-Breadcrumb_Item
          a.st-Breadcrumb_Link(href="/products/") Products
        li.st-Breadcrumb_Item
          a.st-Breadcrumb_Link(href="/products/semiconductor_components/") Semiconductor Components
        li.st-Breadcrumb_Item LED Lighting
  .st-ContentsHeader_Title
    .st-TitleB
      .st-TitleB_Inner
        p.st-TitleB_Title Semiconductor Components
        a.st-TitleB_Inquiry(href="#")
          span.st-TitleB_InquiryIcon(aria-hidden="true")
          span.st-TitleB_InquiryText Inquiry Form
  .st-ContentsHeader_Hero
    .sw-HeroA
      picture(src="/assets/img/products/semiconductor_components/hero_sp.jpg" alt).sw-HeroA_Image.
        <!--[if IE 9]><video style="display: none;"><![endif]-->
        <source media="(min-width: 768px)" srcset="/careers/uploads/us/image02.jpg">
        <!--[if IE 9]></video><![endif]-->
        <img src="/careers/uploads/us/image01.jpg" alt="">
      .sw-HeroA_Bg
```
*/
.st-ContentsHeader {
  overflow: hidden;

  @include mq-up(xl) {
    padding: 0;
  }
}

.st-ContentsHeader_Breadcrumb {
  display: none;
  max-width: $max-width;
  margin: auto;
  padding: 0 rem(15);

  @include mq-up(md) {
    display: block;
    padding-right: rem(30);
    padding-left: rem(30);
  }

  @include mq-up(xl) {
    padding-right: 0;
    padding-left: 0;
  }
}

.st-ContentsHeader_Title {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 rem(15) rem(6);

  @include mq-up(md) {
    margin: 0 auto 0;
    padding-right: rem(30);
    padding-left: rem(30);
  }

  @include mq-up(xl) {
    padding-right: 0;
    padding-left: 0;
  }
}

.st-ContentsHeader_Hero {
  .st-ContentsHeader_Title + & {
    margin-top: rem(-6);
  }
}
