/*
---
name: sw-ListArrowB
namespace: sw-
category: List
id: LINK-13
---

<span class="sw-Label">LINK-13</span>

矢印付きのリンクリストです。

```jade
ul.sw-ListArrowB
  li.sw-ListArrowB_Item
    span.sw-ListArrowB_Icon(aria-hidden="true")
    a.sw-ListArrowB_Company(href="http://ca.kyoceramita.com/KMCAGlobalpub/jsp/Kyocera/home.jsp" target="_blank")
      b KYOCERA Document Solutions Canada, Ltd.
      span.sw-ListArrowB_ExternalIconA(aria-hidden="true")
  li.sw-ListArrowB_Item
    span.sw-ListArrowB_Icon(aria-hidden="true")
    a.sw-ListArrowB_Company(href="http://mx.kyoceramita.com/KMMXGlobalpub/jsp/Kyocera/home.jsp" target="_blank")
      b KYOCERA Document Solutions Mexico, S.A. de C.V.
      span.sw-ListArrowB_ExternalIconA(aria-hidden="true")
  li.sw-ListArrowB_Item
    span.sw-ListArrowB_Icon(aria-hidden="true")
    a.sw-ListArrowB_Company(href="http://br.kyoceramita.com/KMBRGlobalpub/jsp/Kyocera/home.jsp" target="_blank")
      b KYOCERA Document Solutions Brazil Ltda.
      span.sw-ListArrowB_ExternalIconA(aria-hidden="true")
  li.sw-ListArrowB_Item
    span.sw-ListArrowB_Icon(aria-hidden="true")
    a.sw-ListArrowB_Company(href="http://www.kdsda.com/" target="_blank")
      b KYOCERA Document Solutions Development America, Inc.
      span.sw-ListArrowB_ExternalIconA(aria-hidden="true")
    p.sw-ListArrowB_Text Concord, California Develops printing software for Kyocera Document Solutions family of digital printers and copiers.
  li.sw-ListArrowB_Item
    span.sw-ListArrowB_Icon(aria-hidden="true")
    a.sw-ListArrowB_Company(href="https://global.kyocera.com/prdct/printing-devices/index.html?_ga=2.94084189.1737095979.1551417166-969523987.1542871067" target="_blank")
      b Printing Device Sales Division
      span.sw-ListArrowB_ExternalIconA(aria-hidden="true")
    p.sw-ListArrowB_Text Vancouver, WA
      br
      a.sw-ListArrowB_Link(href="https://global.kyocera.com/prdct/printing-devices/thermal-printheads/?_ga=2.163706879.1737095979.1551417166-969523987.1542871067" target="_blank") Thermal
      | ,
      a.sw-ListArrowB_Link(href="https://global.kyocera.com/prdct/printing-devices/inkjet-printheads/index.html?_ga=2.163706879.1737095979.1551417166-969523987.1542871067" target="_blank") Inkjet
      | ,
      a.sw-ListArrowB_Link(href="https://global.kyocera.com/prdct/printing-devices/electrophotography/index.html?_ga=2.163706879.1737095979.1551417166-969523987.1542871067" target="_blank") LED printheads
      |  and
      a.sw-ListArrowB_Link(href="https://global.kyocera.com/prdct/printing-devices/led-uv/index.html?_ga=2.163706879.1737095979.1551417166-969523987.1542871067" target="_blank") UV-LED devices
      |  for digital printing and curing applications.
```
*/
.sw-ListArrowB {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  font-size: rem(16);
  line-height: 1.6;
  list-style-type: none;

  & > :last-child {
    margin-bottom: 0;
  }
}

.sw-ListArrowB_Item {
  position: relative;
  margin-bottom: rem(12);
  padding-left: rem(18);

  @include mq-up(md) {
    margin-bottom: rem(20);
  }
}

.sw-ListArrowB_Icon {
  display: block;
  position: absolute;
  top: rem(6);
  left: rem(2);
  height: rem(16);
  margin: auto;
  font-size: rem(16);
  color: $color-red;
  transform: scale(0.5);
  transform-origin: left top;

  &::before {
    @include icon;

    content: "#{$icon-triangle}";
    position: relative;
    top: -0.3em;
  }
}

.sw-ListArrowB_ExternalIconA {
  height: rem(14);
  margin-left: rem(4);
  font-size: rem(14);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.1em;
  }
}

.sw-ListArrowB_ExternalIconB {
  height: rem(14);
  margin-left: rem(4);
  font-size: rem(14);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.1em;
  }
}

.sw-ListArrowB_Company {
  color: $color-gray-minus2;
}

a.sw-ListArrowB_Company {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $color-red;
  }
}

.sw-ListArrowB_Text {
  color: $color-gray-minus2;
}

.sw-ListArrowB_Link {
  text-decoration: underline;
  color: $color-red;

  &:hover {
    text-decoration: none;
    color: $color-red;
  }
}
