/*
---
name: sw-Link
namespace: sw-
category: Link
id: LINK-2
---

<span class="sw-Label">LINK-2</span>

テキストリンクのデフォルトスタイルです。

```jade
p.sw-Text
  | Kyocera designed
  a.sw-Link(href="#")  the Sheltap branch connector series
  |  to provide a high level of waterproof functionality and reliability.
```

別窓リンク

```jade
p.sw-Text
  | Kyocera designed
  a.sw-Link(href="#")  the Sheltap branch connector series
    span.sw-Link_ExternalA(aria-hidden="true")
  |  to provide a high level of waterproof functionality and reliability.
```

外部リンク

```jade
p.sw-Text
  | Kyocera designed
  a.sw-Link(href="#")  the Sheltap branch connector series
    span.sw-Link_ExternalB(aria-hidden="true")
  |  to provide a high level of waterproof functionality and reliability.
```
*/
.sw-Link {
  text-decoration: underline;
  color: $color-red;

  &:hover {
    text-decoration: none;
  }

  /* CMS対応 */
  .news-Body & {
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }

  .l-DetailTemplate & {
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }
}

a:not(.sw-Button) {
  .sw-Text & {
    text-decoration: underline;
    color: $color-red;

    &:hover {
      text-decoration: none;
    }

    /* CMS対応 */
    .news-Body & {
      font-weight: 400;

      &:hover {
        text-decoration: none;
      }
    }

    .l-DetailTemplate & {
      font-weight: 400;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.sw-Link_ExternalA,
.sw-Link_ExternalB {
  display: inline;
  margin-left: rem(4);
  font-size: rem(14);

  &::before {
    @include icon;

    position: relative;
    top: -0.1em;
  }
}

.sw-Link_ExternalA {
  &::before {
    content: "#{$icon-filter_none}";
  }
}

.sw-Link_ExternalB {
  &::before {
    content: "#{$icon-external-link}";
  }
}
