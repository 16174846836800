/*
---
name: sw-HeroA
namespace: sw-
category: Image
id: CT-2
---

下層ページのメインビジュアルです。

- `.st-ContentsHeader`内に配置します
- メインコンテンツとサイドバーより前に記述します
- ブラウザ幅100%の要素内に配置してください（`max-width`の中は禁止）
- Pugでは`+picture()`と`+source()`を使用します

```scss
header.st-ContentsHeader
  .st-ContentsHeader_Hero
    .sw-HeroA
      +picture("/assets/img/products/semiconductor_components/hero_sp.jpg", "").sw-HeroA_Image
        +source(md, "/assets/img/products/semiconductor_components/hero_pc.jpg")
```

```jade
header.st-ContentsHeader
  .st-ContentsHeader_Hero
    .sw-HeroA
      picture.sw-HeroA_Image.
        <!--[if IE 9]><video style="display: none;"><![endif]-->
        <source media="(min-width: 768px)" srcset="/careers/uploads/us/image02.jpg">
        <!--[if IE 9]></video><![endif]-->
        <img src="/careers/uploads/us/image01.jpg" alt="">
```
*/

/*
---
name: sw-HeroA
namespace: sw-
category: Image
id: CT-3
---

`.sw-HeroA.sw-HeroA-small`とすることで、タブレット時のサイズを調整できます。`/carrer/`以下で使用しています。

```scss
header.st-ContentsHeader
  .st-ContentsHeader_Hero
    .sw-HeroA.sw-HeroA-small
      +picture("/careers/uploads/us/image01.jpg", "").sw-HeroA_Image
        +source(md, "/careers/uploads/us/image02.jpg")
```

```jade
header.st-ContentsHeader
  .st-ContentsHeader_Hero
    .sw-HeroA
      picture.sw-HeroA_Image.
        <!--[if IE 9]><video style="display: none;"><![endif]-->
        <source media="(min-width: 768px)" srcset="/careers/uploads/us/image02.jpg">
        <!--[if IE 9]></video><![endif]-->
        <img src="/careers/uploads/us/image01.jpg" alt="">
```
*/
.sw-HeroA {
  position: relative;

  @include mq-up(md) {
    overflow: hidden;
    height: 350px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: rem(6);
    background-image: linear-gradient(
      0deg,
      rgba(102, 102, 102, 0) 0%,
      rgba(102, 102, 102, 0.15) 98%
    );
  }

  &.sw-HeroA-small {
    @include mq-up(md) {
      height: 200px;
    }

    @include mq-up(lg) {
      height: 350px;
    }
  }
}

.sw-HeroA_Image {
}

.sw-HeroA_Image > img {
  display: block;
  width: 100%;

  @include mq-up(md) {
    position: relative;
    left: 50%;
    min-height: 350px;
    min-width: 1920px;
    margin-left: -960px;
  }

  // 画像を画面幅に合わせて拡大する
  @media print, screen and (min-width: 1920px) {
    left: 50vw;
    min-width: 100vw;
    margin-left: -50vw;
  }

  .sw-HeroA-small & {
    @include mq-up(md) {
      min-height: 200px;
      // タブレット時はサイズをやや縮小する（テキストが隠れないようにするため）
      // アスペクト比が崩れないように57.142857143%（200/350）を基準に横幅を指定する
      min-width: (1920 * 0.571428571) * 1px;
      margin-left: ((1920 * 0.571428571) / 2) * -1px;
    }

    @include mq-up(lg) {
      min-height: 350px;
      min-width: 1920px;
      margin-left: -960px;
    }

    @media print, screen and (min-width: 1920px) {
      left: 50vw;
      min-width: 100vw;
      margin-left: -50vw;
    }
  }
}

.sw-HeroA_Bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  margin: auto;
  background-image: url("../img/common/bg_heroA_sp.svg");
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat;

  @include mq-up(lg) {
    position: absolute;
    left: 50%;
    min-height: 350px;
    min-width: 1920px;
    margin-left: -960px;
    background-image: url("../img/common/bg_heroA_pc.svg");
  }

  // 画像を画面幅に合わせて拡大する
  @media print, screen and (min-width: 1920px) {
    left: 50vw;
    min-width: 100vw;
    margin-left: -50vw;
  }
}

.sw-HeroA_Body {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  width: calc(100vw - 70px);
  max-width: $max-width;
  margin: auto;

  @include mq-up(sm) {
    width: calc(100vw - 60px);
  }

  @include mq-up(lg) {
    padding: 0 rem(100);
  }
}

.sw-HeroA_Inner {
}

.sw-HeroA_Title {
  text-transform: uppercase;
  letter-spacing: em(3.5, 24);
  font-family: $font-family-pt-sans;
  font-size: rem(24);
  line-height: 1.3;
  color: #fff;

  @include mq-up(md) {
    letter-spacing: em(5, 32);
    font-size: rem(32);
  }
}
