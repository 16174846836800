.st-DropNav {
  background-color: #fff;

  @include mq-up(lg) {
    display: none;
    position: absolute;
    top: 85px;
    right: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    margin: auto;
    padding-bottom: rem(28);
    text-align: left;
    background-color: rgba(79, 79, 79, 0.95);

    &[aria-hidden="false"] {
      top: 85px;
    }

    .st-GlobalNav-divition &[aria-hidden="false"] {
      display: none;
    }
  }

  & .st-GlobalSiteNav_SubLinkTitle {
    display: none;

    @include mq-up(md) {
      display: block;
    }
  }

  & .l-Grid {
    margin-bottom: 0;
  }
}

.st-DropNav_List {
  @include mq-up(lg) {
    display: block !important;
  }
}

.st-DropNav_SpButton {
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 15px;
  text-align: left;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  border: 0;
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  color: #444;

  @include mq-up(lg) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &[aria-expanded="true"] {
    border-bottom: 0;
  }
}

.st-DropNav_SpButtonInner {
  max-width: 1200px;
  margin: auto;
}

.st-DropNav_SpButtonIcon {
  .st-DropNav_SpButton & {
    position: absolute;
    top: 0;
    right: rem(15);
    bottom: 0;
    width: 15px;
    height: 9px;
    margin: auto;
    font-size: rem(14);

    &::before {
      @include icon;

      content: "#{$icon-chevron-down}";
      position: relative;
      top: -0.3em;
      color: currentColor;
    }
  }

  .st-DropNav_SpButton[aria-expanded="true"] & {
    &::before {
      top: -0.5em;
      transform: rotate(-180deg);
    }
  }
}

.st-DropNav_Inner {
  max-width: $max-width;
  margin: auto;

  @include mq-up(lg) {
    margin-top: 30px;
    line-height: 1.4;
  }

  @include mq-up(xl) {
    padding: 0;
  }

  .st-GlobalSiteNav_SubLinkTop + & {
    margin-top: -1px;

    @include mq-up(lg) {
      margin-top: 30px;
    }
  }
}

.st-DropNav_List {
  display: none;

  @include mq-up(lg) {
    display: block;
    max-width: $max-width;
    margin: auto;
    padding: 0 rem(15);
  }

  @include mq-up(xl) {
    padding: 0;
  }
}

.st-DropNav_Item {
  @include mq-up(lg) {
    display: inline-block;
    margin: 0 60px 0 0;

    &:last-child {
      margin-right: 0;
    }

    &.st-DropGlobalNav_Item-Global {
      display: none;
    }
  }
}

.st-DropNav_Link {
  display: block;
  position: relative;
  padding: 16px 0;
  text-decoration: none;
  font-size: 1rem;
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  color: #444;

  @include mq-up(lg) {
    display: block;
    padding: 0;
    text-decoration: none;
    font-size: rem(15);
    border: 0;
    background: transparent;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  &:not(:first-child) {
    @include mq-up(lg) {
      margin-top: 60px;
      border-top: 0;
    }
  }

  .st-DropNav_Item:first-child & {
    border-top: 1px solid #dadada;

    @include mq-up(lg) {
      border-top: 0;
    }
  }

  &[aria-expanded="true"] {
    border-bottom: 0;
  }

  .st-DropNav_Item-global & {
    padding: 30px 0;
    text-transform: uppercase;
    font-size: rem(15);
    border-bottom: 0;
    color: #8d8d8d;
  }
}

.st-DropNav_LinkIcon-pc {
  .st-DropGlobalNav_Link & {
    display: none;

    @include mq-up(lg) {
      display: inline;
    }
  }
}

.st-DropNav_Item-Global {
}

.st-GlobalCommonNav_GlobalIcon {
}

.st-DropNav_Close {
  display: none;

  @include mq-up(lg) {
    display: none;

    &[aria-hidden="false"] {
      display: block;
      position: absolute;
      top: rem(119);
      right: 15px;
      z-index: 100000;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: transparent;
      appearance: none;
    }
  }
}

.st-DropNav_SubLinkTitle {
  display: none;
  padding: 16px 0;
  font-size: rem(17);
  font-weight: 600;
  color: #fff;

  @include mq-up(lg) {
    display: block;
    margin-bottom: 18px;
    padding: 0;
    font-size: rem(17);
    font-weight: 700;
  }

  &:not(:first-child) {
    border-top: 1px solid #727272;

    @include mq-up(lg) {
      margin-top: 60px;
      border-top: 0;
    }
  }

  .st-Grid_Item:not(:first-of-type) & {
    border-top: 1px solid #727272;

    @include mq-up(lg) {
      border-top: 0;
    }
  }
}

a.st-DropNav_SubLinkTitle {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.st-DropNav_CloseIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -1px;
  left: -1px;
  width: 32px;
  height: 32px;

  // IE9以上
  :root & {
    top: -0.5px\0;
    left: -0.5px\0;
  }

  &::before {
    @include icon;

    content: "#{$icon-close}";
    display: block;
    position: relative;
    font-size: rem(12);
    color: #fff;
  }
}

//
.st-GlobalSiteNav_SubLinkTitleIcon {
  .st-DropGlobalNav_Link & {
    @include mq-up(lg) {
      display: none;
    }
  }
}

//

span.st-DropGlobalNav_LinkIcon-pc {
  display: none;

  @include mq-up(lg) {
    display: inline;

    &::before {
      @include icon;

      content: "#{$icon-arrow_drop_down}";
      margin: -0.25rem 0 0;
      font-size: rem(20);
      color: #fff;
      transform: rotate(-90deg);
    }
  }
}

.st-DropNav_PcButtonArea {
  display: none;

  @include mq-up(lg) {
    display: inline;

    &:nth-of-type(n + 2) {
      margin-left: rem(20);
    }
  }
}

.st-DropNav_PcButton {
  display: none;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  transition-duration: $transition-duration;
  appearance: none;

  @include mq-up(lg) {
    display: inline-block;
    position: relative;
    top: -3px;
    z-index: 1;
    width: auto;
    height: auto;
    padding-left: rem(20);
    font-size: rem(12);
    line-height: 1;
    color: #666;

    &:hover {
      color: #df0523;
    }

    &:active {
      &::before {
        position: relative;
      }
    }
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &.focus-ring:focus {
    box-shadow: $form-box-shadow-focus;
  }
}

.st-DropNav_PcButtonIcon {
  display: block;
  position: absolute;
  top: rem(4);
  right: 0;
  bottom: 0;
  left: 0;
  width: rem(12);
  height: rem(12);
  margin: auto;

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    left: rem(-2);
    width: 18px;
    height: 18px;
    margin: auto 0;
    font-size: rem(18);
    pointer-events: none;

    [lang="ja"] & {
      left: -75px;
    }

    [lang="zh-cn"] & {
      left: -75px;
    }
  }

  &::before {
    @include icon;

    content: "#{$icon-arrow_drop_down}";
    position: relative;
    top: -0.05em;
    color: $color-red;
    transition-duration: $transition-duration;
    transform: rotate(0deg) scale3d(0.5, 0.5, 1);
    transform-origin: center;

    @include mq-up(lg) {
      .st-DropNav_PcButton:hover & {
        color: currentColor;
      }
    }

    .st-DropNav_PcButton[aria-expanded="true"] & {
      transform: scale3d(0.8, 0.8, 1) rotate(-190deg);
      transform: rotate(-180deg) scale3d(0.5, 0.5, 1);
    }
  }
}

.st-DropNav_SubLinkTop {
  display: none;
  padding: 16px 15px;
  text-decoration: none;
  font-size: rem(16);
  background-color: rgba(#747474, 0.9);
  color: #fff;

  @include mq-up(lg) {
    display: block;
    height: rem(98);
    padding: 0;
    font-size: rem(22);
    font-weight: 600;
    line-height: rem(98);

    &:hover {
      text-decoration: underline;
    }
  }
}

.st-DropNav_SubLinkTopInner {
  max-width: $max-width;
  margin: auto;

  @include mq-up(lg) {
    padding: 0 15px;
  }

  @include mq-up(xl) {
    padding: 0;
  }
}

.st-DropNav_SubLinkTopIcon {
  @include mq-up(lg) {
    position: relative;
    top: -0.1em;
    left: rem(6);
    font-size: 0.8em;

    &::before {
      @include icon;

      content: "#{$icon-chevron-right}";
    }
  }
}

.st-DropNav_SubList {
  padding: 0 rem(15);
  list-style-type: none;

  @include mq-up(lg) {
    .st-GlobalSiteNav_Link + .st-GlobalSiteNav_SubArea & {
      display: block;
      margin-left: -30px;
      margin-left: 0;
      padding-left: 0;
      font-size: 0;
      list-style-type: none;
    }
  }
}

.st-DropNav_SubItem {
  position: relative;
  font-size: rem(16);

  @include mq-up(lg) {
    .st-GlobalSiteNav_Link + .st-GlobalSiteNav_SubArea & {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      padding-left: 30px;
      font-size: medium;
      font-size: 1rem;
    }
  }

  &::before {
    content: "";
    display: none;
    position: absolute;
    top: 1.4em;
    left: 0;
    width: 0;
    height: 0;
    border-width: 5px 0 5px 8px;
    border-style: solid;
    border-color: transparent transparent transparent #fff;

    @include mq-up(lg) {
      display: block;
      top: 0.9em;
      left: -0.5em;
    }
  }
}

.st-DropNav_SubLink {
  display: block;
  padding: 16px 0 16px 0;
  text-decoration: none;
  border-top: 1px solid #dadada;
  color: #ebebeb;

  @include mq-up(lg) {
    padding: 8px 1em;
    padding-left: 0;
    font-size: rem(15);
    line-height: 1.4;
    border-top: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .st-GlobalNav-divition & {
    color: #444;

    @include mq-up(lg) {
      color: #fff;
    }
  }

  // 開閉時にボーダーが重なるのを防止する
  .st-DropNav_List[aria-hidden="true"] & {
    border-color: transparent;
  }
}

.st-DropNav_IconExternalA {
  display: inline-block;
  vertical-align: middle;
  height: rem(18);
  font-size: rem(18);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.1em;
  }
}

.st-DropNav_IconExternalB {
  display: inline-block;
  vertical-align: middle;
  height: rem(18);
  font-size: rem(18);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.1em;
  }
}
