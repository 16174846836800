.st-DrawerNav {
  // ドキュメントをクリックすると、template.jsの`hideAllMenu()`で`display:none;`される。
  // それ以降は開閉できなくなってしまうため、強制的に表示させる。
  display: block !important;
  max-width: $max-width;
  margin: auto;
  padding: 0 15px;
  list-style-type: none;

  @include mq-up(lg) {
    margin-top: 30px;
    line-height: 1.4;
  }

  .st-GlobalSiteNav_SubLinkTop + & {
    margin-top: -1px;

    @include mq-up(lg) {
      margin-top: 30px;
    }
  }
}

.st-DrawerNav_Item {
  position: relative;
  font-size: rem(16);

  @include mq-up(lg) {
    .st-GlobalSiteNav_Link + .st-GlobalSiteNav_SubArea & {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      padding-left: 30px;
      font-size: medium;
      font-size: 1rem;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1.4em;
    left: 0;
    width: 0;
    height: 0;
    border-width: 5px 0 5px 8px;
    border-style: solid;
    border-color: transparent transparent transparent $color-red;

    @include mq-up(lg) {
      top: 0.9em;
      left: calc(1em + 16px);
    }
  }
}

.st-DrawerNav_Trigger {
  display: block;
  padding: 16px 0 16px 18px;
  text-decoration: none;
  border-top: 1px solid #727272;
  color: #ebebeb;

  @include mq-up(lg) {
    padding: 8px 1em;
    font-size: rem(15);
    line-height: 1.4;
    border-top: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .st-DrawerNav_Item:first-of-type & {
    border-top: 0;
  }
}

.st-DrawerNav_Drawer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100%;
  background: #616161;
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: ease-in-out;

  @include mq-up(lg) {
    display: none;
  }

  &[aria-hidden="true"] {
    opacity: 0;
    transform: translate3d(100vw, 0, 0);
  }

  &[aria-hidden="false"] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.st-DrawerNav_DrawerHeading {
  display: block;
  padding: 16px 15px;
  text-decoration: none;
  font-size: rem(16);
  background-color: rgba(#747474, 0.9);
  color: #fff;

  @include mq-up(lg) {
    height: 64px;
    padding: 0;
    font-size: rem(22);
    font-weight: 600;
    line-height: 64px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.st-DrawerNav_DrawerList {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-width: $max-width;
  // 53 + 57 + 54 = 164
  height: calc(100vh - 164px);
  margin: auto;
  padding: 0 rem(15) rem(50);
  list-style-type: none;

  .st-DrawerNav_Drawer[aria-hidden="true"] & {
    display: none;
  }

  .st-GlobalSiteNav_SubLinkTop + & {
    margin-top: -1px;
  }
}

.st-DrawerNav_DrawerItem {
  position: relative;
  font-size: rem(16);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1.4em;
    left: 0;
    width: 0;
    height: 0;
    border-width: 5px 0 5px 8px;
    border-style: solid;
    border-color: transparent transparent transparent $color-red;

    @include mq-up(lg) {
      top: 0.9em;
      left: calc(1em + 16px);
    }
  }
}

.st-DrawerNav_DrawerLink {
  display: block;
  padding: rem(16) rem(14);
  text-decoration: none;
  font-size: rem(16);
  line-height: 1.25;
  border-bottom: 1px solid #727272;
  color: #ebebeb;
}

.st-DrawerNav_DrawerBack {
  @include on-event() {
    text-decoration: none;
  }

  display: inline-block;
  position: relative;
  width: 100%;
  height: rem(53);
  margin: 0;
  padding: rem(20) rem(14);
  text-align: left;
  text-decoration: none;
  font-family: inherit;
  font-size: rem(14);
  font-weight: bold;
  line-height: 1;
  border: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  background-color: #4f4f4f;
  color: inherit;
  color: #fff;
  cursor: pointer;
  appearance: none;

  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    appearance: none;
  }

  &:focus {
    outline: 0;
  }
}

.st-DrawerNav_DrawerBackIcon {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: rem(15);
  height: rem(21);
  margin: auto;
  font-size: rem(21);
  color: $color-gray-plus1;

  &::before {
    @include icon();

    content: "#{$icon-arrow_left}";
    position: relative;
    top: -0.1em;
  }
}
