/*
---
name: swm-BoxA
namespace: swm-
category: Block
id: [M_BOX-4A, M_BOX-4B]
---

<span class="sw-Label">M_BOX-4A</span>

Productsで使用される製品リストです。

- ボタンでページに遷移します（クリック範囲はボタンのみ）
- ボタンに.swm-LinkIcon~を付けるとリンクアイコンを表示します

```jade
.swm-BoxA.swm-BoxA-col6to6
  .swm-BoxA_Item
    .swm-BoxA_Box
      .swm-BoxA_Head
        span.swm-BoxA_Image
          img(src="/assets/img/common/img_690_296.jpg" alt)
      .swm-BoxA_Body
        h3.swm-BoxA_Heading UV lighting
        p.swm-BoxA_Lead A good choice from all points of view
        p.swm-BoxA_Text Kyocera have been developing and manufacturing photovoltaic modules for nearly 45 years. Our modules
        p.swm-BoxA_Text Kyocera have been developing and manufacturing
          a.swm-BoxA_Link(href="#")  photovoltaic modules
            span.swm-BoxA_LinkExternalA(aria-hidden="true")
          |  for nearly 45 years. Our modules
        p.swm-BoxA_Text Kyocera have been developing and manufacturing
          a.swm-BoxA_Link(href="#")  photovoltaic modules
            span.swm-BoxA_LinkExternalB(aria-hidden="true")
          |  for nearly 45 years. Our modules
        p.swm-BoxA_Text Kyocera have been developing and manufacturing
          a.swm-BoxA_Link(href="#")  photovoltaic modules
          |  for nearly 45 years. Our modules
        ul.swm-BoxA_ListUnorder
          li UV hardening (printing industry)
          li Purification
          li Sensing
          li Sterilization
        a.swm-BoxA_more(href="/products/automotive_components/heaters/automotive_led.html")
          | SN Header
```

<span class="sw-Label">M_BOX-4B</span>

```jade
.swm-BoxA.swm-BoxA-col4to8
  .swm-BoxA_Item
    .swm-BoxA_Box
      .swm-BoxA_Head
        span.swm-BoxA_Image
          img(src="/assets/img/common/img_690_296.jpg" alt)
      .swm-BoxA_Body
        h3.swm-BoxA_Heading Heaters for Oxygen Sensors
        p.swm-BoxA_Lead A good choice from all points of view
        p.swm-BoxA_Text Can activate oxygen sensors immediately after the engine starts, which detect oxygen concentration in the exhaust gas of vehicles
        ul.swm-BoxA_ListUnorder
          li Rapid heat up
          li High temperature durability
          li High operating temperature range
          li Long lifetime
          li Robust
        a.swm-BoxA_more(href="/products/automotive_components/heaters/automotive_led.html")
          | SN Header
```
*/
.swm-BoxA {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  list-style-type: none;
}

.swm-BoxA_Item {
  padding-bottom: rem(30);

  &:nth-of-type(n + 2) {
    padding-top: rem(30);
    border-top: 1px solid #dadada;
  }

  &:last-of-type() {
    padding-bottom: rem(30);
    border-bottom: 1px solid #dadada;
  }
}

.swm-BoxA_Box {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-34);
}

.swm-BoxA_Head {
  width: 100%;
  padding-left: rem(34);

  @include mq-up(md) {
    .swm-BoxA-col1to11 & {
      width: percentage(1 / 12);
    }

    .swm-BoxA-col2to10 & {
      width: percentage(2 / 12);
    }

    .swm-BoxA-col3to9 & {
      width: percentage(3 / 12);
    }

    .swm-BoxA-col4to8 & {
      width: percentage(4 / 12);
    }

    .swm-BoxA-col5to7 & {
      width: percentage(5 / 12);
    }

    .swm-BoxA-col6to6 & {
      width: percentage(6 / 12);
    }

    .swm-BoxA-col7to5 & {
      width: percentage(7 / 12);
    }

    .swm-BoxA-col8to4 & {
      width: percentage(8 / 12);
    }

    .swm-BoxA-col9to3 & {
      width: percentage(9 / 12);
    }

    .swm-BoxA-co10to2 & {
      width: percentage(10 / 12);
    }

    .swm-BoxA-col11to1 & {
      width: percentage(11 / 12);
    }
  }
}

.swm-BoxA_Image {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}

.swm-BoxA_Body {
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
  margin-top: rem(13);
  padding-left: rem(34);

  @include mq-up(md) {
    flex-basis: 0;
    flex-grow: 1;
    margin-top: 0;
  }

  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

.swm-BoxA_Heading {
  margin-bottom: rem(10);
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.3;
  color: $color-red;
}

.swm-BoxA_Lead {
  margin-bottom: rem(17);
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.6;
  color: $color-black;
}

.swm-BoxA_Text {
  margin-bottom: rem(20);
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;

  @include mq-up(md) {
    margin-bottom: rem(18);
  }
}

.swm-BoxA_Link {
  text-decoration: underline;
  color: $color-red;

  &:hover {
    text-decoration: none;
  }
}

.swm-BoxA_LinkExternalA,
.swm-BoxA_LinkExternalB {
  display: inline;
  margin-left: rem(4);
  font-size: rem(14);

  &::before {
    @include icon;

    position: relative;
    top: -0.1em;
  }
}

.swm-BoxA_LinkExternalA {
  &::before {
    content: "#{$icon-filter_none}";
  }
}

.swm-BoxA_LinkExternalB {
  &::before {
    content: "#{$icon-external-link}";
  }
}

.swm-BoxA_ListUnorder {
  margin-left: rem(20);
  padding-left: 0;
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;
  list-style-type: none;

  & > li {
    position: relative;
    margin-bottom: rem(5);
  }

  & > li::before {
    content: "";
    display: block;
    position: absolute;
    top: rem(9);
    left: rem(-15);
    width: rem(5);
    height: rem(5);
    border: 2.5px solid #fc9898;
    border-radius: 50%;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.swm-BoxA_more {
  display: block;
  width: 100%;
  max-width: percentage(208 / 345);
  margin: rem(20) auto 0;
  padding: rem(10) rem(15) rem(12);
  text-align: center;
  text-decoration: none;
  font-size: rem(13);
  font-weight: 700;
  border: 1px solid $color-red;
  border-radius: 4px;
  color: $color-red;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    float: right;
    width: auto;
    max-width: none;
    margin-right: 0;
  }

  &:hover {
    background-color: $color-red;
    color: #fff;
  }
}

.swm-BoxA_moreExternal {
  position: relative;
  margin-left: rem(5);
  font-size: 0.8em;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
  }
}

.swm-BoxA_moreExternalB {
  position: relative;
  margin-left: rem(5);
  font-size: 0.8em;

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
  }
}
