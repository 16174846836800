/*
---
name: st-CategoryNav
namespace: st-
category: Global
---

PC以外で表示するカテゴリのナビゲーションです。


```jade
.st-CategoryNav
  a.st-CategoryNav_Parent
    | LED Lighting
    span.st-CategoryNav_ParentIcon(aria-hidden="true")
  ul.st-CategoryNav_List
    li.st-CategoryNav_Item
      a.st-CategoryNav_Link(href="#")
        span.st-CategoryNav_Icon(aria-hidden="true")
        | menu 1-1
    li.st-CategoryNav_Item
      a.st-CategoryNav_Link(href="#")
        span.st-CategoryNav_Icon(aria-hidden="true")
        | UV LED Lighting
        span.st-CategoryNav_IconExternalA(aria-hidden="true")
    li.st-CategoryNav_Item
      a.st-CategoryNav_Link(href="#")
        span.st-CategoryNav_Icon(aria-hidden="true")
        | menu 1-2
        span.st-CategoryNav_IconExternalB(aria-hidden="true")
```
*/
.st-CategoryNav {
  @include mq-up(md) {
    display: none;
  }
}

.st-CategoryNav_Parent {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: rem(24);
  padding: rem(14) rem(30) rem(14) rem(15);
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.6;
  border-bottom: 1px solid $color-gray-plus2;
  background-color: rgba(#f0f3f6, 0.8);
  color: $color-black;

  &:hover {
    text-decoration: none;
  }

  &[aria-current="page"] {
    color: $color-red;
  }
}

.st-CategoryNav_ParentIcon {
  position: absolute;
  top: 0;
  right: rem(14);
  bottom: 0;
  height: rem(14);
  margin: auto;
  font-size: 0.8em;
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.3em;
  }
}

.st-CategoryNav_List {
  padding-left: 0;
  list-style-type: none;

  & > :last-child {
    margin-bottom: 0;
  }
}

.st-CategoryNav_Item {
  margin-bottom: rem(18);
}

.st-CategoryNav_Link {
  display: flex;
  width: 100%;
  text-decoration: none;
  font-size: rem(16);
  line-height: 1.6;
  color: #555;

  &:hover {
    text-decoration: underline;
  }

  &[aria-current="page"] {
    color: $color-red;
  }
}

.st-CategoryNav_Icon {
  position: relative;
  top: rem(4);
  width: rem(30);
  height: rem(16);
  padding-left: rem(15);
  font-size: rem(16);
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-triangle}";
    position: relative;
    top: -0.3em;
    transform: scale(0.5);
  }
}

.st-CategoryNav_Text {
  flex-basis: 0;
  flex-grow: 1;
}

.st-CategoryNav_IconExternalA {
  height: rem(11);
  margin-top: rem(6);
  margin-left: rem(4);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.4em;
  }
}

.st-CategoryNav_IconExternalB {
  height: rem(11);
  margin-top: rem(6);
  margin-left: rem(4);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.4em;
  }
}
