.home-News {
  margin-top: rem(60);

  @include mq-up(md) {
    margin-top: rem(90);
  }
}

.home-News_Body {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(30);
  margin-left: rem(-15);

  @include mq-up(md) {
    margin-top: rem(38);
    margin-left: rem(-50);
  }
}

.home-News_Box {
  flex-basis: 100%;
  max-width: 100%;
  margin-top: rem(30);
  padding-left: rem(15);

  @include mq-up(md) {
    flex-basis: percentage(4 / 12);
    max-width: percentage(4 / 12);
    margin-top: 0;
    padding-left: rem(50);
  }
}

.home-News_List {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: rem(15);

  @include mq-up(md) {
    flex-basis: percentage(8 / 12);
    max-width: percentage(8 / 12);
    padding-left: rem(50);
  }
}

.home-News_Foot {
  margin-top: rem(30);
  text-align: center;

  @include mq-up(md) {
    margin-top: rem(30);
  }

  @include mq-up(lg) {
    margin-top: rem(54);
  }
}

.home-News_FootButton {
  @include sw-Button;

  width: 100%;
  max-width: none;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    max-width: percentage(1 / 2);
  }

  @include mq-up(lg) {
    &:hover {
      background-color: #df0523;
      color: #fff;
    }
  }
}
