/*
---
name: event-BoxA
namespace: event-
category: Block
id: BOX-5
---

<span class="sw-Label">BOX-5</span>

Productsのイベント一覧です。

- 画像、罫線、テキストを追加することができます


```jade
ul.event-BoxA
  li.event-BoxA_Item
    .event-BoxA_Body
      .event-BoxA_Content
        .event-BoxA_Time
          span.event-BoxA_TimeIcon(aria-hidden="true")
          span.event-BoxA_TimeText October 17-19, 2018
        p.event-BoxA_Heading ChipEXPO 2018
        p.event-BoxA_ContentText
          | Expocentre, pavilion "Forum"
          br
          | Booth # B15
          br
          a.event-BoxA_ContentLink(href="www.chipexpo.ru/en/exhibitors" target="_blank") www.chipexpo.ru/en/exhibitors
      .event-BoxA_Image
        img.event-BoxA_ImageThumbnail(src="/assets/img/common/img_690_296.jpg" alt="")
  li.event-BoxA_Item
    .event-BoxA_Body
      .event-BoxA_Content
        .event-BoxA_Time
          span.event-BoxA_TimeIcon(aria-hidden="true")
          span.event-BoxA_TimeText October 17-19, 2018
        p.event-BoxA_Heading electronica 2018
        p.event-BoxA_ContentText
          | Messe München
          br
          | Hall B6, Booth # 536
          br
          a.event-BoxA_ContentLink(href="https://electronica.de/" target="_blank") https://electronica.de/
            span.event-BoxA_IconExternal(aria-hidden="true")
      .event-BoxA_Image
        img.event-BoxA_ImageThumbnail(src="/assets/img/common/img_690_296.jpg" alt="")
  li.event-BoxA_Item
    .event-BoxA_Body
      .event-BoxA_Content
        .event-BoxA_Time
          span.event-BoxA_TimeIcon(aria-hidden="true")
          span.event-BoxA_TimeText October 17-19, 2018
        p.event-BoxA_Heading TheIJC.com - 4th Annual InkJet Conference
        p.event-BoxA_ContentText
          | Düsseldorf 
          br
          | Booth # D13
          br
          a.event-BoxA_ContentLink(href="www.theijc.com/" target="_blank") www.theijc.com/
            span.event-BoxA_IconExternalB(aria-hidden="true")
      .event-BoxA_Image
        img.event-BoxA_ImageThumbnail(src="/assets/img/common/img_690_296.jpg" alt="")
  li.event-BoxA_Item
    .event-BoxA_Body
      .event-BoxA_Content
        .event-BoxA_Time
          span.event-BoxA_TimeIcon(aria-hidden="true")
          span.event-BoxA_TimeText October 17-19, 2018
        p.event-BoxA_Heading ChipEXPO 2018
        p.event-BoxA_ContentText
          | Messe München
          br
          | Hall B6, Booth # 536
          br
          a.event-BoxA_ContentLink(href="https://electronica.de/" target="_blank") https://electronica.de/
      .event-BoxA_Image
        img.event-BoxA_ImageThumbnail(src="/assets/img/common/img_690_296.jpg" alt="")
    .event-BoxA_Foot
      .event-BoxA_FootImage
        img(src="/assets/img/common/img_690_296.jpg" alt="")
      hr.event-BoxA_FootDelimiter
      p.event-BoxA_FootText Furthermore, we will be participating in trade fairs in practically all foreign and regional markets in cooperation with our distributors. For dates, please contact your local sales partners.
```
*/
.event-BoxA {
  margin-top: rem(30);
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-top: rem(40);
  }
}

.event-BoxA_Item {
  &:nth-of-type(n + 2)::before {
    content: "";
    display: block;
    margin-top: rem(30);
    padding-bottom: rem(30);
    border-top: 1px solid #d8d8d8;

    @include mq-up(md) {
      margin-top: rem(40);
      padding-bottom: rem(40);
    }
  }
}

.event-BoxA_Body {
  @include mq-up(md) {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(-30);
  }
}

.event-BoxA_Time {
  display: flex;
  align-items: center;
  margin-bottom: rem(22);
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.375;
  color: #444;

  @include mq-up(md) {
    margin-bottom: rem(12);
    font-size: rem(15);
  }
}

.event-BoxA_TimeIcon {
  position: relative;
  width: rem(30);
  height: rem(19);
  font-size: rem(19);
  color: $color-gray-minus1;

  @include mq-up(md) {
    width: rem(26);
  }

  &::before {
    @include icon();

    content: "#{$icon-calender}";
    position: relative;
    top: -0.35em;
  }
}

.event-BoxA_TimeText {
  width: 100%;
  font-weight: 600;
}

.event-BoxA_Content {
  @include mq-up(md) {
    flex-basis: percentage(525 / 870);
    max-width: percentage(525 / 870);
    padding-left: rem(30);
  }
}

.event-BoxA_Heading {
  margin-bottom: rem(17);
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.3;
  color: $color-black;

  @include mq-up(md) {
    margin-bottom: rem(21);
    font-size: rem(24);
    line-height: 1.375;
  }
}

.event-BoxA_ContentText {
  font-size: rem(15);
  line-height: 1.4;
  color: #444;

  &:nth-of-type(n + 2) {
    margin-top: rem(5);
  }
}

.event-BoxA_ContentLink {
  text-decoration: none;
  color: $color-red;
  transition: $transition-duration;

  &:hover {
    text-decoration: underline;
  }
}

.event-BoxA_IconExternal {
  display: inline;
  margin-left: rem(4);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.2em;
  }
}

.event-BoxA_IconExternalB {
  display: inline;
  margin-left: rem(4);
  font-size: rem(11);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.2em;
  }
}

.event-BoxA_Image {
  margin-top: rem(20);
  text-align: center;

  @include mq-up(md) {
    flex-basis: percentage(345 / 870);
    max-width: percentage(345 / 870);
    margin-top: 0;
    padding-left: rem(30);
  }
}

.event-BoxA_Foot {
  margin-top: rem(20);

  @include mq-up(md) {
    margin-top: rem(40);
  }
}

.event-BoxA_FootImage {
  text-align: center;
}

.event-BoxA_FootDelimiter {
  margin: rem(30) 0;
  border: 0;
  border-top: 1px solid #d8d8d8;

  @include mq-up(md) {
    margin-top: rem(30);
    margin-bottom: rem(30);
  }
}

.event-BoxA_FootText {
  font-size: rem(16);
  font-weight: 600;
  line-height: 1.6;
  color: #444;

  &:nth-of-type(n + 2) {
    margin-top: rem(30);
  }
}
