/*
---
name: sw-Image
namespace: .sw-
category: Image
id: [IMG-1, IMG-8A, IMG-8B, IMG-8C]
---

<span class="sw-Label">IMG-1</span>

```jade
.sw-Image
  img(src="/assets/img/common/img_290_180.jpg" alt)
```

<span class="sw-Label">IMG-8A</span>

画像をセンタリングします。画像のサイズはなりゆきです。

```jade
.sw-Image.sw-Image-center
  img(src="/assets/img/common/img_870_280.jpg" alt)
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronicshas created new demand for connectors that can
.sw-Image.sw-Image-center
  img(src="/assets/img/common/img_290_180.jpg" alt)
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronicshas created new demand for connectors that can
```

<span class="sw-Label">IMG-8B</span>

画像を左寄せにします。

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronicshas created new demand for connectors that can
.sw-Image.sw-Image-left
  img(src="/assets/img/common/img_290_180.jpg" alt)
```

<span class="sw-Label">IMG-8C</span>

画像を右寄せにします。

```jade
p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronicshas created new demand for connectors that can
.sw-Image.sw-Image-right
  img(src="/assets/img/common/img_290_180.jpg" alt)
```
*/
.sw-Image {
  margin-top: rem(20);
  margin-bottom: rem(20);

  @include mq-up(md) {
    margin-top: rem(30);
    margin-bottom: rem(30);
  }

  .cms-Component_Block & > img {
    max-width: 100%;
  }
}

.sw-Image-full {
  & > img {
    width: 100%;
    max-width: none;
  }
}

.sw-Image-center {
  text-align: center;
}

.sw-Image-left {
  text-align: left;
}

.sw-Image-right {
  text-align: right;
}
