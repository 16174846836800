/*
---
name: home-Events
namespace: home-
category: Block
id: BOX-9
---

<span class="sw-Label">BOX-9</span>

ホームページのEventsエリアです。

```jade
main
  .home-Products_Wrapper
    .home-Events
      h2.home-Heading2: span Events
      ul.home-Events_Events
        li.home-Events_Event
          .home-Events_EventBox
            h3.home-Events_EventTitle electronica 2018
            p.home-Events_EventDate November 13 - 16, 2018
            p.home-Events_EventText
              | Messe München Hall<br>B6, Booth # 536
            a.home-Events_EventLink(href="https://electronica.de/index.html" target="_blank") https://electronica.de/index.html
              span.home-Events_EventExternalA(aria-hidden="true")
        li.home-Events_Event
          .home-Events_EventBox
            h3.home-Events_EventTitle TheIJC.com - 4th Annual InkJet Conference
            p.home-Events_EventDate October 24 - 25, 2017
            p.home-Events_EventText
              | Düsseldorf <br>Booth # D13
            a.home-Events_EventLink(href="http://www.theijc.com/" target="_blank") www.theijc.com/
              span.home-Events_EventExternalA(aria-hidden="true")
        li.home-Events_Event
          .home-Events_EventBox
            h3.home-Events_EventTitle INPRINT 2017
            p.home-Events_EventDate November 14 - 16, 2017
            p.home-Events_EventText
              | Düsseldorf <br>Booth # D13
              a.home-Events_EventLink(href="http://www.inprintshow.com/germany/2017/english/" target="_blank") www.inprintshow.com/germany/2017/english/
                span.home-Events_EventExternalB(aria-hidden="true")
```
*/
.home-Events {
  width: 100vw;
  margin-right: calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);
  padding: rem(40) calc(50vw - 50%) rem(60);
  background: #f2f4f6;

  @include mq-up(lg) {
    padding-top: rem(60);
  }
}

.home-Events_Events {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(30);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-left: rem(-6);
  }
}

.home-Events_Event {
  flex-basis: 100%;
  max-width: 100%;

  @include mq-up(md) {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);
    padding-left: rem(6);
  }

  &:nth-of-type(n + 2) {
    margin-top: rem(10);

    @include mq-up(md) {
      margin-top: 0;
    }
  }

  &:nth-of-type(n + 4) {
    @include mq-up(md) {
      margin-top: rem(6);
    }
  }
}

.home-Events_EventBox {
  height: 100%;
  padding: rem(35) rem(30);
  background: $color-white;
}

.home-Events_EventTitle {
  position: relative;
  margin-bottom: rem(15);
  padding-bottom: rem(15);
  font-size: rem(20);
  font-weight: 300;
  line-height: 1.3;
  color: $color-black;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: rem(30);
    height: rem(1);
    border-top: 1px solid $color-gray-plus1;
  }
}

.home-Events_EventDate {
  margin-bottom: rem(10);
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.114;
  color: $color-gray-minus2;
}

.home-Events_EventText {
  font-size: rem(15);
  line-height: 1.4;
  color: $color-gray-minus2;

  @include mq-up(md) {
    margin-bottom: rem(6);
  }
}

.home-Events_EventLink {
  display: block;
  text-decoration: none;
  font-size: rem(15);
  line-height: 1.4;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.home-Events_EventExternalA {
  height: rem(14);
  margin-left: rem(4);
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.1em;
  }
}

.home-Events_EventExternalB {
  height: rem(14);
  margin-left: rem(4);
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.1em;
  }
}
