.home-RegionList {
  display: block;
  width: 100%;
  margin-top: rem(20);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin-top: 0;

    &:nth-of-type(n + 2) {
      border-left: 1px solid #d8d8d8;
    }
  }
}

.home-RegionList_Item {
  display: inline-block;
  vertical-align: middle;

  &:nth-of-type(n + 2) {
    border-left: 1px solid #d8d8d8;
  }
}

.home-RegionList_Link {
  padding: 0 rem(18);
  text-align: center;
  text-decoration: none;
  font-size: rem(15);
  line-height: 1.7;
  color: #df0523;

  @include mq-up(md) {
    padding: 0 rem(22);
    font-size: rem(14);
    line-height: 1.8;
  }

  &:hover {
    text-decoration: underline;
  }
}

.home-RegionList_Video {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: rem(-1);
  height: rem(14);
  margin-right: rem(8);
  font-size: rem(14);

  @include mq-up(md) {
    top: rem(-2);
    height: rem(18);
    font-size: rem(18);
  }

  &::before {
    @include icon;

    content: "#{$icon-circle_arrow}";
    position: relative;
    top: -0.35em;
    color: #df0523;

    @include mq-up(md) {
      top: -0.5em;
    }
  }
}
