/*
---
name: sw-ListDownload
namespace: sw-
category: List
id: LINK-10
---

<span class="sw-Label">LINK-10</span>

ダウンロードリスト

```jade
ul.sw-ListDownload
  li.sw-ListDownload_Item
    a.sw-ListDownload_Link(href="#")
      span.sw-ListDownload_DownloadIcon(aria-hidden="true")
      span.sw-ListDownload_Text Multi Layer - Open tooled package listMulti Layer - Open tooled package list
        span.sw-ListDownload_Size  (50.30 KB)
  li.sw-ListDownload_Item
    a.sw-ListDownload_Link(href="#")
      span.sw-ListDownload_DownloadIcon(aria-hidden="true")
      span.sw-ListDownload_Text Single Layer - Open tooled package list
        span.sw-ListDownload_Size  (52.61 KB)
```
*/
.sw-ListDownload {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  font-size: rem(15);
  line-height: 1.3;
  border-top: 1px solid $color-gray-plus2;
  list-style-type: none;
}

.sw-ListDownload_Item {
  border-bottom: 1px solid $color-gray-plus2;
}

.sw-ListDownload_Link {
  display: flex;
  padding: rem(15) 0 rem(15) rem(20);
  text-decoration: none;
  color: $color-red;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    background-color: #fbf2f1;
  }
}

.sw-ListDownload_Text {
  flex-basis: 0;
  flex-grow: 1;
}

.sw-ListDownload_DownloadIcon {
  width: rem(32);
  height: rem(22);
  font-size: rem(22);
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-cloud-download}";
    position: relative;
    top: -0.3em;
  }
}

.sw-ListDownload_Size {
  color: #999;
}
