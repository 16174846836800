/*
---
name: sw-LeadC
namespace: sw-
category: Text
id: TXT-12
---

<span class="sw-Label">TXT-12</span>

Careerで使用されます。

```jade
p.sw-LeadC At Kyocera, we’re committed to building a better future through technology
```

テキストを中央揃え、右揃え、左揃えに指定できます。

- `.sw-LeadC-center`
- `.sw-LeadC-centerSm`
- `.sw-LeadC-centerMd`
- `.sw-LeadC-centerLg`
- `.sw-LeadC-centerXl`
- `.sw-LeadC-right`
- `.sw-LeadC-rightSm`
- `.sw-LeadC-rightMd`
- `.sw-LeadC-rightLg`
- `.sw-LeadC-rightXl`
- `.sw-LeadC-left`
- `.sw-LeadC-leftSm`
- `.sw-LeadC-leftMd`
- `.sw-LeadC-leftLg`
- `.sw-LeadC-leftXl`

```jade
p.sw-LeadC At Kyocera, we’re committed to building a better future through technology
p.sw-LeadC.sw-LeadC-center At Kyocera, we’re committed to building a better future through technology
p.sw-LeadC.sw-LeadC-right At Kyocera, we’re committed to building a better future through technology
```
*/
.sw-LeadC {
  margin-top: rem(25);
  margin-bottom: rem(25);
  font-size: rem(24);
  font-weight: 700;
  line-height: 1.3;
  color: $color-red;

  @include mq-up(md) {
    margin-top: rem(40);
    margin-bottom: rem(40);
    font-size: rem(30);
  }
}

.sw-LeadC-center {
  text-align: center;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-LeadC-center#{$suffix} {
    @media #{$breakpoint} {
      text-align: center;
    }
  }
}

.sw-LeadC-right {
  text-align: right;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-LeadC-right#{$suffix} {
    @media #{$breakpoint} {
      text-align: right;
    }
  }
}

.sw-LeadC-left {
  text-align: left;
}

@each $name, $breakpoint in $breakpoint-up {
  $suffix: to-lower-camel-case(#{$name});

  .sw-LeadC-left#{$suffix} {
    @media #{$breakpoint} {
      text-align: left;
    }
  }
}
