/*
---
name: office-BoxC
namespace: office-
category: Block
id: [BOX-1B, BOX-2B]
---

<span class="sw-Label">BOX-1B</span><span class="sw-Label">BOX-2B</span>

サイドナビ用のSales Office一覧です。


```jade
.office-BoxC
  h3.office-BoxC_CompanyName Contact for journalists
  p.office-BoxC_Text
   | Daniela Faust
   br
   | Manager Corporate
   br
   | Communications
  a.office-BoxC_Mail(href="mailto:daniela.faust@kyocera.de")
    span.office-BoxC_MailIcon(aria-hidden="true")
    span.office-BoxC_MailText daniela.faust@kyocera.de

.office-BoxC
  h3.office-BoxC_CompanyName Contact for journalists
  a.office-BoxC_Form(href="https://contact.kyocera.co.jp/inquiry/us/others/input.html")
    span.office-BoxB_FormText Inquiries by E-mail
    span.office-BoxC_FormIcon(aria-hidden="true")
```
*/
.office-BoxC {
  margin-top: rem(15);
  margin-bottom: rem(15);
  padding: rem(25) rem(20);
  background: #f2f4f6;

  @include mq-up(lg) {
    margin-top: rem(26);
    margin-bottom: rem(26);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.office-BoxC_CompanyName {
  position: relative;
  margin-bottom: rem(15);
  padding-bottom: rem(15);
  font-size: rem(16);
  font-weight: 700;
  line-height: 1.375;
  color: $color-gray-minus2;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 1px;
    border-top: 1px solid #bcbcbc;
  }
}

.office-BoxC_Text {
  margin-bottom: 1em;
  font-size: rem(15);
  font-weight: 400;
  line-height: 1.4;
  color: $color-gray-minus2;
}

.office-BoxC_Mail {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  font-size: rem(13);
  font-weight: 400;
  line-height: 1.5;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.office-BoxC_MailIcon {
  position: relative;
  height: rem(13);
  margin-right: rem(6);
  font-size: rem(16);

  &::before {
    @include icon;

    content: "#{$icon-mail}";
    position: relative;
    top: -0.2em;
  }
}

.office-BoxC_Form {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  text-decoration: none;
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.3;
  color: $color-red;

  &:hover {
    text-decoration: underline;
  }
}

.office-BoxC_FormIcon {
  position: relative;
  height: rem(12);
  margin-left: rem(7);
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.3em;
  }
}

.office-BoxC_MailText {
  word-break: break-all;
}
