@mixin sw-Button() {
  @include on-event() {
    text-decoration: none;
  }

  display: inline-block;
  margin: 0;
  padding: rem(12) rem(25) rem(11);
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.2;
  border: none;
  border: 1px solid $color-red;
  border-radius: (3 / 14) * 1em;
  background: transparent;
  background-color: #fff;
  color: $color-red;
  cursor: pointer;
  transition-duration: $transition-duration;
  appearance: none;

  &[type="button"],
  &[type="reset"],
  &[type="submit"] {
    appearance: none;
  }

  &:focus {
    outline: 0;
    box-shadow: $form-box-shadow-focus;
  }

  &:disabled,
  &-disabled {
    border-color: $color-gray-plus1;
    color: $color-gray-plus1;
    cursor: $form-cursor-disabled;
  }

  &-disabled {
    pointer-events: none;
  }
}
