/*
---
name: swm-ListOrderA
namespace: swm-
category: List
id: [M_LIST-2A]
---

<span class="sw-Label">M_LIST-2A</span>

入れ子に対応した順序リストです。

```jade
ol.swm-ListOrderA
  li Ordered nested list level 1
  li Ordered nested list level 1
  li Ordered nested list level 1 Ordered nested list level 1 Ordered nested list level 1
```
*/

.swm-ListOrderA {
  margin-left: rem(20);
  padding-left: 0;
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;
  list-style-type: none;

  & > li {
    position: relative;
    margin-bottom: rem(5);
  }

  & > li::before {
    content: "";
    display: block;
    position: absolute;
    top: rem(9);
    left: rem(-15);
    width: rem(5);
    height: rem(5);
    border: 2.5px solid #fc9898;
    border-radius: 50%;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
