/*
---
name: l-ButtonB
namespace: l-
category: Layout
id: [BTN-6A, BTN-6B]
---

全デバイスで50%幅になります。

<span class="sw-Label">BTN-6A</span>

```jade
ul.l-ButtonB
  li.l-ButtonB_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
```

<span class="sw-Label">BTN-6B</span>

```jade
ul.l-ButtonB
  li.l-ButtonB_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
  li.l-ButtonB_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
```

3カラム以上にも対応しています。

```jade
ul.l-ButtonB
  li.l-ButtonB_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
  li.l-ButtonB_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
  li.l-ButtonB_Item
    a.sw-Button.sw-Button-full(href="#") Primary Button
```
*/
.l-ButtonB {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: rem(20);
  margin-bottom: rem(20);
  margin-left: rem(-15);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-left: rem(-30);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.l-ButtonB_Item {
  flex-basis: 50%;
  max-width: 50%;
  padding-left: rem(15);

  @include mq-up(md) {
    margin-bottom: 0;
    padding-left: rem(30);
  }

  &:nth-of-type(n + 3) {
    margin-top: rem(15);

    @include mq-up(md) {
      margin-top: rem(30);
    }
  }
}
