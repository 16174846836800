$home-Carousel-max-width: 1170px !default;
$home-Carousel-height: 320px !default;
$home-Carousel-height-sm: 152px !default;
$home-Carousel-height-md: 320px !default;
$home-Carousel-height-lg: 500px !default;

@keyframes home-Carousel {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.home-Carousel {
  position: relative;
  margin-bottom: rem(20);
  animation-name: home-Carousel;
  animation-duration: 1s;
  animation-timing-function: ease-in;

  @include mq-up(md) {
    margin-bottom: 0;
  }
}

.home-Carousel_Head {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: $home-Carousel-max-width;
  height: auto;
  min-width: 100%;
  margin: 0 auto;
}

.home-Carousel_Inner {
  @include mq-up(md) {
    height: $home-Carousel-height-md;
  }

  @include mq-up(lg) {
    height: $home-Carousel-height-lg;
  }
}

// 背景画像とテキスト
.home-Carousel_Items {
  padding-left: 0;
  list-style-type: none;
}

.home-Carousel_Item {
  &:nth-of-type(n + 2) {
    display: none;
  }
}

.home-Carousel_Foot {
  position: absolute;
  right: 0;
  bottom: rem((12 + 15) * -1px);
  z-index: 100;
  width: 100%;

  @include mq-up(md) {
    bottom: rem(20);
    padding: 0 rem(50);
  }

  @include mq-up(lg) {
    bottom: rem(16);
  }
}

// サムネイル
.home-Carousel_Thumbnails {
  display: flex;
  justify-content: center;
  max-width: 1170px;
  margin: auto;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    justify-content: flex-start;
  }
}

.home-Carousel_ThumbnailLink {
  display: block;
  position: relative;
  width: rem(12);
  height: rem(12);
  margin: 0 rem(10);
  border-radius: rem((12 / 2));
  background-color: $color-gray-plus2;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    width: rem(14);
    height: rem(14);
    border-radius: rem((14 / 2));
  }

  &.active {
    background-color: $color-red;
  }
}

.home-Carousel_ThumbnailProgress {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;

  .home-Carousel_ThumbnailLink &::after {
    content: "";
    display: none;
    width: 0;
    height: 100%;
    background-color: #e10320;
    transition-timing-function: linear;
    transition-property: width;

    @include mq-up(md) {
      display: block;
    }
  }

  .home-Carousel_ThumbnailLink.active &::after {
    width: 100%;
    transition-duration: 4s;
  }
}

// Prev Next
.home-Carousel_Control {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: $home-Carousel-max-width + 140px + 100px;
  margin: auto;
  padding-left: 0;
  list-style-type: none;
  pointer-events: none;

  @include mq-up(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 rem(30);
  }

  @media print, screen and (min-width: $home-Carousel-max-width + 140px + 100px + 30px) {
    width: $home-Carousel-max-width + 140px + 100px;
    padding: 0;
  }
}

.home-Carousel_ControlPrev {
  pointer-events: auto;

  & > .bx-prev {
    display: block;
    width: rem(27);
    height: rem(43);
    background-image: url("../img/index/icon_arrow-prev.svg");
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
  }
}

.home-Carousel_ControlNext {
  margin-left: rem(15);
  pointer-events: auto;

  & > .bx-next {
    display: block;
    width: rem(27);
    height: rem(43);
    background-image: url("../img/index/icon_arrow-next.svg");
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
  }
}
