/*
---
name: sw-LeadA
namespace: sw-
category: Text
id: TXT-1
---

<span class="sw-Label">TXT-1</span>

リード文です。見出しではなく`p`を使います。

```jade
p.sw-LeadA Kyocera - your partner for semiconductor packaging solutions
```
*/
.sw-LeadA {
  margin-bottom: rem(20);
  padding-bottom: rem(20);
  font-size: rem(24);
  font-weight: 300;
  line-height: 1.4;
  border-bottom: 1px solid #d8d8d8;
  color: $color-red;

  @include mq-up(md) {
    margin-bottom: rem(30);
    padding-bottom: rem(30);
    font-size: rem(28);
  }

  .sw-LeadB + & {
    margin-top: 0 !important;
  }
}
