.result-Pager {
}

.result-Pager_Sp {
  display: flex;
  justify-content: center;

  @include mq-up(md) {
    display: none;
  }
}

.result-Pager_Pc {
  display: none;

  @include mq-up(md) {
    display: block;
  }
}

.result-Pager_Prev,
.result-Pager_Next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: rem(48);
  height: rem(45);
  text-align: center;
}

.result-Pager_Prev a,
.result-Pager_Next a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(48);
  height: rem(45);
  font-size: 0;
  color: #999;

  @include mq-up(md) {
    transition-duration: $transition-duration;

    &:hover {
      color: $color-red;
    }
  }

  &[aria-disabled] {
    opacity: 0;
    pointer-events: none;
  }
}

.result-Pager_PrevIcon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: rem(22);
  margin: auto;
  font-size: rem(22);
  color: #999;

  &::before {
    @include icon;

    content: "#{$icon-slide_arrow_left}";
    position: relative;
    top: -0.4em;
  }

  .result-Pager_PrevDisabled + & {
    display: none;
  }
}

.result-Pager_NextIcon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: rem(22);
  margin: auto;
  font-size: rem(22);
  color: #999;

  &::before {
    @include icon;

    content: "#{$icon-slide_arrow_right}";
    position: relative;
    top: -0.4em;
  }

  .result-Pager_NextDisabled + & {
    display: none;
  }
}

.result-Pager_PrevDisabled,
.result-Pager_NextDisabled {
  @include sr-only;
}

.result-Pager_Location {
  display: flex;
}

.result-Pager_LocationItem {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: rem(48);
  height: rem(45);
  font-weight: 600;
  box-shadow: 0 -1px 0 0 $color-gray-plus3 inset;

  &:first-child {
    color: $color-red;
  }

  &:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid $color-red;
    pointer-events: none;
  }
}

.result-Pager_List {
  display: flex;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    align-items: center;
    justify-content: center;
  }

  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: rem(48);
    height: rem(45);
    font-weight: 600;
    box-shadow: 0 -1px 0 0 #e9e9e9 inset;
    list-style: none;

    &.current::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 2px solid $color-red;
      pointer-events: none;
    }
  }

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: $color-gray-minus1;
    transition-duration: $transition-duration;

    &:hover {
      text-decoration: none;
      color: $color-red;
    }
  }
}

.result-Pager_List.SS_pagingFirst {
}

.result-Pager_List.SS_numberPage {
}

.result-Pager_List.SS_pagingLast {
}

.result-Pager_List.result-Pager_ListPrev {
}

.result-Pager_List .result-Pager_ListPrev,
.result-Pager_List .result-Pager_ListNext {
  box-shadow: 0 -1px 0 0 transparent inset;
}

.result-Pager_List .result-Pager_ListPrev a,
.result-Pager_List .result-Pager_ListNext a {
  position: relative;
  font-size: 0;
}

.result-Pager_ListPrev a::before,
.result-Pager_ListNext a::before {
  @include icon;

  position: relative;
  position: absolute;
  top: -0.4em;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: rem(22);
  margin: auto;
  text-align: center;
  font-size: rem(22);
  color: #999;
  transition-duration: $transition-duration;
}

.result-Pager_ListPrev a:hover::before,
.result-Pager_ListNext a:hover::before {
  color: $color-red;
}

.result-Pager_ListPrev a::before {
  content: "#{$icon-slide_arrow_left}";
}

.result-Pager_ListNext a::before {
  content: "#{$icon-slide_arrow_right}";
}
