@mixin sw-Input() {
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  height: rem(50);
  padding: 0 rem(10);
  font-size: 1rem;
  border: 1px solid #d0d0d0;
  border-radius: $form-border-radius;
  background-color: #fff;
  box-shadow: 1px 1px 6px -3px #9e9e9e inset;
  appearance: none;

  /**
   * Normalize.cssのリセットをします。
   */
  &[type="search"] {
    box-sizing: border-box;
    appearance: none;
  }

  &::placeholder {
    color: $form-placeholder-color;
  }

  &:focus {
    outline-width: 0;
    border-color: $form-border-color-focus;
    box-shadow: $form-box-shadow-focus;
  }

  &-error {
    background-color: #ffecec;
  }

  &:disabled {
    background-color: $form-background-color-disabled;
    opacity: $form-opacity-disabled;
    cursor: $form-cursor-disabled;
  }
}
