/*
---
name: sw-Heading2B
namespace: sw-
category: Link
id: LINK-1
---

<span class="sw-Label">LINK-1</span>

リンクとアイコン付きのh2コンポーネントです。

```jade
h2.sw-Heading2B
  a.sw-Heading2B_Link(href="#")
    | Products
    span.sw-Heading2B_Icon(aria-hidden="true")
```
*/
.sw-Heading2B {
  margin-top: rem(25);
  margin-bottom: rem(25);
}

.sw-Heading2B_Link {
  display: block;
  position: relative;
  text-decoration: none;
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.3;
  color: $color-black;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    font-size: rem(24);
    line-height: 1.3;

    &:hover {
      text-decoration: underline;
      color: $color-link-hover;
    }
  }
}

.sw-Heading2B_Icon {
  height: rem(24);
  margin-left: rem(4);
  font-size: rem(12);
  transform: scale(0.5);

  @include mq-up(lg) {
    height: rem(14);
    font-size: rem(14);
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.25em;
  }

  .sw-Heading2B_Link:hover & {
    color: $color-red;
  }
}
