/*
---
name: sw-Heading3A
namespace: sw-
category: Heading
id: HD-3
---

<span class="sw-Label">HD-3</span>

ベーシックなh3コンポーネントです。

```jade
h3.sw-Heading3A H3 Title name
```
*/
.sw-Heading3A {
  margin-top: rem(30);
  margin-bottom: rem(15);
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.3;
  color: $color-red;

  & + * {
    margin-top: 0 !important;
  }

  .swm-BoxA + & {
    margin-bottom: rem(10);
    font-size: rem(18);
    font-weight: 400;
    line-height: 1.3;
    color: $color-red;
  }
}
