@mixin sw-FormRadio() {
  /* デフォルトのラジオボタンを非表示にする。 */
  @include sr-only();

  & + label {
    display: inline-block;
    position: relative;
    padding-left: rem(27);
    font-size: rem(14);
    line-height: 1.5;
    color: #444;
    cursor: pointer;
  }

  & + label::before {
    $size: rem(19);

    content: "";
    display: inline-block;
    vertical-align: text-bottom;
    position: absolute;
    top: 1px;
    left: 0;
    width: $size;
    height: $size;
    margin: auto;
    border: 1px solid $color-gray-plus2;
    border-radius: 50%;
    background-color: $color-gray-plus4;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:focus + label::before {
    border-color: $form-border-color-focus;
    border-radius: 50%;
    box-shadow: $form-box-shadow-focus;
  }

  &:focus:not(.focus-ring) + label::before {
    border-color: $form-boder-color;
    box-shadow: none;
  }

  &:checked + label::before {
    border: 0;
    background-image: url("../img/common/icon_radio_on.svg");
  }

  &:disabled,
  & + label[disabled] {
    opacity: $form-opacity-disabled;
    cursor: $form-cursor-disabled;
  }
}
