/*
---
name: sw-SocialList
namespace: sw-
category: Block
id: BOX-10
---

<span class="sw-Label">BOX-10</span>


```jade
section.sw-SocialList
  h2.sw-SocialList_Heading KYOCERA International, Inc. - Corporate
  ul.sw-SocialList_List
    li.sw-SocialList_Item
      a.sw-SocialList_Link(href="https://www.linkedin.com/company/kyocera-north-america/?viewAsMember=true " target="_blank")
        img.sw-SocialList_Icon(src="/assets/img/common/icon_sns-linkedin.svg" alt="LinkedIn")

section.sw-SocialList
  h2.sw-SocialList_Heading KYOCERA International, Inc. - Communications Equipment Group
  ul.sw-SocialList_List
    li.sw-SocialList_Item
      a.sw-SocialList_Link(href="https://www.linkedin.com/company/kyoceramobile/" target="_blank")
        img.sw-SocialList_Icon(src="/assets/img/common/icon_sns-linkedin.svg" alt="LinkedIn")
    li.sw-SocialList_Item
      a.sw-SocialList_Link(href="https://www.instagram.com/kyoceramobile/" target="_blank")
        img.sw-SocialList_Icon(src="/assets/img/common/icon_sns-instagram.svg" alt="Instagram")
    li.sw-SocialList_Item
      a.sw-SocialList_Link(href="https://www.facebook.com/KyoceraMobile/" target="_blank")
        img.sw-SocialList_Icon(src="/assets/img/common/icon_sns-facebook.svg" alt="Facebook")
    li.sw-SocialList_Item
      a.sw-SocialList_Link(href="https://twitter.com/kyoceramobile/" target="_blank")
        img.sw-SocialList_Icon(src="/assets/img/common/icon_sns-twitter.svg" alt="Twitter")
    li.sw-SocialList_Item
      a.sw-SocialList_Link(href="https://www.youtube.com/user/kyoceramobilephones/" target="_blank")
        img.sw-SocialList_Icon(src="/assets/img/common/icon_sns-youtube.svg" alt="YouTube")
```
*/
.sw-SocialList {
  margin-top: rem(30);
  margin-bottom: rem(30);

  @include mq-up(md) {
    margin-top: rem(40);
    margin-bottom: rem(40);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  &::after {
    content: "";
    display: block;
    padding-bottom: rem(30);
    border-bottom: 1px solid $color-gray-plus3;

    @include mq-up(md) {
      padding-bottom: rem(40);
    }
  }
}

.sw-SocialList_Heading {
  font-size: rem(16);
  font-weight: 700;
  color: $color-gray-minus2;
}

.sw-SocialList_List {
  padding-left: 0;
  list-style-type: none;
}

.sw-SocialList_Item {
  display: inline-block;
  vertical-align: middle;
  margin-top: rem(25);
  margin-right: rem(20);

  @include mq-up(md) {
    margin-right: rem(30);
  }
}

.sw-SocialList_Link {
  display: block;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    &:hover {
      opacity: $opacity;
    }
  }
}

.sw-SocialList_Icon {
  display: block;
  width: rem(30) !important;
  height: auto;
}
