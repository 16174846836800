.result-Footer {
  padding-top: rem(24);

  @include mq-up(md) {
    padding-top: rem(40);
  }
}

.result-Footer_Inner {
  margin: auto;
}
