/*
---
name: sw-Delimiter
namespace: sw-
category: Text
---

途中で改行をさせたくないフレーズやキーワードに指定します。囲まれた範囲（Delimiter）がまとまって改行されます。

```jade
p.sw-Text テキストは基本的にスペースや役物を基準に改行されますが、
  span.sw-Delimiter この部分はまとめて改行されます。
  | ここは通常のテキストです。
```
*/
.sw-Delimiter {
  display: inline-block;
}
