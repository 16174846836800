/*
---
name: l-MediaC
namespace: sw-
category: Image
id: IMG-11
---

<span class="sw-Label">IMG-11</span>

- 画像とテキストの中央揃えです
- 画像は拡大縮小されません

```jade
.l-MediaC
  span.l-MediaC_Image
    img(src="/assets/img/common/image_75_50.jpg" alt="")
  span.l-MediaC_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
```
*/
.l-MediaC {
  display: flex;
  align-items: center;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
}

.l-MediaC_Image {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    margin-right: rem(10);

    // @note:使われていないよう（first-childが適用されるページなし）
    & > :first-child {
      margin-top: 0;
    }

    // @note:使われていないよう（last-childが適用されるページなし）
    & > :last-child {
      margin-bottom: 0;
    }
  }
}

.l-MediaC_Text {
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;
}
