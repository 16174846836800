/*
---
name: top-Inner
namespace: top-
category: Layout
---

トップページ系の上下の余白を管理します。


```jade
.l-Wrapper
  .top-Inner
    p Contents
```
*/
.top-Inner {
  padding-top: rem(30);
  padding-bottom: rem(60);

  @include mq-up(md) {
    padding-top: rem(50);
    padding-bottom: rem(90);
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
