/*
---
name: swm-NavC
namespace: swm-
category: Navigation
id: [M_CARD-3A]
---

<span class="sw-Label">M_CARD-3A</span>

Careersページで使用されるナビゲーションです。

スマホ1カラム、タブレット3カラム

```jade
.swm-NavC.swm-NavC-col3Md
  .swm-NavC_Item
    .swm-NavC_Head
      span.swm-NavC_Thumbnail
        img(src="/assets/img/common/img_690_224.jpg" alt)
    .swm-NavC_Foot
      ul.sw-ListArrow
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link.swm-LinkIconA(href="#") Terms of use
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link.swm-LinkIconB(href="#") Data Privacy Notice
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link(href="#") Modern Slavery ActModern Slavery ActModern Slavery ActModern Slavery ActModern Slavery Act
  .swm-NavC_Item
    .swm-NavC_Head
      span.swm-NavC_Thumbnail
        img(src="/assets/img/common/img_690_224.jpg" alt)
    .swm-NavC_Foot
      ul.sw-ListArrow
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link.swm-LinkIconA(href="#") Terms of use
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link.swm-LinkIconB(href="#") Data Privacy Notice
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link(href="#") Modern Slavery ActModern Slavery ActModern Slavery ActModern Slavery ActModern Slavery Act
  .swm-NavC_Item
    .swm-NavC_Head
      span.swm-NavC_Thumbnail
        img(src="/assets/img/common/img_690_224.jpg" alt)
    .swm-NavC_Foot
      ul.sw-ListArrow
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link.swm-LinkIconA(href="#") Terms of use
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link.swm-LinkIconB(href="#") Data Privacy Notice
        li.sw-ListArrow_Item.swm-ListArrow_Item
          a.sw-ListArrow_Link(href="#") Modern Slavery ActModern Slavery ActModern Slavery ActModern Slavery ActModern Slavery Act
```
*/
$swm-NavC-column-gap: rem(15);
$swm-NavC-row-gap: rem(15);
$swm-NavC-column-gap-md: rem(30);
$swm-NavC-row-gap-md: rem(25);

.swm-NavC {
  display: flex;
  flex-wrap: wrap;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  margin-left: rem(-30);
  padding-left: 0;
  list-style-type: none;
}

.swm-NavC_Item {
  flex-basis: 100%;
  max-width: 100%;

  /* 1 Column */
  margin-top: 0;
  padding-left: rem(30) + $swm-NavC-row-gap;
  padding-right: $swm-NavC-row-gap;
}

/* 3 Columns */
.swm-NavC-col3 > .swm-NavC_Item {
  flex-basis: percentage(1 / 3);
  max-width: percentage(1 / 3);

  &:nth-of-type(n + 1) {
    margin-top: 0;
    padding-left: rem(30);
  }
}

@include mq-up(md) {
  .swm-NavC-col3Md > .swm-NavC_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
      padding-left: rem(30);
      padding-right: 0;
    }
  }
}

@include mq-up(lg) {
  .swm-NavC-col3Lg > .swm-NavC_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
      padding-left: rem(30);
      padding-right: 0;
    }
  }
}

@include mq-up(xl) {
  .swm-NavC-col3Xl > .swm-NavC_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
      padding-left: rem(30);
      padding-right: 0;
    }
  }
}

.swm-NavC_Box {
  display: block;
  height: 100%;
  text-decoration: none;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }

  .cms-Component_Editor & {
    height: auto;
  }
}

.swm-NavC_Thumbnail {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
    display: block;
    width: 100%;
  }
}

.swm-NavC_Body {
  padding: rem(16) rem(15) rem(20);
  font-size: rem(16);
  font-weight: bold;
  line-height: 1.375;
  background: #f2f4f6;
  color: $color-gray-minus2;

  @include mq-up(md) {
    font-size: rem(16);
  }
}

.swm-NavC_Icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: rem(12);
  margin-left: rem(3);
  font-size: rem(12);

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.2em;
    color: $color-red;
  }
}

.swm-NavC_IconExternalA {
  position: relative;
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.1em;
    left: 0.5em;
    color: $color-red;
  }
}

.swm-NavC_IconExternalB {
  position: relative;
  font-size: rem(14);

  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    position: relative;
    top: -0.1em;
    left: 0.5em;
    color: $color-red;
  }
}

.swm-NavC_Foot {
  margin-bottom: rem(10);
//  padding-top: rem(20);
  padding-top: 0;
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;

  @include mq-up(md) {
    margin-bottom: 0;
    padding-top: rem(0);
  }
}
