/*
---
name: news-NavA
namespace: news-
category: Navigation
---

Productsのカテゴリトップページで使用するNews一覧です。MTで、該当するカテゴリのNewsだけが出力されます。


```jade
.news-NavA
  .news-NavA_Body
    ol.news-NavA_List
      li.news-NavA_Item
        a.news-NavA_Link(href="#")
          p.news-NavA_Title KYOCERA introduces reliable, Waterproof Branch Connector for harsh conditions of automotive
          p.news-NavA_PostDate 26 October 2018
          ul.news-NavA_Categories
            li.news-NavA_Category Corporate
            li.news-NavA_Category Management
          span.news-NavA_Icon(aria-hidden="true")
      li.news-NavA_Item
        a.news-NavA_Link(href="#")
          p.news-NavA_Title KYOCERA introduces reliable, Waterproof Branch Connector for harsh conditions of automotive
          p.news-NavA_PostDate 26 October 2018
          ul.news-NavA_Categories
            li.news-NavA_Category Corporate
            li.news-NavA_Category Management
          span.news-NavA_Icon(aria-hidden="true")
      li.news-NavA_Item
        a.news-NavA_Link(href="#")
          p.news-NavA_Title KYOCERA introduces reliable, Waterproof Branch Connector for harsh conditions of automotive
          p.news-NavA_PostDate 26 October 2018
          ul.news-NavA_Categories
            li.news-NavA_Category Corporate
            li.news-NavA_Category Management
          span.news-NavA_Icon(aria-hidden="true")
      li.news-NavA_Item
        a.news-NavA_Link(href="#")
          p.news-NavA_Title KYOCERA introduces reliable, Waterproof Branch Connector for harsh conditions of automotive
          p.news-NavA_PostDate 26 October 2018
          ul.news-NavA_Categories
            li.news-NavA_Category Corporate
            li.news-NavA_Category Management
          span.news-NavA_Icon(aria-hidden="true")
  .news-NavA_Foot
    a.news-NavA_Button(href="#") More News
```
*/
.news-NavA {
}

.news-NavA_Body {
}

.news-NavA_List {
  margin-top: rem(-14);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-top: rem(-24);
  }
}

.news-NavA_Item {
  @include mq-up(md) {
    &:first-of-type::before {
      display: none;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    margin-right: calc(-50vw + 50%);
    margin-left: calc(-50vw + 50%);
    border-bottom: 1px solid #e9e9e9;

    @include mq-up(md) {
      width: 100%;
      margin: 0;
    }
  }
}

.news-NavA_Link {
  display: block;
  position: relative;
  padding-top: rem(14);
  padding-bottom: rem(14);
  text-decoration: none;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    padding-top: rem(24);
    padding-right: rem(30);
    padding-bottom: rem(18);

    .home-NewsList_Item:first-of-type & {
      padding-top: 0;
    }
  }

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.news-NavA_Title {
  margin-bottom: rem(10);
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.3;
  color: #000;

  @include mq-up(md) {
    margin-bottom: rem(8);
    font-size: rem(20);
  }
}

.news-NavA_IconExternalA {
  display: inline-block;
  height: rem(14);
  margin-left: rem(4);
  font-size: rem(14);
  color: $color-gray;

  &::before {
    @include icon;

    content: "#{$icon-filter_none}";
    position: relative;
    top: -0.15em;
  }
}

.news-NavA_IconExternalB {
  @extend .news-NavA_IconExternalA;

  &::before {
    content: "#{$icon-external-link}";
  }
}

.news-NavA_PostDate {
  margin-bottom: rem(5);
  font-size: rem(12);
  font-weight: 600;
  line-height: 1.114285714;
  color: $color-red;

  @include mq-up(md) {
    display: inline-block;
    position: relative;
    margin-right: rem(24);
    font-size: rem(14);

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: rem(-12);
      bottom: 0;
      width: 1px;
      height: 100%;
      border-right: 1px solid #dadada;
    }
  }
}

.news-NavA_Categories {
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    display: inline-block;
  }
}

.news-NavA_Category {
  display: inline-block;
  font-size: rem(12);
  font-weight: 600;
  line-height: 1.3;
  color: #444;

  &:nth-of-type(n + 2)::before {
    content: "/";
    margin-right: rem(4);
  }
}

.news-NavA_Icon {
  position: absolute;
  right: rem(5);
  bottom: rem(20);
  font-size: 0.8em;

  @include mq-up(md) {
    top: 0;
    right: rem(4);
    bottom: 0;
    height: 1.8em;
    margin: auto;
    font-size: 0.95em;
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    color: $color-red;
  }
}

.news-NavA_Foot {
  margin-top: rem(20);
  text-align: center;

  @include mq-up(md) {
    margin-top: rem(30);
  }
}

.news-NavA_Button {
  @include sw-Button;

  width: 100%;
  max-width: rem(162);
  transition-duration: $transition-duration;

  @include mq-up(md) {
    max-width: percentage(5 / 12);
  }

  @include mq-up(lg) {
    &:hover {
      background-color: $color-red;
      color: #fff;
    }
  }
}
