/*
---
name: sw-PageNavA
namespace: sw-
category: Navigation
id: CTL-2
---

<span class="sw-Label">CTL-2</span>

Productsページで使用されるページ内リンクです。

- `.sw-PageNavA`内のh2タグから自動生成され、`.sw-PageNavA`の最初の子要素として出力されます

```jade
.sw-PageNavA
  ul.sw-PageNavA_List
    li.sw-PageNavA_Item
      a.sw-PageNavA_Link(href="#")
        span.sw-PageNavA_Icon(aria-hidden="true")
        | Automotive Applications
    li.sw-PageNavA_Item
      a.sw-PageNavA_Link(href="#")
        span.sw-PageNavA_Icon(aria-hidden="true")
        | Industrial Heater Applications
    li.sw-PageNavA_Item
      a.sw-PageNavA_Link(href="#")
        span.sw-PageNavA_Icon(aria-hidden="true")
        | Water-Heating Applications
    li.sw-PageNavA_Item
      a.sw-PageNavA_Link(href="#")
        span.sw-PageNavA_Icon(aria-hidden="true")
        | Kerosene, Gas & Pellet Appliances / Consumer Electronic
    li.sw-PageNavA_Item
      a.sw-PageNavA_Link(href="#")
        span.sw-PageNavA_Icon(aria-hidden="true")
        | Medical Applications
    li.sw-PageNavA_Item
      a.sw-PageNavA_Link(href="#")
        span.sw-PageNavA_Icon(aria-hidden="true")
        | Water-Heating Applications
```
*/
.sw-PageNavA {
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.sw-PageNavA_List {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(26);
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-bottom: rem(40);
  }
}

.sw-PageNavA_Item {
  flex-basis: 100%;
  max-width: 100%;
  border-left: 1px solid $color-gray-plus2;

  @include mq-up(md) {
    flex-basis: auto;
    max-width: none;
    margin-right: 0;
    margin-bottom: rem(5);

    &:last-of-type {
      border-right: 1px solid $color-gray-plus2;
    }
  }
}

.sw-PageNavA_Link {
  display: block;
  position: relative;
  padding: rem(9) 0 rem(9) rem(34);
  text-decoration: none;
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.3;
  color: $color-red;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    padding: rem(9) rem(24) rem(9) rem(32);
    border: 0;
  }

  &:hover {
    text-decoration: underline;
    color: $color-link-hover;
  }
}

.sw-PageNavA_Icon {
  position: absolute;
  top: rem(8);
  left: rem(8);
  font-size: 1.14em;
  color: $color-red;

  &::before {
    @include icon;

    content: "#{$icon-arrow_drop_down}";
    transform: scale(0.5);
  }
}
