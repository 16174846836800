/*
---
name: l-Grid
namespace: l-
category: Layout
---

標準的なグリッドシステムです。カラムごとに任意の値を指定したい場合に使います。
カラム間の余白は幅を`$l-grid-column-gap`で統一しています。

以下のような指定ができます。

- `.l-Grid_Item-8of12` カラム数の指定。
- `.l-Grid-reverse` カラムの反転。
- `.l-Grid-center` カラムのセンタリング。
- `.l-Grid-middle` カラムの垂直配置。

```jade
.l-Grid
  .l-Grid_Item.l-Grid_Item-8of12Md
    .test-Box One<br>1/1 → 8/12
  .l-Grid_Item.l-Grid_Item-4of12Md
    .test-Box Two<br>1/1 → 4/12
```

```jade
.l-Grid.l-Grid-reversMd
  .l-Grid_Item.l-Grid_Item-8of12Md
    .test-Box One<br>1/1 → 8/12
  .l-Grid_Item.l-Grid_Item-4of12Md
    .test-Box Two<br>1/1 → 4/12
```

```jade
.l-Grid.l-Grid-centerMd
  .l-Grid_Item.l-Grid_Item-8of12Md
    .test-Box One<br>1/1 → 8/12
```
*/
$l-grid-column-gap: rem(15);
$l-grid-column-gap-md: rem(30);

.l-Grid {
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $margin-bottom-base;
  margin-left: -$l-grid-column-gap;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-left: -$l-grid-column-gap-md;
  }
}

.l-Grid_Item {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: $l-grid-column-gap;

  @include mq-up(md) {
    padding-left: $l-grid-column-gap-md;
  }

  &-1of12 {
    flex-basis: percentage(1 / 12);
    max-width: percentage(1 / 12);
  }

  &-2of12 {
    flex-basis: percentage(2 / 12);
    max-width: percentage(2 / 12);
  }

  &-3of12 {
    flex-basis: percentage(3 / 12);
    max-width: percentage(3 / 12);
  }

  &-4of12 {
    flex-basis: percentage(4 / 12);
    max-width: percentage(4 / 12);
  }

  &-5of12 {
    flex-basis: percentage(5 / 12);
    max-width: percentage(5 / 12);
  }

  &-6of12 {
    flex-basis: percentage(6 / 12);
    max-width: percentage(6 / 12);
  }

  &-7of12 {
    flex-basis: percentage(7 / 12);
    max-width: percentage(7 / 12);
  }

  &-8of12 {
    flex-basis: percentage(8 / 12);
    max-width: percentage(8 / 12);
  }

  &-9of12 {
    flex-basis: percentage(9 / 12);
    max-width: percentage(9 / 12);
  }

  &-10of12 {
    flex-basis: percentage(10 / 12);
    max-width: percentage(10 / 12);
  }

  &-11of12 {
    flex-basis: percentage(11 / 12);
    max-width: percentage(11 / 12);
  }

  &-12of12 {
    flex-basis: percentage(12 / 12);
    max-width: percentage(12 / 12);
  }
}

@include mq-up(md) {
  .l-Grid_Item {
    &-1of12Md {
      flex-basis: percentage(1 / 12);
      max-width: percentage(1 / 12);
    }

    &-2of12Md {
      flex-basis: percentage(2 / 12);
      max-width: percentage(2 / 12);
    }

    &-3of12Md {
      flex-basis: percentage(3 / 12);
      max-width: percentage(3 / 12);
    }

    &-4of12Md {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }

    &-5of12Md {
      flex-basis: percentage(5 / 12);
      max-width: percentage(5 / 12);
    }

    &-6of12Md {
      flex-basis: percentage(6 / 12);
      max-width: percentage(6 / 12);
    }

    &-7of12Md {
      flex-basis: percentage(7 / 12);
      max-width: percentage(7 / 12);
    }

    &-8of12Md {
      flex-basis: percentage(8 / 12);
      max-width: percentage(8 / 12);
    }

    &-9of12Md {
      flex-basis: percentage(9 / 12);
      max-width: percentage(9 / 12);
    }

    &-10of12Md {
      flex-basis: percentage(10 / 12);
      max-width: percentage(10 / 12);
    }

    &-11of12Md {
      flex-basis: percentage(11 / 12);
      max-width: percentage(11 / 12);
    }

    &-12of12Md {
      flex-basis: percentage(12 / 12);
      max-width: percentage(12 / 12);
    }
  }
}

@include mq-up(lg) {
  .l-Grid_Item {
    &-1of12Lg {
      flex-basis: percentage(1 / 12);
      max-width: percentage(1 / 12);
    }

    &-2of12Lg {
      flex-basis: percentage(2 / 12);
      max-width: percentage(2 / 12);
    }

    &-3of12Lg {
      flex-basis: percentage(3 / 12);
      max-width: percentage(3 / 12);
    }

    &-4of12Lg {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }

    &-5of12Lg {
      flex-basis: percentage(5 / 12);
      max-width: percentage(5 / 12);
    }

    &-6of12Lg {
      flex-basis: percentage(6 / 12);
      max-width: percentage(6 / 12);
    }

    &-7of12Lg {
      flex-basis: percentage(7 / 12);
      max-width: percentage(7 / 12);
    }

    &-8of12Lg {
      flex-basis: percentage(8 / 12);
      max-width: percentage(8 / 12);
    }

    &-9of12Lg {
      flex-basis: percentage(9 / 12);
      max-width: percentage(9 / 12);
    }

    &-10of12Lg {
      flex-basis: percentage(10 / 12);
      max-width: percentage(10 / 12);
    }

    &-11of12Lg {
      flex-basis: percentage(11 / 12);
      max-width: percentage(11 / 12);
    }

    &-12of12Lg {
      flex-basis: percentage(12 / 12);
      max-width: percentage(12 / 12);
    }
  }
}

@include mq-up(xl) {
  .l-Grid_Item {
    &-1of12Xl {
      flex-basis: percentage(1 / 12);
      max-width: percentage(1 / 12);
    }

    &-2of12Xl {
      flex-basis: percentage(2 / 12);
      max-width: percentage(2 / 12);
    }

    &-3of12Xl {
      flex-basis: percentage(3 / 12);
      max-width: percentage(3 / 12);
    }

    &-4of12Xl {
      flex-basis: percentage(4 / 12);
      max-width: percentage(4 / 12);
    }

    &-5of12Xl {
      flex-basis: percentage(5 / 12);
      max-width: percentage(5 / 12);
    }

    &-6of12Xl {
      flex-basis: percentage(6 / 12);
      max-width: percentage(6 / 12);
    }

    &-7of12Xl {
      flex-basis: percentage(7 / 12);
      max-width: percentage(7 / 12);
    }

    &-8of12Xl {
      flex-basis: percentage(8 / 12);
      max-width: percentage(8 / 12);
    }

    &-9of12Xl {
      flex-basis: percentage(9 / 12);
      max-width: percentage(9 / 12);
    }

    &-10of12Xl {
      flex-basis: percentage(10 / 12);
      max-width: percentage(10 / 12);
    }

    &-11of12Xl {
      flex-basis: percentage(11 / 12);
      max-width: percentage(11 / 12);
    }

    &-12of12Xl {
      flex-basis: percentage(12 / 12);
      max-width: percentage(12 / 12);
    }
  }
}

/**
 * 要素の垂直位置を上寄せ。
 */
.l-Grid-top {
  align-items: flex-start;
}

@include mq-up(md) {
  .l-Grid-topMd {
    align-items: flex-start;
  }
}

@include mq-up(lg) {
  .l-Grid-topLg {
    align-items: flex-start;
  }
}

@include mq-up(xl) {
  .l-Grid-topXl {
    align-items: flex-start;
  }
}

/**
 * 要素の垂直位置を真ん中寄せ。
 */
.l-Grid-middle {
  align-items: center;
}

@include mq-up(md) {
  .l-Grid-middleMd {
    align-items: center;
  }
}

@include mq-up(lg) {
  .l-Grid-middleLg {
    align-items: center;
  }
}

@include mq-up(xl) {
  .l-Grid-middleXl {
    align-items: center;
  }
}

/**
 * 要素の垂直位置を下寄せ。
 */
.l-Grid--bottom {
  align-items: flex-end;
}

@include mq-up(md) {
  .l-Grid-bottomMd {
    align-items: flex-end;
  }
}

@include mq-up(lg) {
  .l-Grid-bottomLg {
    align-items: flex-end;
  }
}

@include mq-up(xl) {
  .l-Grid-bottomXl {
    align-items: flex-end;
  }
}

/**
 * 要素を中央寄せ、要素内は左寄せ。
 */
.l-Grid-center {
  justify-content: center;
}

@include mq-up(md) {
  .l-Grid-centerMd {
    justify-content: center;
  }
}

@include mq-up(lg) {
  .l-Grid-centerLg {
    justify-content: center;
  }
}

@include mq-up(xl) {
  .l-Grid-centerXl {
    justify-content: center;
  }
}

/**
 * 要素を右寄せ、要素内は左寄せ。
 */
.l-Grid-right {
  justify-content: flex-end;
}

@include mq-up(md) {
  .l-Grid-rightMd {
    justify-content: flex-end;
  }
}

@include mq-up(lg) {
  .l-Grid-rightLg {
    justify-content: flex-end;
  }
}

@include mq-up(xl) {
  .l-Grid-rightXl {
    justify-content: flex-end;
  }
}

/**
 * 要素の反転。
 */
.l-Grid-reverse {
  flex-direction: row-reverse;
}

@include mq-up(md) {
  .l-Grid-reversMd {
    flex-direction: row-reverse;
  }
}

@include mq-up(lg) {
  .l-Grid-reverseLg {
    flex-direction: row-reverse;
  }
}

@include mq-up(xl) {
  .l-Grid-reverseXl {
    flex-direction: row-reverse;
  }
}
