/*
---
name: l-Images
namespace: l-
category: Layout
id: [IMG-4A, IMG-4B, IMG-12A, IMG-12B, IMG-12C]
---

<span class="sw-Label">IMG-4A</span>

バリエーションの豊富なグリッドシステムです。親要素（`.l-Images`）にクラスを追加することで、1/2や1/3のような均等なカラムの指定ができます。

カラム間の余白は幅を`$l-images-column-gap`、高さを`$l-images-row-gap`で統一しています。

以下のような指定ができます。

- `.l-Images-col2`のように1/2から1/5までの均等なカラム指定。
- `.l-Images-col2Md`のように`Md`、`Lg`、`Xl`を追加してブレイクポイントの指定。

### 均等なカラム指定。

```jade
.l-Images.l-Images-col2Md
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
```

<span class="sw-Label">IMG-4B</span>

```jade
.l-Images.l-Images-col3Md
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
```

<span class="sw-Label">IMG-12A</span>


```jade
.l-Images.l-Images-col2
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
```

<span class="sw-Label">IMG-12B</span>


```jade
.l-Images.l-Images-col3
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
```

<span class="sw-Label">IMG-12C</span>


```jade
.l-Images.l-Images-col2.l-Images-col4Md
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
  .l-Images_Item
    figure.sw-Caption.sw-Caption-full
      span.sw-Caption_Image
        img(src="/assets/img/common/img_290_180.jpg" alt="")
      figcaption.sw-Caption_Text Size Caption Text
```

<span class="sw-Label">IMG-9A</span>

```jade
.l-Images.l-Images-col7to5Md
  .l-Images_Item
    p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronichas created new demand for connectors that can withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditions Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability
  .l-Images_Item
    .l-Images_Image
      img(src="/assets/img/common/image_708_440.jpg" alt="")
```

```jade
.l-Images.l-Images-col8to4Md
  .l-Images_Item
    p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronichas created new demand for connectors that can withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditions Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability
  .l-Images_Item
    .l-Images_Image
      img(src="/assets/img/common/image_708_440.jpg" alt="")
```

<span class="sw-Label">IMG-9B</span>

```jade
.l-Images.l-Images-col9to3Md
  .l-Images_Item
    p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronichas created new demand for connectors that can withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditions Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability
  .l-Images_Item
    .l-Images_Image
      img(src="/assets/img/common/image_708_440.jpg" alt="")
```

```jade
.l-Images.l-Images-col10to2Md
  .l-Images_Item
    p.sw-Text A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronichas created new demand for connectors that can withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditions Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability
  .l-Images_Item
    .l-Images_Image
      img(src="/assets/img/common/image_708_440.jpg" alt="")
```
*/
$l-images-column-gap: rem(10);
$l-images-row-gap: rem(20);
$l-images-column-gap-md: rem(30);
$l-images-row-gap-md: rem(30);

.l-Images {
  -js-display: flex;
  display: flex;
  flex-wrap: wrap;
  margin-top: $l-images-row-gap;
  margin-bottom: $margin-bottom-base;
  margin-left: -$l-images-column-gap;
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    margin-top: $l-images-row-gap-md;
    margin-left: -$l-images-column-gap-md;
  }
}

.l-Images_Item {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: $l-images-column-gap;

  @include mq-up(md) {
    padding-left: $l-images-column-gap-md;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  /* 1 Column */
  &:nth-of-type(n + 2) {
    margin-top: $l-images-row-gap;
  }
}

.l-Images_Image {
  text-align: center;
}

/* 2 Columns */
.l-Images-col2 > .l-Images_Item {
  flex-basis: percentage(1 / 2);
  max-width: percentage(1 / 2);

  /**
   * いったんすべての`margin-top`をリセットします。
   * `:nth-of-type(n+1)`は1つ目以降のすべての要素に適応されます。
   * リセット後に対象となる要素に`margin-top`を指定します。
   */
  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 3) {
    margin-top: $l-images-row-gap;
  }
}

@include mq-up(md) {
  .l-Images-col2Md > .l-Images_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .l-Images-col2Lg > .l-Images_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .l-Images-col2Xl > .l-Images_Item {
    flex-basis: percentage(1 / 2);
    max-width: percentage(1 / 2);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

/* 3 Columns */
.l-Images-col3 > .l-Images_Item {
  flex-basis: percentage(1 / 3);
  max-width: percentage(1 / 3);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 4) {
    margin-top: $l-images-row-gap;
  }
}

@include mq-up(md) {
  .l-Images-col3Md > .l-Images_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .l-Images-col3Lg > .l-Images_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .l-Images-col3Xl > .l-Images_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 4) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

/* 4 Columns */
.l-Images-col4 > .l-Images_Item {
  flex-basis: percentage(1 / 3);
  max-width: percentage(1 / 4);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 5) {
    margin-top: $l-images-row-gap;
  }
}

@include mq-up(md) {
  .l-Images-col4Md > .l-Images_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .l-Images-col4Lg > .l-Images_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .l-Images-col4Xl > .l-Images_Item {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 4);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 5) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

/* 5 Columns */
.l-Images-col5 > .l-Images_Item {
  flex-basis: percentage(1 / 5);
  max-width: percentage(1 / 5);

  &:nth-of-type(n + 1) {
    margin-top: 0;
  }

  &:nth-of-type(n + 6) {
    margin-top: $l-images-row-gap;
  }
}

@include mq-up(md) {
  .l-Images-col5Md > .l-Images_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

@include mq-up(lg) {
  .l-Images-col5Lg > .l-Images_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $l-images-row-gap-md;
    }
  }
}

@include mq-up(xl) {
  .l-Images-col5Xl > .l-Images_Item {
    flex-basis: percentage(1 / 5);
    max-width: percentage(1 / 5);

    &:nth-of-type(n + 1) {
      margin-top: 0;
    }

    &:nth-of-type(n + 6) {
      margin-top: $l-images-row-gap-md;
    }
  }
}
