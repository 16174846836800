.home-NewsList {
  padding-left: 0;
  list-style-type: none;
}

.home-NewsList_Item {
  @include mq-up(md) {
    &:first-of-type::before {
      display: none;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 100vw;
    height: 1px;
    margin-right: calc(-50vw + 50%);
    margin-left: calc(-50vw + 50%);
    border-bottom: 1px solid #e9e9e9;

    @include mq-up(md) {
      width: 100%;
      margin: 0;
    }
  }
}

.home-NewsList_Link {
  display: block;
  position: relative;
  padding-top: rem(20);
  padding-bottom: rem(20);
  text-decoration: none;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    padding-right: rem(30);

    .home-NewsList_Item:first-of-type & {
      padding-top: 0;
    }
  }

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.home-NewsList_Title {
  margin-bottom: rem(10);
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.3;
  color: #000;

  @include mq-up(md) {
    margin-bottom: rem(6);
    font-size: rem(20);
  }
}

.home-NewsList_PostDate {
  margin-bottom: rem(5);
  font-size: rem(14);
  font-weight: 600;
  line-height: 1.114285714;
  color: #df0523;

  @include mq-up(md) {
    display: inline-block;
    position: relative;
    margin-right: rem(24);

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: rem(-12);
      bottom: 0;
      width: 1px;
      height: 100%;
      border-right: 1px solid #dadada;
    }
  }
}

.home-NewsList_Categories {
  padding-left: 0;
  list-style-type: none;

  @include mq-up(md) {
    display: inline-block;
  }
}

.home-NewsList_Category {
  display: inline-block;
  font-size: rem(12);
  font-weight: 600;
  line-height: 1.3;
  color: #444;

  &:nth-of-type(n + 2)::before {
    content: "/";
    margin-right: rem(4);
    font-weight: 300;
  }
}

.home-NewsList_Icon {
  position: absolute;
  right: rem(5);
  bottom: rem(20);
  height: rem(14);
  font-size: rem(14);

  @include mq-up(md) {
    top: 0;
    right: rem(4);
    bottom: 0;
    height: rem(16);
    margin: auto;
    font-size: rem(16);
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.3em;
    color: #df0523;

    @include mq-up(md) {
      position: relative;
      top: -0.3em;
    }
  }
}
