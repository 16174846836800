/*
---
name: sw-ListOrderB
namespace: sw-
category: List
id: LIST-2B
---

<span class="sw-Label">LIST-2B</span>

契約内容の確認リストです。
https://europe.kyocera.com/index/products/lcds_glass_glass_touch_panels/general_terms_and.html

```jade
ol.sw-ListOrderB
  li.sw-ListOrderB_Item
    span.sw-ListOrderB_Number 1.1
    span.sw-ListOrderB_Text Our offers are non-binding. Technical data in our general product information is indicative only and not binding, unless expressly confirmed by us.
  li.sw-ListOrderB_Item
    span.sw-ListOrderB_Number 1.2
    span.sw-ListOrderB_Text The prices in our quotations or order acknowledgements are net prices excluding Value Added Tax. The prices for custom-made products are based on the specifications and quantities agreed with the customer. In case of subsequent requests for modification, we are entitled to alter the price according to the additional costs. We will mutually discuss adjustments based on changes in foreign exchange rates, increases in material costs, inflation, increases in labor or other production and supply costs, or any other event affecting the price or availability of the Products.
  li.sw-ListOrderB_Item
    span.sw-ListOrderB_Number 1.3
    span.sw-ListOrderB_Text Orders placed by the customer shall only be deemed accepted when confirmed by a written order acknowledgement. Any modifications of orders must be confirmed by us in writing.
  li.sw-ListOrderB_Item
    span.sw-ListOrderB_Number 2
    span.sw-ListOrderB_Text Delivery dates are only binding when expressly confirmed by us in writing.
  li.sw-ListOrderB_Item
    span.sw-ListOrderB_Number 2.1
    span.sw-ListOrderB_Text The delivery is subject to the correct and punctual supply to us. Deviations from the quantity ordered by the customer within the common industrial range and partial deliveries shall be accepted if this is reasonable for the customer. We shall be entitled to assign the rights and obligations under the present Contract to a third party,including the execution of its Performance.
      br
      br
      | Where Performance is to be executed pursuant to a request by the Customer, the Customer shall be obliged to make that request to us in writing, giving an exact description of the Performance and the date of Performance, not less than four weeks prior to the execution of Performance.
```
*/
.sw-ListOrderB {
  display: block;
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  padding-left: 0;
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.6;
  color: $color-gray-minus2;
  list-style-type: none;

  & > :last-child {
    margin-bottom: 0;
  }
}

.sw-ListOrderB_Item {
  display: flex;
  margin-bottom: rem(20);
}

.sw-ListOrderB_Number {
  width: rem(33);
}

.sw-ListOrderB_Text {
  flex-basis: 0;
  flex-grow: 1;
}
