/*
---
name: st-TitleA
namespace: sw-
category: Heading
id: CT-1A
---

<span class="sw-Label">CT-1A</span>

カテゴリトップページやNewsカテゴリで使用するタイトルです。

- ページの構造によって`.st-TitleA`は`h1`と`p`を使い分けてください
- テキストだけのシンプルなコンポーネントです


```jade
.st-ContentsHeader_Title
  h1.st-TitleA Title
```
*/
.st-TitleA {
  position: relative;
  padding: rem(15) 0;
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.2;
  color: $color-black;

  @include mq-up(md) {
    font-size: rem(22);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: rem(-6);
    left: 0;
    width: 100vw;
    height: rem(6);
    margin-right: calc(-50vw + 50%);
    margin-left: calc(-50vw + 50%);
    background-image: linear-gradient(
      0deg,
      rgba(102, 102, 102, 0) 0%,
      rgba(102, 102, 102, 0.15) 98%
    );
  }
}
