/*
---
name: st-GlobalHeader1
namespace: st-
category: Global
id: GH1
---

```jade
header.st-GlobalHeader
  .st-GlobalHeader_Inner
    .st-GlobalHeader_CloseButtonArea
      // Close Button
      button.st-GlobalNav_NavClose(type="button" aria-label="Close")
        span.st-GlobalNav_NavCloseIcon(aria-hidden="true")
      button.st-GlobalNav_SearchClose(type="button" aria-label="Close")
        span.st-GlobalNav_SearchCloseIcon(aria-hidden="true")
      // / Close Button

    .st-GlobalHeader_Logo
      .st-Logo
        a.st-Logo_Main(href="/")
          picture
            <!--[if IE 9]><video style="display: none;"><![endif]-->
            source(media="(min-width: 768px)" srcset="/_assets/img/common/logo.svg")
            <!--[if IE 9]></video><![endif]-->
            img(src="/_assets/img/common/logo_statement.svg" alt="Kyocera")
        p.st-Logo_Sub Europe

    .st-GlobalHeader_Buttons
      // Search Button
      button.st-SearchButton#st-SearchButton(type="button" aria-controls="st-SearchForm" aria-expanded="false")
        span.st-SearchButton_Icon(aria-hidden="true" aria-label="Search")
        //- | Search
      // / Search Button

      // Toggle Button
      button.st-ToggleButton(type="button" aria-controls="st-GlobalNav_Area" aria-expanded="false")
        span.st-ToggleButton_Icon(aria-hidden="true" aria-label="Navigation Open")
      // / Toggle Button

      // Global Link
      a.st-GlobalLink(href="https://global.kyocera.com/")
        span.st-GlobalLink_Icon(aria-hidden="true")
        | Global
      // / Global Link

    // Navigation Wrap Area
    nav.st-GlobalNav.st-GlobalNav-categoryFirst#st-GlobalNav

      // Search Form Area
      .st-SearchForm#st-SearchForm(aria-hidden="true")
        form.st-SearchForm_Form#SS_searchForm(action='https://search.kyocera.co.jp/search', method='get', name='gs', enctype="application/x-www-form-urlencoded")
          input(type='hidden', name='site', value='ZHS4H5NP')
          input(type='hidden', name='charset', value='UTF-8')
          input(type='hidden', name='group', value="19", id='SS_group')
          input(type='hidden', name='design', value="98")
          .st-SearchForm_Field
            // Search Icon
            .st-SearchForm_Icon(aria-hidden="true")
            input.st-SearchForm_Input#SS_searchQuery(name='query' type='text' placeholder="Search" size='44' value='' maxlength='100' autocomplete='off')
            button.st-SearchForm_Submit#SS_searchSubmit(type="submit" tabindex="-1") Search
      // / Search Form Area

      .st-GlobalNav_Area#st-GlobalNav_Area
              // Main Navigation
              .st-GlobalSiteNav
                ul.st-GlobalSiteNav_List

                  li.st-GlobalSiteNav_Item
                    a.st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-company(href="/company/") About
                      span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")
                    .st-GlobalSiteNav_SubArea
                      a.st-GlobalSiteNav_SubLinkTop(href="/company/")
                        .st-GlobalSiteNav_SubLinkTopInner
                          | About Top
                          span.st-GlobalSiteNav_SubLinkTopIcon(aria-hidden="true")
                      .st-GlobalSiteNav_SubAreaInner
                        ul.st-GlobalSiteNav_SubList
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="/company/asiapacific/") About Kyocera EU
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://global.kyocera.com/company/summary/index.html") Europe Network
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://global.kyocera.com/company/division/index.html") About the KYOCERA Group
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://global.kyocera.com/company/movie/index.html") Business Segments
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://global.kyocera.com/ecology/index.html") KYOCERA Corporate Profile Video
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://global.kyocera.com/company/location/index.html") Corporate Social Responsibility
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://global.kyocera.com/company/location/index.html") Global Network

                  li.st-GlobalSiteNav_Item

                    a.st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-products(href="/products/") Products
                      span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")
                    .st-GlobalSiteNav_SubArea
                      a.st-GlobalSiteNav_SubLinkTop(href="/products/")
                        .st-GlobalSiteNav_SubLinkTopInner
                          | Products Top
                          span.st-GlobalSiteNav_SubLinkTopIcon(aria-hidden="true")
                      .st-GlobalSiteNav_SubAreaInner
                        .l-Grid
                          .l-Grid_Item.l-Grid_Item-9of12Lg
                            p.st-GlobalSiteNav_SubLinkTitle Business to Business
                            ul.st-GlobalSiteNav_SubList
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="http://www.kyoceradocumentsolutions.eu/" target="_blank") Printers / Multifunctionals
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/fineceramic_components/") Fine Ceramic Components
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/semiconductor_components/") Semiconductor Components
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/automotive_components/") Automotive Components
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/cutting_tools/") Cutting Tools
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/electronic_components/") Electronic Components &amp; Devices
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/printing_devices/") Printing Devices
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/lcds_glass_glass_touch_panels/") LCDs and Touch Solutions
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/organic_materials/") Organic Materials
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/solar_electric_systems/") Solar Electric Systems
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/watch-_and_jewelry/") Watch and Jewelry Industry

                          .l-Grid_Item.l-Grid_Item-3of12Lg
                            p.st-GlobalSiteNav_SubLinkTitle Consumer
                            ul.st-GlobalSiteNav_SubList
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/kitchen_products/") Kitchen Products
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/solar_electric_systems/") Solar Electric Systems
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="/products/office_products/") Office Products

                  li.st-GlobalSiteNav_Item
                    a.st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-news(href="/news/") News
                      span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")
                    .st-GlobalSiteNav_SubArea
                      a.st-GlobalSiteNav_SubLinkTop(href="/news/")
                        .st-GlobalSiteNav_SubLinkTopInner
                          | News Top
                          span.st-GlobalSiteNav_SubLinkTopIcon(aria-hidden="true")
                      .st-GlobalSiteNav_SubAreaInner
                        ul.st-GlobalSiteNav_SubList
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="/news/2019/") 2019
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="/news/2018/") 2018

                  li.st-GlobalSiteNav_Item
                    a.st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-career(href="/careers/") Careers
                      span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")
                    .st-GlobalSiteNav_SubArea
                      a.st-GlobalSiteNav_SubLinkTop(href="/careers/")
                        .st-GlobalSiteNav_SubLinkTopInner
                          | Careers Top
                          span.st-GlobalSiteNav_SubLinkTopIcon(aria-hidden="true")
                      .st-GlobalSiteNav_SubAreaInner
                        ul.st-GlobalSiteNav_SubList
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://usa.kyoceradocumentsolutions.com/en/about-us/careers.html" target="_blank") KYOCERA Document Solutions America, Inc.
                              span.st-GlobalSiteNav_SubLinkIconInternal(aria-hidden="true")
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="/careers/kii/") KYOCERA International, Inc.
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://recruiting2.ultipro.com/KYO1000KYO/JobBoard/76b8c73c-befd-f875-1815-e0b7a1f6e65f/?q=&o=postedDateDesc" target="_blank") KYOCERA Precision Tools, Inc.
                              span.st-GlobalSiteNav_SubLinkIconInternal(aria-hidden="true")
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="https://www.kyocera-sgstool.com/about/careers" target="_blank") KYOCERA SENCO Industrial Tools, Inc.
                              span.st-GlobalSiteNav_SubLinkIconInternal(aria-hidden="true")
                          li.st-GlobalSiteNav_SubItem
                            a.st-GlobalSiteNav_SubLink(href="/news/archive/") KYOCERA SGS Precision Tools, Inc.
                              span.st-GlobalSiteNav_SubLinkIconInternal(aria-hidden="true")

                  li.st-GlobalSiteNav_Item
                    a.st-GlobalSiteNav_Link.st-GlobalSiteNav_Link-inquiry(href="/contact/") Contact
                      span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")
                    .st-GlobalSiteNav_SubArea
                      a.st-GlobalSiteNav_SubLinkTop(href="/contact/")
                        .st-GlobalSiteNav_SubLinkTopInner
                          | Contact Top
                          span.st-GlobalSiteNav_SubLinkTopIcon(aria-hidden="true")
                      .st-GlobalSiteNav_SubAreaInner

                        .l-Grid
                          .l-Grid_Item.l-Grid_Item-9of12Lg
                            p.st-GlobalSiteNav_SubLinkTitle Business to Business
                            ul.st-GlobalSiteNav_SubList
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Printers / Multifunctionals
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Fine Ceramic Components
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Semiconductor Components
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Automotive Components
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Cutting Tools
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Electronic Components &amp; Devices
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Printing Devices
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") LCDs and Touch Solutions
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Organic Materials
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Solar Electric Systems
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Watch and Jewelry Industry

                          .l-Grid_Item.l-Grid_Item-3of12Lg
                            ap.st-GlobalSiteNav_SubLinkTitle Consumer
                            ul.st-GlobalSiteNav_SubList
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Kitchen Products
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Solar Electric Systems
                              li.st-GlobalSiteNav_SubItem
                                a.st-GlobalSiteNav_SubLink(href="#") Office Products

                a.st-GlobalSiteNav_GlobalLink(href="https://global.kyocera.com/")
                  span.st-GlobalSiteNav_GlobalLinkIcon(aria-hidden="true")
                  | Global

              // / Main Navigation

    // / Navigation Wrap Area

    // statement
    .st-GlobalHeader_StatementArea
      .st-GlobalHeader_Statement
        img.st-GlobalHeader_StatementImage(src="/_assets/img/common/statement.svg" alt="THE NEW VALUE FRONTIER")
    // / statement
```
*/

/*
---
name: st-GlobalHeader2
namespace: st-
category: Global
id: GH2
---

```jade
//- グローバルヘッダー
header.st-GlobalHeader
  .st-GlobalHeader_Inner
    .st-GlobalHeader_CloseButtonArea
      // Close Button
      button.st-DropNav_Close#st-DropNav_Close(type="button" aria-label="Close")
        span.st-DropNav_CloseIcon(aria-hidden="true")
      button.st-GlobalNav_SearchClose(type="button" aria-label="Close")
        span.st-GlobalNav_SearchCloseIcon(aria-hidden="true")
      // / Close Button

    .st-GlobalHeader_Logo
      .st-Logo
        a.st-Logo_Main(href="/")
          picture
            <!--[if IE 9]><video style="display: none;"><![endif]-->
            source(media="(min-width: 768px)" srcset="/_assets/img/common/logo.svg")
            <!--[if IE 9]></video><![endif]-->
            img(src="/_assets/img/common/logo_statement.svg" alt="Kyocera")
        p.st-Logo_Sub Europe

    .st-GlobalHeader_Buttons.st-GlobalHeader_Buttons-devision

      // Gnav Button1
      .st-DropNav_PcButtonArea
        button.st-DropNav_PcButton#st-DropNav_PcButton1(aria-expanded="false" aria-controls="st-DropNav1")
          span.st-DropNav_PcButtonIcon(aria-hidden="true" aria-label="All Products")
          | All Products
      // / Gnav Button1

      // Gnav Button2
      .st-DropNav_PcButtonArea.st-DropNav_PcButton-devider
        button.st-DropNav_PcButton#st-DropNav_PcButton2(aria-expanded="false" aria-controls="st-DropNav2")
          span.st-DropNav_PcButtonIcon(aria-hidden="true" aria-label="All Products")
          | About Kyocera
      // / Gnav Button2

      // Search Button
      button.st-SearchButton#st-SearchButton(type="button" aria-controls="st-SearchForm" aria-expanded="false")
        span.st-SearchButton_Icon(aria-hidden="true" aria-label="Search")
      // / Search Button

      // Toggle Button
      button.st-ToggleButton(type="button" aria-controls="st-GlobalNav_Area" aria-expanded="false")
        span.st-ToggleButton_Icon(aria-hidden="true" aria-label="Navigation Open")
      // / Toggle Button

      // Global Link
      .st-GlobalRegionPc
        button.st-GlobalRegionPc_Toggle.js-documentClose#st-GlobalRegionPc_Toggle(type="button" aria-controls="st-GlobalRegionPc_SubArea" aria-expanded="false")
          span.st-GlobalRegionPc_Region
            img(src="/assets/img/common/region_eu.svg" alt)
          | EU
      // / Global Link


    // Navigation Wrap Area
    nav.st-GlobalNav.st-GlobalNav-divition#st-GlobalNav

      // Search Form Area
      .st-SearchForm#st-SearchForm(aria-hidden="true")
        form.st-SearchForm_Form#SS_searchForm(action='https://search.kyocera.co.jp/search', method='get', name='gs', enctype="application/x-www-form-urlencoded")
          input(type='hidden', name='site', value='ZHS4H5NP')
          input(type='hidden', name='charset', value='UTF-8')
          input(type='hidden', name='group', value="19", id='SS_group')
          input(type='hidden', name='design', value="98")
          .st-SearchForm_Field
            // Search Icon
            .st-SearchForm_Icon(aria-hidden="true")
            input.st-SearchForm_Input#SS_searchQuery(name='query' type='text' placeholder="Search" size='44' value='' maxlength='100' autocomplete='off')
            button.st-SearchForm_Submit#SS_searchSubmit(type="submit" tabindex="-1") Search
      // / Search Form Area

      .st-GlobalRegionPc_SubArea.js-documentClose#st-GlobalRegionPc_SubArea(aria-hidden="true" aria-labelledby="st-GlobalRegionPc_Toggle")
        .st-GlobalSiteNav_SubLinkTop
          .st-GlobalSiteNav_SubLinkTopInner
            | Select Your Region
        .st-GlobalSiteNav_SubAreaInner
          a.st-GlobalSiteNav_SubLinkTitle(href="https://global.kyocera.com/") Kyocera Global
            span.st-GlobalSiteNav_SubLinkTitleIcon(aria-hidden="true")
          a.st-GlobalSiteNav_SubLinkTitle(href="/") Europe
            span.st-GlobalSiteNav_SubLinkTitleIcon(aria-hidden="true")
          ul.st-GlobalRegionPc_RegionList
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://austria.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_austria.svg" alt)
                span.st-GlobalRegionPc_RegionText Austria
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://denmark.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_denmark.svg" alt)
                span.st-GlobalRegionPc_RegionText Denmark
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://finland.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_finland.svg" alt)
                span.st-GlobalRegionPc_RegionText Finland
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://france.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_france.svg" alt)
                span.st-GlobalRegionPc_RegionText France
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://germany.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_germany.svg" alt)
                span.st-GlobalRegionPc_RegionText Germany
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://italy.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_italy.svg" alt)
                span.st-GlobalRegionPc_RegionText Italy
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://netherlands.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_netherlands.svg" alt)
                span.st-GlobalRegionPc_RegionText Netherlands
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://norway.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_norway.svg" alt)
                span.st-GlobalRegionPc_RegionText Norway
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://portugal.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_portugal.svg" alt)
                span.st-GlobalRegionPc_RegionText Portugal
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://russia.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_russia.svg" alt)
                span.st-GlobalRegionPc_RegionText Russia
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://spain.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_spain.svg" alt)
                span.st-GlobalRegionPc_RegionText Spain
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://sweden.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_sweden.svg" alt)
                span.st-GlobalRegionPc_RegionText Sweden
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://switzerland.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_switzerland.svg" alt)
                span.st-GlobalRegionPc_RegionText Switzerland
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://southafrica.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_south-africa.svg" alt)
                span.st-GlobalRegionPc_RegionText South Africa
            li.st-GlobalRegionPc_RegionItem
              a.st-GlobalRegionPc_RegionLink(href="https://uk.kyocera.com/")
                span.st-GlobalRegionPc_RegionImage
                  img(src="/assets/img/common/region_united-kingdom.svg" alt)
                span.st-GlobalRegionPc_RegionText United Kingdom


      .st-GlobalNav_Area.js-documentClose#st-GlobalNav_Area
        // Main Navigation
        .st-GlobalSiteNav
          ul.st-GlobalSiteNav_List
            li.st-GlobalSiteNav_Item
              a.st-GlobalSiteNav_DivisionTitle(href="#") Dummy

            li.st-GlobalSiteNav_Item
              a.st-GlobalSiteNav_Link(href="/") Top
                span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")

            li.st-GlobalSiteNav_Item
              a.st-GlobalSiteNav_Link(href="/products/") Products
                span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")
              .st-GlobalSiteNav_SubArea
                ul.st-DrawerNav.js-documentClose
                  li.st-DrawerNav_Item
                    //- idは`st-DrawerNav_Trigger-` + ディレクトリ名
                    a.st-DrawerNav_Trigger.js-documentClose#st-DrawerNav_Trigger-aaa(href="/products/xxx/prd/aaa/" aria-expanded="false" aria-controls="st-DrawerNav_Drawer-aaa")
                      | Child A
                      span.st-DrawerNav_TriggerExternalA(aria-hidden="true")
                  li.st-DrawerNav_Item
                    a.st-DrawerNav_Trigger.js-documentClose#st-DrawerNav_Trigger-bbb(href="/products/xxx/prd/bbb/" aria-expanded="false" aria-controls="st-DrawerNav_Drawer-bbb")
                      | Child B
                      span.st-DrawerNav_TriggerExternalB(aria-hidden="true")
                  li.st-DrawerNav_Item
                    a.st-DrawerNav_Trigger.js-documentClose#st-DrawerNav_Trigger-ccc(href="/products/xxx/prd/ccc/" aria-expanded="false" aria-controls="st-DrawerNav_Drawer-ccc")
                      | Child C

            li.st-GlobalSiteNav_Item
              a.st-GlobalSiteNav_Link(href="/products/xxx/news/") News
                span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")
            li.st-GlobalSiteNav_Item
              a.st-GlobalSiteNav_Link(href="/products/xxx/contact/") Sales Office
                span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")
            li.st-GlobalSiteNav_Item
              a.st-GlobalSiteNav_Link(href="/products/xxx/events/") Events
                span.st-GlobalSiteNav_LinkIcon(aria-hidden="true")

        // / Main Navigation

        // Sub Navigation1
        .st-DropNav#st-DropNav1(aria-hidden="true" aria-labelledby="st-DropNav_PcButton1")
          button.st-DropNav_SpButton#st-DropNav_SpButton1(aria-expanded="false" aria-controls="st-DropNav_List1")
            .st-DropNav_SpButtonInner
              | All Products
              span.st-DropNav_SpButtonIcon(aria-hidden="true")
          a.st-DropNav_SubLinkTop(href="/products/")
            .st-DropNav_SubLinkTopInner
              | Products Top
              span.st-DropNav_SubLinkTopIcon(aria-hidden="true")
          .st-DropNav_Inner
            .st-DropNav_List#st-DropNav_List1(aria-hidden="true" aria-labelledby="st-DropNav_SpButton1")
              .l-Grid
                .l-Grid_Item.l-Grid_Item-9of12Lg
                  p.st-DropNav_SubLinkTitle Business to Business
                  ul.st-DropNav_SubList
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="https://www.kyoceradocumentsolutions.eu/") Printers / Multifunctionals
                        span.st-DropNav_SubLinkExternalA(aria-hidden="true")
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/fineceramic_components/") Fine Ceramic Components
                        span.st-DropNav_SubLinkExternalB(aria-hidden="true")
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/semiconductor_components/") Semiconductor Components
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/automotive_components/") Automotive Components
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/industrial_tools/") Industrial Tools
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/electronic_components/") Electronic Components &amp; Devices
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/printing_devices/") Printing Devices
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/lcds_glass_glass_touch_panels/") LCDs and Touch Solutions
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/organic_materials/") Organic Materials
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/solar_electric_systems/") Solar Electric Systems
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/watch_and_jewelry/") Watch and Jewelry Industry

                .l-Grid_Item.l-Grid_Item-3of12Lg
                  p.st-GlobalSiteNav_SubLinkTitle Consumer
                  ul.st-DropNav_SubList
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/kitchen_products/") Kitchen Products
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/solar_electric_systems/") Solar Electric Systems
                    li.st-DropNav_SubItem
                      a.st-DropNav_SubLink(href="/products/office_products/") Office Products
        // / Sub Navigation1


        // Sub Navigation2
        .st-DropNav#st-DropNav2(aria-hidden="true" aria-labelledby="st-DropNav_PcButton2")
          button.st-DropNav_SpButton#st-DropNav_SpButton1(aria-expanded="false" aria-controls="st-DropNav_List2")
            .st-DropNav_SpButtonInner
              | About Kyocera
              span.st-DropNav_SpButtonIcon(aria-hidden="true")
          .st-DropNav_Inner
            .st-DropNav_List#st-DropNav_List2(aria-hidden="true" aria-labelledby="st-DropNav_SpButton2")
              ul.st-DropNav_SubList
                li.st-DropNav_SubItem
                  a.st-DropNav_SubLink(href="/products/") Products
                li.st-DropNav_SubItem
                  a.st-DropNav_SubLink(href="/company/") About
                li.st-DropNav_SubItem
                  a.st-DropNav_SubLink(href="/news/") News
                li.st-DropNav_SubItem
                  a.st-DropNav_SubLink(href="/contact/") Contact
        // / Sub Navigation2

        .st-GlobalRegionSp
          button.st-GlobalRegionSp_Toggle.js-documentClose#st-GlobalRegionSp_Toggle(type="button" aria-controls="st-GlobalRegionSp_SubArea" aria-expanded="false")
            span.st-GlobalRegionSp_Region
              img(src="/assets/img/common/region_eu.svg" alt)
            span.st-GlobalRegionSp_Text EU
            span.GloablRegion_Icon(aria-hidden="true")
          .st-GlobalRegionSp_SubArea.js-documentClose#st-GlobalRegionSp_SubArea(aria-hidden="true" aria-labelledby="st-GlobalRegionSp_Toggle")
            a.st-GlobalRegionSp_Heading(href="https://global.kyocera.com/")
              | Kyocera Global
              span.st-GlobalRegionSp_HeadingIcon(aria-hidden="true")
            a.st-GlobalRegionSp_Heading(href="/")
              | Europe
              span.st-GlobalRegionSp_HeadingIcon(aria-hidden="true")
            ul.st-GlobalRegionSp_RegionList
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://austria.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_austria.svg" alt)
                  span.st-GlobalRegionSp_RegionText Austria
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://denmark.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_denmark.svg" alt)
                  span.st-GlobalRegionSp_RegionText Denmark
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://finland.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_finland.svg" alt)
                  span.st-GlobalRegionSp_RegionText Finland
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://france.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_france.svg" alt)
                  span.st-GlobalRegionSp_RegionText France
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://germany.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_germany.svg" alt)
                  span.st-GlobalRegionSp_RegionText Germany
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://italy.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_italy.svg" alt)
                  span.st-GlobalRegionSp_RegionText Italy
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://netherlands.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_netherlands.svg" alt)
                  span.st-GlobalRegionSp_RegionText Netherlands
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://norway.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_norway.svg" alt)
                  span.st-GlobalRegionSp_RegionText Norway
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://portugal.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_portugal.svg" alt)
                  span.st-GlobalRegionSp_RegionText Portugal
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://russia.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_russia.svg" alt)
                  span.st-GlobalRegionSp_RegionText Russia
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://spain.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_spain.svg" alt)
                  span.st-GlobalRegionSp_RegionText Spain
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://sweden.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_sweden.svg" alt)
                  span.st-GlobalRegionSp_RegionText Sweden
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://switzerland.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_switzerland.svg" alt)
                  span.st-GlobalRegionSp_RegionText Switzerland
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://southafrica.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_south-africa.svg" alt)
                  span.st-GlobalRegionSp_RegionText South Africa
              li.st-GlobalRegionSp_RegionItem
                a.st-GlobalRegionSp_RegionLink(href="https://uk.kyocera.com/")
                  span.st-GlobalRegionSp_RegionImage
                    img(src="/assets/img/common/region_united-kingdom.svg" alt)
                  span.st-GlobalRegionSp_RegionText United Kingdom


        .st-DrawerNav_Drawer.js-documentClose#st-DrawerNav_Drawer-bbb(aria-hidden="true" aria-labelledby="st-DrawerNav_Trigger-bbb")
          button.st-DrawerNav_DrawerBack(type="button" aria-label="Back")
            span.st-DrawerNav_DrawerBackIcon(aria-hidden="true")
          a.st-DrawerNav_DrawerHeading(href="/products/xxx/bbb/") Child B
          ul.st-DrawerNav_DrawerList.js-documentClose
            li.st-DrawerNav_DrawerItem
              a.st-DrawerNav_DrawerLink(href="/products/xxx/prd/bbb/000.html")
                span.st-DrawerNav_DrawerIcon(aria-hidden="true")
                | Grandchild 0
                span.st-DrawerNav_DrawerIconExternalA(aria-hidden="true")
            li.st-DrawerNav_DrawerItem
              a.st-DrawerNav_DrawerLink(href="/products/xxx/prd/bbb/111.html")
                span.st-DrawerNav_DrawerIcon(aria-hidden="true")
                | Grandchild 1
                span.st-DrawerNav_DrawerIconExternalB(aria-hidden="true")
            li.st-DrawerNav_DrawerItem
              a.st-DrawerNav_DrawerLink(href="/products/xxx/prd/bbb/222.html")
                span.st-DrawerNav_DrawerIcon(aria-hidden="true")
                | Grandchild 2

    // / Navigation Wrap Area

    // statement
    .st-GlobalHeader_StatementArea
      .st-GlobalHeader_Statement
        img.st-GlobalHeader_StatementImage(src="/_assets/img/common/statement.svg" alt="THE NEW VALUE FRONTIER")


    // / statement
```
*/
