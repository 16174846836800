/*
---
name: sw-SearchBoxA
namespace: sw-
category: Form
id: [FO-1A, FO-1B]
---

<span class="sw-Label">FO-1A</span>

コンテンツ内に設置する検索ボックスです。
パラメータによって検索条件を変更することができます。


```jade
.sw-SearchBoxA
  p.sw-SearchBoxA_Text Kyocera is active worldwide in a wide range of sectors.Kyocera uses highly sophisticated materials to produce extremely reliable products both for the major global industries as well as its end consumers.
  form.sw-SearchBoxA_Form#SS_searchForm(action="https://search.kyocera.co.jp/search" method="get" name="gs" enctype="application/x-www-form-urlencoded")
    input(type="hidden" name="site" value="ZHS4H5NP")
    input(type="hidden" name="charset" value="UTF-8")
    input(type="hidden" name="group" value="19" id="SS_group")
    input(type="hidden" name="design" value="58")
    .sw-SearchBoxA_Search
      span.sw-SearchBoxA_SearchIcon(aria-hidden="true")
      input.sw-SearchBoxA_SearchInput#SS_searchQuery(name="query" type="text" placeholder="Product name, Keywords" size="44" value="" maxlength="100" autocomplete="off")
      button.sw-SearchBoxA_SearchSubmit#SS_searchSubmit(type="submit" tabindex="-1") Search
```
*/
.sw-SearchBoxA {
  margin-bottom: rem(30);
}

.sw-SearchBoxA_Text {
  margin-bottom: rem(30);
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.5;
}

.sw-SearchBoxA_Form {
  display: block;
}

.sw-SearchBoxA_Search {
  position: relative;
  max-width: rem(370);
  margin-right: 0;
  margin-left: auto;
}

.sw-SearchBoxA_SearchIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: rem(12);
  height: rem(24);
  margin: auto;
  font-size: rem(24);
  color: $color-gray-minus2;

  &::before {
    @include icon;

    content: "#{$icon-search}";
    position: relative;
    top: -0.4em;
  }
}

.sw-SearchBoxA_SearchInput {
  @include sw-Input;

  padding-left: rem(44);
  font-size: rem(15);
  line-height: 1.8;
  border: 1px solid $color-gray-plus3;
  border-radius: rem(3);
  background: $color-gray-plus4;
  box-shadow: none;
  color: $color-black;
}

.sw-SearchBoxA_SearchSubmit {
  @include sr-only;
}
