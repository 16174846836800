.home-RegionNav {
  display: flex;
  flex-wrap: wrap;
  margin-left: rem(-6);
  padding-left: 0;
  list-style-type: none;
}

.home-RegionNav_Item {
  flex-basis: 100%;
  max-width: 100%;
  padding-left: rem(6);

  @include mq-up(md) {
    flex-basis: percentage(1 / 3);
    max-width: percentage(1 / 3);
  }

  &:nth-of-type(n + 2) {
    margin-top: rem(10);

    @include mq-up(md) {
      margin-top: 0;
    }
  }
}

.home-RegionNav_Link {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  transition-duration: $transition-duration;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(92);
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 98%);

    @include mq-up(md) {
      height: rem(102);
    }
  }

  &:hover {
    text-decoration: none;
    opacity: $opacity;
  }
}

.home-RegionNav_Title {
  position: absolute;
  bottom: rem(10);
  left: rem(15);
  font-size: rem(18);
  font-weight: 600;
  line-height: 1.4;
  color: #fff;

  @include mq-up(md) {
    bottom: rem(15);
    font-size: rem(16);
    line-height: 1.6;
  }
}

.home-RegionNav_Icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: rem(12);
  margin-left: rem(6);
  font-size: rem(12);

  @include mq-up(md) {
    height: rem(13);
    margin-left: rem(4);
    font-size: rem(13);
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    position: relative;
    top: -0.2em;

    @include mq-up(md) {
      top: -0.4em;
    }
  }
}

.home-RegionNav_Thumbnail {
  /* CMSの仕様のためimgにクラス名をつけれない */
  & > img {
  }
}
