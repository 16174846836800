/*
---
name: news-Body
namespace: news-
category: Block
id: NL-2
---

<span class="sw-Label">NL-2</span>

News詳細の本文です。

```jade
.news-Body
  h2.news-Heading2 H2 Title heading
  h3.news-Heading3 H3 Title heading
  h4.news-Heading4 H4 Title heading

  p.news-Summary Kyoto/London – Kyocera today introduced a new clamshell-style branch connector for automotive electronics that features a unique locking structure for waterproof connections, ensuring reliability in harsh conditions. The new Sheltap 9715 Series Waterproof Electronic Branch Connector was shown for the first time at CEATEC JAPAN 2018 (Booth #H009), October 16-19. Samples are now available upon request.

  .news-Box
    dl.news-Definition
      dt About Sheltap<sup class="sw-Sup">®</sup>
      dd Kyocera’s Sheltap series of branch-style electronic connectors are engineered for automotive use. The clamshell design allows Sheltap connectors to branch and distribute electricity easily and with long-term reliability, even in harsh environments. Sheltap is registered trademarks of Kyocera in Japan, U.S.A., China, and Europe

  .news-Box.l-Grid
    .l-Grid_Item.l-Grid_Item-2of12Md
        img(src="/assets/img/common/image_600_400.png")
    .l-Grid_Item.l-Grid_Item-10of12Md
        dl.news-Definition
          dt About Sheltap<sup class="sw-Sup">®</sup>
          dd Kyocera’s Sheltap series of branch-style electronic connectors are engineered for automotive use. The clamshell design allows Sheltap connectors to branch and distribute electricity easily and with long-term reliability, even in harsh environments. Sheltap is registered trademarks of Kyocera in Japan, U.S.A., China, and Europe
```
*/
.news-Body {
  margin: 0 0 rem(60);
  color: $color-gray-minus2;
}

// Link
.news-Body a {
  text-decoration: underline;
  color: $color-red;

  @include mq-up(md) {
    &:hover {
      text-decoration: none;
    }
  }
}

// Box
.news-Body .news-Box {
  margin-bottom: rem(60);

  &::before,
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

// paragraph
.news-Body .news-Box p:not(:last-child) {
  margin-bottom: rem(20);
}
// CMS用
.news-Body p:not(:last-child) {
  margin-bottom: rem(20);
}

// Heading
.news-Body [class*="news-Heading"] {
  + [class*="news-Heading"] {
    margin-top: rem(30);
  }
}

.news-Body .news-Heading2 {
  margin-bottom: rem(18);
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.3;
  color: $color-black;

  @include mq-up(md) {
    margin-bottom: rem(20);
  }
}

.news-Body .news-Heading3 {
  margin-bottom: rem(18);
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.3;
  color: $color-red;

  @include mq-up(md) {
    margin-bottom: rem(15);
  }
}

.news-Body .news-Heading4 {
  margin-bottom: rem(18);
  font-family: 600;
  font-size: rem(16);
  line-height: 1.375;
  color: $color-gray-minus2;

  @include mq-up(md) {
    margin-bottom: rem(15);
  }
}

.news-Summary {
  margin-bottom: rem(60);
  font-size: rem(16);
  line-height: 1.6;
  color: $color-black;
}

// List
.news-Definition {
  color: #444;
}

.news-Definition dt {
  font-size: rem(16);
  font-weight: 700;
  line-height: 1.3;
}

.news-Definition dd {
  margin-top: rem(5);
  font-size: rem(15);
  line-height: 1.4;
}

// table
// sw-TableAと同じスタイル
.news-Body .news-TableA {
  width: 100%;
  max-width: 100%;
  margin-bottom: rem(60);
  border-collapse: collapse;
}

.news-Body .news-TableA caption {
  display: block;
  margin-bottom: rem(20);
  font-size: rem(20);
  font-weight: 600;
  line-height: 1.3;
  color: $color-black;
}

.news-Body .news-TableA tr {
  border-bottom: 1px solid $color-gray-plus2;

  &:nth-of-type(odd) {
    background-color: $color-gray-plus4;
  }

  &:nth-of-type(even) {
    background-color: transparent;
  }

  &:nth-of-type(1) {
    border-top: 1px solid $color-gray-plus2;
  }
}

.news-Body .news-TableA th,
.news-Body .news-TableA td {
  vertical-align: top;
  padding: rem(15) rem(20);
  text-align: left;
  font-size: rem(14);
  line-height: 1.3;
  border-width: 0;
  color: $color-gray-minus2;
}

.news-Body .news-TableA th {
  font-weight: 600;
}

// sup
// .sw-Supと同じスタイル
.news-Box sup {
  vertical-align: super;
  position: static;
  font-size: 0.75em;
}
// CMS用
.news-Body sup {
  vertical-align: super;
  position: static;
  font-size: 0.75em;
}
