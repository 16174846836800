/*
---
name: swm-Button
namespace: swm-
category: Button
id: [M_BTN-1, M_BTN-2, M_BTN-2A, M_BTN-2B, M_BTN-4]
---

<span class="sw-Label">M_BTN-1</span>
```jade
div.l-ButtonC
  a.swm-Button(href="#") Button
```
<span class="sw-Label">M_BTN-1-C</span>
```jade
div.l-ButtonC.l-ButtonC-center
  a.l-ButtonC_Item.swm-Button(href="#") Button
```
<span class="sw-Label">M_BTN-1-R</span>
```jade
div.l-ButtonC.l-ButtonC-right
  a.l-ButtonC_Item.swm-Button(href="#") Button
```

<span class="sw-Label">M_BTN-2A</span>
```jade
div.l-ButtonC
  a.swm-Button.swm-Button-small(href="#") Button
```
<span class="sw-Label">M_BTN-2A-C</span>
```jade
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-small(href="#") Button
```
<span class="sw-Label">M_BTN-2A-R</span>
```jade
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-small(href="#") Button
```

<span class="sw-Label">M_BTN-4</span>
```jade
div.l-ButtonC
  a.swm-Button.swm-Button-danger(href="#") Button
```
<span class="sw-Label">M_BTN-4-C</span>
```jade
div.l-ButtonC.l-ButtonC-center
  a.swm-Button.swm-Button-danger(href="#") Button
```
<span class="sw-Label">M_BTN-4-R</span>
```jade
div.l-ButtonC.l-ButtonC-right
  a.swm-Button.swm-Button-danger(href="#") Button
```

*/
.swm-Button {
  @include sw-Button;

  &:not(.swm-Button-disabled):not(:disabled):hover {
    background-color: $color-red;
    color: $color-white;
  }

  &.swm-LinkIconA,
  &.swm-LinkIconB,
  &.swm-LinkIconPdf,
  &.swm-LinkIconArrow {
    padding: rem(12) rem(20) rem(11) rem(24);
  }
}

.swm-Button-small {
  padding: rem(12) rem(15) rem(11);
  font-size: rem(13);
  border-radius: (3 / 13) * 1em;
}

.swm-Button-danger {
  padding: rem(11) rem(15) rem(10);
  font-weight: 600;
  background-color: $color-red;
  color: $color-white;

  &:not(.swm-Button-disabled):not(:disabled):hover {
    border-color: #b80f26;
    background: #b80f26;
  }

  &.swm-Button-disabled {
    border-color: $color-gray-plus1;
    background-color: $color-gray-plus1;
  }
}