.home-Region {
  padding-top: rem(60);
  padding-bottom: rem(40);

  @include mq-up(md) {
    padding-top: rem(90);
    padding-bottom: rem(60);
  }

  @include mq-up(lg) {
    padding-bottom: rem(90);
  }
}

.home-Region_List {
  margin-top: rem(38);
  text-align: center;

  @include mq-up(md) {
    margin-top: rem(20);
  }

  @include mq-up(lg) {
    margin-top: rem(38);
  }
}

.home-Region_Nav {
  margin-top: rem(28);

  @include mq-up(md) {
    margin-top: rem(30);
  }

  @include mq-up(lg) {
    margin-top: rem(20);
  }
}
