/*
---
name: sw-ListAnnotation
namespace: sw-
category: List
id: TXT-10
---

<span class="sw-Label">TXT-10</span>

注釈用のリストです。米印やアスタリスクが表示されます。

```jade
ul.sw-ListAnnotation
  li Japanese Automotive Standards Organization
  li Additional fees may apply
```
*/
.sw-ListAnnotation {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;
  margin-left: rem(13);
  padding-left: 0;
  font-size: rem(14);
  line-height: 1.3;
  color: $color-gray-minus2;
  list-style-type: none;

  & > li {
    position: relative;
    margin-bottom: rem(10);
    font-style: italic;
  }

  & > li::before {
    content: "*";
    position: absolute;
    top: 0;
    left: rem(-12);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
