/*
---
name: l-MediaA
namespace: l-
category: Layout
id: [IMG-5A, IMG-5B, IMG-5C, IMG-6A, IMG-6B, IMG-6C]
---

<span class="sw-Label">IMG-5A</span>

- 回り込みあり
- 画像は常に5/12幅
- 画像は右にフロート
- 画像にキャプションなし

```jade
.l-MediaA.l-MediaA-right
  .l-MediaA_Image
    .sw-Image
      img(src="/assets/img/common/image_708_440.jpg" alt)
  .l-MediaA_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

- 回り込みあり
- 画像は常に5/12幅
- 画像は右にフロート
- 画像にキャプションあり

```jade
.l-MediaA.l-MediaA-right
  .l-MediaA_Image
    figure.sw-Caption
      span.sw-Caption_Image
        img(src="/assets/img/common/image_708_440.jpg" alt="")
      figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
  .l-MediaA_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

- 回り込みあり
- 画像は常に5/12幅
- 画像は右にフロート
- 画像に背景色付きのキャプションあり

```jade
.l-MediaA.l-MediaA-right
  .l-MediaA_Image
    figure.sw-Caption.sw-Caption-Bg
      span.sw-Caption_Image
        img(src="/assets/img/common/image_708_440.jpg" alt="")
      figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
  .l-MediaA_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

<span class="sw-Label">IMG-5B</span>

- 回り込みあり
- 画像はスマホで5/12幅、タブレット以降で3/12幅
- 画像は右にフロート

```jade
.l-MediaA.l-MediaA-right.l-MediaA-small
  .l-MediaA_Image
    .sw-Image
      img(src="/assets/img/common/image_708_440.jpg" alt)
  .l-MediaA_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

<span class="sw-Label">IMG-5C</span>

- タブレット以降で回り込みあり
- 画像はスマホで12/12幅、タブレット以降で5/12幅
- 画像は右にフロート

```jade
.l-MediaA.l-MediaA-right.l-MediaA-stack
  .l-MediaA_Image
    .sw-Image
      img(src="/assets/img/common/image_708_440.jpg" alt)
  .l-MediaA_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

<span class="sw-Label">IMG-6A</span>

- 画像は常に5/12幅
- 画像は左にフロート

```jade
.l-MediaA.l-MediaA-left
  .l-MediaA_Image
    .sw-Image
      img(src="/assets/img/common/image_708_440.jpg" alt)
  .l-MediaA_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

<span class="sw-Label">IMG-6B</span>

- 画像はスマホで5/12幅、タブレット以降で3/12幅
- 画像は左にフロート

```jade
.l-MediaA.l-MediaA-left.l-MediaA-small
  .l-MediaA_Image
    figure.sw-Caption
      span.sw-Caption_Image
        img(src="/assets/img/common/image_708_440.jpg" alt="")
      figcaption.sw-Caption_Text Size comparison to AA batterySize comparison to AA batterySize comparison to AA battery
  .l-MediaA_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```

<span class="sw-Label">IMG-6C</span>

- タブレット以降で回り込みあり
- 画像はスマホで12/12幅、タブレット以降で5/12幅
- 画像は左にフロート

```jade
.l-MediaA.l-MediaA-left.l-MediaA-stack
  .l-MediaA_Image
    .sw-Image
      img(src="/assets/img/common/img_290_180.jpg" alt)
  .l-MediaA_Body
    p A branch connector is used to install electronic equipment, such as a navigation system or dashboard camera, by branching power from the vehicle’s main wiring harness. Since Kyocera introduced its original 9215 series branch connectors in 1992, the products have been lauded for providing reliable connections while streamlining assembly and installation workflow through their unique clamshell structure. In 2014, Kyocera became an early developer of connectors for aluminum wiring to support weight-saving initiatives among automakers. Rapid adoption of advanced driver assistance systems (ADAS) and other automotive electronics withstand harsh environments, including the vehicle exterior and direct exposure to the elements in all weather conditionsKyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality Kyocera designed the Sheltap branch connector series to provide a high level of waterproof functionality and reliability — successfully passing the high-pressure washing and high-temperature exposure testing of the JASO*1 D616 standards, which evaluate wire harness connectors with specially designed enclosures and sealant materials for automotive applications.  Kyocera’s Sheltap series connectors embody the company’s commitment to supporting the rapid advancement of electronic technologies in diverse markets, including automotive, shipping, and outdoor security / camera-monitoring applications.
```
*/
.l-MediaA {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;

  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

.l-MediaA-stack {
  @include mq-down(md) {
    display: flex;
    flex-direction: column;
  }
}

.l-MediaA_Body {
  & > :last-child {
    margin-bottom: 0;
  }
}

.l-MediaA_Image {
  width: percentage(5 / 12);
  padding-bottom: rem(15);
  text-align: center;

  @include mq-up(md) {
    padding-bottom: rem(30);
  }

  .l-MediaA-left & {
    float: left;
    padding-right: rem(15);

    @include mq-up(md) {
      padding-right: rem(30);
    }
  }

  .l-MediaA-right & {
    float: right;
    padding-left: rem(15);

    @include mq-up(md) {
      padding-left: rem(30);
    }
  }

  .l-MediaA-small & {
    width: percentage(5 / 12);

    @include mq-up(md) {
      width: percentage(3 / 12);
    }
  }

  .l-MediaA-stack & {
    @include mq-down(md) {
      width: 100%;
      padding-right: 0;
      padding-bottom: rem(20);
      padding-left: 0;
    }
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
