/*
---
name: eventm-BoxB
namespace: eventm-
category: Block
id: M_BOX-5B
---

<span class="sw-Label">M_BOX-5B</span>

Products（Kitchen）のイベント一覧です。

```jade
.eventm-BoxB_Item
  .eventm-BoxB_Image
    .eventm-BoxB_ImageThumbnail
      img(src="/assets/img/common/img_690_296.jpg" alt="")
  .eventm-BoxB_Body
    p.eventm-BoxB_Text February 8 - 12, 2019
    p.eventm-BoxB_Text Frankfurt Fair<br>Hall 3.1, Booth # A40</p>
    p.eventm-BoxB_Text
      a.eventm-BoxB_Link(href="https://ambiente.messefrankfurt.com/frankfurt/en.html")
        | https://ambiente.messefrankfurt.com/frankfurt/en.html
```

続けると罫線を表示します。

```jade
.eventm-BoxB_Item
  .eventm-BoxB_Image
    .eventm-BoxB_ImageThumbnail
      img(src="/assets/img/common/img_690_296.jpg" alt="")
  .eventm-BoxB_Body
    p.eventm-BoxB_Text February 8 - 12, 2019
    p.eventm-BoxB_Text Frankfurt Fair<br>Hall 3.1, Booth # A40</p>
    p.eventm-BoxB_Text
      a.eventm-BoxB_Link(href="https://ambiente.messefrankfurt.com/frankfurt/en.html")
        | https://ambiente.messefrankfurt.com/frankfurt/en.html
.eventm-BoxB_Item
  .eventm-BoxB_Image
    .eventm-BoxB_ImageThumbnail
      img(src="/assets/img/common/img_690_296.jpg" alt="")
  .eventm-BoxB_Body
    p.eventm-BoxB_Text February 8 - 12, 2019
    p.eventm-BoxB_Text Frankfurt Fair<br>Hall 3.1, Booth # A40</p>
    p.eventm-BoxB_Text
      a.eventm-BoxB_Link(href="https://ambiente.messefrankfurt.com/frankfurt/en.html")
        | https://ambiente.messefrankfurt.com/frankfurt/en.html
```
*/

.eventm-BoxB_Item {
  margin-top: $margin-top-base;
  margin-bottom: $margin-bottom-base;

  &::after {
    content: "";
    clear: both;
    display: block;
  }

  & + .eventm-BoxB_Item::before {
    content: "";
    display: block;
    margin-top: rem(30);
    padding-bottom: rem(30);
    border-top: 1px solid #d8d8d8;

    @include mq-up(md) {
      margin-top: rem(40);
      padding-bottom: rem(40);
    }
  }
}

.eventm-BoxB_Body {
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.eventm-BoxB_Image {
  width: percentage(5 / 12);
//  padding-bottom: rem(15);
  text-align: center;

  @include mq-up(md) {
//    padding-bottom: rem(30);
  }

    float: right;
    padding-left: rem(15);

    @include mq-up(md) {
      padding-left: rem(30);
    }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.eventm-BoxB_ImageThumbnail {
  margin-top: rem(20);
  margin-bottom: rem(20);

  @include mq-up(md) {
    margin-top: rem(30);
    margin-bottom: rem(30);
  }

  .cms-Component_Block & > img {
    max-width: 100%;
  }
}

.eventm-BoxB_Text {
  margin-top: rem(20);
  margin-bottom: rem(20);
  font-size: rem(16);
  line-height: 1.6;
  color: $color-gray-minus2;
}

.eventm-BoxB_Link {
  text-decoration: underline;
  color: $color-red;

  &:hover {
    text-decoration: none;
  }

  /* CMS対応 */
  .news-Body & {
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }

  .l-DetailTemplate & {
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }
}
