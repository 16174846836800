/*
---
name: sw-LinkInstagram
namespace: sw-
category: Link
id: LINK-12
---

<span class="sw-Label">LINK-12</span>

公式Instagramへのリンクです。レイアウトは<a href="/styleguide/category/Global/index.html#st-RelatedB">st-RelatedB</a>を参照してください。


```jade
a.sw-LinkInstagram(href="#")
  .sw-LinkInstagram_Icon(aria-hidden="true")
  span.sw-LinkInstagram_Text Follow us Instagram
```
*/
.sw-LinkInstagram {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: rem(14);
  line-height: 1.3;
  color: $color-gray-minus2;
  transition-duration: $transition-duration;

  &:hover {
    text-decoration: underline;
    color: $color-red;
  }
}

.sw-LinkInstagram_Icon {
  width: rem(36);
  height: rem(26);
  font-size: rem(26);
  color: #e4405f;

  &::before {
    @include icon;

    content: "#{$icon-instagram}";
    position: relative;
    top: -0.24em;
  }
}

.sw-LinkInstagram_Text {
  flex-basis: 0;
  flex-grow: 1;
}
